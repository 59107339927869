import { Avatar, AvatarGroup, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext } from "react";
import { EventSession } from "../../interfaces";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import _ from "lodash";
import { ActionsDropdown } from "../../EventBudget/EventBudgetDataGrid";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddSession from "../../../../components/Events/Agenda/AddSession";
import { CONTENT } from "../../../../scripts/i18n";
import { EventStageContext } from "../../../../contexts/EventStage/EventStageContext";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { createColumnHelper } from "@tanstack/react-table";
import CustomTooltip from "../../../../common/Tooltip";

const StageDataGrid = (props) =>
{
    const { rows, setRefresh, stageData, eventId } = props;

    const { deleteSessionFromTable } = useContext(EventStageContext);

    const columnHelper = createColumnHelper<EventSession>();
    const tanstackColumns = [
        columnHelper.accessor('title', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: '',
            size: 300,
            id: 'name',
        }),
        columnHelper.accessor('startDateTime', {
            cell: (row) => {
                return sessionTiming(row.row.original);
            },
            header: '',
            size: 250,
            id: 'timing',
        }),
        columnHelper.accessor('speakerIds', {
            cell: (row) => {
                return sessionSpeakers(row.row.original);
            },
            header: '',
            size: 250,
            id: 'speakers',
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return sessionType(row.row.original);
            },
            header: '',
            minSize: 250,
            id: 'type',
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return sessionActions(row.row.original);
            },
            header: '',
            size: 40,
            id: 'actions',
        })
    ]
    
    const sessionTiming = (row: EventSession): React.ReactElement => 
    {
        const startDateTime = moment.unix(Number(row.startDateTime)).format('hh:mm A');
        const endDateTime = moment.unix(Number(row.endDateTime)).format('hh:mm A');
        return(
            <Typography className="cellContent">{startDateTime} - {endDateTime}</Typography>
        )
    };

    const sessionSpeakers = (row: EventSession): React.ReactElement =>
    {        
        const speakerImage = row?.speakerIds && row?.speakerIds?.length > 0 ? row.speakerIds.map((item) => 
        {
            const ids = _.find(row?.speakers, ['id', String(item)]);
            if (ids) 
            {
                return ids?.additionalInfo?.image;
            }
        }) : [];

        const speakerName = row?.speakerIds && row?.speakerIds?.length > 0 ? row.speakerIds.map((item) => 
        {
            const ids = _.find(row?.speakers, ['id', String(item)]);
            if (ids) 
            {
                return ids?.name;
            }
        }) : [];
        
        return (
            <AvatarGroup max={2} total={row?.speakerIds?.length}>
                {speakerImage?.length > 0 && speakerImage.map((item, index) => 
                    {
                        return (
                            <CustomTooltip title={<Typography fontSize={'14px'}>{speakerName[index]}</Typography>}>
                                <Avatar key={index} alt="speaker image" src={item} style={{ width: 24, height: 24 }}>
                                    {item ? null : speakerName[index]?.charAt(0)}
                                </Avatar>
                            </CustomTooltip>
                        )
                    }
                )}
            </AvatarGroup>
        )
    };

    const sessionType = (row: EventSession): React.ReactElement =>
    {
        const sessionTypes = {
            1: 'Session',
            2: 'Keynote',
            3: 'Break',
        };
    
        const sessionTypeClass = {
            1: 'blue',
            2: 'violet',
            3: 'grey'
        };
        return (
            <MuiChip circleIcon label={sessionTypes[row?.type as keyof typeof sessionTypes]} chipColor={sessionTypeClass[row?.type as unknown as keyof typeof sessionTypeClass] as 'blue' | 'violet' | 'grey'} />
        )
    };

    const sessionActions = (row: EventSession): React.ReactElement =>
    {
        const editClick = (): void => 
        {
            handleEdit(row);
        };

        const deleteClick = (): void => 
        {
            handleDelete(row);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleEdit = (row: EventSession): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit Session',
            component: <AddSession eventId={eventId} sessionData={row} setRefresh={setRefresh} stageId={stageData?.id} stageData={stageData} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const handleDelete = (row: EventSession): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: 'Delete Session ?',
            content: `Are you sure you want to delete ${row.title} ?`,
            acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
            acceptEvent: 'delete-event-session',
            rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
            acceptObj: {
                id: row.id
            },
        });

        deleteSessionFromTable();
    };

    return (
        <div id="stageDataGrid" style= {{ flex:1, height: '100%' }}>
            <TanstackTable 
                data={rows}
                rowCount={rows.length}
                showSpinner={false}
                hidePagination={true}
                fullWidth
                initialColumns={tanstackColumns}
                onRowClick={(row) => handleEdit(row)}
                hideTableHeader={true}
                rightPinnedColumns={['actions']}
            />
        </div>
    )
};

export default StageDataGrid;