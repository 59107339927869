import React from 'react';
import './styles.scss';

interface LandscapeTypeProps {
    selected:boolean;
}

const LandscapeType  = ({ selected }: LandscapeTypeProps):React.JSX.Element => 
{
    return (
        <div id="landscapeType">
            <div className="event-banner-box">
                <div className={selected ? 'selected-event-banner-image':'event-banner-image'}>
                    <div className="dimension-text">{'Poster 16:9'}</div>
                </div>
            </div>
            <div className="content-box">
                <div className="content-text">{'Your title will be here'}</div>
                <div className="register-btn-box">{'Register'}</div>
            </div>
        </div>
    );
};

export default LandscapeType;