import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import { resetBuyerPersona } from '../../../redux/settings/BuyerPersona';
import { useDispatch } from 'react-redux';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import { BuyerPersonaPageContext } from '../../../contexts/Settings/BuyerPersonaPageContext';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import _ from 'lodash';
import buyerPersonaEmptyImg from '../../../assets/icons/empty-state/ICPEmptyState.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { BuyerPersona as IBuyerPersona } from '../interface/buyer-persona_interface';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import DeletePopup from '../../../common/DeletePopup';
import HeaderBar from '../../../common/Headerbar';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import BuyerPersona from '../../../components/BuyerPersona/AddBuyerPersona';
import { UserContext } from '../../../contexts/UserContext';
import { UserViewType } from '../enum/users.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { MinimalViewComponent } from '../../../common/MinimalView/MinimalViewComponent';
import TanstackToolbarWithServerFilter from '../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColumnVisibilityDropdown from '../../../common/TanstackTable/ColumnVisibilityDropdown';
import CustomSpinner from '../../../common/CustomSpinner';

import './styles.scss';

const BuyerPersonaPage = ():React.JSX.Element => 
{
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        isEmpty, 
        rows,
        setRows, 
        buyerPersonaDataCount, 
        buyerPersona, 
        deleteBuyerPersonaFromTable, 
        setRefresh, 
        pageSize, 
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        showDeletePopup,
        setShowDeletePopup,
        selectedBuyerPersona,
        setSelectedBuyerPersona, 
        setCurrentUrl
    } = useContext(BuyerPersonaPageContext);

    const columnHelper = createColumnHelper<IBuyerPersona>();
    const columns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('role', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Role',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'role'
        }),
        columnHelper.accessor('subRole', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Sub Role',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'subRole'
        }),
        columnHelper.accessor('seniority', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Seniority',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'seniority'
        }),
        columnHelper.accessor('description', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Description',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'description'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return buyerPersonaActions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Role',
            id: 'role',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Sub Role',
            id: 'subRole',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Seniority',
            id: 'seniority',
            meta: {
                type: 'string'
            }
        }
    ];

    const openDrawer = ():void => 
    {
        
        dispatch(resetBuyerPersona());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.BUYER_PERSONA.SIDE_DRAWER.HEADING.CREATE,
            component: < BuyerPersona setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const buyerPersonaActions = (buyerPersonaInfo: IBuyerPersona):React.JSX.Element => 
    {

        const editClick = ():void => 
        {
            handleViewEdit(buyerPersonaInfo);
        };

        const deleteClick = ():void => 
        {
            setSelectedBuyerPersona(buyerPersonaInfo);
            setShowDeletePopup(true);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleViewEdit = (buyerPersonaInfo: IBuyerPersona):void => 
    {

        dispatch(resetBuyerPersona());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.BUYER_PERSONA.SIDE_DRAWER.HEADING.EDIT,
            component: <BuyerPersona buyerPersonaData={buyerPersonaInfo} is-editable={true} setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };
    
    const renderNormalViewForBuyerPersona = ():React.JSX.Element => {
        return (
            <Box id="buyerPersonaPage">
                <HeaderBar 
                    title={'Buyer Persona (BP)'}
                    buttons={[
                        <CustomButton btnType="secondary" name="Create Buyer Persona" onClick={() => openDrawer()} />
                    ]}
                />
                <Box sx={{
                    flex: 1,
                    height: 'calc(100% - 64px)', 
                }}>
                    {isEmpty ? (<TableEmptyComponent emptyImg={buyerPersonaEmptyImg} openDrawer={openDrawer} infoText={'No Buyer Persona'} subInfoText={'Add your first Buyer Persona'} buttonName={'Add Buyer Persona'} />) :
                        (
                            <div className="h-100">
                                <TanstackToolbarWithServerFilter 
                                    columns={columns}
                                    setColumns={setAllColumns}
                                    tabs={[
                                        {
                                            tabName: 'All',
                                            count: buyerPersonaDataCount as number,
                                            navigation: () => {},
                                            selectedTab: true
                                        }
                                    ]}
                                    filterColumns={filterColumns}
                                    handleApplyFilters={handleApplyFilters}
                                    setCurrentUrl={setCurrentUrl}
                                />
                                <TanstackTable 
                                    initialColumns={allColumns}
                                    data={rows}
                                    showSpinner={showSpinner}
                                    pageSize={pageSize}
                                    updatePageSize={updatePageSize}
                                    updateCurrentPage={updateCurrentPage as any}
                                    rowCount={buyerPersonaDataCount}
                                    showNoRowsOverlay={buyerPersonaDataCount==0}
                                    currentPage={currentPage - 1}
                                    rightPinnedColumns={['actions']}
                                    onRowClick={(row) => handleViewEdit(row)}
                                    height='calc(100% - 124px)'
                                />
                            </div>
                        )}
                </Box>
            </Box>
        );
    };

    const renderMinimalViewForBp = (): React.JSX.Element => {
        return (
            <div style={{ height: 'calc(100% - 16px)' }} id="buyerPersonaPage">
                {
                    showSpinner ? <CustomSpinner height="100%" /> :
                    <MinimalViewComponent
                        title="Buyer Persona"
                        description="Manage your Buyer Persona here."
                        tabData={[
                            {
                                tabName: 'All',
                                count: buyerPersonaDataCount,
                                navigation: () => { },
                                selectedTab: true
                            }
                        ]}
                        actions={[
                            <TanstackToolbarWithServerFilter
                                key={1}
                                columns={columns}
                                filterColumns={filterColumns}
                                handleApplyFilters={handleApplyFilters}
                                setCurrentUrl={setCurrentUrl}
                                minimalView={true}
                            />,
                            <ColumnVisibilityDropdown 
                                key={2}
                                columns={columns}
                                setColumns={setAllColumns}
                                showIconButton={true}
                            />,
                            <CustomButton
                                key={3}
                                name={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                btnType='primary'
                                onClick={() => {
                                    openDrawer();
                                }}
                            />
                        ]}
                        component={
                            <div className="minimalView-table-container">
                                {
                                    isEmpty ?
                                        <div style={{ height: 'calc(100vh - 348px)' }}>
                                            <TableEmptyComponent
                                                emptyImg={''}
                                                infoText={'No ICP'}
                                            />
                                        </div>
                                        :
                                        <TanstackTable
                                            data={buyerPersona}
                                            initialColumns={allColumns}
                                            rowCount={buyerPersonaDataCount}
                                            showNoRowsOverlay={buyerPersonaDataCount==0}
                                            pageSize={pageSize}
                                            currentPage={currentPage - 1}
                                            updateCurrentPage={updateCurrentPage as any}
                                            updatePageSize={updatePageSize}
                                            showSpinner={false}
                                            height={'calc(100vh - 348px)'}
                                            rightPinnedColumns={['actions']}
                                            onRowClick={(row) => {
                                                handleViewEdit(row)
                                            }}
                                        />
                                }

                            </div>
                        }
                        componentType='table'
                    />
                }
                
            </div>
        );
    };

    return (
        <>

            {
                userDetails?.viewType === UserViewType.NORMAL ? renderNormalViewForBuyerPersona() : renderMinimalViewForBp()
            }

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteBuyerPersonaFromTable(selectedBuyerPersona as IBuyerPersona)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedBuyerPersona(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedBuyerPersona?.name}?`}
                        modalTitle='Delete Buyer Persona'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }

        </>
    );
};
export default BuyerPersonaPage;