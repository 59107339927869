import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const EditorFilePond = ({ onChange}: { onChange: (file: any) => void }) => 
{
  return (
    <div style={{ minWidth: '500px', minHeight: '200px' }}>
      <FilePond
        name="files"
        onupdatefiles={(files) => { onChange(files[0]?.file) }}
        allowMultiple={false}
        maxFileSize={'25MB'}
        labelMaxFileSize="Maximum file size is 25MB"
        acceptedFileTypes={['image/png', 'image/jpeg']}
        labelFileTypeNotAllowed="Invalid file type"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}

export default EditorFilePond;
