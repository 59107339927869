import { useEffect, useRef, useState } from 'react';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
// import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { useSharedHistoryContext } from './context/SharedHistoryContext';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { CAN_USE_DOM } from './shared/src/canUseDOM';
import { useSettings } from './context/SettingsContext';
import { EditorState } from 'lexical';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import TableCellResizer from './plugins/TableCellResizer';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import EquationsPlugin from './plugins/EquationsPlugin';
import PageBreakPlugin from './plugins/PageBreakPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
// import TreeViewPlugin from './plugins/TreeViewPlugin';
import ToolbarPlugin, { ToolbarOptions } from './plugins/ToolbarPlugin';
import TwitterPlugin from './plugins/TwitterPlugin';
import ActionsPlugin from './plugins/ActionsPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import ContentEditable from './ui/ContentEditable';
import EmojisPlugin from './plugins/EmojisPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import PollPlugin from './plugins/PollPlugin';
import Placeholder from './ui/Placeholder';

import {$generateHtmlFromNodes} from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SlashCommandOptions } from '../interfaces/lexical_interface';
// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

// @ts-expect-error
const skipCollaborationInit = window.parent != null && window.parent.frames.right === window;

export default function Editor({ value, placeHolder, handleChange, minHeight, slashCommands, maxHeight, toolBarOptions, noDrag }: {value: string, handleChange: (html: string, json: any) => void, minHeight?: string, placeHolder?: string, slashCommands?: SlashCommandOptions[], maxHeight?: string, toolBarOptions?: ToolbarOptions[], noDrag?: boolean}): JSX.Element {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const { historyState } = useSharedHistoryContext();
  const isEditable = useLexicalEditable();
  const [editor] = useLexicalComposerContext();
  const {
    settings: {
      showTreeView,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings();
  const text = 'Write something';
  const placeholder = <Placeholder>{text}</Placeholder>;

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const editorRef = useRef(true);

  useEffect(() => {
    if(editorRef.current)
    {
      if(value)
      {
        const initialEditorState = editor.parseEditorState(value);
        Promise.resolve().then(() => { 
          editor.setEditorState(initialEditorState);
        });
        editorRef.current = false;
      }
    }
  }, [value, editor]);

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);




  // let status = true;
  // useEffect(() => {
  //   if (status) {
  //     const singleQuoteHtmlString = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"background-color: #d0021b;","text":"Akash","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"textFormat":0}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
  //     if (singleQuoteHtmlString) {
  //       const initialEditorState = editor.parseEditorState(singleQuoteHtmlString)
  //       editor.setEditorState(initialEditorState)
  //     }
  //     status = false;
  //   }
  // }, [])

  // const handleChange = (editorState: EditorState, editor) => {
  //   // console.log(JSON.stringify(editorState.toJSON()));

  //   const htmlString = $generateHtmlFromNodes(editor, null);

  //   console.log(htmlString, 'htmlString');
    
  //   // const singleQuoteHtmlString = htmlString.replace(/"/g, "'");
  //   // console.log(htmlString);
  // };

  const handleEditorChange = (editorState: EditorState) => { 
    
      editor.registerUpdateListener(({ editorState }) =>
      {
          editorState.read(() => {
              const htmlString = $generateHtmlFromNodes(editor);
              handleChange(htmlString, JSON.stringify(editorState.toJSON()));
          });
      })
  };

  return (
    <>
      {toolBarOptions && toolBarOptions?.length > 0 && <ToolbarPlugin options={toolBarOptions} setIsLinkEditMode={setIsLinkEditMode} />}
      <div style={{ minHeight: minHeight || '150px' }} className={`editor-container ${showTreeView ? 'tree-view' : ''}`}>
        <DragDropPaste />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        {slashCommands && slashCommands?.length > 0 && <ComponentPickerPlugin dynamicOptions={slashCommands as SlashCommandOptions[]} />}
        <EmojiPickerPlugin />
        <AutoEmbedPlugin />
        <MentionsPlugin />
        <EmojisPlugin />
        <HashtagPlugin />
        <KeywordsPlugin />
        <AutoLinkPlugin />
        <HistoryPlugin externalHistoryState={historyState} />
        <RichTextPlugin contentEditable={
          <div style={{ minHeight: minHeight || '150px', maxHeight: maxHeight || 'initial' }} className="editor-scroller">
            <div style={{ minHeight: minHeight || '150px', maxHeight: maxHeight || 'initial' }} className="editor" ref={onRef}>
              <ContentEditable padding={noDrag ? '8px 16px' : undefined} minHeight={minHeight} maxHeight={maxHeight} />
            </div>
          </div>
        }
          placeholder={placeHolder ? <Placeholder left={noDrag ? '16px' : ''}>{placeHolder}</Placeholder> : <Placeholder left={noDrag ? '16px' : ''}>{'Write something'}</Placeholder>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <MarkdownShortcutPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        {/* <MaxLengthPlugin /> */}
        <TablePlugin
          hasCellMerge={tableCellMerge}
          hasCellBackgroundColor={tableCellBackgroundColor}
        />
        <TableCellResizer />
        <ImagesPlugin />
        <InlineImagePlugin />
        <LinkPlugin />
        <PollPlugin />
        <TwitterPlugin />
        <YouTubePlugin />
        <ClickableLinkPlugin disabled={isEditable} />
        <HorizontalRulePlugin />
        <EquationsPlugin />
        <TabFocusPlugin />
        <TabIndentationPlugin />
        <CollapsiblePlugin />
        <PageBreakPlugin />
        <LayoutPlugin />
        {floatingAnchorElem && !isSmallWidthViewport && (
          <>
            {!noDrag && <DraggableBlockPlugin anchorElem={floatingAnchorElem} />}
            <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
            <TableCellActionMenuPlugin
              anchorElem={floatingAnchorElem}
              cellMerge={true}
            />
            {slashCommands && <FloatingTextFormatToolbarPlugin
              anchorElem={floatingAnchorElem}
              setIsLinkEditMode={setIsLinkEditMode}
            />}
          </>
        )}
        <OnChangePlugin onChange={handleEditorChange} />
        {/* <ActionsPlugin /> */}
      </div>
      {/* {showTreeView && <TreeViewPlugin />} */}
    </>
  );
}
