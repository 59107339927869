import { createSlice } from '@reduxjs/toolkit';

export const speakersRowSlice = createSlice({
    name: 'speakers',
    initialState: {
        value: {
            name: '',
            email: '',
            additionalInfo: {
                headline: '',
                bio: '',
                image: '',
                social: {
                    twitter: '',
                    linkedIn: '',
                }
            }
        }
    },
    reducers: {
        addSpeakers: (state, action):void => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetSpeakers: (state):void => 
        {
            state.value = {
                name: '',
                email: '',
                additionalInfo: {
                    headline: '',
                    bio: '',
                    image: '',
                    social: {
                        twitter: '',
                        linkedIn: '',
                    }
                }
            };
        }
    },
});

export const { addSpeakers, resetSpeakers } = speakersRowSlice.actions;
export default speakersRowSlice.reducer;