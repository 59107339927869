import React, { useState } from 'react';
import { Chip, Popover, Stack, Tooltip } from '@mui/material';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APP_CONSTANTS from '../../scripts/constants';
import CustomTooltip from '../Tooltip';

interface IDropdownchipOptions {
    name: string;
    value: string | number;
}

interface DropdownChipProps {
    label: string;
    options?: IDropdownchipOptions[];
    onChange?: (selected: IDropdownchipOptions) => void;
    chipColor?: 'blue' | 'green' | 'grey' | 'yellow' | 'red' | 'pink' | 'violet' | 'orange' | 'null';
    disabled?: boolean;
    disabledLabel?: string;
    onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DropdownChip: React.FC<DropdownChipProps> = ({ label, options, onChange, onClick, chipColor = 'grey', disabled, disabledLabel }) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleOpenDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => 
    {
        event.stopPropagation();
        if (disabled) return;
        // setAnchorEl(event.currentTarget);
    };

    const handleCloseDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => 
    {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const chipClasses = {
        blue: 'chip-blue',
        green: 'chip-green',
        grey: 'chip-grey',
        yellow: 'chip-yellow',
        red: 'chip-red',
        pink: 'chip-pink',
        violet: 'chip-violet',
        null: 'chip-null',
        orange: 'chip-orange'
    };

    const handleOptionClick = (event, option: IDropdownchipOptions) => {
        onChange(option);
        handleCloseDropdown(event);
    };

    const chipLabel = ( 
        <Stack direction="row" gap={'4px'} alignItems={'center'}>
            {label}
            {!disabled && <FontAwesomeIcon icon={['fal', 'chevron-down']} />}
        </Stack>
    )

    return (
        <div id="dropdownChipComponent">
            <CustomTooltip title={disabled ? disabledLabel : ''}>
                <Chip label={chipLabel} className={`chip-component ${chipClasses[chipColor]}`} onClick={(event) => {
                    event.stopPropagation();
                    if (disabled) return;
                    onClick && onClick();
                    // handleOpenDropdown;
                }} />
            </CustomTooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div id="dropDownBlock">
                    {
                        options && options?.length > 0 && options?.map((option) => {
                            return (
                                <div key={option.name} onClick={(event) => handleOptionClick(event, option)} className="dropDownMenu">
                                    {option.name}
                                </div>
                            )
                        })
                    }
                </div>
            </Popover>
        </div>
    );
};

export default DropdownChip;