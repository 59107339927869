import { HubspotSyncDetails } from "../../pages/Settings/interface/hubspot_interface";
import APP_CONSTANTS from "../constants";
import { triggerApi } from "../helpers";

export const getAllHubspotProperties = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/all-hubspot-properties';
    return triggerApi(API_URL, 'GET', '');
}

export const getHubspotIntegrationProperties = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties';
    return triggerApi(API_URL, 'GET', '');
}

export const postHubspotIntegrationProperties = async (requestBody: any, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties';
    return triggerApi(API_URL, 'POST', requestBody, csrfToken);
}

export const deleteHubspotIntegrationProperties = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties';
    return triggerApi(API_URL, 'DELETE', '');
}

export const searchHubspotProperties = async (name: string) => { 
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties/' + name;
    return triggerApi(API_URL, 'GET', '');
}

export const mapHubspotPropertiesWithCustomFields = async (data: any, csrfTokenData: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties/map';
    return triggerApi(API_URL, 'POST', data, csrfTokenData);
}

export const getHubspotSyncDetails = async (): Promise<HubspotSyncDetails> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/sync-details';
    return triggerApi(API_URL, 'GET', '');
}