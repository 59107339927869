/* eslint-disable no-prototype-builtins */
/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { AnalyticsGraphContainer, AnalyticsGraphsBlock, toTitleCase } from './BudgetAnalytics';
import TrendsComponent from '../../common/TrendsComponent';
import { getConversionAnalytics, getRoiAnalyticsGraphData, getRoiTableData } from '../../scripts/apis/roi';
import { NumberFormatter } from '../../scripts/helpers';
import { CONTENT } from '../../scripts/i18n';
import CustomSpinner from '../../common/CustomSpinner';
import PopUpView from '../../common/PopupView';
import Charts from '../../common/ECharts/Charts';
import APP_CONSTANTS from '../../scripts/constants';
import _ from 'lodash';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import MuiChip from '../../common/FormComponents/MuiChip';

const RoiAnalytics = () => 
{

    // const conversionInnerFunnelColors = ['#FFDAC2', '#CBF5E5', '#CAC2FF', '#C2EFFF'];

    const [graphData, setGraphData] = useState<{
        audienceFunnelData?: any,
        avgRoiByEventTypeData?: any,
        roiRangeByEventTypeData?: any,
        pipelineGeneratedByEventTypeData?: any,
        revenueGeneratedByEventTypeData?: any,
        pipelineByEventLocationData?: any,
        revenueByEventLocationData?: any
    }>({
        audienceFunnelData: [],
        avgRoiByEventTypeData: [],
        roiRangeByEventTypeData: [],
        pipelineGeneratedByEventTypeData: [],
        revenueGeneratedByEventTypeData: [],
        pipelineByEventLocationData: [],
        revenueByEventLocationData: []
    });

    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        audienceFunnelGraph: false,
        avgRoiByEventTypeGraph: false,
        roiRangeByEventTypeGraph: false,
        pipelineGeneratedByEventTypeGraph: false,
        revenueGeneratedByEventTypeGraph: false,
        pipelineByEventLocationGraph: false,
        revenueByEventLocationGraph: false
    });

    const [isChartReady, setChartReady] = useState<{
        audienceFunnelGraphReady?: boolean,
        avgRoiByEventTypeGraphReady?: boolean,
        roiRangeByEventTypeGraphReady?: boolean,
        pipelineGeneratedByEventTypeGraphReady?: boolean,
        revenueGeneratedByEventTypeGraphReady?: boolean,
        pipelineByEventLocationGraphReady?: boolean,
        revenueByEventLocationGraphReady?: boolean
    }>({
        audienceFunnelGraphReady: false,
        avgRoiByEventTypeGraphReady: false,
        roiRangeByEventTypeGraphReady: false,
        pipelineGeneratedByEventTypeGraphReady: false,
        revenueGeneratedByEventTypeGraphReady: false,
        pipelineByEventLocationGraphReady: false,
        revenueByEventLocationGraphReady: false
    });

    const [trendsData, setTrendsData] = useState<{
        pipelineGenerated?: number,
        onsitePipeline?: number,
        virtualPipeline?: number,
        hybridPipeline?: number,
        revenueGenerated?: number,
        onsiteRevenue?: number,
        virtualRevenue?: number,
        hybridRevenue?: number,
    }>({
        pipelineGenerated: 0,
        onsitePipeline: 0,
        virtualPipeline: 0,
        hybridPipeline: 0,
        revenueGenerated: 0,
        onsiteRevenue: 0,
        virtualRevenue: 0,
        hybridRevenue: 0,
    });

    const [tableData, setTableData] = useState<{
        topPerformingEventsTable?: any,
        lowestPerformingEventsTable?: any
    }>({
        topPerformingEventsTable: [],
        lowestPerformingEventsTable: []
    });

    const [tableReady, setTableReady] = useState<{
        topPerformingEventsTable?: boolean,
        lowestPerformingEventsTable?: boolean
    }>({
        topPerformingEventsTable: false,
        lowestPerformingEventsTable: false
    });

    const fetchRoiGraphData = async (): Promise<void> => 
    {
        try 
        {
            const graphData = await getRoiAnalyticsGraphData();

            if (graphData) 
            {
                const pipeLineGenerated = [];
                const revenueGenerated = [];
                if (graphData.type) 
                {
                    const avgRoi = Object.keys(graphData.type).map((item) => 
                    {
                        return {
                            value: graphData.type[item].avgRoi, name: toTitleCase(item)
                        };
                    });
                    setGraphData((prev) => 
                    {
                        return {
                            ...prev, avgRoiByEventTypeData: avgRoi
                        };
                    });

                    const pipelineGenerated = Object.keys(graphData.type).map((item) => 
                    {
                        return {
                            value: graphData.type[item].pipeline, name: toTitleCase(item)
                        };
                    });
                    setGraphData((prev) => 
                    {
                        return {
                            ...prev, pipelineGeneratedByEventTypeData: pipelineGenerated
                        };
                    });

                    const revenueGenerated = Object.keys(graphData.type).map((item) => 
                    {
                        return {
                            value: graphData.type[item].revenue, name: toTitleCase(item)
                        };
                    });
                    setGraphData((prev) => 
                    {
                        return {
                            ...prev, revenueGeneratedByEventTypeData: revenueGenerated
                        };
                    });
                }
                if (graphData.location) 
                {
                    const sum = _.sumBy(Object.values(graphData?.location), function (o: {
                        count?: number;
                        revenue?: number;
                        pipeline?: number;
                        avgRoi?: number;
                    }): number 
                    {
                        return o?.pipeline;
                    });
                    const pipelineByLocation = Object.keys(graphData.location).map((item) => 
                    {
                        return {
                            value: graphData.location[item].pipeline, name: toTitleCase(item), percentage: Math.round((Number(graphData.location[item].pipeline) / sum) * 100)
                        };
                    });
                    setGraphData((prev) => 
                    {
                        return {
                            ...prev, pipelineByEventLocationData: pipelineByLocation
                        };
                    });

                    const revenueByLocation = Object.keys(graphData.location).map((item) => 
                    {
                        const sum = _.sumBy(Object.values(graphData.location), function (o: {
                            count?: number;
                            revenue?: number;
                            pipeline?: number;
                            avgRoi?: number;
                        }): number 
                        {
                            return o?.revenue;
                        });
                        return {
                            value: graphData.location[item].revenue, name: toTitleCase(item), percentage: Math.round((Number(graphData.location[item].revenue) / sum) * 100)
                        };
                    });
                    setGraphData((prev) => 
                    {
                        return {
                            ...prev, revenueByEventLocationData: revenueByLocation
                        };
                    });

                    if (graphData.location.ONSITE) 
                    {
                        setTrendsData((prev) => 
                        {
                            return {
                                ...prev, onsitePipeline: graphData.location.ONSITE.pipeline, onsiteRevenue: graphData.location.ONSITE.revenue
                            };
                        });
                        pipeLineGenerated.push(graphData.location.ONSITE.pipeline);
                        revenueGenerated.push(graphData.location.ONSITE.revenue);
                    }

                    if (graphData.location['VIRTUAL']) 
                    {
                        setTrendsData((prev) => 
                        {
                            return {
                                ...prev, virtualPipeline: graphData.location['VIRTUAL'].pipeline, virtualRevenue: graphData.location['VIRTUAL'].revenue
                            };
                        });
                        pipeLineGenerated.push(graphData.location['VIRTUAL'].pipeline);
                        revenueGenerated.push(graphData.location['VIRTUAL'].revenue);
                    }

                    if (graphData.location['HYBRID']) 
                    {
                        setTrendsData((prev) => 
                        {
                            return {
                                ...prev, hybridPipeline: graphData.location['HYBRID'].pipeline, hybridRevenue: graphData.location['HYBRID'].revenue
                            };
                        });
                        pipeLineGenerated.push(graphData.location['HYBRID'].pipeline);
                        revenueGenerated.push(graphData.location['HYBRID'].revenue);
                    }
                }
                if (graphData.typeRoiRange) 
                {
                    const roiRange = Object.keys(graphData.typeRoiRange).map((item) => 
                    {
                        return {
                            value: graphData.typeRoiRange[item], name: toTitleCase(item)
                        };
                    });
                    setGraphData((prev) => 
                    {
                        return {
                            ...prev, roiRangeByEventTypeData: roiRange
                        };
                    });
                }

                if (pipeLineGenerated.length > 0) 
                {
                    const pipelineGeneratedSum = pipeLineGenerated.reduce((acc, curr) => 
                    {
                        return acc + curr;
                    }, 0);
                    setTrendsData((prev) => 
                    {
                        return {
                            ...prev, pipelineGenerated: pipelineGeneratedSum
                        };
                    });

                }

                if (revenueGenerated.length > 0) 
                {
                    const revenueGeneratedSum = revenueGenerated.reduce((acc, curr) => 
                    {
                        return acc + curr;
                    }, 0);
                    setTrendsData((prev) => 
                    {
                        return {
                            ...prev, revenueGenerated: revenueGeneratedSum
                        };
                    });
                }

            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally 
        {
            setChartReady((prevState) => 
            {
                return {
                    ...prevState,
                    avgRoiByEventTypeGraphReady: true,
                    roiRangeByEventTypeGraphReady: true,
                    pipelineGeneratedByEventTypeGraphReady: true,
                    revenueGeneratedByEventTypeGraphReady: true,
                    pipelineByEventLocationGraphReady: true,
                    revenueByEventLocationGraphReady: true
                };
            });
        }
    };

    const fetchRoiFunnelData = async (): Promise<void> => 
    {
        try 
        {
            const funnelData = await getConversionAnalytics('1');

            if (funnelData) 
            {
                const conversionActualFunnelArr = [];
                let conversionActualFunnelIndex = 0;
                for (const key in funnelData?.funnel?.['actual']) 
                {
                    if (funnelData.funnel?.['actual'].hasOwnProperty(key)) 
                    {
                        const icpLabel = (conversionActualFunnelIndex === 1) ? key.toUpperCase() : key;
                        conversionActualFunnelArr.push({
                            name: icpLabel.charAt(0).toUpperCase() + icpLabel.slice(1),
                            value: funnelData.funnel['actual'][key]
                        });
                        conversionActualFunnelIndex++;
                    }
                }
                setGraphData((prev) => 
                {
                    return {
                        ...prev,
                        audienceFunnelData: conversionActualFunnelArr
                    };
                });

            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally 
        {
            setChartReady((prevState) => 
            {
                return {
                    ...prevState,
                    audienceFunnelGraphReady: true
                };
            });
        }

    };

    const fetchRoiTableData = async (): Promise<void> => 
    {
        try 
        {
            const roiTableData = await getRoiTableData();

            if (roiTableData) 
            {
                if (roiTableData.topData) 
                {
                    setTableData((prev) => 
                    {
                        return {
                            ...prev, topPerformingEventsTable: roiTableData.topData
                        };
                    });
                }
                if (roiTableData.bottomData) 
                {
                    setTableData((prev) => 
                    {
                        return {
                            ...prev, lowestPerformingEventsTable: roiTableData.bottomData
                        };
                    });
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally 
        {
            setTableReady((prev) => 
            {
                return {
                    ...prev,
                    topPerformingEventsTable: true,
                    lowestPerformingEventsTable: true
                };
            });
        }
    };

    useEffect((): void => 
    {
        fetchRoiGraphData();
        fetchRoiFunnelData();
        fetchRoiTableData();
    }, []);

    const funnelGraphic = graphData.audienceFunnelData.map((item, index) => 
    {
        return [
            {
                type: 'text',
                left: '80%',
                top: `${15 + index * 20}%`,
                style: {
                    text: item.name,
                    fill: '#53575E',
                    fontSize: '12px',
                    fontWeight: 400,
                    textAlign: 'right',
                },
            },
            {
                type: 'text',
                left: '80%',
                top: `${20 + index * 20}%`,
                style: {
                    text: NumberFormatter(item.value.toString()),
                    fill: '#2A2E34',
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'right',
                },
            },
        ];
    });

    const conversionFunnelChartOption = {
        legend: {
            orient: 'horizontal',
            left: 'left',
            padding: [0, 0, 0, 8]
        },
        graphic: funnelGraphic.flat(),
        series: [
            // {
            //     name: 'Expected',
            //     type: 'funnel',
            //     left: '10%',
            //     width: '60%',
            //     // height: '85%',
            //     triggerEvent: false,
            //     label: {
            //         show: false,
            //         // formatter: '{b}',
            //         // color: '#000',
            //         // position: 'right',
            //     },
            //     trigger: {
            //         show: false
            //     },
            //     labelLine: {
            //         show: false
            //     },
            //     itemStyle: {
            //         opacity: 0.7,
            //         color: function (params) {
            //             return conversionOuterFunnelColors[params.dataIndex];
            //         },
            //     },
            //     axisPointer: {
            //         show: false
            //     },
            //     data: conversionExpectedFunnelData.map(item => ({
            //         value: item.value,
            //         name: item.name
            //     })),
            // },
            {
                name: 'Actual',
                type: 'funnel',
                left: '10%',
                width: '60%',
                triggerEvent: false,
                maxSize: '80%',
                label: {
                    show: false,
                },
                tooltip: {
                    trigger: 'none'
                },
                labelLine: {
                    show: false
                },
                itemStyle: {
                    opacity: 1,
                    borderColor: '#fff',
                    borderWidth: 2,
                    color: function (params): string 
                    {
                        return APP_CONSTANTS.GRAPH_COLORS.FUNNEL_CHART.DEFAULT[params.dataIndex];
                    },
                },
                data: graphData.audienceFunnelData.map((item) => 
                {
                    return {
                        value: item.value,
                        name: item.name
                    };
                }),
                z: 100
            }
        ]
    };

    const avgRoiByEventTypeChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            show: true,
            icon: 'circle',
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 4],
        },
        dataset: {
            source: graphData?.avgRoiByEventTypeData
        },
        xAxis: {
            nameLocation: 'middle',
            nameGap: 30,
            type: 'category',
            data: graphData?.avgRoiByEventTypeData?.length > 0 && graphData?.avgRoiByEventTypeData.map((item) => 
            {
                return item.name;
            }),
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.avgRoiByEventTypeData?.length > 0 && graphData?.avgRoiByEventTypeData.map((item) => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const pipelineGenerateByEventTypeChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            show: true,
            icon: 'circle',
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 4],
        },
        dataset: {
            source: graphData?.pipelineGeneratedByEventTypeData
        },
        xAxis: {
            nameLocation: 'middle',
            nameGap: 30,
            type: 'category',
            data: graphData?.pipelineGeneratedByEventTypeData?.length > 0 && graphData?.pipelineGeneratedByEventTypeData.map((item) => 
            {
                return item.name;
            }),
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.pipelineGeneratedByEventTypeData?.length > 0 && graphData?.pipelineGeneratedByEventTypeData.map((item) => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                        formatter: function (params) 
                        {
                            return `$${NumberFormatter(params?.value)}`;
                        },
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const revenueGeneratedByEventTypeChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            show: true,
            icon: 'circle',
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 4],
        },
        dataset: {
            source: graphData?.revenueGeneratedByEventTypeData,
        },
        xAxis: {
            nameLocation: 'middle',
            nameGap: 30,
            type: 'category',
            data: graphData?.revenueGeneratedByEventTypeData?.length > 0 && graphData?.revenueGeneratedByEventTypeData.map((item) => 
            {
                return item.name;
            }),
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.revenueGeneratedByEventTypeData?.length > 0 && graphData?.revenueGeneratedByEventTypeData.map((item) => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                        formatter: function (params): string 
                        {
                            return `$${NumberFormatter(params?.value)}`;
                        },
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const pipelineByEventLocationChartOption = constructObjectForPieChart(graphData?.pipelineByEventLocationData, ['50%','72%'], null, '$');

    const revenueByEventLocationChartOption = constructObjectForPieChart(graphData?.revenueByEventLocationData, ['50%','72%'], null, '$');

    const roiRangeChartOption = {
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            splitLine: {
                show: false
            },
            data: graphData?.roiRangeByEventTypeData?.map((item) => 
            {
                return item.name;
            }),
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                type: 'line',
                smooth: true,
                showSymbol: false,
                stack: 'Total',
                barWidth: 30,
                itemStyle: {
                    borderColor: 'transparent',
                    color: 'transparent'
                },
                emphasis: {
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent'
                    }
                },
                data: graphData?.roiRangeByEventTypeData?.map((item) => 
                {
                    return item.value[0];
                })
            },
            {
                type: 'line',
                smooth: true,
                showSymbol: false,
                stack: 'Total',
                color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0],
                barWidth: 30,
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
                data: graphData?.roiRangeByEventTypeData?.map((item) => 
                {
                    return item.value[1];
                })
            }
        ]
    };

    return (
        <Box id="roiAnalytics">
            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Audience Funnel"
                        chartOptions={conversionFunnelChartOption}
                        isGraphReady={isChartReady.audienceFunnelGraphReady}
                        isDataAvailable={graphData.audienceFunnelData.map((item) => 
                        {
                            return item.value;
                        }).reduce((acc, curr) => 
                        {
                            return acc + curr;
                        }, 0) !== 0}
                        chartHeight="40vh"
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView(
                                {
                                    audienceFunnelGraph: true,
                                    avgRoiByEventTypeGraph: false,
                                    roiRangeByEventTypeGraph: false,
                                    pipelineGeneratedByEventTypeGraph: false,
                                    revenueGeneratedByEventTypeGraph: false,
                                    pipelineByEventLocationGraph: false,
                                    revenueByEventLocationGraph: false
                                }
                            );
                        }}
                    />,
                    <AnalyticsGraphsBlock
                        elem={[
                            <AnalyticsGraphContainer
                                title="Avg ROI by Event Type"
                                chartOptions={avgRoiByEventTypeChartOptions}
                                isGraphReady={isChartReady.avgRoiByEventTypeGraphReady}
                                isDataAvailable={graphData.avgRoiByEventTypeData.map((item) => 
                                {
                                    return item.value;
                                }).reduce((acc, curr) => 
                                {
                                    return acc + curr;
                                }, 0) !== 0}
                                enlargeOnclick={(): void => 
                                {
                                    setOpenEnlargeGraphView(
                                        {
                                            audienceFunnelGraph: false,
                                            avgRoiByEventTypeGraph: true,
                                            roiRangeByEventTypeGraph: false,
                                            pipelineGeneratedByEventTypeGraph: false,
                                            revenueGeneratedByEventTypeGraph: false,
                                            pipelineByEventLocationGraph: false,
                                            revenueByEventLocationGraph: false
                                        }
                                    );
                                }}
                            />,

                            <AnalyticsGraphContainer
                                title="ROI range by Event Type"
                                chartOptions={roiRangeChartOption}
                                isGraphReady={isChartReady.roiRangeByEventTypeGraphReady}
                                isDataAvailable={graphData.roiRangeByEventTypeData.map((item) => 
                                {
                                    return item.value.length;
                                }).reduce((acc, curr) => 
                                {
                                    return acc + curr;
                                }, 0) !== 0}
                                enlargeOnclick={(): void => 
                                {
                                    setOpenEnlargeGraphView(
                                        {
                                            audienceFunnelGraph: false,
                                            avgRoiByEventTypeGraph: false,
                                            roiRangeByEventTypeGraph: true,
                                            pipelineGeneratedByEventTypeGraph: false,
                                            revenueGeneratedByEventTypeGraph: false,
                                            pipelineByEventLocationGraph: false,
                                            revenueByEventLocationGraph: false
                                        }
                                    );
                                }}
                            />
                        ]}
                        style={{
                            gridTemplateColumns: '1fr'
                        }}
                    />
                ]}
                style={{
                    gridTemplateColumns: '1fr 1fr',
                    padding: '16px'
                }}
            />

            <Box className="graph-heading-container">
                <Typography className="graph-heading">{'Pipeline'}</Typography>
            </Box>

            <TopPageAnalyticsContainer
                TrendsComponent={ <TrendsComponent
                    Trends={
                        [
                            {
                                title: 'Pipeline Generated',
                                value: `${Number(trendsData?.pipelineGenerated) !== 0 ? '$' + NumberFormatter(Number(trendsData.pipelineGenerated)) : '-'}`
                            },
                            {
                                title: 'In Person Pipeline',
                                value: `${Number(trendsData?.onsitePipeline) !== 0 ? '$' + NumberFormatter(trendsData.onsitePipeline ? Number(trendsData.onsitePipeline) : 0) : '-'}`
                            },
                            {
                                title: 'Online Pipeline',
                                value: `${Number(trendsData?.virtualPipeline) !== 0 ? '$' + NumberFormatter(trendsData.virtualPipeline ? Number(trendsData.virtualPipeline) : 0) : '-'}`
                            },
                            {
                                title: 'Hybrid Pipeline',
                                value: `${Number(trendsData?.hybridPipeline) !== 0 ? '$' + NumberFormatter(trendsData.hybridPipeline ? Number(trendsData.hybridPipeline) : 0) : '-'}`
                            }
                        ]
                    }
                />}
                GraphComponentsArray={
                    [
                        {
                            GraphComponent: <AnalyticsGraphContainer
                                title="Pipeline Generated by Event Type"
                                chartOptions={pipelineGenerateByEventTypeChartOptions}
                                isGraphReady={isChartReady.pipelineGeneratedByEventTypeGraphReady}
                                isDataAvailable={graphData.pipelineGeneratedByEventTypeData.map((item) => 
                                {
                                    return item.value;
                                }).reduce((acc, curr) => 
                                {
                                    return acc + curr;
                                }, 0) !== 0}
                                enlargeOnclick={(): void => 
                                {
                                    setOpenEnlargeGraphView(
                                        {
                                            audienceFunnelGraph: false,
                                            avgRoiByEventTypeGraph: false,
                                            roiRangeByEventTypeGraph: false,
                                            pipelineGeneratedByEventTypeGraph: true,
                                            revenueGeneratedByEventTypeGraph: false,
                                            pipelineByEventLocationGraph: false,
                                            revenueByEventLocationGraph: false
                                        }
                                    );
                                }}
                            />,
                            GraphSpacingBlockCSS:{
                                maxWidth: '50% !important'
                            }
                        },
                        {
                            GraphComponent:  <AnalyticsGraphContainer
                                title="Pipeline by Event Location"
                                chartOptions={pipelineByEventLocationChartOption}
                                isGraphReady={isChartReady.pipelineByEventLocationGraphReady}
                                isDataAvailable={graphData.pipelineByEventLocationData.map((item) => 
                                {
                                    return item.value; 
                                }).reduce((acc, curr) => 
                                {
                                    return acc + curr; 
                                }, 0) !== 0}
                                enlargeOnclick={(): void => 
                                {
                                    setOpenEnlargeGraphView(
                                        {
                                            audienceFunnelGraph: false,
                                            avgRoiByEventTypeGraph: false,
                                            roiRangeByEventTypeGraph: false,
                                            pipelineGeneratedByEventTypeGraph: false,
                                            revenueGeneratedByEventTypeGraph: false,
                                            pipelineByEventLocationGraph: true,
                                            revenueByEventLocationGraph: false
                                        }
                                    );
                                }}
                            />,
                            // GraphSpacingBlockCSS:{
                            //     maxWidth: '266px !important'
                            // }
                        }
                    ]
                }
                BlockStyling={{
                    padding: '16px !important'
                }}
                GraphComponentBlockStyle={{
                    justifyContent: 'flex-start !important',
                }}
            />

            <Box className="graph-heading-container">
                <Typography className="graph-heading">{'Revenue'}</Typography>
            </Box>

            <TopPageAnalyticsContainer
                TrendsComponent={<TrendsComponent
                    Trends={
                        [
                            {
                                title: 'Revenue Generated',
                                value: `${Number(trendsData?.revenueGenerated) !== 0 ? '$' + NumberFormatter(trendsData.revenueGenerated ? Number(trendsData.revenueGenerated) : 0) : '-'}`
                            },
                            {
                                title: 'In Person Revenue',
                                value: `${Number(trendsData?.onsiteRevenue) !== 0 ? '$' + NumberFormatter(trendsData.onsiteRevenue ? Number(trendsData.onsiteRevenue) : 0) : '-'}`
                            },
                            {
                                title: 'Online Revenue',
                                value: `${Number(trendsData?.virtualRevenue) !== 0 ? '$' + NumberFormatter(trendsData.virtualRevenue ? Number(trendsData.virtualRevenue) : 0) : '-'}`
                            },
                            {
                                title: 'Hybrid Revenue',
                                value: `${Number(trendsData?.hybridRevenue) !== 0 ? '$' + NumberFormatter(trendsData.hybridRevenue ? Number(trendsData.hybridRevenue) : 0) : '-'}`
                            }
                        ]
                    }
                />}
                GraphComponentsArray={
                    [
                        {
                            GraphComponent:  <AnalyticsGraphContainer
                                title="Revenue Generated by Event Type"
                                chartOptions={revenueGeneratedByEventTypeChartOptions}
                                isGraphReady={isChartReady.revenueGeneratedByEventTypeGraphReady}
                                isDataAvailable={graphData.revenueGeneratedByEventTypeData.map((item) => 
                                {
                                    return item.value;
                                }).reduce((acc, curr) => 
                                {
                                    return acc + curr;
                                }, 0) !== 0}
                                enlargeOnclick={(): void => 
                                {
                                    setOpenEnlargeGraphView(
                                        {
                                            audienceFunnelGraph: false,
                                            avgRoiByEventTypeGraph: false,
                                            roiRangeByEventTypeGraph: false,
                                            pipelineGeneratedByEventTypeGraph: false,
                                            revenueGeneratedByEventTypeGraph: true,
                                            pipelineByEventLocationGraph: false,
                                            revenueByEventLocationGraph: false
                                        }
                                    );
                                }}
                            />,
                            GraphSpacingBlockCSS:{
                                maxWidth: '50% !important'
                            }
                        },
                        {
                            GraphComponent:   <AnalyticsGraphContainer
                                title="Revenue by Event Location"
                                chartOptions={revenueByEventLocationChartOption}
                                isGraphReady={isChartReady.revenueByEventLocationGraphReady}
                                isDataAvailable={graphData.revenueByEventLocationData.map((item) => 
                                {
                                    return item.value; 
                                }).reduce((acc, curr) => 
                                {
                                    return acc + curr; 
                                }, 0) !== 0}
                                enlargeOnclick={(): void => 
                                {
                                    setOpenEnlargeGraphView(
                                        {
                                            audienceFunnelGraph: false,
                                            avgRoiByEventTypeGraph: false,
                                            roiRangeByEventTypeGraph: false,
                                            pipelineGeneratedByEventTypeGraph: false,
                                            revenueGeneratedByEventTypeGraph: false,
                                            pipelineByEventLocationGraph: false,
                                            revenueByEventLocationGraph: true
                                        }
                                    );
                                }}
                            />,
                            // GraphSpacingBlockCSS:{
                            //     maxWidth: '266px !important'
                            // }
                        }
                    ]
                }
                BlockStyling={{
                    padding: '16px !important',
                }}
                GraphComponentBlockStyle={{
                    justifyContent: 'flex-start !important',
                }}
            />

           
            <AnalyticsGraphsBlock
                elem={[
                    <RoiTable header={'Top Performing Events'} rowData={tableData.topPerformingEventsTable} isTableReady={tableReady.topPerformingEventsTable} />,
                    <RoiTable header={'Lowest Performing Events'} rowData={tableData.lowestPerformingEventsTable} isTableReady={tableReady.lowestPerformingEventsTable} />
                ]}
                style={{
                    gridTemplateColumns: '1fr 1fr',
                    padding: '16px'
                }}
            />
            {openEnlargeGraphView.audienceFunnelGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Audience Funnel'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={conversionFunnelChartOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.avgRoiByEventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Avg ROI by Event Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={avgRoiByEventTypeChartOptions} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.roiRangeByEventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'ROI Range by Event Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={roiRangeChartOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.pipelineGeneratedByEventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Pipeline Generated By Event Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={pipelineGenerateByEventTypeChartOptions} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.revenueGeneratedByEventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Revenue Generated By Event Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={revenueGeneratedByEventTypeChartOptions} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}
            {openEnlargeGraphView.pipelineByEventLocationGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Pipeline by Event Location'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={pipelineByEventLocationChartOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.revenueByEventLocationGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        audienceFunnelGraph: false,
                        avgRoiByEventTypeGraph: false,
                        roiRangeByEventTypeGraph: false,
                        pipelineGeneratedByEventTypeGraph: false,
                        revenueGeneratedByEventTypeGraph: false,
                        pipelineByEventLocationGraph: false,
                        revenueByEventLocationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Revenue by Event Location'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={revenueByEventLocationChartOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

        </Box>
    );
};

export default RoiAnalytics;


const RoiTable = ({ header, rowData, isTableReady }): React.JSX.Element => 
{
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('title', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_TYPES[row.getValue()]}</p>)
            },
            header: 'Type',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('locationType', {
            cell: (row) => {
                return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[row.getValue()]}</p>)
            },
            header: 'Location',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('roi', {
            cell: (row) => {
                const roiChipColor = row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi >= 1 ? 'green' : row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi < 1 ? 'red' : 'null';
                return (
                    <Box className="dashboard-roi-container">
                        <MuiChip chipColor={roiChipColor} label={row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi !== null ? row?.row?.original?.roi?.roi : '-'} />
                    </Box>
                );
            },
            header: 'RoI',
            size: 50,
            maxSize: 50,
            sortingFn: 'alphanumericCaseSensitive'
        })
    ]

    const eventType = (row: { type: number; }): React.ReactElement => 
    {
        const eventTypeLabel = CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL[Number(row.type)];

        return (
            <p>{eventTypeLabel}</p>
        );
    };

    const locationType = (row: { locationType: number; }): React.ReactElement => 
    {
        const locationTypeLabel = CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[Number(row.locationType)];

        return (
            <p>{locationTypeLabel}</p>
        );
    };

    const roiTag = (row: { roi?: { roi?: number }; }): React.ReactElement => 
    {
        const className = row?.roi?.roi > 1 ? 'safe-roi' : 'unsafe-roi';

        return (
            <p className={className}>{row?.roi?.roi}</p>
        );
    };

    return (
        <Box id='roiTableComp'>
            <Box className="table-name">{header}</Box>
            <Box className="roi-table">
                {isTableReady ?
                    <div className={rowData?.length == 0 ? "roi-table-div" : ""}>
                        <TanstackTable 
                            data={rowData}
                            initialColumns={columns}
                            rowCount={3}
                            showSpinner={false}
                            hidePagination
                            showNoRowsOverlay
                            height={rowData?.length == 0 ? '200px' : ''}
                        />
                    </div>
                    : <CustomSpinner height={'100%'} />}
            </Box>
        </Box>
    );
};