import { useLocation, useNavigate } from "react-router-dom";
import FormTabs from "../../../../../../common/FormTabs";

import './styles.scss';

const AnalyticsPageTabs: React.FC<{ eventId: string | number }> = ({ eventId }): React.JSX.Element =>
{
    const currentpath = useLocation().pathname;
    const navigate = useNavigate();

    const tabData = 
    [
        {
            tabName: 'Revenue',
            selectedTab: false,
            navigation: () => 
            {
                navigate(`/events/${eventId}/analytics/revenue`);
            }  
        },
        // {
        //     tabName: 'Registrations',
        //     selectedTab: currentpath?.includes('registrations'),
        //     navigation: () => 
        //     {
        //         navigate(`/events/${eventId}/analytics/registrations`);
        //     }
        // }
    ];

    return (
        <div className="analytics-page-tabs">
            <FormTabs tabs={tabData} />
        </div>
    )
};

export default AnalyticsPageTabs;