import { createSlice } from '@reduxjs/toolkit';

export const budgetSlice = createSlice({
    name: 'eventBudget',
    initialState: {
        value: {
            refreshBudget:false
        }
    },
    reducers: {
        refreshBudgetDetails: (state, actions) => {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        }

    },
});

export const { refreshBudgetDetails } = budgetSlice.actions;
export default budgetSlice.reducer;