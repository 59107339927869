import { useEffect, useMemo, useState } from "react";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import PortalSidedrawer from "../Common/PortalSidedrawer";
import { getSponsorInfo } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { Sponsor } from "../../../Sponsors/interfaces";

import './styles.scss';
import AddSponsorInfo from "./AddSponsorInfo";

const PortalSponsorInfo: React.FC = (): React.JSX.Element =>
{

    const [infoSidebarOpen, setInfoSidebarOpen] = useState<boolean>(false);
    const [sponsorInfo, setSponsorInfo] = useState<Sponsor | undefined>(undefined);
    const [refresh, setRefresh] = useState<boolean>(false);

    const isEdit = sponsorInfo?.companyDescription !== '' || sponsorInfo?.primaryLogos && sponsorInfo?.primaryLogos?.length > 0 || sponsorInfo?.boothAssets && sponsorInfo?.boothAssets?.length > 0;

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const data = await getSponsorInfo();
            if (data) 
            {
                setSponsorInfo(data);
            }
        } 
        catch (error) 
        {
            console.error('Error fetching sponsor info:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useMemo(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [refresh])

    return (
        <div id="portalSponsorInfo">
            <div className="heading-div">
                <div className="heading-wrapper">
                    <h2 className="heading">{'Information'}</h2>
                </div>
                <CustomButton name={isEdit ? "Edit Information" : "Add Information"} btnType='secondary' onClick={() => { setInfoSidebarOpen(true); } } />
            </div>

            <div className="information-container">
                <div className="info-wrapper">
                    <h3 className="title">{'Company Description:'}</h3>
                    <p className="content">{sponsorInfo?.companyDescription}</p>
                </div>

                <div className="info-wrapper">
                    <h3 className="title">{'Brand Assets:'}</h3>
                    <div className="asset-container">
                        {
                            [...(sponsorInfo?.primaryLogos || []), ...(sponsorInfo?.alternateLogos || [])]?.map((logo, index) => {
                                return (
                                    <img key={index} src={logo} alt="brand-assets" />
                                )
                            })
                        }
                    </div>
                </div>

                <div className="info-wrapper">
                    <h3 className="title">{'Booth Assets:'}</h3>
                    <div className="asset-container">
                        {
                            sponsorInfo?.boothAssets?.map((asset, index) => {
                                return (
                                    <img key={index} src={asset} alt="booth-assets" />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {
                infoSidebarOpen &&
                    <PortalSidedrawer 
                        component={<AddSponsorInfo existingSponsorInfo={sponsorInfo} setRefresh={setRefresh} handleClose={() => setInfoSidebarOpen(false)} />}
                        handleClose={() => setInfoSidebarOpen(false)}
                        heading={isEdit ? "Edit Sponsor Info" : "Add Sponsor Info"}
                        open={infoSidebarOpen}
                        containerWidth="440px"
                    />
            }
        </div>
    )
};

export default PortalSponsorInfo;