import { useEffect, useState } from "react";
import { getSponsorPortalTrends } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { SponsorPortalTrend } from "../../interfaces/portal_interface";
import PortalSponsorInfo from "../../Components/SponsorInfo/PortalSponsorInfo";
import PortalDashboardTrends from "../../Components/Dashboard/PortalDashboardTrends";
import PortalTeam from "../../Components/Team/PortalTeam";

import './styles.scss';

const PortalDashboard: React.FC = (): React.JSX.Element =>
{

    const [trends, setTrends] = useState<SponsorPortalTrend | undefined>();

    const trendsData =
    [
        {
            name: 'Registration',
            value: trends?.registration
        },
        {
            name: 'Check In',
            value: trends?.checkIn
        },
        {
            name: 'Target List',
            value: trends?.targetList
        },
        {
            name: 'Leads Captured',
            value: trends?.leadsCaptured
        }
    ];

    const fetchPortalTrends = async (): Promise<void> =>
    {
        try 
        {
            const trends = await getSponsorPortalTrends();
            if (trends)
            {
                setTrends(trends);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPortalTrends();
    }, [])

    return (
        <div id="portalDashboard">
            <PortalDashboardTrends trendsData={trendsData} />
            <PortalTeam />
            <PortalSponsorInfo />
        </div>
    )
};

export default PortalDashboard;