import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext } from 'react'
import { Event } from '../../../../interfaces';
import TableEmptyComponent from '../../../../../../common/TableEmptyComponent';
import { EventsPageContext } from '../../../../../../contexts/EventsPageContext';
import TanstackTable from '../../../../../../common/TanstackTable/TanstackTable';
import { EventTargetListContext } from '../../../../../../contexts/EventTargetList/EventTargetListContext';
import _ from 'lodash';
import { getLocationName } from './FilterEventTargetListComponent';
import { UserContext } from '../../../../../../contexts/UserContext';
import { UserViewType } from '../../../../../Settings/enum/users.enum';

const ViewAllEventInTargetListComponent: React.FC = (): JSX.Element => 
{

    const { selectedEventIds, setSelectedEventIds, selectedEvents, setSelectedEvents } = useContext(EventTargetListContext);
    const { isEmpty, eventDataCount, events, pageSize, updateCurrentPage, updatePageSize, currentPage, showSpinner } = useContext(EventsPageContext);
    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

    const onCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>, row: Event) => 
    {
        if (event.target.checked && row.id)
        {
            setSelectedEvents([...selectedEvents, row]);

            if(selectedEventIds)
            {
                setSelectedEventIds([...selectedEventIds, row?.id.toString()]);
            }
            else
            {
                setSelectedEventIds([...selectedEventIdsrow?.id.toString()]);

            }
        }
        else
        {
            setSelectedEvents(selectedEvents.filter(event => event.id !== row?.id));
            setSelectedEventIds(selectedEventIds.filter(id => id !== row.id));
        }
    }
    

    const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>, rows: Event[]) =>
    {
        if (event.target.checked)
        {
            const existingSelectedIds = new Set(selectedEventIds);
            const uniqueRowsToAdd = _.filter(rows, row => !existingSelectedIds.has(row.id));

            setSelectedEvents([...selectedEvents, ...uniqueRowsToAdd]);
            setSelectedEventIds([...selectedEventIds, ...uniqueRowsToAdd.map(row => row.id)]);
        }
        else
        {
            setSelectedEvents([]);
            setSelectedEventIds([]);
        }
    }


    const columnHelper = createColumnHelper<Event>();
    const eventColoumns = [
        columnHelper.accessor('title', {
            cell: (row) =>
            {
                return (<p className="bolded-cellContent">{ row.getValue() }</p>)
            },
            header: 'Event Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('locationType', {
            cell: (row) =>
            {
                const locationName =   getLocationName(row.getValue());
              
                return (<p className="cellContent">{ locationName }</p>)
            },
            header: 'Event Location Type',
        }),
        columnHelper.accessor('eventStartDateTime', {
            cell: (row) => {
                const epochTime = row.getValue(); 
                const date = new Date(epochTime * 1000); 
                const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
                return (
                    <p className="cellContent">{formattedDate}</p>
                );
            },
            header: 'Date',
        })
    ];

    const targetListByPastEventsView = () => 
    {
        return (
            <div id="eventTargetListItemComponent">
                    { isEmpty ? (
                        <TableEmptyComponent infoText={ "No Events Exists" } />
                    ) : (
                        <div className='h-100 w-100'>
                            <div className='event-count-text'>
                                {eventDataCount} {' '} Past events found
                            </div>
                            { !isEmpty &&
                                <TanstackTable
                                    height='calc(100vh - 271px)'
                                    checkboxSelection={ true }
                                    onCheckBoxClick={ onCheckBoxClick }
                                    onSelectAllClick={ onSelectAllClick }
                                    checkBoxSelectedIds={ selectedEventIds }
                                    data={ events }
                                    initialColumns={ eventColoumns }
                                    rowCount={ eventDataCount }
                                    pageSize={ pageSize }
                                    currentPage={ currentPage - 1 }
                                    updateCurrentPage={ updateCurrentPage }
                                    updatePageSize={ updatePageSize }
                                    showSpinner={showSpinner}
                                />
                            }
                        </div>
                    ) }
            </div>
        );
    }

    const targetListByPastEventsMinimalView = () => 
    {
        return (
            <div id="eventTargetListItemMinimalView">
                {isEmpty ? (
                    <TableEmptyComponent infoText={ "No Events Exists" } />
                ) : (
                    <div className='h-100 w-100'>
                        <div className='event-count-text'>
                            {eventDataCount} {' '} Past events found
                        </div>
                        {!isEmpty &&
                            <TanstackTable
                                height='calc(100vh - 271px)'
                                checkboxSelection={ true }
                                onCheckBoxClick={ onCheckBoxClick }
                                onSelectAllClick={ onSelectAllClick }
                                checkBoxSelectedIds={ selectedEventIds }
                                data={ events }
                                initialColumns={ eventColoumns }
                                rowCount={ eventDataCount }
                                pageSize={ pageSize }
                                currentPage={ currentPage - 1 }
                                updateCurrentPage={ updateCurrentPage }
                                updatePageSize={ updatePageSize }
                                showSpinner={showSpinner}
                            />
                        }
                    </div>
                ) }
            </div>
        );
    }

    return minimalView ? targetListByPastEventsMinimalView() : targetListByPastEventsView();
};



export default ViewAllEventInTargetListComponent