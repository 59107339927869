import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addTicket } from '../../../redux/events/eventRegistrations/Tickets';
import { createEventTicket, deleteTicket, updateEventTicket } from '../../../scripts/apis/eventTickets';
import APP_CONSTANTS from '../../../scripts/constants';
import eventBus from '../../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CONTENT } from '../../../scripts/i18n';
import { CloseIconComponent, FormControlComponent, FormLabelComponent, MultipleSelectComponent, RadioGroupComponent, SelectComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import { ITickets, IticketReduxData } from '../interfaces/create-ticket_interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import _ from 'lodash';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { Currency, EnableApprovalStatus, EnableInvoiceStatus, EnableTicketWaitlistStatus, EventRegistrationTypes, EventTicketPaymentIntegration, PrivateTicket, TicketType } from '../../../pages/Events/enum';
import InvoiceSettings from './InvoiceSettings';
import toast from 'react-hot-toast';
import DeletePopup from '../../../common/DeletePopup';
import { EventTicket } from '../../../pages/Events/interfaces';
import DateTimePicker from '../../../common/FormComponents/DateTimePicker';
import { combineDateTime, currencyOptionLabel } from '../../../scripts/helpers';
import { IIntegration } from '../../../pages/Settings/interface/integration_interface';
import { getIntegrationsData } from '../../../scripts/apis/integration';
import { IntegrationTypes } from '../../../pages/Settings/enum/integrations.enum';
import getSymbolCurrency from "currency-symbol-map";
import { getEmojiByCurrencyCode } from "country-currency-emoji-flags";

import './styles.scss';
import CustomTooltip from '../../../common/Tooltip';

const CreateTickets = (props):React.JSX.Element => 
{
    const { eventId, isTableView } = props;

    const ticketsInfo: EventTicket | undefined = props?.ticketData;

    const dispatch = useDispatch();

    const ticketsData = useSelector((state: IticketReduxData): ITickets => 
    {
        return state.tickets.value;
    });

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });
    
    const csrfTokenData = useSelector((state): string => 
    { 
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [spinner, setSpinner] = useState<boolean>(false);
    const [maxEndDate, setMaxEndDate] = useState<number | null>(null);
    const [stageIdData, setStageIdData] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [integrations, setIntegrations] = useState<IIntegration[]>([]);

    const ticketTypeOptions = [
        {  
            name: 'Public', value: 2, description: 'Shown to the public on the landing page. Anyone can register using this ticket.', icon: <FontAwesomeIcon icon={['fal', 'globe']} />
        },
        {
            name: 'Private', value: 1, description: 'Unlisted on the landing page. It can be used for last-minute or special registrations.', icon: <FontAwesomeIcon icon={['fal', 'lock-keyhole']} />
        }
    ];

    const ticketPricingOptions = [
        {
            name: 'Free', value: 1, description: 'Free tickets for the registrant. No payment is required.', icon: <FontAwesomeIcon icon={['fal', 'circle-0']} />
        },
        {  
            name: 'Paid', value: 2, description: 'A fixed amount needs to be paid to buy the ticket.', icon: <FontAwesomeIcon icon={['fal', 'circle-dollar']} />
        }
    ];

    const generateIntegrationOptions = () =>
    {
        const integrationOptions = [];

        if (integrations && integrations.length > 0 && integrations.some((integration: IIntegration) => integration?.type === IntegrationTypes.RAZORPAY))
        {
            integrationOptions.push({
                id: EventTicketPaymentIntegration.RAZORPAY, name: 'Razorpay'
            });
        }
        if (integrations && integrations.length > 0 && integrations.some((integration: IIntegration) => integration?.type === IntegrationTypes.STRIPE))
        {
            integrationOptions.push({
                id: EventTicketPaymentIntegration.STRIPE, name: 'Stripe'
            });
        }

        return integrationOptions;
    };

    const integrationOptionLabel = {
        [EventTicketPaymentIntegration.RAZORPAY]: 'Razorpay',
        [EventTicketPaymentIntegration.STRIPE]: 'Stripe'
    };

    const generateCurrencyOptions = () =>
    {
        const currencyOptions = 
        [
            {
                id: Currency.CANADIAN_DOLLAR, name: getSymbolCurrency(Currency.CANADIAN_DOLLAR) as string, startIcon: getEmojiByCurrencyCode(Currency.CANADIAN_DOLLAR)
            },
            {
                id: Currency.US_DOLLAR, name: getSymbolCurrency(Currency.US_DOLLAR) as string, startIcon: getEmojiByCurrencyCode(Currency.US_DOLLAR)
            },
            {
                id: Currency.INDIAN_RUPEE, name: getSymbolCurrency(Currency.INDIAN_RUPEE) as string, startIcon: getEmojiByCurrencyCode(Currency.INDIAN_RUPEE)
            },
            {
                id: Currency.EURO, name: getSymbolCurrency(Currency.EURO) as string, startIcon: getEmojiByCurrencyCode(Currency.EURO)
            },
            {
                id: Currency.BRITISH_POUND, name: getSymbolCurrency(Currency.BRITISH_POUND) as string, startIcon: getEmojiByCurrencyCode(Currency.BRITISH_POUND)
            }
        ];

        if (formik.values.integrationType === EventTicketPaymentIntegration.STRIPE)
        {
            return currencyOptions;
        }
        else if (formik.values.integrationType === EventTicketPaymentIntegration.RAZORPAY)
        {
            return currencyOptions.filter(option => option.id === Currency.INDIAN_RUPEE);
        }
    };

    const isPaymentIntegrationDone = integrations?.some((integration: IIntegration) => integration?.type === IntegrationTypes.RAZORPAY || integration?.type === IntegrationTypes.STRIPE);

    const navigate = useNavigate();

    // const handleTicketGroupChange = (event, newValue): void => 
    // {

    //     if (newValue && newValue.includes('Add :')) 
    //     {
    //         const ticketGroupName = newValue.split('Add : ')[1];

    //         setTimeout((): void => 
    //         {
    //             eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
    //                 heading: 'Create New Ticket Group',
    //                 component: <CreateTicketGroup dataId={eventId} setTicketGroupDetails={setTicketGroupDetails} ticketGroupName={ticketGroupName} />,
    //             });

    //             eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
    //                 open: true
    //             });
    //         });
    //     } 
    //     else
    //     {
    //         formik.setFieldValue('ticketGroupId', newValue);

    //         const selectedTicketGroup = _.filter(ticketGroupDetails, function (group):boolean
    //         {
    //             return newValue === group.name;
    //         })[0];
    //         // setGroup(selectedTicketGroup);

    //         dispatch(addTicket({
    //             ticketGroupName: newValue ? newValue : ''
    //         }));

    //         dispatch(addTicket({
    //             ticketGroupId: selectedTicketGroup ? selectedTicketGroup.id : ''
    //         }));
    //     }
    // };

    // const ticketGroupFilterOptions = (options: any, params: any):any[] => 
    // {
    //     const filtered = filter(options, params);

    //     if (params.inputValue !== '') 
    //     {
    //         filtered.push('Add : ' + params.inputValue);
    //     }

    //     return filtered;
    // };

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/events/' + eventId + '/registrations/tickets');
        }
    };

    const handleStageSelect = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        const value = event.target.value;
        formik.setFieldValue('stageIds', typeof (value) === 'string' ? value.split(',') : value);
    };

    const handleAddTax = (): void => 
    {
        formik.setFieldValue('tax', [...formik.values.tax, { name: '', percentage: '' }]);
    };

    const handleTaxInput = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => 
    {
        const { name, value } = event.target;
        const fieldName = `tax[${index}].${name}`;
        formik.setFieldValue(fieldName, value);
    }; 

    const handleTaxRemove = (event: React.MouseEvent<SVGSVGElement>, index: number): void =>
    {
        const taxData = formik.values.tax;
        taxData.splice(index, 1);
        formik.setFieldValue('tax', taxData);
    };

    const IntegrationInfoComponent = ():React.JSX.Element =>
    {
        return (
            <div className="integration-info-container">
                <div className="integration-info-card">
                    <div className="integration-info-card-header">
                        <div className="integrations-info-header-icon">
                            <FontAwesomeIcon 
                                icon={['fal', 'coins']}
                            />
                        </div>
                    </div>
                    <div className="integration-info-details">
                        <h2>{'Integrate your Payment Gateway'}</h2>
                        <p>{'Integrate to create a paid ticket and to receive payments.'}</p>
                    </div>
                    <CustomButton 
                        name="Integrate Now"
                        btnType="secondary"
                        width="100%"
                        onClick={() => 
                        {
                            handleDrawerClose();
                            navigate('/settings/integrations/payments')
                        }}
                        style={{ width: "100%", height: "44px", maxHeight: "44px", fontSize: "16px", fontWeight: 600 }}
                    />
                </div>
            </div>
        )
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required').test('isNameValid','Ticket name already exists.',(value):boolean => 
            {
                if(props.presentTicketsName.length > 0)
                {
                    if(ticketsInfo?.name === value)
                    {
                        return true;
                    }
                    else if(props.presentTicketsName.includes(value.replace(/\s/g, '').toLowerCase()))
                    {
                        return false;
                    }
                    else
                    {
                        return true;
                    }
                }
                else
                {
                    return true;
                }
            }),
        description: Yup.string()
            .notRequired(),
        totalTickets: Yup.number()
            .required('Total Tickets is required'),
        // saleStartDateTime: Yup.string()
        //     .required('Ticket Sale Start Date & Time is required'),
        // saleEndDateTime: Yup.string()
        //     .required('Ticket Sale End Date & Time is required'),
        stageIds: Yup.array(),
        enableApproval: Yup.number(),
        enableWaitlistStatus: Yup.number(),
        privateTicket: Yup.number().required('Ticket Type is required'),
        type: Yup.number()
            .test('isTicketFree', 'Pricing Type is required', (value): boolean => 
            {
                if (formik.values.privateTicket === PrivateTicket.PUBLIC) 
                {
                    if (!value) 
                    {
                        return false;
                    }
                    else 
                    {
                        return true;
                    }
                }
                else 
                {
                    return true;
                }
            }
            ),
        ticketPrice: Yup.number()
            .test('isTicketPriceMin', 'Ticket Price should be greater than 0', (value): boolean => 
            {
                if (formik.values.privateTicket === PrivateTicket.PUBLIC && formik.values.type === TicketType.PAID) 
                {
                    if (value && value < 1) 
                    {
                        return false;
                    }
                }
                return true;
            })
            .test('isTicketPriceRequired', 'Enter the Ticket Price', (value): boolean => 
            {
                if (formik.values.privateTicket === PrivateTicket.PUBLIC && formik.values.type === TicketType.PAID)
                {
                    if (!value) 
                    {
                        return false;
                    }
                }
                return true;
            }),
        tax: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                    .test('isTaxNameRequired', 'Tax Name is required', (value): boolean => { 
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (!value) 
                            {
                                return false;
                            }
                        }
                        return true;
                    }),
                percentage: Yup.number()
                    .test('isTaxPercentageRequired', 'Tax Percentage is required', (value): boolean => {
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (!value) 
                            {
                                return false;
                            }
                        }
                        return true;
                    })
                    .test('isTaxPercentageMin', 'Tax percentage should be greater than 0', (value): boolean => {
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (value && value < 0.1) 
                            {
                                return false;
                            }
                        }
                        return true;
                    })
                    .test('isTaxPercentageMax', 'Tax percentage should be less than 100', (value): boolean => {
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (value && value > 100) 
                            {
                                return false;
                            }
                        }
                        return true;
                    })
              })
            )
            .test('isTaxNeeded', 'Tax is required', (value) =>
            {
                if (formik.values.includeTax)
                {
                    if (value?.length === 0) 
                    {
                        return false;
                    }
                }
                return true;
            }),
            includeTax: Yup.boolean()
    });
    
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: ticketsData.name ? ticketsData.name : '',
            description: ticketsData.description ? ticketsData.description : '',
            totalTickets: ticketsData.totalTicketsAvailable ? ticketsData.totalTicketsAvailable : 0,
            // saleStartDateTime: ticketsData.saleStartDateTime ? ticketsData.saleStartDateTime : '',
            // saleEndDateTime: ticketsData.saleEndDateTime ? ticketsData.saleEndDateTime : '',
            saleStartDate: ticketsInfo && ticketsInfo?.saleStartDateTime ? moment.unix(Number(ticketsInfo?.saleStartDateTime)) : moment.unix(Number(eventReduxData?.eventStartDateTime)),
            saleStartTime: ticketsInfo && ticketsInfo?.saleStartDateTime ? moment.unix(Number(ticketsInfo?.saleStartDateTime)).format('HH:mm') : moment.unix(Number(eventReduxData?.eventStartDateTime)).format('HH:mm'),
            saleCloseDate: ticketsInfo && ticketsInfo?.saleCloseDateTime ? moment.unix(Number(ticketsInfo?.saleCloseDateTime)) : moment.unix(Number(eventReduxData?.closingDateTime)),
            saleCloseTime: ticketsInfo && ticketsInfo?.saleCloseDateTime ? moment.unix(Number(ticketsInfo?.saleCloseDateTime)).format('HH:mm') : moment.unix(Number(eventReduxData?.closingDateTime)).format('HH:mm'),
            stageIds: ['1'],
            // enableApproval: ticketsData?.enableApproval ? ticketsData?.enableApproval : 2,
            enableApproval: eventReduxData?.registrationType === EventRegistrationTypes.INVITE ? 1 : ticketsData?.enableApproval,
            enableWaitlistStatus: Number(ticketsData?.enableWaitlistStatus) ? Number(ticketsData?.enableWaitlistStatus) : Number(eventReduxData?.enableWaitlistStatus) || EnableTicketWaitlistStatus.DISABLE,
            privateTicket: ticketsData?.privateTicket ? ticketsData?.privateTicket : PrivateTicket.PRIVATE,
            type: ticketsData?.type ? ticketsData?.type : 1,
            ticketPrice: ticketsData?.ticketPrice ? ticketsData?.ticketPrice : '',
            enableInvoice: ticketsData?.enableInvoice ? ticketsData?.enableInvoice : EnableInvoiceStatus.DISABLE,
            includeTax: ticketsData?.enableInvoice === EnableInvoiceStatus.ENABLE && ticketsInfo?.tax && ticketsInfo?.tax.length > 0 ? true : false,
            tax: ticketsInfo?.tax ? ticketsInfo?.tax : [],
            integrationType: ticketsInfo?.paymentIntegration ? ticketsInfo?.paymentIntegration : generateIntegrationOptions()?.[0]?.id,
            currency: ticketsInfo?.currency ? ticketsInfo?.currency : ticketsInfo?.paymentIntegration === EventTicketPaymentIntegration.STRIPE ? Currency.US_DOLLAR : Currency.INDIAN_RUPEE
        },
        onSubmit: async (): Promise<void> => 
        {

            const saleStartDateTime = combineDateTime(formik.values.saleStartDate, formik.values.saleStartTime);
            const saleCloseDateTime = combineDateTime(formik.values.saleCloseDate, formik.values.saleCloseTime);

            if (Number(saleCloseDateTime) > Number(eventReduxData?.eventEndDateTime)) 
            {
                toast.error('Ticket Sale End Date & Time should be less than Event End Date & Time');
                return;
            }

            if (Number(saleStartDateTime) > Number(saleCloseDateTime))
            {
                toast.error('Ticket Sale Start Date & Time should be less than Ticket Sale End Date & Time');
                setSpinner(false);
                return;
            }
            setSpinner(true);
            const ticketData = {
                name: formik.values.name,
                description: formik.values.description,
                type: formik.values.type,
                totalTicketsAvailable: formik.values.totalTickets,
                saleStartDateTime,
                saleCloseDateTime,
                privateTicket: formik.values.privateTicket,
                stageIds: [],
                tax: formik.values.tax?.length > 0 ? formik.values.tax : null,
                enableWaitlistStatus: Number(formik.values.enableWaitlistStatus),
                enableApproval: formik.values.enableApproval || EnableApprovalStatus.DISABLE,
                enableInvoice: formik.values?.enableInvoice,
                ticketPrice: formik.values.type === TicketType.PAID ? Number(formik.values?.ticketPrice) : 0,
                paymentIntegration: formik.values.integrationType,
                currency: formik.values.currency
            };

            if (ticketsInfo) 
            {
                if (ticketsInfo.title !== formik.values.name || ticketsInfo.description !== formik.values.description || ticketsInfo.totalTicketsAvailable !== formik.values.totalTickets || ticketsInfo.ticketAvailability !== ticketsData.ticketAvailability || Number(ticketsInfo.saleStartDateTime) !== Number(saleStartDateTime) || Number(ticketsInfo.saleCloseDateTime) !== Number(saleCloseDateTime)) 
                {
                    try 
                    {
                        const ticketCreated = await updateEventTicket(eventId, ticketsInfo.id, ticketData);
                        if (ticketCreated) 
                        {
                            toast.success('Ticket Details Updated');
                            props.setRefresh(true);
                            setSpinner(false);
                            handleDrawerClose();
                        }
                    }
                    catch (error) 
                    {
                        setSpinner(false);
                        console.log(error);
                        if ((error as Error)?.message == 'INVOICE PREFIX OR INVOICE NUMBER NOT SET')
                        {
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                                heading: 'Invoice Settings',
                                component: <InvoiceSettings eventId={eventId} isInvoiceDetailsPresent={false} />,
                                componentWidth: '512px',
                                componentHeight: '216px'
                            });
                    
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                                open: true
                            });
    
                            return toast.error('Please update Invoice settings to create the ticket.');
                        }
                        toast.error((error as Error)?.message || 'Error in updating ticket');
                    }
                }
            }
            else 
            {
                try 
                {
                    const ticketCreated = await createEventTicket(eventId, ticketData, csrfTokenData);
                    if (ticketCreated) 
                    {
                        toast.success('Ticket Created');
                        props.setRefresh(true);
                        setSpinner(false);
                        handleDrawerClose();
                    }
                }
                catch (error) 
                {
                    setSpinner(false);

                    if ((error as Error)?.message == 'INVOICE PREFIX OR INVOICE NUMBER NOT SET')
                    {
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                            heading: 'Invoice Settings',
                            component: <InvoiceSettings eventId={eventId} isInvoiceDetailsPresent={false} />,
                            componentWidth: '512px',
                            componentHeight: '216px'
                        });
                
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                            open: true
                        });

                        return toast.error('Please update Invoice settings to create the ticket.');
                    }
                    toast.error(_.startCase((error as Error).message.toLowerCase()));
                    console.log(error);
                }
            }
        }
    });

    const fetchData = async ():Promise<void> => 
    {

        setStageIdData([
            {
                id: '1', name: 'All'
            }
        ]);
        
        if (ticketsInfo) 
        {
            dispatch(addTicket({
                name: ticketsInfo && ticketsInfo.name ? ticketsInfo.name : '',
                description: ticketsInfo && ticketsInfo.description ? ticketsInfo.description : '',
                totalTicketsAvailable: ticketsInfo && ticketsInfo.totalTicketsAvailable ? ticketsInfo.totalTicketsAvailable : 0,
                saleStartDate: moment.unix(ticketsInfo.saleStartDateTime).format('YYYY-MM-DD'),
                saleStartTime: moment.unix(ticketsInfo.saleStartDateTime).format('HH:mm'),
                saleEndDate: moment.unix(ticketsInfo.saleCloseDateTime).format('YYYY-MM-DD'),
                saleEndTime: moment.unix(ticketsInfo.saleCloseDateTime).format('HH:mm'),
                saleStartDateTime: ticketsInfo?.saleStartDateTime,
                saleEndDateTime: ticketsInfo?.saleCloseDateTime,
                maximumPeopleWaitlist: ticketsInfo && ticketsInfo.maximumPeopleWaitlist ? ticketsInfo.maximumPeopleWaitlist : 0,
                privateTicket: ticketsInfo && ticketsInfo.privateTicket ? ticketsInfo.privateTicket : 1,
                ticketGroupId: ticketsInfo && ticketsInfo.ticketGroupId ? ticketsInfo.ticketGroupId : 0,
                enableApproval: ticketsInfo?.enableApproval,
                enableInvoice: ticketsInfo?.enableInvoice,
                enableWaitlistStatus: ticketsInfo.enableWaitlistStatus === 1 ? 1 : Number(ticketsInfo?.enableWaitlistStatus),
                type: ticketsInfo?.type,
                ticketPrice: ticketsInfo?.ticketPrice,
            }));
        }

        if (!eventReduxData.closingDateTime) 
        {
            setMaxEndDate(Number(eventReduxData.eventEndDateTime));
        }
        else 
        {
            setMaxEndDate(Number(eventReduxData.closingDateTime));
        }
    };

    const fetchIntegrations = async ():Promise<void> =>
    {
        try 
        {
            const integrationsData = await getIntegrationsData();
            if (integrationsData) 
            {
                setIntegrations(integrationsData);
            }
        } 
        catch (error) 
        {
            console.log(error, 'Error in fetching integrations');
        }
    };

    const removeTicketFromTable = async (ticket: EventTicket): Promise<void> => 
    {
        try 
        {
            const ticketDeleted = await deleteTicket(eventId, ticket?.id as string);
            if (ticketDeleted) 
            {
                setShowDeletePopup(false);
                handleDrawerClose();
                toast.success('Ticket Deleted');
                props?.setRefresh(true);
            }
        }
        catch (error) 
        {
            toast.error((error as Error)?.message);
            console.log(error);
        }         
    };

    useEffect((): void => 
    {
        fetchData();
        fetchIntegrations();
    }, [eventReduxData]);

    let ticketPrice = Number(formik.values?.ticketPrice);
    if(formik.values?.tax && formik.values.tax?.length > 0)
    {
        const totalPercentage = _.sumBy(formik.values.tax, (tax: { name: string; percentage: number }) => Number(tax?.percentage));
        ticketPrice += (ticketPrice * totalPercentage / 100);
    }

    return (
        <Box id="createTicket">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate
                onSubmit={(values) : void => 
                {
                    return formik.handleSubmit(values);
                }}
                autoComplete="off">

                {/* Ticket Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.LABEL.TITLE} required />
                    <FormControlComponent
                        type="text"
                        placeholder='Name'
                        value={formik.values.name}
                        onChange={(event): void =>
                        {
                            formik.setFieldValue('name', event.target.value);
                        }}
                        isInvalid={formik.errors.name && formik.touched.name}
                    />
                    <Form.Control.Feedback type="invalid">{'Enter valid name'}</Form.Control.Feedback>
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* Total tickets available */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.LABEL.TOTAL_TICKETS} required />
                    <FormControlComponent
                        type="number"
                        value={formik.values.totalTickets}
                        required
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('totalTickets', event.target.value);
                        }}
                        onKeyDown={(evt): void => 
                        {
                            return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                        }}
                        isInvalid={formik.errors.totalTickets && formik.touched.totalTickets}
                    />
                    {formik.errors.totalTickets && formik.touched.totalTickets ? <Typography className="error-msg">{formik.errors.totalTickets}</Typography> : null}
                </Box>

                {/* Description */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Description'} />
                    <FormControlComponent
                        type={'text'}
                        as='textarea'
                        placeholder='Description'
                        rows={4}
                        value={formik.values.description}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('description', event.target.value);
                        }}
                        isInvalid={formik.errors.description && formik.touched.description}
                    />
                    {formik.errors.description && formik.touched.description ? <Typography className="error-msg">{formik.errors.description}</Typography> : null}
                </Box>

                {/* Stages */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Select Stage' />
                    <MultipleSelectComponent value={formik.values.stageIds} all={true} onChange={handleStageSelect} multiple={false} options={stageIdData} optionLabels={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.TICKET_OPTION} />
                </Box>

                {/* Ticket timing */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Sale Date & Time" required />
                    <div className="ticket-sale-timing">
                        <DateTimePicker 
                            dateValue={moment(formik.values.saleStartDate, 'DD/MM/YYYY').unix()}
                            timeValue={formik.values.saleStartTime}
                            onDateChange={(date): void => 
                            {
                                formik.setFieldValue('saleStartDate', date);
                            }}
                            onTimeChange={(time): void => 
                            {
                                formik.setFieldValue('saleStartTime', time);
                            }}
                            dateFormat='ddd, MMM D'
                            maxDate={Number(eventReduxData?.closingDateTime)}
                        />
                        <p>-</p>
                        <DateTimePicker 
                            dateValue={moment(formik.values.saleCloseDate, 'DD/MM/YYYY').unix()}
                            timeValue={formik.values.saleCloseTime}
                            onDateChange={(date): void => 
                            {
                                formik.setFieldValue('saleCloseDate', date);
                            }}
                            onTimeChange={(time): void => 
                            {
                                formik.setFieldValue('saleCloseTime', time);
                            }}
                            dateFormat='ddd, MMM D'
                            minDate={moment(formik.values.saleStartDate).unix()}
                            maxDate={Number(eventReduxData?.closingDateTime)}
                        />
                    </div>
                </Box>

                {/* Require approval */}
                <Box className="sidebar-container-spacing">
                    <Box className="approval-container">
                        <Box className="approval-icon-label-container">
                            <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                            <FormLabelComponent label={'Require Approval'} noBottomMargin />
                        </Box>
                        <CustomTooltip key={1} title={eventReduxData.registrationType === 2 && <Typography variant="subtitle1" fontSize={'12px'}>Approval is Enabled for Event</Typography>}>
                            <FormControlLabel
                                checked={formik.values.enableApproval === 1 || eventReduxData.registrationType === 2}
                                control={<CustomSwitch
                                    defaultChecked={eventReduxData.registrationType === 2}
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('enableApproval', event.target.checked ? 1 : 2);
                                    }}
                                    disabled={eventReduxData.registrationType === 2}
                                />}
                                label={''}
                            />
                        </CustomTooltip>
                    </Box>
                </Box>

                {/* Enable waitlist */}
                <Box className="sidebar-container-spacing">
                    <Box className="approval-container">
                        <Box className="approval-icon-label-container">
                            <FontAwesomeIcon icon={['fal', 'user-clock']} className="approval-icon" />
                            <FormLabelComponent label={'Enable Waitlist'} noBottomMargin />
                        </Box>
                        <CustomTooltip key={1} title={Number(eventReduxData.enableWaitlistStatus) === 1 && <Typography variant="subtitle1" fontSize={'12px'}>Waitlist is Enabled for Registrations</Typography>}>
                            <FormControlLabel
                                checked={formik.values.enableWaitlistStatus === 1 || Number(eventReduxData.enableWaitlistStatus) === 1}
                                control={<CustomSwitch
                                    defaultChecked={Number(eventReduxData.enableWaitlistStatus) === 1}
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('enableWaitlistStatus', event.target.checked ? 1 : 2);
                                    }}
                                />}
                                label={''}
                                disabled={Number(eventReduxData.enableWaitlistStatus) === 1}
                            />
                        </CustomTooltip>
                        
                    </Box>
                </Box>

                {/* Ticket Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Ticket Type" required />
                    <RadioGroupComponent value={formik.values.privateTicket} options={ticketTypeOptions} onChange={(event): void => 
                    {
                        formik.setFieldValue('privateTicket', Number(event.target.value));
                    }} />
                    {formik.errors.privateTicket && formik.touched.privateTicket ? <Typography className="error-msg">{formik.errors.privateTicket}</Typography> : null}
                </Box>

                {/* Free / Paid ticket */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Pricing" required />
                    <RadioGroupComponent value={formik.values.type} options={ticketPricingOptions} onChange={(event): void => 
                    {
                        formik.setFieldValue('type', Number(event.target.value));
                    }} />
                    {formik.errors.type && formik.touched.type ? <Typography className="error-msg">{formik.errors.type}</Typography> : null}
                </Box>
                
                {/* Integration info */}
                {
                    (formik.values.type === TicketType.PAID && !isPaymentIntegrationDone) && 
                    <IntegrationInfoComponent />
                }

                {/* Payment Gateway */}
                {
                    (formik.values.type === TicketType.PAID && isPaymentIntegrationDone) &&
                    <Box className="sidebar-container-spacing">
                        <FormLabelComponent label="Payment Gateway" required />
                        <SelectComponent 
                            options={generateIntegrationOptions()}
                            value={formik.values.integrationType || ''}
                            onChange={(event): void => 
                            {
                                formik.setFieldValue('integrationType', event.target.value);
                            }}
                            optionLabels={integrationOptionLabel}
                            defaultPlaceholder="Select Payment Gateway"
                        />
                    </Box>
                }

                {/* Ticket price */}
                {formik.values.type === TicketType.PAID && isPaymentIntegrationDone &&
                <>
                    <Box className="sidebar-container-spacing">
                        <Box className="approval-container">
                            <Box className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} className="approval-icon" />
                                <FormLabelComponent label={'Enable Invoice'} noBottomMargin />
                            </Box>
                            <FormControlLabel
                                checked={formik.values.enableInvoice === 1}
                                control={<CustomSwitch
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('enableInvoice', event.target.checked ? 1 : 2);
                                        if(!event.target.checked)
                                        {
                                            formik.setFieldValue('includeTax', false);
                                            formik.setFieldValue('tax', []);
                                        }
                                    }}
                                />}
                                label={''}
                            />
                        </Box>
                    </Box>

                    {
                        formik.values.enableInvoice === EnableInvoiceStatus.ENABLE && <Box className="sidebar-container-spacing">
                            <Box className="approval-container">
                            <Box className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'money-check-dollar']} className="approval-icon" />
                                <FormLabelComponent label={'Include Tax'} noBottomMargin />
                            </Box>
                            <FormControlLabel
                                checked={formik.values.includeTax}
                                control={<CustomSwitch
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('includeTax', event.target.checked);
                                        if(!event.target.checked)
                                        {
                                            formik.setFieldValue('tax', []);
                                        }
                                    }}
                                />}
                                label={''}
                            />
                        </Box>
                        </Box>
                    }

                    {formik.values.includeTax && <Box className="sidebar-container-spacing">
                        <Box className="tax-remove-icon-cont">
                            <FormLabelComponent label={`Tax`} />
                        </Box>
                        {
                            
                            formik.values?.tax && formik.values?.tax.map((tax, index) => {
                                return(
                                    <Box className="tax-container" key={index}>
                                        <Box className="tax-input-cont">
                                            <div>
                                                <FormControlComponent name="name" placeholder="VAT" onChange={(event): void => 
                                                {
                                                    handleTaxInput(event, index, ) 
                                                }} isInvalid={formik.errors.tax && formik.errors.tax[index]?.name && formik.touched.tax && formik.touched.tax[index]?.name} value={tax.name} type='text' />
                                                {formik.errors.tax && formik.errors.tax[index]?.name && formik.touched.tax && formik.touched.tax[index]?.name ? <Typography className="error-msg">{formik.errors.tax[index]?.name}</Typography> : null}
                                            </div>
                                            <div>
                                                <FormControlComponent name="percentage" placeholder="5" onChange={(event): void => 
                                                {
                                                    handleTaxInput(event, index, ) 
                                                }} isInvalid={formik.errors.tax && formik.errors.tax[index]?.percentage && formik.touched.tax && formik.touched.tax[index]?.percentage} value={tax.percentage} type='text' />
                                                {formik.errors.tax && formik.errors.tax[index]?.percentage && formik.touched.tax && formik.touched.tax[index]?.percentage ? <Typography className="error-msg">{formik.errors.tax[index]?.percentage}</Typography> : null}
                                            </div>
                                            <FontAwesomeIcon onClick={(event): void => 
                                            {
                                                handleTaxRemove(event, index);
                                            }} className='remove-icon' icon={['fal', 'circle-minus']} />
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                        <Box className="add-tax-block">
                            <Box className="mid-line"></Box>
                            <CustomButton style={{ position: 'absolute', right: '42%', top: '4px' }} btnType='secondary' name='+ Add Tax' type='button' onClick={handleAddTax} />
                        </Box>
                    </Box>}
                    
                    <Box className="sidebar-container-spacing">
                        <FormLabelComponent label="Ticket Cost" required />
                        <div className="ticket-price-container w-100">
                            <SelectComponent 
                                options={generateCurrencyOptions()}
                                optionLabels={currencyOptionLabel}
                                value={formik.values.currency}
                                selectedAsString
                                onChange={(event): void => 
                                {
                                    formik.setFieldValue('currency', String(event.target.value));
                                }}
                                customValueRender={(value) => {
                                    return (
                                        <div className="currency-select">
                                            <p>{getEmojiByCurrencyCode(value as string)}</p>
                                            <p>{getSymbolCurrency(value as string)}</p>
                                        </div>
                                    )
                                }}
                                defaultPlaceholder="Select Currency"
                            />
                            <div className="w-100">
                                <FormControlComponent
                                    type={'number'}
                                    // asInputGroup
                                    // inputGroupText='₹'
                                    placeholder='500'
                                    rows={4}
                                    value={formik.values.ticketPrice}
                                    onKeyDown={(evt): void => 
                                    {
                                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                    }}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('ticketPrice', event.target.value);
                                    }}
                                    isInvalid={!!formik.errors.ticketPrice && formik.touched.ticketPrice}
                                />
                                {formik.errors.ticketPrice && formik.touched.ticketPrice ? <Typography className="error-msg">{formik.errors.ticketPrice}</Typography> : null}
                            </div>
                        </div>
                    </Box>

                    {formik.values.includeTax && formik.values.tax && formik.values.tax?.length > 0 && <Box className="sidebar-container-spacing">
                        <div style={{ borderTop: '1px solid #E3E4E9', borderBottom: '1px solid #E3E4E9', padding: '8px 0' }}>
                            {
                                formik.values.tax?.map((tax, index) => {

                                    const taxValue = _.round((Number(Number(formik.values?.ticketPrice) * tax.percentage) / 100), 2);
                                    return(
                                        <div className="tax-calc-container" key={index}>
                                            <div className="tax-details">
                                                <p className="name">{`${tax?.name}`}</p>
                                                <p className="percentage">{`(${tax?.percentage}%)`}</p>
                                            </div>
                                            <p className="value">{`${getSymbolCurrency(formik.values.currency || Currency.INDIAN_RUPEE)}${taxValue}`}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="total-price-container">
                            <p>Total Cost</p>
                            <p>{getSymbolCurrency(formik.values.currency || Currency.INDIAN_RUPEE)}{Number(ticketPrice)?.toFixed(2)}</p>
                        </div>
                    </Box>}
                </>
                }

                <Box width={'500px'} className="submit-btn-container">
                    {(!ticketsInfo) ? <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton btnType='primary' loading={spinner} disabled={spinner || (formik.values.type === TicketType.PAID && !isPaymentIntegrationDone)} name={'Create Ticket'} type='submit' />
                    </Stack>
                    : (ticketsInfo) &&
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                            <CustomButton btnType='primary' loading={spinner} disabled={spinner || (formik.values.type === TicketType.PAID && !isPaymentIntegrationDone)} name={'Update Ticket'} type='submit' />
                        </Stack>
                        {!isTableView && <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />}
                    </Stack>
                    }
                </Box>
            </Form>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => removeTicketFromTable(ticketsInfo as EventTicket)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                        }} 
                        modalContent={`Are you sure you want to delete ${ticketsInfo?.name}?`}
                        modalTitle='Delete Ticket'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
        </Box >
    );
};

export default CreateTickets;


{ /* Maybe needed in future */ }
{ /* <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.LABEL.TICKET_GROUP_SELECT} />
                    <AutocompleteComponentWithFilterOption value={formik.values.ticketGroupId} defaultValue={formik.values.ticketGroupId} onChange={handleTicketGroupChange} filterOptions={ticketGroupFilterOptions} keyToShow='name' optionsArr={ticketGroupDetails} placeholder={ticketGroupDetails.length > 0 ? CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.PLACEHOLDER.TICKET_GROUP_SELECT : CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.PLACEHOLDER.TICKET_GROUP_EMPTY_SELECT} />
                    {formik.errors.ticketGroupId && formik.touched.ticketGroupId ? <Typography className="error-msg">{formik.errors.ticketGroupId}</Typography> : null}
                </Box> */ }
