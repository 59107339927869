import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Box, Grid } from '@mui/material';
import { uniqueId } from 'lodash';

interface IGraphComponent {
    GraphComponent: React.JSX.Element;
    GraphSpacingBlockCSS?: React.CSSProperties;
}
interface ITopPageAnalyticsContainerProps {
    TrendsComponent?: React.JSX.Element;
    GraphComponentsArray: IGraphComponent[];
    BlockStyling?: React.CSSProperties;
    GraphComponentBlockStyle?: React.CSSProperties;
}

const TopPageAnalyticsContainer = ({ TrendsComponent, GraphComponentsArray, BlockStyling, GraphComponentBlockStyle }: ITopPageAnalyticsContainerProps):React.JSX.Element => 
{  
    const [updatedKey, setUpdatedKey] = useState<string>('default-charts');

    useEffect((): () => void => 
    {
        const handleResize = (): void => 
        {
            setUpdatedKey(`${uniqueId()}-charts`);

        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return (): void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Box id='topPageAnalyticsContainer' key={updatedKey} sx={ BlockStyling? BlockStyling : null}>
            <Grid container className='analytics-trends-grid'>
                <Grid item xs={3}>
                    <Box className="trends-component-block" sx={ GraphComponentBlockStyle? GraphComponentBlockStyle : null}>
                        {TrendsComponent}
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className="graph-components-block" sx={ GraphComponentBlockStyle? GraphComponentBlockStyle : null}>
                        {GraphComponentsArray.map((graphComponent, index):React.JSX.Element => 
                        {
                            return (
                                <Box className="graph-component" key={index} sx={graphComponent?.GraphSpacingBlockCSS? graphComponent.GraphSpacingBlockCSS : null}>
                                    {graphComponent.GraphComponent}
                                </Box>
                            ); 
                        })}
                    </Box>
                </Grid>
            </Grid>
            {/* <Box className="trends-component-block">
                {TrendsComponent}
            </Box> */}
        </Box>
    );
};

export default TopPageAnalyticsContainer;