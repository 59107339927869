import React from 'react';
import { Box } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { IChartProps } from './ICharts';

const Charts = ({ height, chartOption, chartHeight, chartWidth, boxMargin }:IChartProps): React.JSX.Element => 
{
    return (
        <Box id="charts" height={height} margin={'0 8px'}>
            <ReactECharts
                option={chartOption}
                lazyUpdate={true}
                style={{
                    height: `${chartHeight? chartHeight:'100%'}`, width: `${chartWidth? chartWidth:'100%'}` 
                }}
                notMerge={true}
            />
        </Box>
    );
};

export default Charts;