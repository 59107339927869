import { useEffect, useMemo, useRef, useState } from "react";
import { CustomButton } from "../FormComponents/Buttons";
import { FormControlComponent, FormLabelComponent } from "../FormComponents/ReusableFormComponents";
import _ from "lodash";
import { flattenObjectWithNestedKeys } from "./utils/tanstacktable.utils";

import './styles.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TableFilterProps {
    // selectedOperator: 'contains' | 'equals' | 'notEquals';
    // inputValue: string | number;
    columnOptions: { label: string; value: string }[];
    // selectedColumn: string;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleColumnChange: (event: React.ChangeEvent<HTMLSelectElement>, index: number) => void;
    // filterFn: () => void;
    rows: any[];
    filters: FilterCriteria[];
    setRows: React.SetStateAction<React.Dispatch<any>>;
    addFilter: () => void;
    removeFilter: (index: number) => void;
    clearFilters: () => void;
    customMappings?: Record<string, Record<string, string>>;
    customFilterFunctions? : Record<string, (obj: any, inputValue: string | number) => boolean>;
}

interface FilterCriteria {
    selectedColumn: string;
    inputValue: string | number;
    // selectedOperator: 'contains' | 'equals' | 'notEquals';
}

const TableFilter: React.FC<TableFilterProps> = (props): React.JSX.Element =>
{
    const filterRef = useRef(null);
    const buttonRef = useRef(null);

    const { 
        columnOptions, 
        handleInputChange, 
        handleColumnChange, 
        filters, 
        addFilter, 
        removeFilter, 
        filterFn, 
        rows, 
        setRows, 
        customMappings,
        customFilterFunctions 
    } = props;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    // const [selectedOperator, setSelectedOperator] = useState<'contains' | 'equals' | 'notEquals'>('contains');

    const handleClick = () => 
    {
        setIsVisible((prev) => !prev);
    };

    const filterRows = () => 
    {
        const filteredRows = rows.filter(obj => {
            const flatObj = flattenObjectWithNestedKeys(obj);

            return filters.every(filter => {
                const value = flatObj[filter.selectedColumn];
                const { inputValue } = filter;   
                
                if(customFilterFunctions)
                {
                    const customFilterFunction = customFilterFunctions[filter?.selectedColumn];
                    if (typeof(customFilterFunction) === 'function') return customFilterFunction(obj, inputValue);
                }

                if(customMappings)
                {
                    const customMapping = customMappings[filter.selectedColumn];
    
                    // Handle custom filtering logic if a mapping is provided
                    if (customMapping) {
                        const mappedValue = customMapping[value];
                        if (mappedValue) {
                            return mappedValue?.toLowerCase().includes(String(inputValue)?.toLowerCase());
                        }
                        return false;
                    }
                }
                            
                if (Array.isArray(value)) {
                    return value.some(val => _.isString(val) && val.toLowerCase().includes(String(inputValue)));
                }
                if (_.isString(value)) {
                    return value.toLowerCase().includes(String(inputValue).toLowerCase());
                } else if (_.isNumber(value)) {
                    return value === Number(inputValue);
                }
                return false;
            });
        });

        setRows(filteredRows);
    };

    const getAvailableColumns = (index: number) => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        return columnOptions.filter(option => !selectedColumns.includes(option.value) || filters[index].selectedColumn === option.value);
    };

    const hasAvailableColumns = (): boolean => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        return columnOptions.some(option => !selectedColumns.includes(option.value));
    };

    useEffect(() => 
    {
        const handleClickOutside = (event) => 
        {
            if (filterRef.current && !filterRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) 
            {
                setIsVisible(false);
            }
        };

        if (isVisible) 
        {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else    
        {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => 
        {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isVisible]);

    useEffect(() => 
    {
        filterRows();
    }, [filters, rows]);    

    const handleButtonClick = () => 
    {
        setIsVisible((prev) => !prev);
    };

    return (
        <div className="tanstack-table-filter">
            <CustomButton onClick={handleButtonClick} name="Filters" btnType="tertiary_grey" ref={buttonRef} />
            {
                isVisible && (
                    <div ref={filterRef} className="tanstack-table-filter__content">
                        <div className="heading">
                            <FormLabelComponent label='Columns' />
                            <FormLabelComponent label='Value' />
                        </div>
                        <div className="filter-container">
                            {filters.map((filter, index) => (
                                <div key={index} className="filter-group">
                                    <select className="filter-select" value={filter.selectedColumn} onChange={(event) => { handleColumnChange(event, index); }}>
                                        {    
                                            getAvailableColumns(index).map((column, idx) => 
                                            {
                                                return (
                                                    <option key={idx} value={column.value}>{column.label}</option>
                                                )
                                            })
                                        }
                                    </select>

                                    {/* <div>
                                        <FormLabelComponent label='Operator' />
                                        <select className="filter-select" value={filter.selectedOperator} onChange={(event) => setSelectedOperator(event.target.value)}>
                                            <option value="contains">Contains</option>
                                            <option value="equals">Equals</option>
                                            <option value="notEquals">Not Equals</option>
                                        </select>
                                    </div> */}

                                    <FormControlComponent 
                                        value={filter.inputValue} 
                                        onChange={(event) => handleInputChange(event, index)} 
                                        type="text"
                                        placeholder="Filter Value"
                                    />

                                    {filters?.length > 1 &&
                                        <FontAwesomeIcon icon={['fal', 'circle-minus']} 
                                            onClick={() => removeFilter(index)}
                                            cursor={'pointer'}
                                        /> 
                                    }
                                </div>
                            ))}
                        </div>
                        {hasAvailableColumns() && <div className="add-filter-button">
                            <CustomButton 
                                name="Add Filter" 
                                btnType="primary_outline"
                                onClick={addFilter}
                            />
                        </div>}

                        {
                            filters?.length > 0 && 
                            <div className="clear-filter-button">
                                <CustomButton 
                                    name="Clear Filters" 
                                    btnType="danger_outline"
                                    onClick={props.clearFilters}
                                />
                            </div>
                        }
                        {/* {(inputValue && String(inputValue)?.length > 0) && 
                        <div className="clear-filter-button">
                            <CustomButton 
                                name="Remove Filters" 
                                btnType="danger_outline" */}
                                {/* // onClick={() => {
                                //     setInputValue('');
                                //     setSelectedColumn(columnOptions[0]?.value || '');
                                //     setSelectedOperator('contains');
                                // }}  */}
                            {/* />
                        </div>} */}
                    </div>
                )
            }
        </div>
    )
};

export default TableFilter;