import React, { useContext } from 'react'
import Stepper from '../Stepper';
import FilterEventTargetListComponent from '../Components/ByPastEvents/FilterEventTargetListComponent';
import BuildTargetListComponent from '../Components/ByPastEvents/BuildTargetListComponent';
import ViewAllEventInTargetListComponent from '../Components/ByPastEvents/ViewAllEventInTargetListComponent';
import { useDispatch, useSelector } from 'react-redux';
import { creatTargetListByEventId, updateEventTargetList } from '../../../../../scripts/apis/eventTargetList';
import { EventTargetList, IEventTargetListDispatch, IEventTargetListReduxData } from '../../../interfaces/event-target-list-interface';
import { useNavigate } from 'react-router-dom';
import { resetTargetList } from '../../../../../redux/events/eventTargetList/targetList';
import { EventTargetListContext } from '../../../../../contexts/EventTargetList/EventTargetListContext';

export interface CheckoutStep
{
  name: string;
  Component: React.FC;
  onComplete?: () => void;
}

const TargetListByPastEventsPage: React.FC = (): JSX.Element =>
{

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listId, existingTargetList } = useContext(EventTargetListContext);

  const eventTargetListReduxData = useSelector((state: IEventTargetListDispatch): IEventTargetListReduxData => 
  {
    return state.eventTargetList.value;
  });
  const csrfTokenData = useSelector((state): string => 
  {
    return state['csrfTokenValue'].value.csrfToken;
  });

  const submitTargetList = async () =>
  {
    try
    {
      const eventId = eventTargetListReduxData.eventId;

      const mergedAudienceIds = Array.from(new Set([
        ...(existingTargetList?.audienceIds || []),
        ...(eventTargetListReduxData.audienceIds || [])
      ]));

      const mergedTargetListAudienceIds = Array.from(new Set([
        ...(existingTargetList?.targetListAudienceIds || []),
        ...(eventTargetListReduxData.targetListAudienceIds || [])
      ]));

      const mergedSources = Array.from(new Set([
        ...(eventTargetListReduxData.source || []),
        ...(existingTargetList?.source || [])
      ]));

      const eventTargetList: EventTargetList = {
        name: eventTargetListReduxData.name,
        description: eventTargetListReduxData.description,
        type: eventTargetListReduxData.type,
        source: mergedSources,
        audienceIds: mergedAudienceIds,
        targetListAudienceIds: mergedTargetListAudienceIds,
      };

      if (listId)
      {
        await updateEventTargetList(eventId, listId, eventTargetList);
        navigate(`/events/${ eventId }/target-list/${listId}`);
      }
      else
      {
        const result = await creatTargetListByEventId(eventId, eventTargetList, csrfTokenData);
        navigate(`/events/${ eventId }/target-list/${result?.id}`);
      }

      dispatch(resetTargetList());
    }
    catch (error) 
    {
      console.error('Error submitting target list:', error);
      throw error;
    }
  };

  const CHECKOUT_STEPS: CheckoutStep[] = [
    {
      name: "Past Events",
      Component: ViewAllEventInTargetListComponent
    },
    {
      name: "Filters",
      Component: FilterEventTargetListComponent
    },
    {
      name: "Build List",
      Component: BuildTargetListComponent,
      onComplete: async () =>
      {
        try 
        {
          await submitTargetList();
        }
        catch (error)
        {
          console.error("Submission failed:", error);
        }
      },
    },
  ];

  return (<Stepper props={ CHECKOUT_STEPS } />)
}

export default TargetListByPastEventsPage;

