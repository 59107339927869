import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import _ from 'lodash';
import { getAudienceEventPreference, getAudienceParticipatedEvents } from '../../scripts/apis/audience';
import { CONTENT } from '../../scripts/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import { AudienceInfoProps } from './ViewAudienceInterface';
import { CloseIconComponent } from '../../common/FormComponents/ReusableFormComponents';
import MuiChip from '../../common/FormComponents/MuiChip';
import FormTabs from '../../common/FormTabs';
import { EventLocationType } from '../Events/enum';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import toast from 'react-hot-toast';

enum EventType { 
    ALL = 1,
    ONSITE = 2,
    VIRTUAL = 3,
    HYBRID = 4,
}

const ViewAudienceInfo = (props: AudienceInfoProps):React.JSX.Element => 
{

    const navigate = useNavigate();

    const audienceId = props?.audienceData?.id;
    const name = props.name;
    const email = props?.audienceData.email;
    const clearbitData = props?.audienceData?.clearbitData;

    const [audienceDataByEvent, setAudienceDataByEvent] = useState({
        all: 0,
        onsite: 0,
        webinar: 0,
        hybrid: 0,
        events: [],
    });
    const [accordionExpansion, setAccordionExpansion] = React.useState<string | boolean>('enrichment');
    const [selectedEventType, setSelectedEventType] = useState<number>(1);
    const [rows, setRows] = useState([]);

    const handleAccoridon = (propertyId) => 
    {
        return (event: React.SyntheticEvent, newExpanded: boolean):void => 
        {
            setAccordionExpansion(newExpanded ? propertyId : false);
        }; 
    };

    const tabsData = [
        {
            tabName: 'All',
            selectedTab: selectedEventType === EventType.ALL,
            count: audienceDataByEvent.all,
            navigation: (): void => 
            {
                setRows(audienceDataByEvent.events);
                setSelectedEventType(EventType.ALL);
            }
        },
        {
            tabName: 'On-site',
            selectedTab: selectedEventType === EventType.ONSITE,
            count: audienceDataByEvent.onsite,
            navigation: (): void => 
            {
                const newRows = audienceDataByEvent.events.filter((row):boolean => {
                    return row?.locationType === EventLocationType.ONSITE;
                });
                setRows(newRows);
                setSelectedEventType(EventType.ONSITE);
            }
        },
        {
            tabName: 'Virtual',
            selectedTab: selectedEventType === EventType.VIRTUAL,
            count: audienceDataByEvent.webinar,
            navigation: (): void => 
            {
                const newRows = audienceDataByEvent.events.filter((row):boolean => {
                    return row?.locationType === EventLocationType.VIRTUAL;
                });
                setRows(newRows);
                setSelectedEventType(EventType.VIRTUAL);
            }
        },
        {
            tabName: 'Hybrid',
            selectedTab: selectedEventType === EventType.HYBRID,
            count: audienceDataByEvent.hybrid,
            navigation: (): void => 
            {
                const newRows = audienceDataByEvent.events.filter((row):boolean => {
                    return row?.locationType === EventLocationType.HYBRID;
                });
                setRows(newRows);
                setSelectedEventType(EventType.HYBRID);
            }
        },
    ]

    const AudienceParticipatedEventsComponent = ():React.JSX.Element => 
    {

        // const columns = [
        //     {
        //         field: 'title', headerName: 'Title', flex: 1, editable: false, disableColumnMenu: true,
        //     },
        //     {
        //         field: 'locationType', headerName: 'Type', flex: 1, editable: false, disableColumnMenu: true, renderCell: (params): React.JSX.Element => 
        //         {
        //             return eventType(params.row);
        //         }
        //     },
        // ];

        const eventType = (row): React.JSX.Element => 
        {
            const eventTypeChipLabel = CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[Number(row.locationType)];
            const chipColor = (row.locationType === EventLocationType.ONSITE) ? 'pink' : (row.locationType === EventLocationType.VIRTUAL) ? 'blue' : 'green';
            return (
                <MuiChip label={eventTypeChipLabel} chipColor={chipColor} />
            );
        };

        const columnHelper = createColumnHelper();
        const columns = [
            columnHelper.accessor('title', {
                cell: (row) => {
                    return <p className="bolded-cellContent">{row?.getValue()}</p>
                },
                header: 'Event Name',
                sortingFn: 'alphanumericCaseSensitive',
                id: 'title',
                size: 200
            }),
            columnHelper.accessor('locationType', {
                cell: (row) => {
                    return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[row.getValue()]}</p>)
                },
                header: 'Location',
                sortingFn: 'alphanumericCaseSensitive',
                id: 'locationType',
                size: 200
            }),
        ]

        return (
            <Box className="audience-events-table-container">
                <TanstackTable 
                    initialColumns={columns}
                    data={rows}
                    rowCount={rows.length}
                    hidePagination
                    showSpinner={false}
                />
            </Box>
        );
    };

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/audiences');
        }
    };

    const handleCopyEmail = ():void => 
    {
        navigator.clipboard.writeText(email).then(function ():void 
        {
            toast.success('Email copied to clipboard');
        });

    };

    const fetchData = async ():Promise<void> => 
    {
        try 
        {
            const audienceByEvent = await getAudienceEventPreference(Number(audienceId));
            const audienceParticipatedEvents = await getAudienceParticipatedEvents(Number(audienceId));

            if (audienceByEvent && audienceParticipatedEvents && audienceParticipatedEvents.length > 0) 
            {
                const updatedAudienceParticipatedEvents = audienceParticipatedEvents.map((item: { title: string, locationType: number }, index: number): {
                    title: string;
                    locationType: number;
                    id: number;
                } => 
                {
                    return {
                        id: index, ...item 
                    };
                });

                setRows(updatedAudienceParticipatedEvents);
                setAudienceDataByEvent((prevCounts) => {
                    return {
                        ...prevCounts,
                        all: audienceByEvent.eventLocationType.onsite + audienceByEvent.eventLocationType.webinar + audienceByEvent.eventLocationType.hybrid,
                        onsite: audienceByEvent.eventLocationType.onsite,
                        webinar: audienceByEvent.eventLocationType.webinar,
                        hybrid: audienceByEvent.eventLocationType.hybrid,
                        events: [...updatedAudienceParticipatedEvents],
                    }; 
                });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(():void => 
    {
        fetchData();
    }, []);

    return (
        <Box id="viewAudienceData">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Box className="audience-basic-detail-container">
                <Box className="logo-container">
                    {clearbitData && clearbitData?.companyLogo ? <Avatar src={clearbitData.companyLogo} className="company-logo-img" /> : <Avatar className="audience-empty-logo-img">{name.charAt(0).toUpperCase()}</Avatar>}
                </Box>
                <Box className="name-email-company-container">
                    <Typography className="audience-name">{name}</Typography>
                    {/* {companyName && <Typography className="field-value">{companyName}</Typography>} */}
                    <Box className="email-box">
                        <Typography className="audience-email">{email}</Typography>
                        <FontAwesomeIcon onClick={():void => 
                        {
                            handleCopyEmail(); 
                        }} className="copy-email-icon" icon={['fal', 'copy']} />
                    </Box>
                </Box>
            </Box>
            <Box className="icp-bp-cont">
                {props?.audienceData?.icp && props?.audienceData?.icp?.length > 0 && <MuiChip circleIcon label={props?.audienceData?.icp?.toString()} chipColor='blue' />}
                {props?.audienceData?.buyerPersona && props?.audienceData?.buyerPersona?.length > 0 && <MuiChip circleIcon label={props?.audienceData?.buyerPersona?.toString()} chipColor='red' />}
            </Box>

            {audienceDataByEvent.all !== 0 && <div>
                <p className="events-heading">Events</p>
                <FormTabs tabs={tabsData} />
            </div>}

            {audienceDataByEvent.all !== 0 && <Box className="sidebar-container-spacing"><AudienceParticipatedEventsComponent /></Box>}

            {clearbitData && <Box className="sidebar-container-spacing"><EnrichmentComponent clearbitData={clearbitData} accordionExpansion={accordionExpansion} handleAccoridon={handleAccoridon} fromRegistrant={undefined} /></Box>}
        </Box>
    );
};

export default ViewAudienceInfo;

export const EnrichmentComponent = ({ clearbitData, accordionExpansion, handleAccoridon, fromRegistrant }):React.JSX.Element => 
{

    const generateRowsRecursive = (obj = {
    }, prefix = '') => 
    {
        const rows = [];

        for (const key in obj) 
        {
            const fullKey = prefix ? `${prefix}.${key}` : key;
            if (obj[key] && typeof obj[key] === 'object' && !(Array.isArray(obj[key]))) 
            {
                rows.push(...generateRowsRecursive(obj[key], fullKey));
            }
            else 
            {
                rows.push({
                    field: key,
                    value: obj[key]
                });
            }
        }
        return rows;
    };

    const generateRows = (data) => 
    {
        const excludedFields = ['clearbitData.clearbitId', 'clearbitId'];
        let clearBitDataKeys = [];

        if (data && typeof data === 'object') 
        {
            clearBitDataKeys = [...clearBitDataKeys, ...generateRowsRecursive(data, 'clearbitData')];
        }

        const rows = [...clearBitDataKeys];
        const filteredEnrichmentData = rows.filter((column):boolean => 
        {
            return !excludedFields.includes(column.field); 
        });
        return filteredEnrichmentData;
    };

    const encrichmentData = generateRows(clearbitData);

    return (
        <Box className="enrichment-container">
            <Accordion  expanded={accordionExpansion === 'enrichment'} id={'enrichment'} className="enrichment-accordian" onChange={handleAccoridon('enrichment')}>
                <AccordionSummary
                    className="enrichment-accordian-summary"
                    aria-controls="panel1a-content"
                    id="enrichment-header"
                    expandIcon={<FontAwesomeIcon className="dropdown-icon" icon={['fal', fromRegistrant && accordionExpansion === 'enrichment' ? 'chevron-up' : fromRegistrant && accordionExpansion !== 'enrichment' ? 'chevron-right' : 'chevron-down']} />}
                >
                    <Box className="heading-container">
                        {/* {accordionExpansion ? <FontAwesomeIcon className="dropdown-icon" icon={['fal', 'chevron-up']} /> : <FontAwesomeIcon className="dropdown-icon" icon={['fal', 'chevron-down']} />} */}
                        <Typography className="accordian-title">{'About this Contact'}</Typography>
                        <Box className="company-container">
                            <Typography className="accordian-sub-title">{'(Enriched by Clearbit'} </Typography>
                            <Box component="img" src={'https://www.google.com/s2/favicons?sz=64&domain_url=https://clearbit.com/'} className="clearbit-logo" />
                            <Typography className="accordian-sub-title">{' ' + ')'} </Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="enrichment-accordian-details">
                    <Box className="details-container">
                        {encrichmentData.map((element, index):React.JSX.Element => 
                        {
                            const formTitle = _.startCase(element.field);
                            return (
                                <Box key={index} className="enrichment-component-spacing">
                                    <Typography className="enrichment-label">{formTitle}</Typography>
                                    <Typography className="enrichment-value">{element.value ? element.value : '-'}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );

};