import React, { useContext } from 'react';
import { Box, Typography, } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../../scripts/i18n';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { updateGeneralSettings, uploadUserImage } from '../../../scripts/apis/users';
import { isImageBuffer } from '../../../scripts/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import './styles.scss';
import { useDropzone } from 'react-dropzone';
import { PersonalPageContext } from '../../../contexts/Settings/PersonalPageContext';
import { useSelector } from 'react-redux';
import { FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { UserViewType } from '../enum/users.enum';
import { UserContext } from '../../../contexts/UserContext';
import { MinimalViewComponent } from '../../../common/MinimalView/MinimalViewComponent';

const PersonalPage: React.FC = (): React.JSX.Element => 
{

    const { setProfileImage, setSpinner, profileImage, setRefresh, user, spinner, isEditing, setIsEditing } = useContext(PersonalPageContext);

    const {
        userDetails,
        setUserContextRefresh
    } = useContext(UserContext);

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const { getInputProps, getRootProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        disabled: isEditing,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setProfileImage(file);
                    // setProfile(URL.createObjectURL(file));
                    formik.setFieldValue('profileImage', URL.createObjectURL(file));
                }
                else 
                {
                    setProfileImage(null);
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name cannot be empty'),
        lastName: Yup.string()
            .required('Last Name cannot be empty'),
        // orgName: Yup.string()
        //     .required('Organisation Name cannot be empty')
    });    

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnMount:false,
        initialValues: {
            firstName: userDetails?.firstName ? userDetails?.firstName : '',
            lastName: userDetails?.lastName ? userDetails?.lastName : '',
            profileImage: userDetails?.additionalInfo?.image ? userDetails?.additionalInfo?.image : undefined,
            viewType: userDetails?.viewType || UserViewType.NORMAL,
        },
        // onSubmit: async (): Promise<void> => 
        // {
        //     setSpinner(true);

        //     let imageUpdated: string, userUpdated: User;
        //     if (userDetails.additionalInfo.image !== profileImage) 
        //     {
        //         const image = new FormData();
        //         image.append('file', profileImage);
        //         try 
        //         {
        //             imageUpdated = await uploadUserImage(image, csrfTokenData);
        //         }
        //         catch (error) 
        //         {
        //             console.log(error);
        //         }
        //     } 
        //     else setSpinner(false);

        //     if (userDetails.firstName !== formik.values.firstName || userDetails.lastName !== formik.values.lastName) 
        //     {
        //         const userDetails = {
        //             firstName: formik.values.firstName,
        //             lastName: formik.values.lastName,
        //         };
        //         try 
        //         {
        //             userUpdated = await updateGeneralSettings(userDetails);
        //             if (userUpdated) 
        //             {
        //                 LocalStorage.set('@UserMe', {
        //                     firstName: userUpdated.firstName,
        //                     lastName: userUpdated.lastName,
        //                 });
        //             }
        //         }
        //         catch (error) 
        //         {
        //             console.log(error);
        //         }
        //     }
        //     else setSpinner(false);

        //     if (imageUpdated || userUpdated) 
        //     {
        //         setSpinner(false);
        //         setRefresh(true);
        //     }
        // }
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);

            const imageFormData = new FormData();
            imageFormData.append('file', profileImage);

            const imagePromise = ((userDetails?.additionalInfo?.image !== formik.values?.profileImage) && profileImage)
                ? uploadUserImage(imageFormData, csrfTokenData)
                : Promise.resolve();

            const userPromise = userDetails && (userDetails.firstName !== formik.values.firstName || userDetails.lastName !== formik.values.lastName || userDetails?.viewType !== formik.values.viewType)
                ? updateGeneralSettings({
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    viewType: formik.values.viewType,
                })
                : Promise.resolve();

            try 
            {
                const [imageUpdated, userUpdated] = await Promise.all([imagePromise, userPromise]);

                if (userUpdated) 
                {
                    LocalStorage.set('@UserMe', {
                        firstName: userUpdated.firstName,
                        lastName: userUpdated.lastName,
                    });
                }

                if (imageUpdated || userUpdated) 
                {
                    setUserContextRefresh(true);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally 
            {
                setSpinner(false);
                setIsEditing(!isEditing);
            }
        }
    });

    const renderPersonalPage = (): React.JSX.Element => { 
        return (
            <Box id={userDetails?.viewType === UserViewType.NORMAL ? 'globalBlock' : ''}>
                <Box id="PersonalPage">
                    <Form noValidate onSubmit={(values): void => 
                    {
                        return formik.handleSubmit(values);
                    }} autoComplete="off">

                        {userDetails?.viewType === UserViewType.NORMAL && <Box className="title-cont">
                            <Typography className="title-text">{'Edit Profile'}</Typography>
                            <CustomButton btnType={isEditing ? 'secondary' : 'primary'} disabled={!isEditing} name={'Edit'} onClick={(): void => 
                                {
                                        setIsEditing(!isEditing);
                                }} type={isEditing ? 'submit' : 'button'}  />
                        </Box>}

                        <Box padding={userDetails?.viewType === UserViewType.NORMAL ? '16px' : '16px 0'} className="profile-form-cont">
                            <Box className="profileImage-and-button-cont">
                                <Box>
                                    {
                                        formik.values?.profileImage ? 
                                            <Box className="profile-img-cont">
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps({
                                                        disabled: isEditing 
                                                    })} />
                                                    
                                                    <img src={formik.values?.profileImage} alt="Preview" />
                                                    
                                                    {!isEditing && formik.values?.profileImage && (
                                                        <Box className="icon-box">
                                                            <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(event): void => 
                                                            {
                                                                event.stopPropagation();
                                                                setProfileImage(null);
                                                                formik.setFieldValue('profileImage', null);    
                                                            }} />
                                                        </Box>
                                                    )}
                                                </div>
                                            </Box> :
                                            <Box className="empty-profileImg-cont" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                                            </Box>
                                    }
                                </Box>
                                <div />
                            </Box>
                                
                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.LABEL.FIRST_NAME} />
                                <FormControlComponent
                                    type='text'
                                    placeholder={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.PLACEHOLDER.FIRST_NAME}
                                    value={formik.values.firstName}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('firstName', event.target.value);
                                    }}
                                    disabled={isEditing}
                                />
                                {formik.errors.firstName && formik.touched.firstName ? <Typography className="error-msg">{formik.errors.firstName}</Typography> : null}
                            </Box>

                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.LABEL.LAST_NAME} />
                                <FormControlComponent
                                    type='text'
                                    placeholder={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.PLACEHOLDER.LAST_NAME}
                                    value={formik.values.lastName}
                                    disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('lastName', event.target.value);
                                    }}
                                />
                                {formik.errors.lastName && formik.touched.lastName ? <Typography className="error-msg">{formik.errors.lastName}</Typography> : null}
                            </Box>

                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.LABEL.LOGGED_IN_EMAIL} />
                                <FormControlComponent
                                    type='email'
                                    placeholder={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.PLACEHOLDER.LOGGED_IN_EMAIL}
                                    value={userDetails?.email as string}
                                    disabled
                                />
                            </Box>

                            <div className="sidebar-container-spacing">
                                <div className="minimal-mode-toggle">
                                    <FormLabelComponent label="Minimalistic Mode" />
                                    <CustomSwitch
                                        disabled={isEditing} 
                                        checked={formik.values.viewType === UserViewType.MINIMAL}
                                        onChange={(event) => {
                                            formik.setFieldValue('viewType', event.target.checked ? UserViewType.MINIMAL : UserViewType.NORMAL);
                                        }}
                                    />
                                </div>
                            </div>

                            <Box className="sidebar-container-spacing profile-submit-button-cont">
                                <CustomButton disabled={isEditing} btnType='primary' name='Save' loading={spinner} type='submit' />
                                <CustomButton disabled={isEditing} btnType='secondary' name='Cancel' type='button' onClick={() => 
                                    {
                                        setIsEditing(!isEditing);
                                        formik.resetForm();
                                    }
                                } />
                            </Box>
                        </Box>
                    </Form>
                </Box>
                {/* <Box className='profile-organization-block'>
                    {userInfo?.role === 3 && <OrganisationPageProvider><OrganizationPage /></OrganisationPageProvider>}
                </Box> */}
            
            </Box>
        )
    };
    
    const renderMinimalViewForPersonalPage = (): React.JSX.Element => {
        return (
            <MinimalViewComponent
                title="Profile"
                description="Edit your profile details here." 
                titleBarActions={
                    [<CustomButton btnType={isEditing ? 'secondary' : 'primary'} disabled={!isEditing} name={'Edit'} onClick={(): void => 
                        {
                                setIsEditing(!isEditing);
                        }} type={isEditing ? 'submit' : 'button'}  />]
                }
                component={renderPersonalPage()}
                componentType="list"
            />
        );
    };

    return (
        <>
            {
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForPersonalPage() : renderPersonalPage()
            }
        </>
    );
};
export default PersonalPage;