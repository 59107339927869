import './Placeholder.css';

import {ReactNode} from 'react';

export default function Placeholder({
  children,
  className,
  left
}: {
  children: ReactNode;
  className?: string;
  left?: string;
}): JSX.Element {
  return <div style={{ left: left || '28px' }} className={className || 'Placeholder__root'}>{children}</div>;
}
