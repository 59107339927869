
import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    // eslint-disable-next-line import/named
    RadioProps,
    TextField,
    Typography, } from '@mui/material';
import { Container, Form, Spinner } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import './styles.scss';
import { EmptyRadioBtn, RadioBtn } from '../../../common/StyledComponents/CustomRadio.styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { postRegistrationFormData, validateCoupon, } from '../../../scripts/apis/registrationPublic';
import { addFormComponent, resetFormComponents } from '../../../redux/registrationsForms/FormComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../../scripts/event-bus';
import { BpCheckedIcon, BpIcon } from '../../../common/StyledComponents/CustomCheckBox.styled';
import _ from 'lodash';
import { RegistrationFormComponentDisabled, RegistrationFormCaptcha } from '../enum';
import { CustomFieldMandatory, CustomFieldType } from '../enum/custom-field.enum';
import { ConvertStringtoCamelCase } from '../../Events/EventDetails/People/EventRegistrants/RegistrantDetails';

import toast from 'react-hot-toast';
import { EventTicket } from '../../Events/interfaces';
import { EventCoupon } from '../../Events/interfaces/event-coupon_interface';
import { TicketType } from '../../Events/enum';
import NotFoundPage from './NotFoundPage';

// import { CheckedIcon, EmptyCheckedIcon } from '../../../Settings/CustomField/CustomTypeComponent';

// enum RegistrationTypes {
//     OPEN = 1,
//     INVITE = 2,
//     CUSTOM = 3
// }

enum RegistrationTypes {
    OPEN = 1,
    INVITE_ONLY = 2,
}
export interface IAlertProps {
    show: boolean;
    severity?: string;
    message?: string;
}

// Define named constants for component types
const ComponentType = {
    SingleLine: 1,
    MultiLine: 2,
    CheckBox: 3,
    DropDown: 4,
    Date: 5,
    Numeric: 6,
    Heading: 7,
    Paragraph: 8,
    SingleSelect: 9,
    Legal1: 11,
    Legal2: 12,
    Legal3: 13,
};

// const useStyles = makeStyles((): {
//     formSelectAutocomplete: {
//         background: string;
//     };
// } => 
// {
//     return {
//         formSelectAutocomplete: {
//             background: 'red'
//         },
//     }; 
// });


const FormPreview = (props):React.JSX.Element => 
{
    const [formState, setFormState] = useState({
    });
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const captchaRef = useRef(null);
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [couponCode, setCouponCode] = useState<string>(props?.eventCoupon?.name || '');
    const [isCouponValid, setIsCouponValid] = useState<EventCoupon | false>(props?.eventCoupon ? props?.eventCoupon : false);
    const [showAlert, setShowAlert] = useState<IAlertProps>({
        show: false 
    });
    // const [registrationType, setRegistrationFormType] = useState(null);
    const [submitted, isSubmitted] = useState({
        message: null,
        redirectionUrl: null,
        paymentUrl: null,
    });
    const [error, setError] = useState(false);
    const [mandatoryField, setMandatoryField] = useState<{ [key: string]: boolean }>({
    });
    const [showSpinner, setShowSpinner] = useState(false);
    const [enableErrorFields, setEnableErrorFields] = useState(false);
    const dispatch = useDispatch();
    const registrationFormData = props.registrationFormData;
    const validRegistrationForm = props?.eventToken && props.isValidRegistrationForm || false;

    const formComponents = props?.registrationFormData?.registrationForm;

    useEffect(():()=>void => 
    {
        if (props.isValidRegistrationForm && registrationFormData) 
        {
            if (registrationFormData.registrationType) 
            {
                // setRegistrationFormType(registrationFormData.registrationType);
                // if (registrationFormData.registrationType !== RegistrationTypes.OPEN) 
                // {
                if (registrationFormData.eventTickets) 
                {
                    setEventTickets(registrationFormData.eventTickets);
                }
                // }
            }
            setIsCaptcha(registrationFormData.registrationForm.captcha === RegistrationFormCaptcha.TRUE);
        }

        if (registrationFormData?.registrationForm?.formFields.length > 0) 
        {
            registrationFormData.registrationForm.formFields?.map((elem):void => 
            {
                elem.fieldMandatory = elem?.updatedLabels && elem?.updatedLabels?.length > 0 ? Number(elem?.updatedLabels[0].fieldMandatory) : elem?.fieldMandatory;

                const isFieldMandatory = ((elem?.fieldMandatory === CustomFieldMandatory.MANDATORY) || (elem?.updatedLabels && elem?.updatedLabels[0]?.fieldMandatory == CustomFieldMandatory.MANDATORY));

                if (isFieldMandatory)
                {
                    setMandatoryField((prevState):{
                        [key: string]: boolean;
                    } => 
                    {
                        return {
                            ...prevState,
                            [elem.id]: false,
                        }; 
                    });
                }
                dispatch(
                    addFormComponent({
                        id: elem.id,
                        type: elem.type,
                        default: elem.default,
                        subType: elem.subType,
                        helpText: elem.helpText,
                        label: elem?.label,
                        placeholder: elem.placeholder,
                        typeAttribute: elem.typeAttribute,
                        componentMandatory: elem?.updatedLabels && elem?.updatedLabels?.length > 0 && elem?.updatedLabels[0]?.formId == registrationFormData?.registrationForm?.id ? elem?.updatedLabels[0]?.fieldMandatory : elem.fieldMandatory,
                        componentDisabled: elem.fieldDisabled === RegistrationFormComponentDisabled.DISABLED ? true : false,
                        componentOptions: elem.fieldOptions,
                        componentNumeric: {
                            ...elem.fieldOptions 
                        },
                        componentGDPR: elem.componentGDPR ? elem.componentGDPR : null,
                        paragraph: elem?.paragraph,
                        heading: elem?.heading,
                    })
                );
            });
        }

        if(registrationFormData?.registrationForm?.formGDPR?.gdpr?.length > 0)
        {
            registrationFormData?.registrationForm?.formGDPR?.gdpr?.map((elem) => {
                if(elem && elem?.fieldOptions && elem?.fieldOptions!== '')
                {
                    setMandatoryField((prevState):{
                        [key: string]: boolean;
                    } => 
                    {
                        return {
                            ...prevState,
                            [ConvertStringtoCamelCase(elem?.fieldOptions?.name)]: false,
                        }; 
                    });
                }
            })
        }

        if (props.isValidRegistrationForm) 
        {
            return ():void => 
            {
                dispatch(resetFormComponents());
            };
        }

    }, [registrationFormData]);

    function createValidPayload(data):{
        additionalData: object;
        eventTicketId: string;
        registerTimestamp?: number;
        couponCode?: string;
    } 
    {
        const registerTimestamp = Math.floor(new Date().getTime()/1000.0);
        const validPayload = {
            additionalData: {
            }, eventTicketId: data['eventTicketId'], registerTimestamp
        };

        if(couponCode && isCouponValid)
        {
            validPayload.couponCode = couponCode;
        }

        if (registrationFormData?.registrationForm?.formFields || registrationFormData?.registrationForm?.formGDPR?.gdpr?.length > 0) 
        {
            registrationFormData.registrationForm.formFields.forEach((item):void => 
            {

                if (item.default) 
                {
                    const key = item.default;
                    if (data[key] !== undefined) 
                    {
                        validPayload[key] = data[key];
                    }
                }
                else 
                {
                    if (data[item?.propertyKey]) 
                    {
                        validPayload.additionalData = {
                            ...validPayload.additionalData,
                            [item?.propertyKey]: data[item?.propertyKey],
                        };
                    }
                }
            });
            // Convert keys in additionalData to camel case
            const additionalDataCamelCase = {
            };
            for (const key in validPayload.additionalData) 
            {
                const keyWithoutTags = key.replace(/<\/?p>/g, '');

                const camelCaseKey = keyWithoutTags
                    .split(' ')
                    .map((word, index):string => 
                    {
                        if (index === 0) 
                        {
                            return word.toLowerCase();
                        }
                        else 
                        {
                            return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            );
                        }
                    })
                    .join('');

                additionalDataCamelCase[camelCaseKey] = validPayload.additionalData[key];
            }

            if(registrationFormData?.registrationForm?.formGDPR?.gdpr?.length > 0)
            {
                registrationFormData?.registrationForm?.formGDPR?.gdpr?.forEach((items):void => 
                {
                    if (items?.fieldOptions?.name) 
                    {
                        validPayload.additionalData = {
                            ...validPayload.additionalData,
                            ['gdprData']: {
                                ...validPayload.additionalData['gdprData'],
                                [items.fieldOptions?.name]: data[items.fieldOptions?.name],
                            }
    
                        };
                    }
                });
            }


            if (validPayload.additionalData['gdprData']) 
            {
                const gdprDataCamelCase = {
                };
                for (const key in validPayload.additionalData['gdprData']) 
                {
                    const keyWithoutTags = key.replace(/<\/?p>/g, '');

                    const camelCaseKey = keyWithoutTags
                        .split(' ')
                        .map((word, index):string => 
                        {
                            if (index === 0) 
                            {
                                return word.toLowerCase();
                            }
                            else 
                            {
                                return (
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                );
                            }
                        })
                        .join('');

                    gdprDataCamelCase[camelCaseKey] =
                        validPayload.additionalData['gdprData'][key];
                }
                validPayload.additionalData = validPayload.additionalData;
                validPayload.additionalData['gdprData'] = gdprDataCamelCase;
            }
            else 
            {
                validPayload.additionalData = validPayload?.additionalData;
            }

            return validPayload;
        }
    }

    const postFormData = async (payload): Promise<void> => 
    {
        setShowSpinner(true);
        const validPayloadData = createValidPayload(payload);
        if(validPayloadData.additionalData['gdprData'] && Object.values(validPayloadData.additionalData['gdprData'])?.length > 0 )
        {
            const isInvalid = Object?.values(validPayloadData.additionalData['gdprData'])?.includes(2) || Object?.values(validPayloadData.additionalData['gdprData'])?.includes(undefined) ;
            if(isInvalid)
            {
                toast.error('Please check all the required fields');
                setShowSpinner(false);
                return;
            }
        }
        try 
        {
            const data = await postRegistrationFormData(
                props.eventToken,
                validPayloadData,
            );
            if (data) 
            {
                setShowSpinner(false);
                isSubmitted({
                    message: data.message || data.registerUnSuccessMessage ? data.message ? data.message : data.registerUnSuccessMessage : null,
                    redirectionUrl: data.redirectionUrl
                        ? data.redirectionUrl
                        : null,
                    paymentUrl: data.paymentUrl
                        ? data.paymentUrl
                        : null,
                });
                toast.success(data.message || data.registerUnSuccessMessage ? data.message ? data.message : data.registerUnSuccessMessage : null);
            }
            else 
            {
                if (registrationFormData?.registrationForm?.options) 
                {
                    setShowSpinner(false);
                    isSubmitted({
                        message: registrationFormData?.registrationForm?.options.message ? registrationFormData?.registrationForm?.options.message : null,
                        redirectionUrl: registrationFormData?.registrationForm?.options.message.redirectionUrl
                            ? registrationFormData?.registrationForm?.options.message.redirectionUrl
                            : null,
                        paymentUrl: null
                    });
                }
                toast.error('Something went wrong. Please try again later.');
            }

        }
        catch (error) 
        {
            // setError(true);
            setShowSpinner(false);
            toast.error('Something went wrong');
            console.log(error);
        }
    };

    const handleInputChange = (event, id):void => 
    {
        const { name, value } = event.target;

        if (id) 
        {
            for (const key in mandatoryField) 
            {
                if (key === id) 
                {
                    if (value !== '' || null || undefined) 
                    {
                        setMandatoryField((prevState): {
                            [key: string]: boolean;
                        } => 
                        {
                            return {
                                ...prevState,
                                [id]: true,
                            }; 
                        });
                    }
                    else 
                    {
                        setMandatoryField((prevState): {
                            [key: string]: boolean;
                        } => 
                        {
                            return {
                                ...prevState,
                                [id]: false,
                            }; 
                        });
                    }

                }
            }
        }

        setFormState((prevState): {
            [key: string]: string | number | string[] | number[] | boolean;
        } => 
        {
            return {
                ...prevState,
                [name]: value,
            }; 
        });
        const specialKeys = ['email','firstName','eventTicketId'];
       
        if (specialKeys.includes(name)) 
        {
            formik.handleChange(event);
        }
    };

    const handleDropDownInputChange = (name, value, id):void => 
    {
        if (id) 
        {
            for (const key in mandatoryField) 
            {
                if (key === id) 
                {
                    if (value !== '' || null || undefined) 
                    {
                        setMandatoryField((prevState):{
                            [key: string]: boolean;
                        } => 
                        {
                            return {
                                ...prevState,
                                [id]: true,
                            }; 
                        });
                    }
                    else 
                    {
                        setMandatoryField((prevState):{
                            [key: string]: boolean;
                        } => 
                        {
                            return {
                                ...prevState,
                                [id]: false,
                            }; 
                        });
                    }

                }
            }
        }

        setFormState((prevState): {
            [key: string]: string | number | string[] | number[] | boolean;
        } => 
        {
            return {
                ...prevState,
                [name]: value,
            }; 
        });
        const specialKeys = ['email','firstName','eventTicketId'];
       
        if (specialKeys.includes(name)) 
        {
            formik.handleChange(event);
        }
    };

    const handleBoxChange = (name, value, id):void => 
    {

        if (id) 
        {
            for (const key in mandatoryField) 
            {
                if (key === id) 
                {
                    if (value) 
                    {
                        if (Array.isArray(value)) 
                        {
                            if (value.length > 0) 
                            {
                                setMandatoryField((prevState):{
                                    [key: string]: boolean;
                                } => 
                                {
                                    return {
                                        ...prevState,
                                        [id]: true,
                                    }; 
                                });
                            }
                            else 
                            {
                                setMandatoryField((prevState):{
                                    [key: string]: boolean;
                                } => 
                                {
                                    return {
                                        ...prevState,
                                        [id]: false,
                                    }; 
                                });
                            }
                        }
                        else 
                        {
                            setMandatoryField((prevState):{
                                [key: string]: boolean;
                            } => 
                            {
                                return {
                                    ...prevState,
                                    [id]: true,
                                }; 
                            });
                        }

                    }
                    else 
                    {
                        setMandatoryField((prevState):{
                            [key: string]: boolean;
                        } => 
                        {
                            return {
                                ...prevState,
                                [id]: false,
                            }; 
                        });
                    }

                }
            }
        }

        setFormState((prevState):{
            [key: string]: string | number | string[] | number[] | boolean;
        } => 
        {
            return {
                ...prevState,
                [name]: value,
            }; 
        });
    };

    const handleSubmit = ():void => 
    {
        const payloadToSend = {
            ...formState 
        };

        if (validRegistrationForm) 
        {

            if (isCaptcha) 
            {

                const token = captchaRef.current.getValue();

                if (token) 
                {
                    // if (registrationType === RegistrationTypes.INVITE) 
                    // {
                    if (eventTickets.length >= 2) 
                    {
                        if (payloadToSend?.['eventTicketId']) 
                        {
                            postFormData(payloadToSend);
                        }
                        else 
                        {
                            toast.error('Please fill all the required fields');
                        }
                    }
                    else if (eventTickets.length === 1) 
                    {
                        const eventTicketId = eventTickets[0].id;
                        const updatedPayloadToSend = {
                            ...payloadToSend, ['eventTicketId']: eventTicketId 
                        };
                        postFormData(updatedPayloadToSend);
                    }
                    else 
                    {
                        if(registrationFormData.type === RegistrationTypes.OPEN)
                        {
                            postFormData(payloadToSend);
                        }
                        else
                        {
                            toast.error('Please fill all the required fields');
                        }
    
                    }
                }
                captchaRef.current.reset();
            }
            else 
            {
                // if (registrationType === RegistrationTypes.INVITE) 
                // {
                if (eventTickets.length >= 2) 
                {
                    if (payloadToSend?.['eventTicketId']) 
                    {
                        postFormData(payloadToSend);
                    }
                    else 
                    {
                        toast.error('Please fill all the required fields');
                    }
                }
                else if (eventTickets.length === 1) 
                {
                    const eventTicketId = eventTickets[0].id;
                    const updatedPayloadToSend = {
                        ...payloadToSend, ['eventTicketId']: eventTicketId 
                    };
                    postFormData(updatedPayloadToSend);
                }
                else 
                {
                    if(registrationFormData.type === RegistrationTypes.OPEN)
                    {
                        postFormData(payloadToSend);
                    }
                    else
                    {
                        toast.error('Please fill all the required fields');
                    }

                }
            }
        }
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        firstName: Yup.string()
            .matches(/^[a-zA-Z ]+$/, 'Invalid first name')
            .required('First name is required'),
        eventTicketId: Yup.string().test('eventTicketId-required', 'Ticket ID is required', function ():boolean 
        {
            // const { registrationType } = this.parent;
            // if (registrationType === RegistrationTypes.INVITE) 
            // {
            //     return !!value;
            // }
            return true;
        }),
    });


    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            eventTicketId: '',
        },
        validationSchema: validationSchema,
        onSubmit: ():Promise<void> => 
        {
            if (formState) 
            {

                let isValid = true;

                for (const key in mandatoryField) 
                {
                    if (!mandatoryField[key]) 
                    {
                        toast.error('Please fill all the required fields');
                        isValid = false;
                        break;
                    }
                    else 
                    {
                        setShowAlert({
                            show: false 
                        });
                    }
                }

                if (isValid) 
                {
                    handleSubmit();
                }
            }
            else 
            {
                return;
            }
        },
    });

    // Pricing math here 

    const selectedTicketData: EventTicket | undefined = _.find(eventTickets, function (item) : boolean {
        return item?.name === formik.values?.eventTicketId;
    });

    let discount;

    if (selectedTicketData && isCouponValid && isCouponValid?.couponValue)
    {
        const discountPrice = isCouponValid?.couponValue;
        discount = discountPrice;
    }
    else if (selectedTicketData && isCouponValid && isCouponValid?.couponPercentage)
    {
        const discountPrice = (selectedTicketData?.ticketPrice && selectedTicketData?.ticketPrice * isCouponValid?.couponPercentage / 100);
        discount = discountPrice;
    }

    const discountedPrice = selectedTicketData && selectedTicketData?.ticketPrice && discount ? Number(selectedTicketData?.ticketPrice - discount) : !discount ? selectedTicketData?.ticketPrice : 0;

    useEffect(() => 
    {
        if(props?.eventCoupon)
        {
            setFormState((prevState): {
                eventTicketId: string | number;
            } => 
            {
                return {
                    ...prevState,
                    ['eventTicketId']: props?.registrationFormData?.eventTickets[0]?.id,
                }; 
            });
        }

        if((registrationFormData?.eventTickets && registrationFormData?.eventTickets?.length === 1) || (props?.eventCoupon))
        {
            formik.setFieldValue('eventTicketId', registrationFormData?.eventTickets[0]?.name);
        }
    }, []);

    // Shared component for labels and mandatory indicators
    const FormComponent = ({ data, index }):React.JSX.Element => 
    {
        const isFieldMandatory = ((data?.fieldMandatory === CustomFieldMandatory.MANDATORY) || (data?.updatedLabels && data?.updatedLabels[0]?.fieldMandatory == CustomFieldMandatory.MANDATORY));
        const label = data?.updatedLabels && data?.updatedLabels[0]?.label ? data?.updatedLabels[0]?.label : data?.label;

        return (
            <Box display={'flex'}>
                <Form.Label className="preview-label">
                    {data?.type !== CustomFieldType.HEADER && data?.type !== CustomFieldType.PARAGRAPH && <div dangerouslySetInnerHTML={{
                        __html: label 
                    }} />}
                </Form.Label>
                {isFieldMandatory ? (
                    <Typography className="required-icon">{'*'}</Typography>
                ) : null}
            </Box>
        ); 
    };

    const renderInputComponent = (data, index, inputType):React.JSX.Element => 
    {
        const isFieldMandatory = ((data?.fieldMandatory === CustomFieldMandatory.MANDATORY) || (data?.updatedLabels && data?.updatedLabels[0]?.fieldMandatory == CustomFieldMandatory.MANDATORY));

        const placeholder = data?.updatedLabels && data?.updatedLabels[0]?.placeholder ? data?.updatedLabels[0]?.placeholder : data?.placeholder;
        const helpText = data?.updatedLabels && data?.updatedLabels[0]?.helpText ? data?.updatedLabels[0]?.helpText : data?.helpText;

        return (
            <Box className="component-spacing" sx={!validRegistrationForm ? {
                pointerEvents: 'none' 
            } : null}>
                {data.helpText && <Typography className="component-help-text">
                    {
                        <div
                            dangerouslySetInnerHTML={{
                                __html: helpText || ''
                            }}
                        />
                    }
                </Typography>}
                <Form.Control
                    className='component-info-placeholder'
                    type={inputType}
                    required={isFieldMandatory}
                    size="lg"
                    as={inputType === 'textarea' ? 'textarea' : undefined}
                    placeholder={placeholder || ''}
                    onChange={(e):void => 
                    {
                        handleInputChange(e, data.id); 
                    }}
                    name={data.default ? data.default : data?.propertyKey}
                    value={data.default ? formik.values[data.default] : null
                        // name == 'firstName'? formik.values.firstName: name == 'email'? formik.values.email: undefined
                    }
                    isInvalid={data.default ? (formik.touched[data.default] && formik.errors[data.default]) : enableErrorFields ? (mandatoryField[Number(data?.id)] === false) : false
                        // name === 'firstName'
                        //     ? formik.touched.firstName &&
                        //         formik.errors.firstName
                        //         ? true
                        //         : false
                        //     : name === 'email'
                        //         ? formik.touched.email && formik.errors.email
                        //             ? true
                        //             : false
                        //         : false
                    }
                />
            </Box>
        );
    };

    const OptionRadioGroup = (props: RadioProps):React.JSX.Element => 
    {
        const { checked, onChange } = props;
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<RadioBtn />}
                icon={<EmptyRadioBtn />}
                checked={checked}
                onChange={onChange}
                {...props}
                size='small'
            />
        );
    };

    const OptionCheckBox = (props):React.JSX.Element => 
    {
        const { checked, onChange } = props;

        return (
            <Checkbox
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent' 
                    },
                }}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                disableRipple
                color="default"
                checked={checked}
                onChange={onChange}
                inputProps={{
                    'aria-label': 'Checkbox demo' 
                }}
                className="gdpr-checkbox"
                // className="component-label"
                size='small'
            />
        );
    };

    const renderCheckBoxComponent = (data):React.JSX.Element => 
    {
        const handleCheckboxChange = (item):void => 
        {
            setSelectedOptions((prevSelectedOptions):string[]|number[] => 
            {
                const selectedIndex = prevSelectedOptions.indexOf(item.name);
                const updatedSelectedOptions = [...prevSelectedOptions];

                if (selectedIndex === -1) 
                {
                    updatedSelectedOptions.push(item.name);
                }
                else 
                {
                    updatedSelectedOptions.splice(selectedIndex, 1);
                }

                handleBoxChange(data?.propertyKey, updatedSelectedOptions, data.id);

                return updatedSelectedOptions;
            });
        };

        return (
            <Box className="component-spacing" sx={!validRegistrationForm ? {
                pointerEvents: 'none' 
            } : null}>
                {data.helpText && <Typography className="component-help-text">
                    {
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.helpText 
                            }}
                        />
                    }
                </Typography>}

                <Box className={props.isValidRegistrationForm && 'checkbox-container'}>
                    {data?.fieldOptions && data?.fieldOptions?.length > 0
                        ? data?.fieldOptions.map((item, idx): React.JSX.Element => 
                        {
                            return (
                                <Box key={idx} className="options-box">
                                    <FormControlLabel
                                        control={
                                            <OptionCheckBox
                                                label={item.name}
                                                checked={selectedOptions.includes(
                                                    item.name
                                                )}
                                                onChange={():void =>
                                                {
                                                    return handleCheckboxChange(item); 
                                                }
                                                }
                                            />
                                        }
                                        label={<Typography className="option-label">{item.name}</Typography>}
                                        name={
                                            'checkbox_' +
                                            item.name.toLowerCase()
                                        }
                                    />
                                </Box>
                            );
                        })
                        : null}
                </Box>
            </Box>
        );
    };

    const handleTicketGroupChange = (event, newValue):void => 
    {
        if (newValue) 
        {
            const eventTicketId = _.find(eventTickets, function (item) : boolean
            {
                return item.name === newValue;
            }).id;

            setFormState((prevState): {
                eventTicketId: string | number;
            } => 
            {
                return {
                    ...prevState,
                    ['eventTicketId']: eventTicketId,
                }; 
            });
            formik.setFieldValue('eventTicketId', newValue);

            if(!props?.eventCoupon)
            {
                setCouponCode('');
                setIsCouponValid(false);
            }
        }
        else formik.setFieldValue('eventTicketId', '');
    };

    const handleCouponValidation = async (): Promise<void> =>
    {
        try 
        {

            const eventTicketId = _.find(eventTickets, function (item) : boolean
            {
                return item.name === formik.values?.eventTicketId;
            })?.id;

            const data = {
                ticketId: eventTicketId,
                couponCode: couponCode
            };

            const couponValidation = await validateCoupon(data, props?.eventToken);
            if(couponValidation)
            {
                toast.success('Coupon code applied successfully');
                setIsCouponValid(couponValidation);
            }
            else
            {
                setIsCouponValid(false);
                toast.error('Invalid coupon code');
            }
        } 
        catch (error) 
        {
            console.log('Error in validation coupon');
            toast.error('Invalid coupon code');
        }
    };

    const rendereventTicketIdDropDown = (data):React.JSX.Element => 
    {
        const iseventTicketIdInvalid =
            formik.touched.eventTicketId && formik.errors.eventTicketId ? true : false;

        return (
            <Box className="component-spacing" sx={!validRegistrationForm ? {
                pointerEvents: 'none' 
            } : null}>
                {data.helpText && <Typography className="component-help-text">
                    {
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.helpText 
                            }}
                        />
                    }
                </Typography>}

                <FormControl fullWidth error={iseventTicketIdInvalid}>
                    <Autocomplete
                        value={formik.values.eventTicketId || 'Select'}
                        popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                        onChange={handleTicketGroupChange}
                        className="autocomplete-dropdown"
                        options={data?.fieldOptions?.map((item):string => 
                        {
                            return `${item.option}`;
                        })}
                        getOptionLabel={(option):string => 
                        {
                            return option; 
                        }}
                        disableClearable
                        getOptionDisabled={(option): boolean => 
                        {
                            const eventTicketSaleStartDateTime = _.find(data?.fieldOptions, function(elem): boolean
                            {
                                return elem.option === option;
                            })?.saleStartDateTime;

                            const eventTicketSaleCloseDateTime = _.find(data?.fieldOptions, function(elem): boolean 
                            {
                                return elem.option === option;
                            })?.saleCloseDateTime;
                            
                            const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                            
                            if(eventTicketSaleStartDateTime && eventTicketSaleCloseDateTime) 
                            {
                                if((Number(eventTicketSaleStartDateTime) > currentTimestamp) || (Number(eventTicketSaleCloseDateTime) < currentTimestamp)) return true;
                                else return false;
                            }
                            else return false;
                        }}
                        selectOnFocus
                        clearOnBlur
                        renderOption={(props, option):React.JSX.Element => 
                        {
                            return (
                                <li {...props}>{option}</li>
                            ); 
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    borderRadius: '8px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    background: 'rgba(0, 0, 0, 0.10)',
                                    backdropFilter: 'blur(50px)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: '4px',
                                    marginTop: '4px',
                                    zIndex: 3,
                                    width: '100%',
                                    padding: '4px',
                                    '& .MuiAutocomplete-listbox': {
                                        width: '100% !important'
                                    },
                                    '& .MuiAutocomplete-option': {
                                        color: '#20232D',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        lineHeight: '20px',
                                    },
                                    '& .MuiAutocomplete-option.Mui-focused': {
                                        borderRadius: '5px',
                                        border: '1px solid rgba(255, 255, 255, 0.70)',
                                        background: 'rgba(255, 255, 255, 0.50) !important'
                                    }
                                }
                            }
                        }}
                        renderInput={(params):React.JSX.Element => 
                        {
                            return (
                                <TextField
                                    {...params}
                                    className="autocomplete-placeholder"
                                    placeholder={'select an option'}
                                    required
                                />
                            ); 
                        }}
                    />
                    {iseventTicketIdInvalid && (
                        <FormHelperText>
                            {formik.errors.eventTicketId}
                        </FormHelperText>
                    )}
                </FormControl>

                {((selectedTicketData?.type === TicketType.PAID) && !props?.eventCoupon) && renderCouponComponent()}

                {((isCouponValid || selectedTicketData || props?.eventCoupon) && selectedTicketData?.type === TicketType.PAID) && renderPricingComponent()}
            </Box>
        );
    };

    const renderPricingComponent = (): React.JSX.Element =>
    {
        return (<div style={{ marginBottom: eventTickets?.length === 1 ? '16px' : '' }} className="ticket-pricing-container">
            <div className="discount-details">
                <div className="discount-inner-cont">
                    <p className="name">{selectedTicketData?.name}</p>
                    <p className="description">{selectedTicketData?.description}</p>
                </div>
                <p className="amount">{`₹${selectedTicketData?.ticketPrice}`}</p>
            </div>
            {isCouponValid && <div className="discount-details content-border">
                <div className="discount-inner-cont">
                    <p className="name">{couponCode}</p>
                    <p className="description">{isCouponValid?.description || props?.eventCoupon?.description}</p>
                </div>
                <p className="amount">{`- ₹${discount}`}</p>
            </div>}
            <div className="discount-details">
                <p className="total-text">Total</p>
                <p className="amount">{`₹${Number(discountedPrice) > 0 ? discountedPrice : 0}`}</p>
            </div>
        </div>)
    };

    const renderCouponComponent = (): React.JSX.Element =>
    {
        return (
            <div style={{ paddingTop: eventTickets?.length === 1 ? 0 : '24px' }} className="coupon-div">
                <p className="preview-label">{'Coupon code'}</p>
                <div className="coupon-input-container">
                    <Form.Control
                        className='component-info-placeholder'
                        type={'text'}
                        disabled={!formik.values?.eventTicketId}
                        size="lg"
                        placeholder={'Enter coupon code'}
                        onChange={(event) => { 
                            if(isCouponValid)
                            {
                                setIsCouponValid(false);
                                setCouponCode('');
                            }
                            else
                            {
                                setCouponCode(event.target.value?.toUpperCase()) 
                            }
                        }}
                        value={couponCode}
                    />
                    <Button onClick={handleCouponValidation} disabled={((!formik.values?.eventTicketId) || (couponCode?.length <= 1))} className="coupon-button">{'Verify'}</Button>
                </div>
            </div>
        )
    }

    const renderDropDownComponent = (data):React.JSX.Element => 
    {
        return (
            <Box className="component-spacing" sx={!validRegistrationForm ? {
                pointerEvents: 'none' 
            } : null}>
                {data.helpText && <Typography className="component-help-text">
                    {
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.helpText 
                            }}
                        />
                    }
                </Typography>}

                <Autocomplete
                    value={formState[data?.propertyKey] ? formState[data?.propertyKey] : 'Select'}
                    popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                    onChange={(e, value):void => 
                    {
                        handleDropDownInputChange(data?.propertyKey, value, data.id); 
                    }}
                    className="autocomplete-dropdown"
                    options={data?.fieldOptions?.map((item):string => 
                    {
                        return `${item?.name}`;
                    })}
                    selectOnFocus
                    clearOnBlur
                    slotProps={{
                        paper: {
                            sx: {
                                borderRadius: '8px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                background: 'rgba(0, 0, 0, 0.10)',
                                backdropFilter: 'blur(50px)',
                                boxShadow: 'none !important',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '4px',
                                marginTop: '4px',
                                zIndex: 3,
                                width: '100%',
                                // padding: '4px',
                                '& .MuiAutocomplete-listbox': {
                                    width: '100% !important',
                                    padding: '8px !important'
                                },
                                '& .MuiAutocomplete-option': {
                                    color: '#20232D',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    lineHeight: '20px',
                                },
                                '& .MuiAutocomplete-option.Mui-focused': {
                                    borderRadius: '5px',
                                    border: '1px solid rgba(255, 255, 255, 0.70)',
                                    background: 'rgba(255, 255, 255, 0.50) !important'
                                }
                            }
                        }
                    }}
                    renderInput={(params):React.JSX.Element => 
                    {
                        return (
                            <TextField
                                {...params}
                                className="auto-complete-textfield"
                                placeholder={'select an option'}
                                required
                            />
                        ); 
                    }}
                />
            </Box>
        );
    };

    const renderRadioGroupComponent = (data):React.JSX.Element => 
    {
        return (
            <Box className="component-spacing" sx={!validRegistrationForm ? {
                pointerEvents: 'none' 
            } : null}>
                {data.helpText && <Typography className="component-help-text">
                    {
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.helpText 
                            }}
                        />
                    }
                </Typography>}
                <RadioGroup>
                    <Box className={props.isValidRegistrationForm && 'radio-group-container'}>
                        {data?.fieldOptions && data?.fieldOptions?.length > 0
                            ? data?.fieldOptions?.map((item, idx):React.JSX.Element => 
                            {
                                return (
                                    <Box key={idx} className="options-box">
                                        <FormControlLabel
                                            value={String(idx)}
                                            control={
                                                <OptionRadioGroup
                                                    checked={
                                                        formState[data?.propertyKey] ===
                                                    item.name
                                                    }
                                                    onChange={():void =>
                                                    {
                                                        return handleBoxChange(
                                                            data?.propertyKey,
                                                            item.name,
                                                            data.id
                                                        ); 
                                                    }
                                                    }
                                                />
                                            }
                                            label={<Typography className="option-label">{item.name}</Typography>}
                                            name={'radio_' + data?.propertyKey?.toLowerCase()}
                                            className="component-label"
                                        />
                                    </Box>
                                ); 
                            })
                            : null}
                    </Box>
                </RadioGroup>
            </Box>
        );
    };

    const renderDateComponent = (data):React.JSX.Element => 
    {
        return (
            <Box className="component-spacing" sx={!validRegistrationForm ? {
                pointerEvents: 'none' 
            } : null}>
                <Form.Control
                    type="date"
                    className="date-time-picker"
                    onChange={(e):void => 
                    {
                        handleInputChange(e, data.id); 
                    }}
                    name={data?.propertyKey}
                    isInvalid={data.default ? (formik.touched[data.default] && formik.errors[data.default]) : enableErrorFields ? (mandatoryField[Number(data?.id)] === false) : false }
                />
            </Box>
        );
    };

    const renderContentComponent = (data): React.JSX.Element => 
    {
        return (
            <Box className="component-spacing">
                <Typography className={data?.type === 7 ? 'content-type-default-heading' : data?.type === 8 && 'content-type-default-paragraph'}>{<div dangerouslySetInnerHTML={{
                    __html: data?.type === 7 ? data?.heading : data?.type === 8 && data?.paragraph
                }} />}</Typography>
            </Box>
        );
    };

    const renderLegalComponent = (data):React.JSX.Element => 
    {
        return (
            <Box className="gdpr-component-container">
                {data && data?.length > 0 && data?.map((item: { paragraph: string, fieldOptions: { name: string, value: string } }, index: number):React.JSX.Element => 
                {
                    return (
                        <Box className="component-spacing" key={index} sx={!validRegistrationForm ? {
                            pointerEvents: 'none' 
                        } : null}>
                            {item.paragraph && item.paragraph !== '' &&
                                <Typography className="gdpr-paragraph">{
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.paragraph 
                                        }}
                                    />}
                                </Typography>}
                            {
                                item.fieldOptions && item.fieldOptions.name !== '' &&
                                    <FormControlLabel
                                        control={
                                            <OptionCheckBox
                                                onChange={():void =>
                                                {
                                                    return handleBoxChange(
                                                        item.fieldOptions.name,
                                                        formState[item.fieldOptions.name]
                                                            ? formState[item.fieldOptions.name] === 1
                                                                ? 2
                                                                : 1
                                                            : 1,
                                                        ConvertStringtoCamelCase(item.fieldOptions?.name)
                                                    ); 
                                                }
                                                }
                                                checked={formState[item.fieldOptions.name] === 1}
                                            />
                                        }
                                        className="gdpr-formControl-label"
                                        label={
                                            <div className='gdpr-checkbox-option'>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: item.fieldOptions.name 
                                                }} className="gdpr-checkbox-label" ></div>
                                                <Typography className="required-icon">{'*'}</Typography>
                                            </div>
                                        }
                                    />
                            }
                            {/* {data.componentOptions && <FormControlLabel
                            control={
                                <OptionCheckBox
                                    onChange={() =>
                                        handleBoxChange(
                                            data.label,
                                            formState[data.label]
                                                ? formState[data.label] === 1
                                                    ? 2
                                                    : 1
                                                : 1
                                        )
                                    }
                                    checked={formState[data.label] === 1}
                                />
                            }
                            label={
                                <div dangerouslySetInnerHTML={{ __html: data.label }} className="component-label" />
                            }
                        />} */}
                        </Box>
                    );
                })}
            </Box>
        );
    };

    function RenderPostComp():React.JSX.Element 
    {
        return (<p>Submitted</p>);
    }

    useEffect(():void => 
    {
        if(Number(registrationFormData?.registrationType) === 1)
        {
            if(submitted.paymentUrl)
            {
                const absoluteUrl = `${submitted.paymentUrl}`;
                window.open(absoluteUrl, '_self');
                return null;
            }
            else if (submitted.message) 
            {
                const textWithoutTags = removeHTMLTags(submitted.message);
                eventBus.dispatch('form-submitted', {
                    message: textWithoutTags,
                    severity: 'success',
                    positionVertical: 'bottom',
                    positionHorizontal: 'center'
                });
                props.onClose();
            }
            else if (submitted.redirectionUrl) 
            {
                const absoluteUrl = `https://${submitted.redirectionUrl}`;
                window.open(absoluteUrl, '_self');
                return null;
            }
        }
        if (submitted.message) 
        {
            const textWithoutTags = removeHTMLTags(submitted.message);
            eventBus.dispatch('form-submitted', {
                message: textWithoutTags,
                severity: 'success',
                positionVertical: 'bottom',
                positionHorizontal: 'center'
            });
            props.onClose();
        }
        else if (submitted.redirectionUrl) 
        {
            const absoluteUrl = `https://${submitted.redirectionUrl}`;
            window.open(absoluteUrl, '_self');
            return null;
        }
    }, [submitted]);

    return (<> {error && props.isValidRegistrationForm ? (
        <NotFoundPage></NotFoundPage>
    ) : (
        <div className={`${props.isValidRegistrationForm ? 'previewFormBlock' : 'form-block'}`}>
            {registrationFormData || !props.isValidRegistrationForm ? (
                submitted.message || submitted.redirectionUrl ? (
                    <RenderPostComp />
                ) : (
                    <>
                        {props.isValidRegistrationForm && <div className="closeIcon" onClick={props.onClose}>
                            <FontAwesomeIcon icon={['fal', 'xmark']} className="closeIconImg" />
                        </div>}
                        <Box id={`${props.isValidRegistrationForm ? 'validPreviewForm' : 'previewForm'}`} sx={props.isValidRegistrationForm ? {
                            maxHeight: '100% !important', maxWidth: '100% !important' 
                        } : {
                            maxHeight: props?.maxHeight || 'calc(100vh - 154px) !important', maxWidth: '100% !important' 
                        }}>
                            <Container className="card-box">
                                <Card
                                    sx={{
                                        minWidth: '50% !important', maxWidth: `${props.maxWidth ? props.maxWidth : '100%'}`, width: `${props.width ? props.width : 'auto'}`, margin: `${props.margin ? props.margin : null}` 
                                    }}
                                    className={props.isValidRegistrationForm ? 'regFormValid' : 'preview-form-card'}
                                >
                                    <CardContent>
                                        {formComponents &&
                                            formComponents?.formFields?.length > 0
                                            ? formComponents?.formFields?.map(
                                                (element, index):React.JSX.Element => 
                                                {
                                                    return (
                                                        <Box key={index}>
                                                            {element.type < 11 && <FormComponent
                                                                data={element}
                                                                index={index}
                                                            />}
                                                            {(():React.JSX.Element => 
                                                            {
                                                                switch (
                                                                    Number(element.type)
                                                                ) 
                                                                {
                                                                case ComponentType.SingleLine:
                                                                case ComponentType.MultiLine:
                                                                    return renderInputComponent(
                                                                        element,
                                                                        index,
                                                                        element.type ===
                                                                            ComponentType.MultiLine
                                                                            ? 'textarea'
                                                                            : 'text'
                                                                    );
                                                                case ComponentType.Numeric:
                                                                    return renderInputComponent(
                                                                        element,
                                                                        index,
                                                                        'number'
                                                                    );
                                                                case ComponentType.CheckBox:
                                                                    return renderCheckBoxComponent(
                                                                        element,
                                                                    );
                                                                case ComponentType.DropDown:
                                                                    return renderDropDownComponent(
                                                                        element,
                                                                    );
                                                                case ComponentType.Date:
                                                                    return renderDateComponent(
                                                                        element,
                                                                    );
                                                                case ComponentType.SingleSelect:
                                                                    return renderRadioGroupComponent(
                                                                        element,
                                                                    );
                                                                case ComponentType.Heading:
                                                                    return renderContentComponent(element);
                                                                case ComponentType.Paragraph:
                                                                    return renderContentComponent(element,);
                                                                default:
                                                                    return null;
                                                                }
                                                            })()}
                                                        </Box>
                                                    ); 
                                                }
                                            )
                                            : null}
                                        <Box className="event-tickets-box">
                                            {props.isValidRegistrationForm &&
                                                eventTickets.length > 1
                                                ? rendereventTicketIdDropDown(
                                                    {
                                                        helpText:
                                                            'Please select your ticket type',
                                                        label: 'eventTicketId',
                                                        fieldOptions:
                                                            eventTickets.map(
                                                                (item):{
                                                                    option: string | number;
                                                                    id: string | number;
                                                                    saleStartDateTime: string | number;
                                                                    saleCloseDateTime: string | number;
                                                                } => 
                                                                {
                                                                    return {
                                                                        ['option']:
                                                                            item.name,
                                                                        ['id']: item.id,
                                                                        ['saleStartDateTime']: item.saleStartDateTime,
                                                                        ['saleCloseDateTime']: item.saleCloseDateTime,
                                                                    };
                                                                }
                                                            ),
                                                        default: 'eventTicketId',
                                                    },
                                                )
                                                : (props.isValidRegistrationForm &&
                                                eventTickets.length === 1 && eventTickets[0]?.type === TicketType.PAID) ? 
                                                    <>
                                                        {!props?.eventCoupon && renderCouponComponent()}
                                                        {renderPricingComponent()}
                                                    </> : null
                                                }
                                        </Box>
                                        {props?.registrationFormData &&
                                            (props?.registrationFormData?.registrationForm?.formGDPR) &&
                                                <div>
                                                    {(():React.JSX.Element | null => 
                                                    {
                                                        switch (
                                                            Number(props?.registrationFormData?.registrationForm?.formGDPR?.type)
                                                        ) 
                                                        {
                                                        case ComponentType.Legal1:
                                                            return renderLegalComponent(
                                                                props?.registrationFormData?.registrationForm?.formGDPR?.gdpr
                                                            );
                                                        case ComponentType.Legal2:
                                                            return renderLegalComponent(
                                                                props?.registrationFormData?.registrationForm?.formGDPR?.gdpr
                                                            );
                                                        case ComponentType.Legal3:
                                                            return renderLegalComponent(
                                                                props?.registrationFormData?.registrationForm?.formGDPR?.gdpr
                                                            );
                                                        default:
                                                            return null;
                                                        }
                                                    })()}
                                                </div>
                                        }

                                        {isCaptcha ? (
                                            <Box className="component-spacing">
                                                <ReCAPTCHA
                                                    sitekey={import.meta.env.VITE_SITE_KEY}
                                                    ref={captchaRef}
                                                />
                                            </Box>
                                        ) : null}

                                        <Box className="submit-btn-box">
                                            {showSpinner ? <Button
                                                className="submit-btn"
                                                sx={{
                                                    maxHeight: '30px', minHeight: '22px', minWidth: '30px', padding: '4px', background: `${props.ctaColor} !important`
                                                }}
                                            >
                                                <Spinner animation="border" role="status" size='sm' />
                                            </Button> :
                                                <Button
                                                    className="submit-btn"
                                                    onClick={validRegistrationForm ? ():void => 
                                                    {
                                                        setEnableErrorFields(true);
                                                        formik.handleSubmit(); 
                                                    } : null}
                                                    sx={
                                                        {
                                                            background: `${props.ctaColor} !important`
                                                        }
                                                    }
                                                >
                                                    {props?.registerMessage ? props?.registerMessage[props?.contentType]?.btnText : 'Submit'}
                                                </Button>
                                            }

                                        </Box>
                                    </CardContent>
                                </Card>
                            </Container>
                        </Box>
                    </>
                )
            ) : (
                <div>
                    <Box id="thankYouMsgBox">
                        <Spinner animation="border" role="status" size='sm' />
                    </Box>
                </div>
            )
            }
        </div>
    )}</>);
};

export default FormPreview;

function removeHTMLTags(input):string 
{
    return input.replace(/<[^>]*>/g, '');
}