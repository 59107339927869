import { createSlice } from '@reduxjs/toolkit';

export const StageRowSlice = createSlice({
    name: 'stage',
    initialState: {
        value: {
            title: '',
            description: '',
            additionalInfo: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            image: '',
            imageAlternateText: '',
            speakerIds: [],
            whoCanWatch: [],
            maximumParticipants: 0,
            scheduled: 2,
        }
    },
    reducers: {
        addStage: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetStage: (state) => {
            state.value = {
                title: '',
                description: '',
                additionalInfo: '',
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                image: '',
                imageAlternateText: '',
                speakerIds: [],
                whoCanWatch: [],
                maximumParticipants: 0,
                scheduled: 2,
            }
        },
        removeEventSpeakers: (state, actions) => {
            state.value.speakerIds.splice(actions.payload.index, 1)
        },
        removeTicketGroups: (state, action) => {
            state.value.whoCanWatch.splice(action.payload.index, 1)
        },
    },
});

export const { addStage, resetStage, removeEventSpeakers, removeTicketGroups } = StageRowSlice.actions;
export default StageRowSlice.reducer;