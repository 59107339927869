import { useContext } from 'react';
import { PortalContext } from '../../../../contexts/Portal/PortalContext';
import { Col, Row } from 'react-bootstrap';
import { FormControlComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../../../../common/FormComponents/Buttons';

const PortalUserProfile: React.FC = (): React.JSX.Element => 
{
    const {
        registrantData,
        handleModuleChange
    } = useContext(PortalContext);

    return (
        <div id="portalUserProfile">
            <div className="portal-profile-container">
                {/* <div className="heading-container">
                    <CustomButton
                        name="Back"
                        btnType="tertiary"
                        startIcon={<FontAwesomeIcon style={{ height: '12px', width: '12px' }} icon={['fal', 'arrow-left']} />}
                        onClick={() => handleModuleChange('dashboard')}
                    />
                    <h3 className="heading">{'Profile'}</h3>
                </div> */}

                <div className="user-image-container">
                    {
                        registrantData?.clearbitData && registrantData?.clearbitData?.companyLogo ?
                        (
                            <img 
                                src={registrantData?.clearbitData?.companyLogo}
                                alt="user"
                                className="user-image"
                            />
                        ):(
                            <div className="default-user-image">
                                {registrantData?.firstName[0]}
                            </div>
                        )
                    }
                </div>

                <div>
                    <FormLabelComponent 
                        label="Full Name"
                    />
                    <FormControlComponent 
                        value={registrantData?.firstName + ' ' + (registrantData?.lastName || '')}
                        type="text"
                        disabled
                    />
                </div>
                <div>
                    <FormLabelComponent 
                        label="Email"
                    />
                    <FormControlComponent 
                        value={registrantData?.email || ''}
                        type="email"
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};

export default PortalUserProfile;