import { createContext, useEffect, useState } from 'react';
import React from 'react';
import { useTablePagination } from '../TablePaginationContext';
import { EventTargetList, IEventTargetListContext } from '../../pages/Events/interfaces/event-target-list-interface';
import { deleteEventTargetList, eventTargetListCount, getEventTargetListById, getEventTargetLists } from '../../scripts/apis/eventTargetList';
import toast from 'react-hot-toast';
import { Event } from '../../pages/Events/interfaces';
import { getEncodedParams } from '../../scripts/helpers';

export const EventTargetListContext = createContext<IEventTargetListContext>({ 
    eventId: '',
    listId: '',
    isEmpty: true,
    eventTargetListDataCount: 0,
    setIsEmpty: () => {},
    setEventTargetList: () => {},
    eventTargetList: [],
    deleteEventTargetListFromTable: () => {},
    setRefresh: () => {},
    pageSize: 10,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    setShowSpinner: () => {},
    anchorEl: null,
    setAnchorEl: () => {},
    currentPage: 1,
    eventTargetListData: [],
    selectedTargetList: null,
    showDeletePopup: false,
    setSelectedTargetList: () => {},
    setShowDeletePopup: () => {},
    selectedEvents: [],
    setSelectedEvents: () => {},
    showCreate: true,
    setShowCreate: () => {},
    selectedEventIds: [],
    setSelectedEventIds: () => {},
    currentUrl: '',
    setCurrentUrl: () => {},
    existingTargetList: null,
    setExistingTargetList: () => {}
});

const EventTargetListProvider: React.FC<{ children: React.ReactNode, eventId: string | number, listId: string | number | null }> = ({ children, eventId, listId }): React.JSX.Element =>
{
    const [eventTargetListDataCount, setEventTargetListDataCount] = useState<number>(0);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [eventTargetList, setEventTargetList] = useState<EventTargetList[]>([]);
    const [eventTargetListData, setEventTargetListData] = useState<EventTargetList[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedTargetList, setSelectedTargetList] = useState<EventTargetList | null>(null);
    const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
    const [showCreate, setShowCreate] = useState<boolean>(true);
    const [selectedEventIds, setSelectedEventIds] = useState<string[]>([]);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [existingTargetList, setExistingTargetList] = useState<EventTargetList | null>(null);

    const fetchTotalTargetListCount = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await eventTargetListCount(eventId, encodedParams);
            if (count) 
            {
                setEventTargetListDataCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> =>
    {
        try
        {
            setShowSpinner(true);
            if (eventTargetListData)
            {
                const encodedParams = getEncodedParams(currentUrl);
                const eventTargetListsData = await getEventTargetLists(eventId, pageSize, currentPage - 1, encodedParams);
                if (eventTargetListData)
                {
                    setEventTargetList([...eventTargetListsData]);
                    setEventTargetListData([...eventTargetListsData])
                }
            }
        }
        catch (error)
        {
            console.error(error);
        }
        finally
        {
            setShowSpinner(false);
            setRefresh(false);
        }
    };

    const fetchPaginationData = async (): Promise<void> =>
    {
        if (eventTargetListDataCount)
        {
            try
            {
                setShowSpinner(true);
                const eventTargetListsData = await getEventTargetLists(eventId, pageSize, currentPage - 1);
                if (eventTargetListData)
                {
                    setEventTargetList([...eventTargetListsData]);
                    setEventTargetListData([...eventTargetListsData])
                    setIsEmpty(eventTargetListsData.length === 0);
                }
            }
            catch (error)
            {
                console.error(error);
            }
            finally
            {
                setShowSpinner(false);
                setRefresh(false);
            }
        }
    };

    useEffect(() => {
        if (listId) {
            getEventTargetListById(eventId, listId).then((data) => {
                setExistingTargetList(data);
            });
        }
    }, [listId]);


    useEffect(() =>
    {
        if (currentPage === 1 && pageSize)
        {
            setShowSpinner(true);
            fetchData();
        }
        else if (currentPage > 1 && pageSize)
        {
            setShowSpinner(true);
            fetchPaginationData();
        }
    }, [currentPage, pageSize, eventTargetListDataCount]);

    useEffect((): void =>
    {
        if (refresh)
        {
            fetchData();
        }
    }, [refresh, currentUrl]);

    useEffect((): void => 
    {
        fetchTotalTargetListCount();
    }, [currentUrl])

    const deleteEventTargetListFromTable = async (targetListData: EventTargetList): Promise<void> =>
    {
        if(eventId)
        {
            try {
                const targetListRemoved = await deleteEventTargetList(eventId, Number(targetListData?.id))
                if(targetListRemoved)
                {
                    toast.success(`Target List ${targetListData.name} deleted successfully`);
                    setSelectedTargetList(null);
                    setShowDeletePopup(false);
                    setRefresh(true);  
                }
                
            } catch (error) {
                console.error(error);
                toast.error(error?.message);
            }
        }
    };

    return (
        <EventTargetListContext.Provider
            value={{
                eventId,
                listId,
                isEmpty,
                eventTargetListDataCount,
                setIsEmpty,
                setEventTargetList,
                eventTargetList,
                deleteEventTargetListFromTable,
                setRefresh,
                pageSize,
                updateCurrentPage,
                updatePageSize,
                showSpinner,
                setShowSpinner,
                anchorEl,
                setAnchorEl,
                currentPage,
                eventTargetListData,
                selectedTargetList,
                showDeletePopup,
                setSelectedTargetList,
                setShowDeletePopup,
                selectedEvents,
                setSelectedEvents,
                selectedEventIds,
                setSelectedEventIds,
                showCreate,
                setShowCreate,
                currentUrl,
                setCurrentUrl,
                existingTargetList,
                setExistingTargetList
            }}
        >
            {children}
        </EventTargetListContext.Provider>
    );
};

export default EventTargetListProvider;
