import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutocompletewithTags } from "../../../../../../common/FormComponents/ReusableFormComponents";
import './styles.scss'
import { ARR, COUNTRY, EMPLOYEE_RANGE, INDUSTRY, TECH_CATEGORIES } from "../../../../../Settings/ICP/icp-dataset";
import { ROLE, SENIORITY, SUB_ROLE } from "../../../../../Settings/BuyerPersona/buyer-persona-dataset";

interface AudienceFilterAccordionProps
{
    onAutocompleteChange: (selectedColumn: string, value: string[]) => void;
}

const AudienceFilterAccordion: React.FC<AudienceFilterAccordionProps> = ({ onAutocompleteChange }) => 
{
    const [expanded, setExpanded] = useState<string | false>(false);
    const [industry, setIndustry] = useState<string[]>([]);
    const [company, setCompany] = useState<string[]>([]);
    const [companySize, setCompanySize] = useState<string[]>([]);
    const [companyLocation, setCompanyLocation] = useState<string[]>([]);
    const [employeeRange, setEmployeeRange] = useState<string[]>([]);
    const [techCategories, setTechCategories] = useState<string[]>([]);
    const [city, setCity] = useState<string[]>([]);
    const [state, setState] = useState<string[]>([]);
    const [country, setCountry] = useState<string[]>([]);
    const [employee, setEmployee] = useState<string[]>([]);
    const [arr, setArr] = useState<string[]>([]);
    const [title, setTitle] = useState<string[]>([]);
    const [role, setRole] = useState<string[]>([]);
    const [subRole, setSubRole] = useState<string[]>([]);
    const [seniority, setSeniority] = useState<string[]>([]);


    const clearBitPropertiesArray = [
        // {id: 'company', name: 'Company', options: TECH, placeholder: 'Select Company', onChange: (event: any, value: string[]) => setCompany(value) },
        // {id: 'companyLocation', name: 'Company Location', options: TECH, placeholder: 'Select Company Location', onChange: (event: any, value: string[]) => setCompanyLocation(value) },
        {
            id: "employeesRange",
            name: "Employee Range",
            options: EMPLOYEE_RANGE,
            placeholder: "Select Employee Range",
            onChange: (event: any, value: string[]) => setEmployeeRange(value),
            value: employeeRange,
        },
        {
            id: "techCategories",
            name: "Tech Categories",
            options: TECH_CATEGORIES,
            placeholder: "Select Tech Categories",
            onChange: (event: any, value: string[]) => setTechCategories(value),
            value: techCategories,
        },
        // {id: 'city', name: 'City', options: TECH, placeholder: 'Select City', onChange: (event: any, value: string[]) => setCity(value) },
        // {id: 'state', name: 'State', options: TECH, placeholder: 'Select State', onChange: (event: any, value: string[]) => setState(value) },
        {
            id: "country",
            name: "Country",
            options: COUNTRY,
            placeholder: "Select Country",
            onChange: (event: any, value: string[]) => setCountry(value),
            value: country,
        },
        {
            id: "subIndustry",
            name: "Sub Industry",
            options: INDUSTRY,
            placeholder: "Select Industry",
            onChange: (event: any, value: string[]) => setIndustry(value),
            value: industry,
        },
        {
            id: "estimatedAnnualRevenue",
            name: "ARR",
            options: ARR,
            placeholder: "Select ARR",
            onChange: (event: any, value: string[]) => setArr(value),
            value: arr,
        },
        {
            id: "role",
            name: "Role",
            options: ROLE,
            placeholder: "Select Role",
            onChange: (event: any, value: string[]) => setRole(value),
            value: role,
        },
        {
            id: "subRole",
            name: "Sub Role",
            options: SUB_ROLE,
            placeholder: "Select Sub Role",
            onChange: (event: any, value: string[]) => setSubRole(value),
            value: subRole,
        },
        {
            id: "seniority",
            name: "Seniority",
            options: SENIORITY,
            placeholder: "Select Seniority",
            onChange: (event: any, value: string[]) => setSeniority(value),
            value: seniority,
        },
    ];

    const [expandedPanels, setExpandedPanels] = useState<boolean[]>(Array(clearBitPropertiesArray.length).fill(false));

    const handleAccordionChange = (panelIndex: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => 
    {
        const newExpandedPanels = [...expandedPanels];
        newExpandedPanels[panelIndex] = isExpanded;
        setExpandedPanels(newExpandedPanels);
    };

    return (
        <div id="AudienceFilterAccordion" className="side-bar-filter">
            {clearBitPropertiesArray.map((item, index) => (
                <Accordion
                    key={index}
                    expanded={expandedPanels[index]}
                    onChange={handleAccordionChange(index)}
                >
                    <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={expandedPanels[index] ? ["fal", "chevron-up"] : ["fal", "chevron-right"]} />}
                        aria-controls={`filter-content-${index}`}
                        id={`filter-header-${index}`}
                    >
                        <div className="accordion-summary-content">{item.name}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AutocompletewithTags 
                            value={item.value}
                            defaultValue={item.value}
                            onChange={(event, value) => {
                                item.onChange(event, value);
                                onAutocompleteChange(item.id, value);
                            }}
                            options={item.options}
                            placeholder={item.placeholder}
                            onRemoveClick={(index: number): void => 
                            {
                                const updatedValue = item.value.filter((_, i): boolean => 
                                {
                                    return i !== index;
                                });
                                item.onChange(null, updatedValue);
                                onAutocompleteChange(item.id, updatedValue);
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default AudienceFilterAccordion;