import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getRoiTrend = () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/roi/trend';
    return triggerApi(API_URL, 'GET', '');
};

export const getVelocityImpact = () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/roi/velocity-impact';
    return triggerApi(API_URL, 'GET', '');
};

export const getConversionAnalytics = (type: number) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/roi/conversion-analytics/' + type;
    return triggerApi(API_URL, 'GET', '');
};

export const getRoiAnalyticsGraphData = () => {
    const query = convertJSONToGETParams({
        type: true,
        location: true,
        typeRoiRange: true,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/roi/graph/?' + query;
    return triggerApi(API_URL, 'GET', '');
}

export const getRoiTableData = () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/roi/';
    return triggerApi(API_URL, 'GET', '');
}