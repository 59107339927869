/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import './styles.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface INavigationBlock {
    icon?: string[] | any;
    title: string;
    navigation: () => void;
    selected?: boolean;
    disabled?:boolean;
}

interface ISideBarTabsNavigationProps {
    navigationBlocks: INavigationBlock[];
}

const SideBarTabsNavigation = ({ navigationBlocks }: ISideBarTabsNavigationProps): React.JSX.Element => 
{
    return (
        <Box id="sideBarTabsNavigation">
            {navigationBlocks.map((block, index): React.JSX.Element => 
            {
                if(!block.disabled)
                {
                    return (
                        <Box key={index} className={`navigation-block-card ${block.selected ? 'selected-block' : null}`} onClick={block.navigation}>
                            {block.icon &&
                                <FontAwesomeIcon icon={['fal', block.icon]} className="module-icon" />
                            }
    
                            <Typography className='block-title'>{block.title}</Typography>
                        </Box>
                    );
                }
              
            })}
        </Box>
    );
};

export default SideBarTabsNavigation;