import { createSlice } from '@reduxjs/toolkit';
import { EventMarketingNotificationType, EventMarketingType } from '../../../pages/Events/enum';

export const marketingSlice = createSlice({
    name: 'marketing',
    initialState: {
        value: {
            name: '',
            type: EventMarketingType.MARKETING,
            notificationType: EventMarketingNotificationType.EMAIL,
            triggerTime: '',
            subject: '',
            emailContent: '',
        }
    },
    reducers: {
        addMarketing: (state, actions):void => 
        {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetMarketing: (state):void => 
        {
            state.value = {
                name: '',
                type: EventMarketingType.MARKETING,
                notificationType: EventMarketingNotificationType.EMAIL,
                triggerTime: '',
                subject: '',
                emailContent: '',
            };
        },
    },
});

export const { addMarketing, resetMarketing } = marketingSlice.actions;
export default marketingSlice.reducer;