import React from 'react';
import { Tab, Tabs } from '@mui/material';

interface AgendaTabsProps {
    dates: string[];
    selectedDate: string;
    handleDateChange: (date: string) => void;
}

const StageDateTabs: React.FC<AgendaTabsProps> = ({ dates, selectedDate, handleDateChange }) => {
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        handleDateChange(newValue);
    };

    return (
        <div id="stageDateTabs">
            <Tabs variant='scrollable' className="agenda-tabs-component" value={selectedDate} onChange={handleTabChange}>
                {dates.map(date => (
                    <Tab key={date} label={date} value={date} className="tab-component-button" />
                ))}
            </Tabs>
        </div>
    );
};

export default StageDateTabs;