import { useContext, useEffect, useState } from "react";
import { RegistrationForm } from "../../../Registration/interfaces/registration-form_interface";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import LeadCaptureRegistrations from "./LeadCaptureRegistrations";
import { getLeadCaptureForm } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import FormPreview from "../../../Registration/Preview/FormPreview";
import { PortalContext } from "../../../../contexts/Portal/PortalContext";
import PortalAppBar from "../../../Portal/Components/Common/PortalAppBar";
import PortalFormsProvider from "../../../../contexts/Portal/PortalFormsContext";

const LeadCaptureFormPage: React.FC = (): React.JSX.Element =>
{

    const { selectedModule, handleModuleChange } = useContext(PortalContext);

    const [regFormData, setRegFormData] = useState<RegistrationForm | null>(null);

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const formData = await getLeadCaptureForm();
    
            if(formData && formData?.formFields && formData?.formFields?.length > 0)
            {
                const filteredFields = formData?.formFields?.filter((field) => !(['firstName', 'lastName', 'email']?.includes(field?.default)));
                setRegFormData({
                    ...formData,
                    formFields: filteredFields,
                });
            }
        } 
        catch (error) 
        {
            
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {selectedModule === 'form' && <div id="leadCaptureFormPage">
                <PortalAppBar title={regFormData?.name || ''} buttons={[<CustomButton btnType="primary" name='Edit Form' onClick={() => handleModuleChange('formFieldEdit')} />]} />
                <FormPreview formId={regFormData?.id} registrationFormData={{ registrationForm: regFormData }} width={'50%'} maxWidth={'50%'} maxHeight={'calc(100vh - 68px)'} margin={'20px 20px 0px 20px'} />
            </div>}

            {
                selectedModule === 'formFieldEdit' && 
                <PortalFormsProvider>
                    <LeadCaptureRegistrations />
                </PortalFormsProvider>
            }
        </>
    )
};

export default LeadCaptureFormPage;