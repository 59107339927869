import React, { createContext, useEffect, useState } from 'react';
import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';
import moment from 'moment';
import { deleteEventStage, getAllStage, getStageCount } from '../../scripts/apis/eventStage';
import { EventStage } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../../scripts/constants';
import eventBus from '../../scripts/event-bus';
import { deleteEventSession } from '../../scripts/apis/eventSession';
import { EventStageContextType } from '../Interfaces/event-stage-context_interface';
import toast from 'react-hot-toast';

export const EventStageContext = createContext<EventStageContextType>(null);

const EventStageProvider: React.FC<{children: React.ReactNode; eventId: string; eventReduxData?: IEventReduxValues }> = ({ children, eventId, eventReduxData }): React.JSX.Element => 
{

    const [stageData, setStageData] = useState<EventStage[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [daysArr, setDaysArr] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState('');

    const fetchStageData = async () =>
    {
        try 
        {
            const count = await getStageCount(eventId);
            if(count)
            {
                const eventStages = await getAllStage(eventId, count, 0);
                if(eventStages && eventStages.length > 0)
                {
                    setStageData(eventStages);
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching stage data', error);
        }

    };

    const deleteSessionFromTable = async (): Promise<void> => 
    {
        if (stageData.length > 0) 
        {
            let deleteSuccess = true;
            eventBus.on('delete-event-session', async (object):Promise<void> => 
            {
                const acceptObj = object.acceptObj;
                
                if (deleteSuccess) 
                {
                    if (stageData && stageData.length > 0 && acceptObj.id) 
                    {
                        try 
                        {
                            const sessionDeleted = await deleteEventSession(eventId, acceptObj.id);
                            if (sessionDeleted) 
                            {
                                setRefresh(true);
                                toast.success('Session Deleted');
                                deleteSuccess = false;
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }

                    }
                }

            });
        }
    };

    const deleteStageFromTable = async (): Promise<void> =>
    {
        let deleteSuccess = true;
        eventBus.on('delete-event-stage', async (object):Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            
            if (deleteSuccess) 
            {
                if (acceptObj.id) 
                {
                    try 
                    {
                        const stageDeleted = await deleteEventStage(eventId, acceptObj.id);
                        if (stageDeleted) 
                        {
                            setRefresh(true);
                            toast.success('Stage Deleted');
                            deleteSuccess = false;
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }

                }
            }

        });
    };

    useEffect(() => {
        fetchStageData();
    }, []);

    useEffect(() => {  
        if(refresh)
        {
            fetchStageData();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        const startDate = moment(moment.unix(Number(eventReduxData?.eventStartDateTime)).format('YYYY-MM-DD'));
        const endDate = moment(moment.unix(Number(eventReduxData?.eventEndDateTime)).format('YYYY-MM-DD'));
    
        const dates = [];
    
        while (startDate.isSameOrBefore(endDate)) {
            dates.push(startDate.format('DD MMM'));
            startDate.add(1, 'day');
        }
        
        if(dates?.length > 0)
        {
            setSelectedDate(dates[0]);
        }
    
        setDaysArr(dates);
    }, [eventReduxData]);    

    return (
        <EventStageContext.Provider value={{
            daysArr, selectedDate, setSelectedDate, stageData, eventId, setRefresh, deleteStageFromTable, deleteSessionFromTable
        }}>
            {children}
        </EventStageContext.Provider>
    );
};

export default EventStageProvider;

