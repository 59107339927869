import AnalyticsPageTabs from "../components/Common/AnalyticsPageTabs";
import { UserViewType } from "../../../../Settings/enum/users.enum";
import { UserContext } from "../../../../../contexts/UserContext";
import RevenueAnalytics from "./RevenueAnalytics";
import { useContext } from "react";
import './styles.scss';
import { MinimalViewComponent } from "../../../../../common/MinimalView/MinimalViewComponent";
import HeaderBar from "../../../../../common/Headerbar";

const EventAnalyticsPage: React.FC<{ eventId: string | number }> = ({ eventId }): React.JSX.Element =>
{

    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

    const analyticsTabObj = [
        {
            tabName: 'Revenue',
            selectedTab: false,
            navigation: () => {}  
        },
    ]

    const eventAnalyticsNormalView = () =>
    {
        return (
            <div id="eventAnalyticsPage">
                <div className="event-analytics-header-container">
                    <HeaderBar 
                        title={'Analytics'}
                        buttons={[]}
                    />
                </div>
                <AnalyticsPageTabs eventId={eventId} />
                <div>
                    <RevenueAnalytics eventId={eventId} />
                </div>
            </div>
        )
    }

    const eventAnalyticsMinimalView = () =>
    {
        return (
            <div id="eventAnalyticsMinimalView">
                <MinimalViewComponent
                    title={'Analytics'}
                    description={'View your analytics here'}
                    tabData={analyticsTabObj}
                    showCharts={false}
                    component={
                        <div>
                            <RevenueAnalytics eventId={eventId} />
                        </div>
                    }
                    componentType="list"
                    componentOverflow="auto"
                />
            </div>
        )
    };

    return minimalView ? eventAnalyticsMinimalView() : eventAnalyticsNormalView();

};

export default EventAnalyticsPage;