'use client'

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import _ from "lodash";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from "axios";
import { saveAs } from 'file-saver';
import ehqLoader from '../../../../assets/ehqlogo_loader.gif';
import { EventPreview } from "../../../Events/interfaces";
import { ImageType } from "../../../Events/interfaces/event-photo-sharing_interface";
import useScreenDimension from "../../../../hooks/screenDimensions";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import CustomSkeleton from "../../../../common/CustomSkeleton";
import { downloadAllRegistrantPhotos, getRegistrantPhotos } from "../../../../scripts/apis/registrationPublic";
import { LocalStorage } from "../../../../scripts/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './styles.scss';
import PopUpView from "../../../../common/PopupView";
import EmblaCarousel from "../../Components/embla/EmblaCarousel";
import APP_CONSTANTS from "../../../../scripts/constants";

interface YourPhotosProps
{
    eventData: EventPreview | undefined;
}

const YourPhotosComponent: React.FC<YourPhotosProps> = (props) =>
{

    const { eventData } = props;

    const faceId = LocalStorage.get('@FaceId');

    const [yourPhotos, setYourPhotos] = useState<ImageType[]>([]);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [imgPreview, setImgPreview] = useState<ImageType | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [downloadAllSpinner, setDownloadAllSpinner] = useState<boolean>(false);

    const screenType = useScreenDimension();

    const handlePopupClose = () =>
    {   
        setPopupOpen(false);
    };

    const fetchInitialYourPhotos = async () =>
    {
        if (eventData && eventData?.token)
        {
            try 
            {
                const data = await getRegistrantPhotos(eventData?.token as string, faceId?.faceId);
                
                if(data)
                {
                    setYourPhotos(data);
                }
                else
                {
                    if(data?.status === 403)
                    {
                        toast.error('Rescan to view the photos');
                    }
                }
            } 
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setIsLoading(false);
            }
        }

    };

    const handleDownloadAllPhotos = async (): Promise<void> =>
    {
        setDownloadAllSpinner(true);
        try 
        {
            const isDownloadComplete = await downloadAllRegistrantPhotos(eventData?.token as string, faceId?.faceId);
            if (isDownloadComplete)
            {
                toast.success('All photos are downloaded successfully');
                saveAs(isDownloadComplete?.data, `${eventData?.title} photos.zip`);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error('Error downloading photos. Please try again later.');
        }
        finally
        {
            setDownloadAllSpinner(false);
        }
    };

    useEffect(() => 
    {
        fetchInitialYourPhotos();
    }, [eventData]);
    
    return(
        <>
            {
                eventData &&
                <div id="yourPhotos">
                    <div className="header-div">
                        {<h3 style={{ textAlign: isLoading ? 'center' : 'initial' }} className="heading">{!isLoading ? yourPhotos?.length > 0 ? `Your Photos` : `` : `Hang tight, we’re getting your photos!`}</h3>}
                        {!isLoading && yourPhotos?.length > 0 && <CustomButton loading={downloadAllSpinner} btnType="secondary" startIcon={<FontAwesomeIcon icon={['fal', 'arrow-down-to-bracket']} />} name='Download All' onClick={handleDownloadAllPhotos} />}
                    </div>
                    {!isLoading ? yourPhotos && yourPhotos?.length > 0 ? 
                        <div className="photos-container">
                            {yourPhotos?.length > 0 && yourPhotos?.map((image, index) => {
                                return (
                                    <div className="image-wrapper" key={index}>
                                        <LazyLoadImage placeholder={<Skeleton screenType={screenType} />} effect="blur" onClick={() => { setImgPreview(image); setPopupOpen(true); setSelectedIndex(index); }} style={{ maxHeight: screenType === 'mobile' ? '116px' : '160px' }} src={image?.webUrl || ''} key={index} alt='' />
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="empty-state">
                            <h3>
                                OOPS !! Looks like there are no photos.
                            </h3>
                        </div> :
                        <div className="loader-container"><img src={ehqLoader} height={24} width={24} alt='' /></div>
                    }
                </div>
            }
            {/* </div> : 
            <div className="empty-state">
                <h3>
                    No photos present
                </h3>
            </div> */}
            
            {popupOpen && 
                <PopUpView screenType={screenType as 'mobile'|'desktop'|'tablet'} onClose={() => { setPopupOpen(false); }}>
                    <EmblaCarousel screenType={screenType as 'mobile'|'desktop'|'tablet'} handlePopupClose={handlePopupClose} eventData={eventData} images={yourPhotos} initialIndex={selectedIndex} /> 
                </PopUpView>
            }
        </>
    )
};

export const Skeleton: React.FC<{ screenType: string }> = ({ screenType }): React.JSX.Element =>
{
    return (
        <div className="skeleton-container">
            {Array.from({ length: screenType === 'mobile' ? 8 : 12 }).map((_, index) => ( 
                <CustomSkeleton key={index} animation='pulse' height={screenType === 'mobile' ? 120 : 160} variant='rect' width={screenType === 'mobile' ? 80 : 200} />
            ) )}
        </div>
    )
};

export default YourPhotosComponent;