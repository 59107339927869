export enum EventMarketingType
    {
    TRANSACTIONAL = 1,
    MARKETING = 2
}

export enum EventMarketingStatus
    {
    ACTIVE = 1,
    DELETED = 2
}

export enum EventMarketingNotificationType 
{
    EMAIL = 1,
    PUSH_NOTIFICATION = 2,
}

export enum EventMarketingMailJobStatus
    {
    TRIGGER_NOT_SET = 1,
    TRIGGER_SET = 2,
    IN_PROGRESS = 3,
    CANCELED = 4,
    COMPLETED = 5,
    FAILED = 6
}

