import React, { useState, useEffect, useRef, useContext } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import './styles.scss';
import { Avatar, Stack, Typography } from '@mui/material';
import { buildInitialsForPicture } from '../scripts/helpers';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { general } from '../contexts/Settings/PersonalPageContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SidebarProps } from './ISideBarNavigation';

// When devrev is required use this in index.html
{/* <script type="text/javascript" src="https://plug-platform.devrev.ai/static/plug.js"></script> */ }


// import LogoImg from '../../public/light/eventhq_light.png';
//import LogoImgClosed from '../assets/images/eventhq_logo_icon.svg';

import DeletePopup from './DeletePopup';
import { registrantMe, unauthorizeSponsor } from '../scripts/apis/sponsorPortal/sponsorPortal';
import { LocalStorage } from '../scripts/LocalStorage';
import { EventRegistrant } from '../pages/Events/interfaces/event-registrant_interface';
import SwitchOrgMenu from './SwitchOrgMenu';
import { getUsersOrganizations } from '../scripts/apis/organisation';
import { CurrentUserOrgInfo, Organization } from '../interfaces/settings/organization_interface';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import { logoutWithWorkOS } from '../scripts/apis/authentication';
import { UserContext } from '../contexts/UserContext';
import toast from 'react-hot-toast';
import CustomTooltip from './Tooltip';

const SideBarNavigation = (props): React.JSX.Element => 
{

    const { sidebarcontent } = props;

    const drawerWidth = APP_CONSTANTS.SIDE_DRAWER_WIDTH;

    const {
        userDetails,
        orgDetails
    } = useContext(UserContext);

    const [open, setOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>();
    // const [userDetails, setUserOnline] = useState(null);
    const [registrantInfo, setRegistrantInfo] = useState<EventRegistrant | undefined>(undefined);
    const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);
    const [usersOrg, setUsersOrg] = useState<CurrentUserOrgInfo[]>([]);
    // const [currentOrg, setCurrentOrg] = useState<Organization>();

    const openedMixin = (theme: Theme): CSSObject => 
    {
        return {
            width: drawerWidth,
            backgroundColor: '#ffffff',
            borderRight: '1px solid #e3e3e8',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingTop: import.meta.env.VITE_NODE_ENV === 'demo' && '56px',
            overflowX: 'hidden',
            zIndex: 'unset',
        };
    };
    const collapsedOpenedMixin = (theme: Theme): CSSObject => 
    {
        return {
            width: drawerWidth,
            backgroundColor: '#ffffff',
            borderRight: '1px solid #e3e3e8',
            // maxWidth: '49px !important',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingTop: import.meta.env.VITE_NODE_ENV === 'demo' && '56px',
            overflowX: 'hidden',
            boxShadow: '0px 10px 30px 0px #00000033, 0px 30px 70px 0px #1A224026, 0px 0px 0px 1px #888FAA1A !important',
        };
    };
    const closedMixin = (theme: Theme): CSSObject => 
    {
        return {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            backgroundColor: '#ffffff',
            borderRight: '1px solid #e3e3e8',
            width: 'calc(64px + 1px)',
            zIndex: 'unset',
            paddingTop: import.meta.env.VITE_NODE_ENV === 'demo' && '56px',
            // marginLeft: '-4px',
            [theme.breakpoints.up('sm')]: {
                width: 'calc(64px + 1px)',
            },
        };
    };


    const DrawerHeader = styled('div')(({ theme }) => 
    {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        };
    });

    const Drawer = styled(MuiDrawer, {
        shouldForwardProp: (prop: string): boolean => 
        {
            return prop !== 'open';
        }
    })(
        ({ theme, open }) => 
        {
            return {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                ...(open && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                }),
                ...(open && props['collapsedSideBar'] && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': collapsedOpenedMixin(theme),
                }),
                ...(!open && {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                }),
            };
        },
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentPath = useLocation().pathname;

    const generalData = useSelector((state: general): SidebarProps => 
    {
        return state.general.value;
    });

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    // const [devrevToggled, isDevrevToggled] = useState(false);
    // const [devrevReady, isDevrevReady] = useState(false);

    const logoutFromSponsorPortal = async (): Promise<void> =>
    {
        try 
        {
            const isLoggedOut = await unauthorizeSponsor();
            if(isLoggedOut)
            {
                LocalStorage.removeAll();
                navigate(0);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error logging out');
        }
    };

    const logoutFromAdminApp = async (): Promise<void> =>
    {
        try 
        {
            const isLoggedOut = await logoutWithWorkOS();
            if (isLoggedOut)
            {
                await (window as any).plugSDK.shutdown();
                // await logoutWithWorkOS();
                LocalStorage.removeAll();
                navigate('/login');
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error logging out');
        }
    }

    const handleLogout = (): void => 
    {
        if (currentPath?.includes('sponsor-portal'))
        {
            logoutFromSponsorPortal();
        }
        else
        {
            logoutFromAdminApp();
        }
    };

    const userOrgInformation = async (): Promise<void> => 
    {
        // try 
        // {
        //     const user = await userMe();
        //     if (user) 
        //     {
        //         setUserOnline(user);
        //         dispatch(updateGeneralInfo({
        //             firstName: user?.firstName,
        //             lastName: user?.lastName,
        //             image: user?.additionalInfo?.image ? user.additionalInfo.image : null,
        //             role: user?.role,
        //         }));
        //     }
        //     // const org = await orgMe();
        //     // if (org) {
        //     //     setOrgImage(org);
        //     // }

        // }
        // catch (error) 
        // {
        //     console.log(error);
        // }

    };

    const fetchRegistrantMe = async (): Promise<void> =>
    {
        try 
        {
            const registrant = await registrantMe();
            if(registrant)
            {
                setRegistrantInfo(registrant);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchUsersOrg = async (): Promise<void> => 
        {
            try 
            {
                const userOrg = await getUsersOrganizations();
                setUsersOrg(userOrg);
            }
            catch (error) 
            {
                console.log(error);
            }
        };
    
        // const fetchCurrentOrg = async (): Promise<void> => 
        // {
        //     try 
        //     {
        //         const org = await orgMe();
        //         setCurrentOrg(org);
        //     }
        //     catch (error) 
        //     {
        //         console.log(error);
        //     }
        // };

    useEffect((): void => 
    {
        // if(!currentPath?.includes('portal'))
        // {
            // userOrgInformation();
        fetchUsersOrg();
            // fetchCurrentOrg();
        // }
        // else if(currentPath?.includes('portal'))
        // {
        //     fetchRegistrantMe();
        // }

        eventBus.on(APP_CONSTANTS.EVENTS.POPUP_EVENT.RESULT, async (data): Promise<void> => 
        {
            if (data.result) 
            {
                await (window as any).plugSDK.shutdown();
                await logoutWithWorkOS();
                LocalStorage.removeAll();
                navigate('/login');
            }
        });
    }, []);


    useEffect((): () => void => 
    {
        if (props['collapsedSideBar']) 
        {
            setOpen(false);
            const navElement = containerRef.current;
            if (containerRef.current) 
            {
                navElement.addEventListener('mouseenter', handleMouseEnter);
                navElement.addEventListener('mouseleave', handleMouseLeave);
                navElement.addEventListener('touchstart', handleTouchStart);
                navElement.addEventListener('touchend', handleTouchEnd);
                return (): void => 
                {
                    navElement.removeEventListener('mouseenter', handleMouseEnter);
                    navElement.removeEventListener('mouseleave', handleMouseLeave);
                    navElement.removeEventListener('touchstart', handleTouchStart);
                    navElement.removeEventListener('touchend', handleTouchEnd);
                };
            }


        }
        else 
        {
            setOpen(true);
        }

    }, [props]);

    useEffect(() => 
    {
        (window as any).plugSDK.init({
          app_id: import.meta.env.VITE_DEVREV_APP_ID,
          spacing: {
            // side: "50px",
            // bottom: "50px"
          }
        });

        (window as any).plugSDK.onEvent((payload: any) => 
        {
            if (payload.type === 'ON_PLUG_WIDGET_READY') 
            {
                (window as any).plugSDK.initSearchAgent();
            }
        });
    }, []);

    // useEffect((): void => 
    // {
    //     const setupDevrev = async (): Promise<void> => 
    //     {
    //         const sessionTokenObj = await getDevrevSessionToken();
    //         if (sessionTokenObj) 
    //         {
    //             (window as any).plugSDK.init({
    //                 app_id: import.meta.env.VITE_DEVREV_APP_ID,
    //                 session_token: sessionTokenObj.access_token,
    //                 enable_default_launcher: false,
    //                 custom_launcher_selector: '.devrev-launcher',
    //                 widget_alignment: 'right',
    //                 spacing: {
    //                     side: '-464px',
    //                     bottom: '0px'
    //                 }
    //             });
    //             (window as any).plugSDK.onEvent((payload): void => 
    //             {
    //                 if (payload.type === 'ON_PLUG_WIDGET_READY') 
    //                 {
    //                     isDevrevReady(true);
    //                 }
    //             });
    //         }
    //     };
    //     setupDevrev();
    // }, []);

    // Function to handle mouse hover
    const handleMouseEnter = (): void => 
    {
        // setOpen(true);
    };

    // Function to handle mouse leave
    const handleMouseLeave = (): void => 
    {
        // setOpen(false);
    };
    const handleTouchStart = (): void => 
    {
        // setOpen(true);
    };

    // Function to handle touch end
    const handleTouchEnd = (): void => 
    {
        // setOpen(false);
    };


    const DropdownMenu = (): React.JSX.Element => 
    {
        return (<>
            <Dropdown.Item onClick={(): void => 
            {
                navigate('/settings/general');
            }}>
                <Box className="user-account-menu-container">
                    <CustomTooltip title={<Stack direction={'column'}><Typography variant="subtitle2">{userDetails?.firstName + ' ' + userDetails?.lastName}</Typography><Typography variant="subtitle2">{userDetails?.email}</Typography></Stack>}>
                        <Box className="user-name-email-container">
                            <Typography className="user-name">{userDetails?.firstName + ' ' + userDetails?.lastName}</Typography>
                            <Typography className="user-email">{userDetails?.email}</Typography>
                        </Box>
                    </CustomTooltip>
                </Box>
            </Dropdown.Item>
            <Dropdown.Item onClick={(): void => 
            {
                navigate('/settings/users');
            }}>
                <Box className="dropdown-actions">
                    <FontAwesomeIcon icon={['fal', 'user-plus']} />
                    <Typography className="action-text">{'Invite Members'}</Typography>
                </Box>
            </Dropdown.Item>
            {orgBillingInfo?.billingModules?.integrations && <Dropdown.Item onClick={(): void => 
            {
                navigate('/settings/integrations');
            }}>
                <Box className="dropdown-actions">
                    <FontAwesomeIcon icon={['fal', 'gear-complex']} />
                    <Typography className="action-text">{'Settings'}</Typography>
                </Box>
            </Dropdown.Item>}
            <Dropdown.Item onClick={() => setShowLogoutPopup(true)}>
                <Box className="dropdown-actions">
                    <FontAwesomeIcon icon={['fal', 'arrow-right-from-bracket']} />
                    <Typography className="action-text">{'Logout'}</Typography>
                </Box>
            </Dropdown.Item>
        </>);
    };

    return (
        <div id="SideBarNavigation" style={{
            position: `${props['collapsedSideBar'] ? 'absolute' : 'relative'}`
        }} ref={containerRef}>
            <Box>
                <CssBaseline />
                <Drawer open={open} variant="permanent" className="side-bar">
                    {/* <hr className="m-0" /> */}
                    <Box className="drawer-header-box" sx={open? null : {
                        marginLeft: '4px !important',
                    }}>
                        <DrawerHeader className="drawer-header" sx={open? null : {
                            width: '42px !important',
                            overflow: 'hidden',
                        }}>
                            <Box className="org-menu">
                                
                                {
                                    !currentPath?.includes('portal') && <SwitchOrgMenu usersOrg={usersOrg} currentOrg={orgDetails as Organization} />
                                }
                            </Box>
                            {/* } */}
                           
                        </DrawerHeader>
                    </Box>

                    {/* {import.meta.env.VITE_NODE_ENV !== 'production' ? <Box>
                        <CmdKButton width={'100%'} height={'28px'} margin={'16px 12px 16px 8px'} />
                        {open ? <Box 
                        onClick={CommandKOpen} 
                            className="cmdk-container">
                            <Stack direction={'row'} alignItems={'center'}>
                                <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                                {open ? <Typography className="search-text">{'Search'}</Typography> : null}
                            </Stack>
                            <Box>
                                {open ? <Typography className="command-text">{'cmd+k'}</Typography> : null}
                            </Box>
                        </Box> : <Box 
                            onClick={CommandKOpen} 
                            className="cmdk-container-close">
                            <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                        </Box>}
                    </Box> : ''} */}
                    {/* onClick={CommandKOpen} */}

                    <List className="side-bar-list">
                        {
                            sidebarcontent && sidebarcontent?.mainContent && sidebarcontent?.mainContent?.length > 0 && sidebarcontent?.mainContent.map((item, index: number) => {
                                return (
                                    <ListItem key={index} disablePadding component={Link} className={item?.selected ? 'activeTabs' : ''} to={item?.navigate} onClick={item?.onClick}>
                                        <ListItemButton className="link-btn" sx={open? null : {
                                            justifyContent: 'center !important'
                                        }}>
                                            {open ?
                                                <ListItemIcon className="item-icon">
                                                    <FontAwesomeIcon className="icon" icon={['fal', item?.icon]} />
                                                </ListItemIcon> :
                                                <CustomTooltip title={<Typography variant="subtitle1" className="tool-tip-text">{item?.title}</Typography>}>
                                                    <ListItemIcon className="item-icon">
                                                        <FontAwesomeIcon className="icon" icon={['fal', item?.icon]} />
                                                    </ListItemIcon>
                                                </CustomTooltip>}

                                            {open ? <Box>
                                                <ListItemText primary={item?.title} className={item?.selected ? 'active item-text' : 'item-text'} />
                                            </Box> : null}
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>

                    <List className="footer-style" sx={open?{
                        borderTop: '1px solid #EAECF0 !important',
                        alignItems: 'center !important',
                    }: {
                        width: '44px !important',
                        overflow: 'hidden !important'
                    }}>
                        <ListItem disablePadding sx={{
                            height: '60px !important'
                        }}>
                            {!currentPath?.includes('portal') && <Box className="account-dropdown-btn">
                                <DropdownButton
                                    title={
                                        <Box className="account-drop-down-title-container">
                                            {generalData && generalData?.image && generalData?.image !== null || '' ? <Avatar className="user-picture-menu-avatar" src={generalData.image}></Avatar> : <Avatar className="menu-icon-user-empty-avatar">
                                                {(userDetails?.email || userDetails?.firstName || userDetails?.lastName) &&
                                                    buildInitialsForPicture(userDetails?.email || '', userDetails?.firstName || '', userDetails?.lastName || '')
                                                }

                                            </Avatar>}
                                            {open &&
                                               <Box className='user-details-block-bar'>
                                                   <Typography className="user-name-bar">
                                                        {(userDetails?.firstName || '-') + ' ' + (userDetails?.lastName || '-')}
                                                    </Typography>
                                                    <Typography className="user-email-bar">
                                                        {userDetails?.email || '-'}
                                                    </Typography>
                                               </Box>
                                            }
                                           
                                        </Box>
                                    }>
                                    <DropdownMenu />
                                </DropdownButton>
                            </Box>}

                            {/* sponsor portal sidebar footer */}
                            {currentPath?.includes('portal') &&
                                <div className="registrant-me-container">
                                    <div className="registrant-info-div">
                                        <p className="sponsor-logo-avatar">{registrantInfo && (`${registrantInfo?.firstName?.charAt(0) || ''} ${registrantInfo?.lastName?.charAt(0) || ''}`)}</p>
                                        <div>
                                            <h3 className="registrant-name">{`${registrantInfo?.firstName || ''} ${registrantInfo?.lastName || ''}`}</h3>
                                            <p className="registrant-email">{registrantInfo?.email || ''}</p>
                                        </div>
                                    </div>
                                    <FontAwesomeIcon className="logout-icon" icon={['fal', 'arrow-right-from-bracket']} onClick={() => setShowLogoutPopup(true)} />
                                </div>
                            }
                            {/* <Box className='account-footer-action-icons'> */}
                                {/* <FontAwesomeIcon className="icon footer-action-icons" icon={['fal', 'bell']} /> */}
                                {/* <FontAwesomeIcon className="icon footer-action-icons devrev-launcher" onClick={(): void => 
                                {
                                    if (devrevReady) 
                                    {
                                        const devrevToggle = !devrevToggled;
                                        isDevrevToggled(devrevToggle);
                                        (window as any).plugSDK.toggleWidget(devrevToggle);
                                    }
                                }} icon={['fal', 'message']} /> */}
                            {/* </Box> */}
                        </ListItem>
                    </List>
                </Drawer>
            </Box>

            {
                showLogoutPopup &&
                <DeletePopup 
                    acceptBtn='Logout' 
                    acceptClick={() => handleLogout()} 
                    cancelClick={() => { 
                        setShowLogoutPopup(false);
                    }} 
                    modalContent={`Are you sure you want to logout ?`}
                    modalTitle='Logout'
                    show={showLogoutPopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='sign-out'
                />
            }
        </div>
    );
};
export default SideBarNavigation;