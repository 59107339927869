import { useState } from 'react';
import { Menu, MenuItem, Typography, Avatar, Divider } from '@mui/material';
import { switchOrganization } from '../scripts/apis/organisation';
import { CurrentUserOrgInfo, Organization } from '../interfaces/settings/organization_interface';
import { useSelector } from 'react-redux';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const SwitchOrgMenu = (props: {currentOrg: Organization, usersOrg: CurrentUserOrgInfo[]}) =>
{
    const usersOrg = props.usersOrg;
    const currentOrg = props.currentOrg;
    const orgLogoImg = (currentOrg?.additionalInfo?.image) ? currentOrg.additionalInfo.image : null;
    const emptyImgSrc = currentOrg?.name.charAt(0).toUpperCase();

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const navigate = useNavigate(); 

    const SwitchOrganization = async (orgId: string): Promise<void> => 
    {
        try 
        {
            const isOrganizationSwitched: Boolean = await switchOrganization(orgId, csrfTokenData);
            if(isOrganizationSwitched)
            {
                handleClose();
                navigate('/dashboard');
                window.location.reload();
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (event: any) =>
    {
        if (usersOrg.length > 0)
        {
            setAnchorEl(event.currentTarget);
        }
        else
        {
            navigate('/dashboard');
        }
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };

    const handleSwitchOrg = (event: any, id: string) =>
    {
        SwitchOrganization(id)
    };

    const avatarStyle = (imageExists: boolean) => ({
        marginRight: 1,
        width: '32px',
        height: '32px',
        borderRadius: '20px',
        backgroundColor: imageExists ? 'transparent' : '#f0f0f0',
        color: imageExists ? 'inherit' : '#667085',
        '& img': {
            objectFit: 'contain'
        }
    });

    return (

        <div>
            <div
                onClick={handleClick}
                className='switch-org-menu'
            >

                {(orgLogoImg) ? (
                    <Avatar className="org-img" src={orgLogoImg}></Avatar>
                ) : (
                    <Avatar className="empty-org-avatar">
                        <p>{emptyImgSrc}</p>
                    </Avatar>
                )}
                <Typography>{currentOrg?.name}</Typography>

            </div>
            {usersOrg.length > 1  && (
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            width: '300px',
                            borderRadius: '10px',
                        },
                    }
                }}
            >
                {usersOrg.filter(org => String(org?.id) !== currentOrg?.id).map((org, index) => (
                    <div key={index}
                        onClick={(event) => handleSwitchOrg(event, String(org?.id))}
                    >
                        <div>
                            <MenuItem>
                                <Avatar
                                    sx={avatarStyle(!!org?.additionalInfo?.image)}
                                    src={org?.additionalInfo?.image || ''}
                                >
                                    {!org?.additionalInfo?.image && (
                                        <p style={{ color: '#667085', fontSize: '14px', fontWeight: '600', fontStyle: 'normal', lineHeight: '20px' }} >{org?.orgName.charAt(0).toUpperCase()}</p>
                                    )}
                                </Avatar>
                                <div>
                                    <Typography variant="subtitle2">{org?.orgName}</Typography>
                                </div>
                            </MenuItem>
                            {index < usersOrg.length - 2 && <Divider />}
                        </div>
                    </div>
                ))}
            </Menu>
            )}
        </div>
    );
};

export default SwitchOrgMenu;
