import { useEffect, useMemo, useState } from 'react';
import { ReferrerData } from '../pages/Events/interfaces';
import { getEventOverviewAnalytics } from '../scripts/apis/events';
import _ from 'lodash';
import moment from 'moment';
import eventBus from '../scripts/event-bus';

interface PirschGraphReady 
{
        uniqueVisitors: boolean,
        referrers: boolean,
        registrations: boolean,
        trends?: boolean
}

interface TrendData {
    uniqueVisitors: string | number;
    pageViews: string | number;
    sessions: string | number;
    bounceRate: number;
    sessionDuration: string;
}

interface PirschGraphData {
        uniqueVisitors: [Date[] | string[], number[]] | null,
        referrers: ReferrerData[] | null,
        registrations: [string[], number[] ] | null,
        registrationCount: number | null;
}  

interface EventTrends {
    name: string;
    value: string | number;
}

const usePirschAnalyticsData = (props: {eventId: number, from?: string, to?: string}): [PirschGraphReady, PirschGraphData, EventTrends[]] => 
{
    const [refresh, setRefresh] = useState(false);

    const { from, to } = props;

    const [pirschGraphReady, isPirschGraphReady] = useState<PirschGraphReady>({
        uniqueVisitors: false,
        referrers: false,
        registrations: false,
        trends: false,
    });

    const [pirschGraphData, setPirschGraphData] = useState<PirschGraphData>({
        uniqueVisitors: null,
        referrers: null,
        registrations: null,
        registrationCount: null
    });

    const [pirschTrendData, setPirschTrendData] = useState<TrendData>({
        uniqueVisitors: 0,
        pageViews: 0,
        sessions: 0,
        bounceRate: 0,
        sessionDuration: '0m0s'
    });

    const pirschTrendsArr: EventTrends[] = [
        {
            name: 'Unique Visitors',
            value: pirschTrendData?.uniqueVisitors
        },
        {
            name: 'Page Views',
            value: pirschTrendData?.pageViews
        },
        {
            name: 'Sessions',
            value: pirschTrendData?.sessions
        },
        {
            name: 'Bounce Rate',
            value: pirschTrendData?.bounceRate !== 0 ? `${Number(pirschTrendData?.bounceRate?.toFixed(2))}%` : 0
        },
        {
            name: 'Session Duration',
            value: pirschTrendData?.sessionDuration
        }
    ];

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const pirschData = await getEventOverviewAnalytics(String(props.eventId), from || undefined, to || undefined);

            if (pirschData) 
            {
                setRefresh(false);

                // unique visitors
                if (pirschData?.visitor?.dates && pirschData?.visitor?.visitors) 
                {
                    if (!(_.every(pirschData?.visitor?.visitors, (num): boolean => 
                    {
                        return num === 0; 
                    }))) 
                    {
                        setPirschGraphData((prevItems): PirschGraphData => 
                        {
                            return {
                                ...prevItems,
                                uniqueVisitors: [[...pirschData.visitor.dates], [...pirschData.visitor.visitors]]
                            }; 
                        });
                    }
                }

                // registrations
                if (pirschData?.registrations && (!(_.isEmpty(pirschData?.registrations))) && pirschData?.visitor?.dates) 
                {

                    const registrationSum = _.sum(_.values(pirschData?.registrations));
                    setPirschGraphData((prevItems): PirschGraphData => 
                    {
                        return {
                            ...prevItems,
                            registrationCount: registrationSum,
                        }; 
                    });

                    const datesArr = pirschData?.visitor?.dates?.map((item): moment.Moment => 
                    {
                        return moment(item); 
                    });
                    const xAxisValues = datesArr?.map((date): string => 
                    {
                        return date.format('YYYY-MM-DD'); 
                    });
                    if (xAxisValues) 
                    {
                        const yAxisValues = Array(pirschData?.visitor?.dates?.length).fill(0);
                        Object.entries(pirschData?.registrations).forEach(([key, value]): void => 
                        {
                            const index = xAxisValues.indexOf(key);
                            if (index !== -1) 
                            {
                                yAxisValues[index] = value;
                            }
                        });

                        if (!(_.every(yAxisValues, (num): boolean => 
                        {
                            return num === 0; 
                        })))
                            setPirschGraphData((prevItems): PirschGraphData => 
                            {
                                return {
                                    ...prevItems,
                                    registrations: [[...xAxisValues], [...yAxisValues]],
                                }; 
                            });
                    }
                }

                // referrers
                if (pirschData?.referrer?.length > 0) 
                {
                    setPirschGraphData((prevItems): PirschGraphData => 
                    {
                        return {
                            ...prevItems,
                            referrers: pirschData?.referrer
                        }; 
                    });
                }

                // trends 
                if (pirschData?.trend) 
                {
                    setPirschTrendData((prevItems): TrendData => 
                    {
                        return {
                            ...prevItems,
                            uniqueVisitors: pirschData?.trend?.visitors,
                            pageViews: pirschData?.trend?.views,
                            sessions: pirschData?.trend?.sessions,
                            bounceRate: pirschData?.trend?.bounceRate,
                            sessionDuration: pirschData?.trend?.sessionDuration !== '0m0s' ? pirschData?.trend?.sessionDuration.replace(/(\d+m)(\d+s)/, '$1 $2') : '-',
                        }; 
                    });
                }
                else 
                {
                    setPirschTrendData((prevItems): TrendData => 
                    {
                        return {
                            ...prevItems,
                            uniqueVisitors: 0,
                            pageViews: 0,
                            sessions: 0,
                            bounceRate: 0,
                            sessionDuration: '-',
                        }; 
                    });
                }
            }
            

        }
        catch (error) 
        {
            console.log(error);
        }
        finally 
        {
            isPirschGraphReady((prevItems): PirschGraphReady => 
            {
                return {
                    ...prevItems,
                    referrers: true,
                    registrations: true,
                    uniqueVisitors: true,
                    trends: true,
                }; 
            });
        }
    };

    useMemo(() => {
        if(props?.eventId)
        {
            fetchData();
        }
    }, [from, to]);

    useEffect((): void => 
    {
        if(props?.eventId)
        {
            fetchData();
        }

        eventBus.on('event-overview-refreshed', (data): void => 
        {
            if (data.refresh) 
            {
                setRefresh(data.refresh);
            }
        });
    }, []);

    useEffect((): void => 
    {
        if (refresh && props?.eventId) 
        {
            fetchData();
        }

    }, [refresh]);

    return [pirschGraphReady, pirschGraphData, pirschTrendsArr];
};

export default usePirschAnalyticsData;
