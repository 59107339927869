import { Box } from '@mui/material';
import React from 'react';
import './styles.scss';


interface IButtonGroupProps {
    tabName: string | React.JSX.Element;
    count?: number;
    selectedTab: boolean;
    navigation: () => void;
}

interface IButtonGroup {
    tabs: IButtonGroupProps[];

}

const ButtonGroup = ({ tabs }: IButtonGroup): React.JSX.Element => 
{
    return (
        <Box id='buttonGroup'>
            {tabs.length > 0 && tabs.map((tabs, index):React.JSX.Element => 
            {
                return (
                    <Box className={`${tabs.selectedTab ? 'selected-btn':'btn'}`} key={index} onClick={tabs.navigation}>
                        {tabs.tabName}
                    </Box>
                );
            })}

        </Box>
    );
};

export default ButtonGroup;