import React from 'react';
import { NoDataComponentProps } from '../analytics_interface';
import { Box, Typography } from '@mui/material';
import './styles.scss';

const NoDataComponent = (props: NoDataComponentProps) : React.JSX.Element => 
{
    const componentHeight = props.height;

    return (
        <div id="noDataComponent">
            <Box height={componentHeight} margin={props.margin && props.margin} className="analytics-empty-container">
                <Typography className="analytics-no-data-content">{'No data available'}</Typography>
            </Box>
        </div>
    );
};

export default NoDataComponent;