import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AutocompleteComponent, CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CONTENT } from '../../scripts/i18n';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import _ from 'lodash';
import { createAnnualBudget, getAnnualBudget, updateAnnualBudget } from '../../scripts/apis/budget';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import CustomSpinner from '../../common/CustomSpinner';
import { AnnualBudget } from './interfaces/annual-budget_interface';
import { CustomButton } from '../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';

const CreateGlobalBudget = (): React.JSX.Element => 
{

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const [spinner, setSpinner] = useState(false);
    const [annualBudgetData, setAnnualBudgetData] = useState(null);
    const [dataSpinner, setDataSpinner] = useState(true);
    const [initialYear, setInitialYear] = useState('2024');
    
    let currentYear = new Date().getFullYear();
    const earliestYear = 1970;
    const futureYears = 5;
    const year = [];
    while (currentYear >= earliestYear) 
    {
        year.push(currentYear.toString());
        currentYear -= 1;
    }
    for (let i = 1; i <= futureYears; i++) 
    {
        year.unshift((new Date().getFullYear() + i).toString());
    }

    const validationSchema = Yup.object().shape({
        Year: Yup.string().required('Year is required'),
        overAllBudget: Yup.number().required('Budget is required'),
        firstQuarter: Yup.number(),
        secondQuarter: Yup.number(),
        thirdQuarter: Yup.number(),
        fourthQuarter: Yup.number(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            Year: initialYear,
            overAllBudget: annualBudgetData?.overallBudget ? annualBudgetData?.overallBudget : 0,
            firstQuarter: annualBudgetData?.firstQuarter ? annualBudgetData?.firstQuarter : 0,
            secondQuarter: annualBudgetData?.secondQuarter ? annualBudgetData?.secondQuarter : 0,
            thirdQuarter: annualBudgetData?.thirdQuarter ? annualBudgetData?.thirdQuarter : 0,
            fourthQuarter: annualBudgetData?.fourthQuarter ? annualBudgetData?.fourthQuarter : 0
        },
        onSubmit: (): void => 
        {
            setSpinner(true);
            handleSubmit();
        },
    });

    const quarterlyBudgets = {
        firstQuarter: Number(formik.values.firstQuarter),
        secondQuarter: Number(formik.values.secondQuarter),
        thirdQuarter: Number(formik.values.thirdQuarter),
        fourthQuarter: Number(formik.values.fourthQuarter)
    };
    const quarterlyBudgetsObj = _.compact(Object.values(quarterlyBudgets));
    const quarterlyBudgetSum = _.sum(Object.values(quarterlyBudgetsObj));

    const handleSubmit = async (): Promise<void> =>
    {        
        if(quarterlyBudgetSum > Number(formik.values.overAllBudget))
        {
            toast.error('Quarterly budget sum should not exceed overall budget');
            setSpinner(false);
            return;
        }

        if(annualBudgetData?.id)
        {
            const payload = {
                year: formik.values.Year,
                overallBudget: formik.values.overAllBudget,
                firstQuarter: formik.values.firstQuarter !== '' ? formik.values.firstQuarter : null,
                secondQuarter: formik.values.secondQuarter !== '' ? formik.values.secondQuarter : null,
                thirdQuarter: formik.values.thirdQuarter !== '' ? formik.values.thirdQuarter : null,
                fourthQuarter: formik.values.fourthQuarter !== '' ? formik.values.fourthQuarter : null,
            };

            try 
            {
                const quarterlyBudgetUpdated = await updateAnnualBudget(annualBudgetData?.id, payload);
                if(quarterlyBudgetUpdated)
                {
                    toast.success('Budget added successfully');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setSpinner(false);
                handleDrawerClose();
            }
        }
        else
        {
            try 
            {
                const payload = {
                    year: formik.values.Year,
                    overallBudget: formik.values.overAllBudget,
                    firstQuarter: formik.values.firstQuarter !== '' ? formik.values.firstQuarter : undefined,
                    secondQuarter: formik.values.secondQuarter !== '' ? formik.values.secondQuarter : undefined,
                    thirdQuarter: formik.values.thirdQuarter !== '' ? formik.values.thirdQuarter : undefined,
                    fourthQuarter: formik.values.fourthQuarter !== '' ? formik.values.fourthQuarter : undefined,
                };
                const quarterlyBudgetCreated = await createAnnualBudget(payload, csrfTokenData);
                if(quarterlyBudgetCreated)
                {
                    toast.success('Budget updated successfully');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setSpinner(false);
                handleDrawerClose();
            }
        }

    };

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
    };

    const fetchQuarterlyBudget = async (): Promise<void> =>
    {
        try 
        {
            const annualBudget: AnnualBudget = await getAnnualBudget(formik.values.Year);
            if(annualBudget)
            {
                setAnnualBudgetData(annualBudget);
            }
            else
            {
                setAnnualBudgetData({
                    overallBudget: '',
                    firstQuarter: '',
                    secondQuarter: '',
                    thirdQuarter: '',
                    fourthQuarter: ''
                });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setDataSpinner(false);
        }
    };

    useEffect((): void =>
    {
        fetchQuarterlyBudget();
    }, [initialYear]);

    return (
        <Box id="createGlobalBudget">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate autoComplete="off" onSubmit={(values): void => 
            {
                return formik.handleSubmit(values);
            }}>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Select Year" required />
                    <AutocompleteComponent disableClearable={true} value={formik.values.Year} onChange={(event, newValue): void => 
                    {
                        formik.setFieldValue('Year', newValue); 
                        setInitialYear(newValue);
                    }} optionsArr={year} placeholder='Select a Year' />
                    {formik.touched.Year && formik.errors.Year ? <Typography className="error-msg">{formik.errors.Year}</Typography> : null}
                </Box>
                {
                    dataSpinner ? 
                        <CustomSpinner height={'100%'} /> :
                        <>
                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label="Overall Budget" required />
                                <FormControlComponent 
                                    type='number'
                                    inputGroupText='$'
                                    asInputGroup
                                    value={formik.values.overAllBudget} 
                                    onKeyDown={(evt): void => 
                                    {
                                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                    }}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('overAllBudget', event.target.value);
                                    }} isInvalid={
                                        formik.touched.overAllBudget && formik.errors.overAllBudget ? true : false
                                    } />
                                {formik.touched.overAllBudget && formik.errors.overAllBudget ? <Typography className="error-msg">{formik.errors.overAllBudget}</Typography> : null}
                            </Box>
                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label='Quarterly Budget (Q1)' />
                                <FormControlComponent
                                    type='number'
                                    value={formik.values.firstQuarter}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('firstQuarter', event.target.value);
                                    }}
                                    asInputGroup
                                    inputGroupText='$'
                                    onKeyDown={(evt): void => 
                                    {
                                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                    }}
                                />
                            </Box>
                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label='Quarterly Budget (Q2)' />
                                <FormControlComponent
                                    type='number'
                                    value={formik.values.secondQuarter}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('secondQuarter', event.target.value);
                                    }}
                                    asInputGroup
                                    inputGroupText='$'
                                    onKeyDown={(evt): void => 
                                    {
                                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                    }}
                                />
                            </Box>
                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label='Quarterly Budget (Q3)' />
                                <FormControlComponent
                                    type='number'
                                    value={formik.values.thirdQuarter}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('thirdQuarter', event.target.value);
                                    }}
                                    asInputGroup
                                    inputGroupText='$'
                                    onKeyDown={(evt): void => 
                                    {
                                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                    }}
                                />
                            </Box>
                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label='Quarterly Budget (Q4)' />
                                <FormControlComponent
                                    type='number'
                                    value={formik.values.fourthQuarter}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('fourthQuarter', event.target.value);
                                    }}
                                    asInputGroup
                                    inputGroupText='$'
                                    onKeyDown={(evt): void => 
                                    {
                                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                    }}
                                />
                            </Box>
                        </>
                }
                
                
                <Box className="submit-btn-container">
                    {
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                            <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' onClick={handleDrawerClose} />
                            <CustomButton name='Save' loading={spinner} btnType='primary' type='submit' />
                        </Stack>
                    }

                </Box>
            </Form>
        </Box>
    );
};

export default CreateGlobalBudget;
