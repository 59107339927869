import React, { useEffect, useMemo, useState } from 'react';
import eventBus from '../../../scripts/event-bus';
import { getAllHubspotProperties, getHubspotIntegrationProperties, getHubspotSyncDetails, mapHubspotPropertiesWithCustomFields } from '../../../scripts/apis/hubspot';
import { IIntegrationMappingPopup } from '../interface/integration_interface';
import { HubspotContactsProperties, IntegrationSyncStatus, IntegrationTypes } from '../enum/integrations.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, FormControlLabel, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { AutocompleteComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { getCustomField } from '../../../scripts/apis/customField';
import { CustomFieldHubspotPropertyOverride, CustomFieldLocations, CustomFieldOrder } from '../../Registration/enum/custom-field.enum';
import { CustomField } from '../../Registration/interfaces/custom-field_interface';
import { stripHtmlTags } from '../../../scripts/helpers';
import { customFieldCountByLocation } from '../CustomField/CustomField.helper';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { HubspotProperty, HubspotSyncDetails } from '../interface/hubspot_interface';
import CustomSpinner from '../../../common/CustomSpinner';
import moment from 'moment';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import CustomTooltip from '../../../common/Tooltip';

import './styles.scss';

interface IPropertyObjectType{
    [key:string]: string|number | boolean | string[] | object | {[key:string]: string | number | boolean} | boolean;
}

interface IMappingProperties {
    contacts: IPropertyObjectType[];
    deals?: IPropertyObjectType[];
    companies?: IPropertyObjectType[];
    availableContacts: IPropertyObjectType[];
    availableDeals?: IPropertyObjectType[];
    availableCompanies?: IPropertyObjectType[];
    integrationType?: string;
    currentTab?: 'mapping' | 'sync';
    setCurrentTab?: React.Dispatch<React.SetStateAction<'mapping' | 'sync'>>;
    loading?: boolean;
    syncStatus?: IntegrationSyncStatus;
}

const IntegrationMappingPopUp = ({ popUpHeading, CloseButtonFunction, type, syncStatus }: IIntegrationMappingPopup):React.JSX.Element => 
{

    const [integrationMappingObject, setIntegrationMappingObject] = useState(null);
    const [allIntegrationProperties, setAllIntegrationProperties] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [mappingPayload, setMappingPayload] = useState<{ [key: string]: HubspotProperty[]; }>({
    });
    const [currentTab, setCurrentTab] = useState<'mapping' | 'sync'>('mapping');
    const [requiredPropertiesFilled, setRequiredPropertiesFilled] = useState<boolean>(false);

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    useEffect(():void => 
    {
        fetchAllIntegrationDataByType(type);

        fetchExistingIntegrationDataByType(type);

        eventBus.on('updated_contact_details', (data):void => 
        {
            setMappingPayload(():{mapArray: HubspotProperty[]} => 
            {
                // const existingContacts = prevMappingPayload.contacts || integrationMappingObject?.['contacts'] || [];

                // const updatedContacts = existingContacts.filter((item):boolean => 
                // {
                //     if (item.propertyType) 
                //     {
                //         return item; 
                //     } 
                // });

                // const existingIndex = updatedContacts.findIndex((contact):boolean => 
                // {
                //     return Number(contact?.propertyType) === data.data.contactsProperty.propertyType;
                // });
                // if (existingIndex !== -1) 
                // {
                //     updatedContacts[existingIndex] = data.data.contactsProperty;
                // }
                // else 
                // {
                //     updatedContacts.push(data.data.contactsProperty);
                // }

                // return {
                //     ...prevMappingPayload,
                //     contacts: updatedContacts
                // };

                return {
                    mapArray: data.data?.contactsProperty
                }
            });

            setRequiredPropertiesFilled(data.data.isAllRequiredPropertiesFilled);
        });

    }, [type]);

    const fetchExistingIntegrationDataByType = async (type: number):Promise<void> => 
    {

        if (type === IntegrationTypes.HUBSPOT) 
        {

            try 
            {
                const ExistingHubspotProperties = await getHubspotIntegrationProperties();

                if (ExistingHubspotProperties) 
                {

                    setIntegrationMappingObject(ExistingHubspotProperties);
                    setMappingPayload({
                        'contacts': ExistingHubspotProperties['contacts'] 
                    });

                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const fetchAllIntegrationDataByType = async (type: number):Promise<void> => 
    {

        if (type === IntegrationTypes.HUBSPOT) 
        {

            try 
            {
                const AllHubspotProperties = await getAllHubspotProperties();

                if (AllHubspotProperties) 
                {
                    setAllIntegrationProperties(AllHubspotProperties);
                }

            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const postIntegrationDataByType = async (type: number):Promise<void> => 
    {
        if (type === IntegrationTypes.HUBSPOT) 
        {
            try 
            {

                if (!processing) 
                {
                    setProcessing(true);

                    const HubspotPropertiesSent = await mapHubspotPropertiesWithCustomFields(mappingPayload, csrfTokenData);

                    if (HubspotPropertiesSent) 
                    {
                        setProcessing(false);
                        toast.success('Hubspot properties saved successfully');
                    }
                }

            }
            catch (error) 
            {
                console.log(error);
                setProcessing(false);
                toast.error((error as Error)?.message || 'Error in saving Hubspot properties');
            }
        }
    };

    const handleNextClick = () => {
        if (requiredPropertiesFilled)
        {
            setCurrentTab('sync');
        }
        else
        {
            toast.error('Please map all required fields before syncing');
        }
    }

    const handleSave = ():void => 
    {
        postIntegrationDataByType(type); 
    };

    return (
        <div id="IntegrationMappingPopUpContainer">
            <div className="popUpContentBlock">
                <div className="headerBlock">
                    <div className="heading">
                        {popUpHeading ? popUpHeading : 'Map Columns'}
                    </div>
                    <div className="close-button" onClick={CloseButtonFunction}>
                        <FontAwesomeIcon icon={['fal', 'xmark']} className="closeIconImg" />
                    </div>
                </div>
                <div className="contentBlock">
                    <IntegrationMappingTable
                        contacts={allIntegrationProperties?.['contacts']}
                        deals={allIntegrationProperties?.['deals']}
                        companies={allIntegrationProperties?.['companies']}
                        availableContacts={
                            integrationMappingObject?.['contacts']
                        }
                        availableDeals={integrationMappingObject?.['deals']}
                        availableCompanies={
                            integrationMappingObject?.['companies']
                        }
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}   
                        loading={processing || (syncStatus && syncStatus === IntegrationSyncStatus.IN_PROGRESS)}
                        syncStatus={syncStatus}
                    />
                </div>

                <div className="footerBlock">
                    <div className="btnsBlock">
                        <CustomButton 
                            btnType='secondary'
                            name='Cancel' 
                            onClick={CloseButtonFunction}
                        />
                        <CustomButton 
                            btnType='primary'
                            name={currentTab === 'mapping' ? 'Next' : 'Save'} 
                            onClick={currentTab === 'mapping' ? handleNextClick : handleSave}
                            disabled={processing || (syncStatus && syncStatus === IntegrationSyncStatus.IN_PROGRESS)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationMappingPopUp;

const IntegrationMappingTable = (props: IMappingProperties):React.JSX.Element => 
{

    const [contactRows, setContactRows] = useState([]);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [requiredIds, setRequiredIds] = useState<string[]>([]);
    const [mappings, setMappings] = useState<{
        customFieldId?: string | number;
        hubspotProperty: HubspotProperty | null;
        hubspotPropertyOverride: CustomFieldHubspotPropertyOverride;
    }[]>([]);
    const [syncDetails, setSyncDetails] = useState<HubspotSyncDetails | null>(null);

    const fetchCustomFields = async (): Promise<void> => {
        try {
            const count = await customFieldCountByLocation(CustomFieldLocations.REGISTRATION_FORM);
            if (count) {
                const customFields = await getCustomField(25, 0, CustomFieldLocations.REGISTRATION_FORM, CustomFieldOrder.ASCENDING);
                
                if (customFields && customFields.length > 0) {
                    const fields = _.sortBy(customFields, (item) => item?.default === 'email' ? 0 : 1);
                    setCustomFields(fields);
                }
            }
        } catch (error) {
            console.log(error, 'Error in fetching custom fields');
        }
    };

    const fetchHubspotSyncDetails = async (): Promise<void> => {
        try 
        {
            const details = await getHubspotSyncDetails();
            if (details)
            {
                setSyncDetails(details);
            }
        } 
        catch (error) 
        {
            console.log(error, 'Error in fetching hubspot sync details');
        }
    };

    // useEffect(():void => 
    // {
        // if (props.availableContacts) 
        // {

        //     const availableMapping = {
        //     };

        //     props.availableContacts.forEach((item):void => 
        //     {
        //         if (item.propertyType) 
        //         {
        //             if(typeof(item.propertyType) === 'number' || typeof(item.propertyType) === 'string')
        //             {
        //                 availableMapping[item.propertyType] = item.label;
        //             }
                    
        //         }
        //     });

        //     const updatedRows = contactRows.map((row):{
        //         id: number;
        //         titleName: string;
        //         contact: string;
        //         selectedValue: number | string;
        //         enumType: HubspotContactsProperties;
        //     } => 
        //     {
        //         if (availableMapping[row.enumType]) 
        //         {
        //             return {
        //                 ...row,
        //                 selectedValue: availableMapping[row.enumType]
        //             };
        //         }
        //         return row;
        //     });

        //     setContactRows(updatedRows);
        // }
    // }, [props.availableContacts]);

    useEffect(() => {
        fetchCustomFields();
        fetchHubspotSyncDetails();
    }, []);

    useEffect(() => {
        if (customFields.length > 0) {
            const emailHubspotProperty = props?.contacts?.find((contact: HubspotProperty) => contact?.name === 'email');

            const mapping = customFields.map((field: CustomField): {
                customFieldId?: string | number;
                hubspotProperty: HubspotProperty | null;
                hubspotPropertyOverride: CustomFieldHubspotPropertyOverride;
            } => {
                let hubspotProperty = field?.hubspotProperty || null;

                if (field?.default === 'email' && !field?.hubspotProperty) {
                    hubspotProperty = emailHubspotProperty as HubspotProperty;
                }

                return {
                    customFieldId: field.id,
                    hubspotProperty,
                    hubspotPropertyOverride: field.hubspotPropertyOverride || CustomFieldHubspotPropertyOverride.YES
                };
            });

            setMappings(mapping);

            setRequiredIds(customFields.filter((field: CustomField) => field?.default === 'email').map((field: CustomField) => field.id));
        }
    }, [customFields, props?.contacts]);

    const onDropDownChangeForContacts = (id, event, value):void => 
    {
        if (value && id) 
        {
            // const updatedRows = contactRows.map((row):{
            //     id: number;
            //     titleName: string;
            //     contact: string;
            //     selectedValue: number | string;
            //     enumType: HubspotContactsProperties;
            // } => 
            // {
            //     if (row.id === id) 
            //     {
            //         return {
            //             ...row, selectedValue: value 
            //         };
            //     }
            //     return row;
            // });

            // setContactRows(updatedRows);

            // const validProperty = props.contacts.filter((item):boolean => 
            // {
            //     if (item.label === value) 
            //     {
            //         return true;
            //     }

            // }).map((item):{
            //     propertyType: string | number;
            // } => 
            // {
            //     const x = {
            //         ...item,
            //         ['propertyType']: propertyType
            //     };
            //     return x;
            // });
            // eventBus.dispatch('updated_contact_details', {
            //     data: {
            //         contactsProperty: validProperty[0]
            //     }
            // });
            
        }
        else if (!value || value === '')
        {
            const updatedRows = contactRows.map((row):{
                id: number;
                titleName: string;
                contact: string;
                selectedValue: number | string;
                enumType: HubspotContactsProperties;
            } => 
            {
                if (row.id === id) 
                {
                    return {
                        ...row, selectedValue: '' 
                    };
                }
                return row;
            });

            setContactRows(updatedRows);
        }
    };

    const handleHubspotPropertyChange = (index: number, value: HubspotProperty, customFieldId: string) => {
        if (value)
        {
            setMappings((prevMappings) => {
                const updatedMappings = [...prevMappings];
                updatedMappings[index] = {
                    ...updatedMappings[index],
                    customFieldId: customFieldId,
                    hubspotProperty: value,
                };
                return updatedMappings;
            });
        }
        else
        {
            setMappings((prevMappings) => {
                const updatedMappings = [...prevMappings];
                updatedMappings[index] = {
                    ...updatedMappings[index],
                    customFieldId: customFieldId,
                    hubspotProperty: null,
                };
                return updatedMappings;
            });
        }
    };

    useMemo(() => {
        eventBus.dispatch('updated_contact_details', {
            data: {
                contactsProperty: _.compact(mappings),
                isAllRequiredPropertiesFilled: requiredIds.every((id) => mappings.some((mapping) => mapping.customFieldId == id && mapping.hubspotProperty))
            }
        });
    }, [mappings]);

    const renderMappingContent = (): React.JSX.Element => {
        return (
            <div className="mapping-table-1">
                <Box id="integrationMappingContainer">
                    <Box className="heading-row">
                        <Box className="column-header">{'EventHQ Fields'}</Box>
                        <Box className="column-header">{'Hubspot Fields'}</Box>
                        <div className="column-header">
                            <p>{'Override'}</p>
                            <CustomTooltip title="Existing data in HubSpot will be overridden if this toggle is turned on">
                                <FontAwesomeIcon cursor={'pointer'} icon={['fal', 'info-circle']} />
                            </CustomTooltip>
                        </div>
                    </Box>
                    {customFields?.length > 0 && customFields?.map((item, index):React.JSX.Element => 
                    {
                        return (
                            <Box key={index} className="properties-row">
                                <Box className="property-name">
                                    {stripHtmlTags(item.fieldName)}
                                    {
                                        item?.default === 'email' && <p className="required-icon">*</p>
                                    }
                                </Box>
                                {/* <div className="property-value-override-toggle"> */}
                                    <Box className="property-value">
                                        <AutocompleteComponent
                                            value={mappings[index]?.hubspotProperty || ''}
                                            onChange={(e, value):void => 
                                            {
                                                handleHubspotPropertyChange(index, value as unknown as HubspotProperty, item?.id);
                                            }}
                                            optionsArr={props?.contacts?.filter(contact => 
                                                !mappings.some(mapping => mapping && mapping.hubspotProperty?.label === contact.label)
                                            ) || []}
                                            storeAsObject='label'
                                            placeholder='Select an Option'
                                            disabled={item?.default === 'email'}
                                        />
                                    </Box>
                                    <div className="override-toggle">
                                        <FormControlLabel
                                            control={<CustomSwitch sx={{
                                                m: 1 
                                            }} checked={mappings[index]?.hubspotPropertyOverride === CustomFieldHubspotPropertyOverride.YES} 
                                            onChange={(): void => 
                                            {
                                                setMappings((prevMappings) => {
                                                    const updatedMappings = [...prevMappings];
                                                    updatedMappings[index] = {
                                                        ...updatedMappings[index],
                                                        hubspotPropertyOverride: updatedMappings[index]?.hubspotPropertyOverride === CustomFieldHubspotPropertyOverride.YES ? CustomFieldHubspotPropertyOverride.NO : CustomFieldHubspotPropertyOverride.YES
                                                    };
                                                    return updatedMappings;
                                                });
                                            }} name="waitList" />}
                                            label={null}
                                        />
                                    </div>
                                {/* </div> */}
                            </Box>
                        ); 
                    })}
                </Box>
            </div>
        )
    }

    const renderSyncContent = (): React.JSX.Element => { 
        return (
            <div className="sync-details-container">
                <div className="sync-details-div">
                    <h3 className="label">{'Last Synced:'}</h3>
                    <p className="value">{syncDetails?.lastSynced !== 0 && moment.unix(Number(syncDetails?.lastSynced))?.format('DD MMM, HH:MM') || ''}</p>
                </div>

                <div className="sync-details-div">
                    <h3 className="label">{'Number of contacts in sync:'}</h3>
                    <p className="value">{syncDetails?.contacts || ''}</p>
                </div>
            </div>
        )
    }

    return (
        <div id="mappingTableBlock">

            {/* Tabs to render map fields and sync details */}
            <div className="mapping-tabs">
                <div className="w-100">
                    <h3 className={`mapping-tab ${props?.currentTab === 'mapping' ? 'active' : ''}`} onClick={():void => props?.setCurrentTab && props?.setCurrentTab('mapping')}>Mapping</h3>
                </div>
                <div className="w-100">
                    <h3 className={`mapping-tab ${props?.currentTab === 'sync' ? 'active' : ''}`} onClick={():void => {
                            if (requiredIds.every((id) => mappings.some((mapping) => mapping.customFieldId == id && mapping.hubspotProperty))) 
                            { 
                                props?.setCurrentTab && props?.setCurrentTab('sync')
                            }
                            else
                            {
                                toast.error('Please map all required fields before syncing');
                            }
                        }
                    }>Sync</h3>
                </div>
            </div>
            
            {
                (props?.loading) ? 
                <CustomSpinner height={'100vh'} loadingText={props?.syncStatus === IntegrationSyncStatus.IN_PROGRESS && 'HubSpot Data Sync In Progress'} /> :
                props?.currentTab === 'mapping' ? (
                    renderMappingContent()
                ) : renderSyncContent ()
            }
            
        </div>
    );
};

