import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { AutocompletewithTags, CloseIconComponent, FormControlComponent, FormLabelComponent, SelectComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { Box, Stack } from "@mui/material";
import { Form } from "react-bootstrap";
import { MuiDateTimeRangePicker } from "../../../common/FormComponents/DateTimePickers";
import { eventSpeakersCount, getEventSpeakers } from "../../../scripts/apis/eventSpeakers";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import moment from "moment";
import { createEventSession, updateEventSession, uploadSessionImage } from "../../../scripts/apis/eventSession";
import { Speaker } from "../../../pages/Speakers/interfaces";
import { EventSessionTypes } from "../../../pages/Events/enum";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { combineDateTime, isImageBuffer } from "../../../scripts/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './styles.scss';
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import DatePicker from "../../../common/FormComponents/DatePicker";
import TimePicker from "../../../common/FormComponents/TimePicker";
import toast from "react-hot-toast";
import DateTimePicker from "../../../common/FormComponents/DateTimePicker";

const AddSession = (props) =>
{
    const [spinner, setSpinner] = useState<boolean>(false);
    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const { eventId, stageId, setRefresh, sessionData, stageData } = props;

    const sessionTypeOptions = [
        {
            id: 1, name: 'Session'
        },
        {
            id: 2, name: 'Keynote'
        },
        {
            id: 3, name: 'Break'
        },
    ];

    const sessionTypeOptionLabels = {
        1: 'Session',
        2: 'Keynote',
        3: 'Break',
    };

    const [eventSpeakersList, setEventSpeakersList] = useState<Speaker[]>([]);
    const [sessionImageFile, setSessionImageFile] = useState<File | null>(null);

    const { getInputProps, getRootProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setSessionImageFile(file);
                    formik.setFieldValue('image', URL.createObjectURL(file));
                }
                else 
                {
                    setSessionImageFile(null);
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image')
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    // const validationSchema = Yup.object().shape({
    //     name: Yup.string()
    //         .required('Stage Name is required'),
    //     description: Yup.string().notRequired(),
    //     startDateTime: Yup.string().required('Start Date & Time is required'),
    //     endDateTime: Yup.string().required('End Date & Time is required'),
    //     type: Yup.number(),
    //     speakerIds: Yup.array()
    //         .of(Yup.number())
    //         // .required('At least one speaker is required'),
    // });

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Session Name is required'),
        description: Yup.string().notRequired(),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date & Time is required'),
        startTime: Yup.string().required('Start Time is required'),
        endTime: Yup.string().required('End Time is required'),
        type: Yup.number(),
        speakerIds: Yup.array()
            .of(Yup.number())
            // .required('At least one speaker is required'),
    });

    const formik = useFormik({ 
        // initialValues: {
        //     name: sessionData ? sessionData?.title : '',
        //     description: sessionData ? sessionData?.description : '',
        //     startDateTime: sessionData ? moment.unix(Number(sessionData?.startDateTime)).tz(eventReduxData?.timeZone).format('MM/DD/YYYY hh:mm A') : moment.unix(Number(stageData?.startDateTime))?.tz(eventReduxData?.timeZone).format('MM/DD/YYYY hh:mm A'),
        //     endDateTime: sessionData ? moment.unix(Number(sessionData?.endDateTime)).tz(eventReduxData?.timeZone).format('MM/DD/YYYY hh:mm A') : moment.unix(Number(stageData?.startDateTime)).tz(eventReduxData?.timeZone).format('MM/DD/YYYY hh:mm A'),
        //     type: sessionData ? sessionData?.type : EventSessionTypes.SESSION,
        //     speakerIds: sessionData?.speakerIds?.length > 0 ? sessionData?.speakerIds : [],
        //     speakerNames: [],
        //     image: sessionData?.image ? sessionData?.image : undefined,
        // },
        initialValues: {
            name: sessionData ? sessionData?.title : '',
            description: sessionData ? sessionData?.description : '',
            startDate: sessionData ? moment.unix(Number(sessionData?.startDateTime)).format('MM/DD/YYYY') : moment.unix(Number(stageData?.startDateTime))?.format('MM/DD/YYYY'),
            startTime: sessionData ? moment.unix(Number(sessionData?.startDateTime)).format('HH:mm') : moment.unix(Number(stageData?.startDateTime)).format('HH:mm'),
            endDate: sessionData ? moment.unix(Number(sessionData?.endDateTime)).format('MM/DD/YYYY') : moment.unix(Number(stageData?.startDateTime)).format('MM/DD/YYYY'),
            endTime: sessionData ? moment.unix(Number(sessionData?.endDateTime)).format('HH:mm') : moment.unix(Number(stageData?.startDateTime)).format('HH:mm'),
            type: sessionData ? sessionData?.type : EventSessionTypes.SESSION,
            speakerIds: sessionData?.speakerIds?.length > 0 ? sessionData?.speakerIds : [],
            speakerNames: [],
            image: sessionData?.image ? sessionData?.image : undefined,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setSpinner(true);

            const startDateTime = combineDateTime(moment(`${values.startDate}`), formik.values.startTime);
            const endDateTime = combineDateTime(moment(`${values.endDate}`), formik.values.endTime);

            if (Number(endDateTime) < Number(startDateTime))
            {
                setSpinner(false);
                toast.error('End Date Time should be greater than Start Date Time');
                return;
            }

            if ((Number(startDateTime) < Number(eventReduxData.eventStartDateTime)) || (Number(endDateTime) > Number(eventReduxData.eventEndDateTime))) 
            {
                setSpinner(false);
                toast.error('Session start and end time should be within event start and end time');
                return;
            }
        
            const data = {
                title: values.name,
                description: values.description,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                type: values.type,
                speakerIds: values.speakerIds,
                stageId: stageId,
            };
        
            try 
            {
                let sessionCreated, sessionUpdated, sessionImageUpdated;
                let successMessage;
        
                if (props?.sessionData) {
                    sessionUpdated = await updateEventSession(eventId, sessionData?.id, data);

                    if(sessionImageFile)
                    {
                        const imageFormData = new FormData();
                        imageFormData.append('file', sessionImageFile);
        
                        sessionImageUpdated = ((sessionData?.additionalInfo?.image !== formik.values?.image) && sessionImageFile)
                        ? await uploadSessionImage(eventId, sessionData?.id, imageFormData, csrfTokenData)
                        : Promise.resolve();
                    }

                    successMessage = 'Session Updated';
                } else {
                    sessionCreated = await createEventSession(eventId, data, csrfTokenData);

                    if(sessionImageFile)
                    {
                        const imageFormData = new FormData();
                        imageFormData.append('file', sessionImageFile);
        
                        sessionImageUpdated = ((sessionData?.additionalInfo?.image !== formik.values?.image) && sessionImageFile)
                        ? await uploadSessionImage(eventId, sessionCreated?.id, imageFormData, csrfTokenData)
                        : Promise.resolve();
                    }

                    successMessage = 'Session Created';
                }
        
                if (sessionCreated || sessionUpdated) {
                    setSpinner(false);
                    setRefresh(true);
                    toast.success(successMessage);
                    handleDrawerClose();
                }
            } 
            catch (error) 
            {
                setSpinner(false);
                toast.error('Error in ' + (props?.sessionData ? 'updating' : 'creating') + ' session');
            }
            finally
            {
                setSpinner(false);
            }
        },
    });

    const handleDrawerClose = (): void => {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const fetchEventSpeakerData = async (): Promise<void> =>
    {
        try {
            const count = await eventSpeakersCount(eventId);
            if(count)
            {
                const eventSpeakers = await getEventSpeakers(count, 0, eventId);
                if(eventSpeakers)
                {
                    setEventSpeakersList(eventSpeakers);
                }
            }
        } catch (error) {
            
        }
    };

    useEffect(() =>
    {
        fetchEventSpeakerData();
    }, []);

    useEffect(() => {
        if(sessionData)
        {
            if(sessionData?.speakerIds?.length > 0)
            {
                const defaultSpeakerIds = sessionData.speakerIds.length > 0 ? sessionData.speakerIds.map((item) => 
                {
                    const ids = _.find(eventSpeakersList, ['id', String(item)]);
                    if (ids) 
                    {
                        return ids?.name;
                    }
                }) : [];
                if(defaultSpeakerIds?.length > 0)
                {
                    formik.setFieldValue('speakerNames', defaultSpeakerIds);
                }
            }
        }
    }, [eventSpeakersList]);

    return (
        <div id="addSession">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate onSubmit={(values): void => {
                return formik.handleSubmit(values);
            }} autoComplete="off">

                {/* Session Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Session Name" required />
                    <FormControlComponent 
                        type="text"
                        name="name"
                        placeholder="Session Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.name && !!formik.errors.name}
                    />
                    {formik.touched.name && formik.errors.name && <div className="error-msg">{formik.errors.name}</div>}
                </Box>

                {/* Description */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Description" />
                    <FormControlComponent 
                        type="text"
                        as="textarea"
                        name="description"
                        placeholder="Description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.description && !!formik.errors.description}
                        rows={3}
                    />
                </Box>

                {/* Start Date & Time */}
                <Box className="sidebar-container-spacing">
                    <div className="session-timing-cont">
                        <FormLabelComponent label="Start & End Date Time" required noBottomMargin />
                        <p className="timezone">{`(${eventReduxData?.timeZone})`}</p>
                    </div>
                    <div className="session-date-time-picker">
                        <DateTimePicker 
                            dateValue={moment(formik.values.startDate).unix()}
                            timeValue={formik.values.startTime}
                            onDateChange={(date) => formik.setFieldValue('startDate', date)}
                            onTimeChange={(time) => formik.setFieldValue('startTime', time)}
                            minDate={Number(stageData?.startDateTime)}
                            maxDate={Number(stageData?.endDateTime)}
                            dateFormat="ddd, MMM D"
                        />
                        <p>-</p>
                        <DateTimePicker 
                            dateValue={moment(formik.values.endDate).unix()}
                            timeValue={formik.values.endTime}
                            onDateChange={(date) => formik.setFieldValue('endDate', date)}
                            onTimeChange={(time) => formik.setFieldValue('endTime', time)}
                            minDate={moment(formik.values.startDate).unix()}
                            maxDate={Number(stageData?.endDateTime)}
                            dateFormat="ddd, MMM D"
                        />
                    </div>
                </Box>

                {/* Session Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Session Type" />
                    <SelectComponent value={String(formik.values.type)} options={sessionTypeOptions} defaultPlaceholder="Select Type" optionLabels={sessionTypeOptionLabels} onChange={(event) => {
                        formik.setFieldValue('type', event.target.value);
                    }} />
                </Box>

                {/* Session image */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Session Image" />
                        <Box>
                        {
                            formik.values?.image ? 
                                <Box className="session-img-cont">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        
                                        <img src={formik.values?.image} alt="Preview" />
                                        
                                        {formik.values?.image && (
                                            <Box className="icon-box">
                                                <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(event): void => 
                                                {
                                                    event.stopPropagation();
                                                    setSessionImageFile(null);
                                                    formik.setFieldValue('image', undefined);    
                                                }} />
                                            </Box>
                                        )}
                                    </div>
                                </Box> :
                                <Box className="empty-sessionImg-cont" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                                </Box>
                        }
                    </Box>
                </Box>

                {/* Speakers */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Speakers" />
                    <AutocompletewithTags defaultValue={formik.values.speakerNames} value={formik.values.speakerNames} placeholder="Select Speaker" options={eventSpeakersList} keyToShow="name" onChange={(event, newValue) => { 
                        formik.setFieldValue('speakerIds', newValue.map(name => {
                            const speaker = eventSpeakersList.find(speaker => speaker.name === name);
                            return speaker ? speaker.id : null;
                        }));
                        formik.setFieldValue('speakerNames', newValue)
                        }} 
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedSpeakerIds = formik.values.speakerIds.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            const updatedSpeakers = formik.values.speakerNames.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            formik.setFieldValue('speakerIds', updatedSpeakerIds);
                            formik.setFieldValue('speakerNames', updatedSpeakers);
                        }}
                    />
                </Box>

                {/* Submit button */}
                <Box width={'500px'} className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton btnType='primary' disabled={spinner} loading={spinner} name={'Save'} type='submit' />
                    </Stack>
                </Box>
            </Form>
        </div>
    );
};

export default AddSession;