import { createSlice } from '@reduxjs/toolkit';

export const icpRowSlice = createSlice({
    name: 'buyerPersona',
    initialState: {
        value: {
            name: '', description: '', role: null, subRole: null, seniority: null,
        }
    },
    reducers: {
        addBuyerPersona: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetBuyerPersona: (state) => {
            state.value = {
                name: '', description: '', role: null, subRole: null, seniority: null,
            }
        }
    },
});

export const { addBuyerPersona, resetBuyerPersona } = icpRowSlice.actions;
export default icpRowSlice.reducer;