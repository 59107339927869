import React, { useEffect, useState } from "react";
import {
  AutocompleteComponent,
  CloseIconComponent,
  FormLabelComponent,
  RadioGroupComponent,
} from "../../../common/FormComponents/ReusableFormComponents";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FormControlLabel, Stack } from "@mui/material";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import connectQrCode from "../../../assets/qrcode.png";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import { getEventById } from "../../../scripts/apis/events";
import { getRegistrationFormById } from "../../../scripts/apis/registrationForm";
import { CustomField } from "../../../pages/Registration/interfaces/custom-field_interface";
import { createBadgeSettings } from "../../../scripts/apis/eventAccessControl";
import { Form } from "react-bootstrap";
import _ from "lodash";
import * as Yup from "yup";
import { Event, EventBadgeSetting } from "../../../pages/Events/interfaces";
import { QrCodeDisplay } from "../../../pages/Events/enum";
import './styles.scss';

interface IAddEventBadgeSettingsProps {
  eventId: string | number;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  eventData: Event;
}

interface IBadgeSettings {
  qrcode: number;
  qrCodeSize: number;
  fontSize: string;
  components: string[];
}

const AddEventBadgeSettings: React.FC<IAddEventBadgeSettingsProps> = (props): React.JSX.Element => {
  const { setRefresh, eventId, eventData } = props;

  const [spinner, setSpinner] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [imageSize, setImageSize] = useState(120);
  const [lineSize, setLineSize] = useState(22);
  const badgeSetting: EventBadgeSetting | undefined = eventData?.badgeSetting;

  const qrSizeOptions = [
    { value: 4, name: "XS" },
    { value: 6, name: "SM" },
    { value: 8, name: "MD" },
  ];

  const fontSizeOptions = [
    { value: 1, name: "XS" },
    { value: 2, name: "SM" },
    { value: 3, name: "MD" },
    { value: 4, name: "LG" },
    { value: 5, name: "XL" },
  ];

  const fontSizeMapping: Record<number, string> = {
    1: "XS",
    2: "SM",
    3: "MD",
    4: "LG",
    5: "XL",
  };
  const reverseFontSizeMapping = (value: string): number | undefined => {
    const entry = Object.entries(fontSizeMapping).find(([key, val]) => val === value);
    return entry ? Number(entry[0]) : 5;
  };

  const getFormNameById = (id: string): string | undefined => {
    const form = lineData.find((form) => form.id === id);
    return form ? form.name : null;
  };

  const fontSizeStyle: Record<string, number> = {
    "1": 14,
    "2": 16,
    "3": 18,
    "4": 20,
    "5": 22,
  };

  const qrSizeStyle: Record<string, number> = {
    4: 100,
    6: 120,
    8: 140,
  };

  const validationSchema = Yup.object().shape({
    qrType: Yup.number().required("QR Type is required"),
    qrSize: Yup.number().required("QR Size is required"),
    fontSize: Yup.number().required("Font Size is required"),
    line1: Yup.string().required("Line1 is required"),
    line2: Yup.string().nullable(),
    line3: Yup.string().nullable(),
    line1Id: Yup.string().required("Line 1 ID is required"),
    line2Id: Yup.string().nullable(),
    line3Id: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      qrType: badgeSetting?.qrcode ? Number(badgeSetting?.qrcode) : 1,
      qrSize: badgeSetting?.qrCodeSize ? Number(badgeSetting?.qrCodeSize) : 6,
      fontSize: badgeSetting?.fontSize ? reverseFontSizeMapping(badgeSetting?.fontSize) : 5,
      line1: badgeSetting?.components[0]
        ? getFormNameById(badgeSetting?.components[0].toString())
        : null,
      line2: badgeSetting?.components[1]
        ? getFormNameById(badgeSetting?.components[1].toString())
        : null,
      line3: badgeSetting?.components[2] 
        ? getFormNameById(badgeSetting?.components[2].toString())
        : null,
      line1Id: badgeSetting?.components[0] ? badgeSetting?.components[0] : "",
      line2Id: badgeSetting?.components[1] ? badgeSetting?.components[1] : "",
      line3Id: badgeSetting?.components[2] ? badgeSetting?.components[2] : "",
    },
    onSubmit: async (values): Promise<void> => {
      await addBadgeSettings();
    },
  });

  const addBadgeSettings = async (): Promise<void> => {
    setSpinner(true);
    try {
      const components = [formik.values.line1Id];
      if (formik.values.line2Id) {
        components.push(formik.values.line2Id);
      }
      if (formik.values.line3Id) {
        components.push(formik.values.line3Id);
      }
      const fontSizeString = fontSizeMapping[formik.values.fontSize] || "XS";

      const badgeSettings: IBadgeSettings = {
        qrcode: formik.values.qrType,
        qrCodeSize: formik.values.qrSize,
        fontSize: fontSizeString,
        components: components,
      };
      await createBadgeSettings(eventId, badgeSettings);
      setSpinner(false);
      setRefresh(true);
      handleDrawerClose();
    } catch (error) {
      console.error("Failed to submit event details:", error);
    }
  };

  const handleDrawerClose = (): void => {
    eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
      open: false,
    });
  };

  const handleQrSize = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void => {
    formik.setFieldValue("qrSize", event.target.value);
    const qrSize = qrSizeStyle[event.target.value] || 120;
    setImageSize(qrSize);
  };

  const handleFontSize = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void => {
    formik.setFieldValue("fontSize", event.target.value);
    const fontSize = fontSizeStyle[event.target.value] || 22;
    setLineSize(fontSize);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("qrType", event.target.checked ? 1 : 2);
    if(event.target.checked)
    {
      formik.setFieldValue("line3", "");
      formik.setFieldValue("line3Id", "");
    }
  };

  const handleLine1Change = (event: React.SyntheticEvent, newValue: any | null): void => {
    const selectedLine = _.filter(lineData, function (line): boolean {
      return newValue === line.name;
    })[0];
    formik.setFieldValue("line1", selectedLine ? selectedLine.name : "");

    formik.setFieldValue("line1Id", selectedLine ? selectedLine.id : "");
  };

  const handleLine2Change = (event: React.SyntheticEvent, newValue: any | null): void => {
    const selectedLine = _.filter(lineData, function (line): boolean {
      return newValue === line.name;
    })[0];
    formik.setFieldValue("line2", selectedLine ? selectedLine.name : "");
    formik.setFieldValue("line2Id", selectedLine ? selectedLine.id : "");
  };

  const handleLine3Change = (event: React.SyntheticEvent, newValue: any | null): void => {
    const selectedLine = _.filter(lineData, function (line): boolean {
      return newValue === line.name;
    })[0];
    formik.setFieldValue("line3", selectedLine ? selectedLine?.name : "");
    formik.setFieldValue("line3Id", selectedLine ? selectedLine?.id : "");
  };

  const stripHtmlTags = (htmlString: string): string => {
    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = htmlString;
    return temporaryElement.textContent || temporaryElement.innerText || "";
  };

  const isHtmlComponent = (inputString: string): boolean => {
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlTagRegex.test(inputString);
  };

  const fetchData = async (): Promise<void> => {
    try {
      const event = await getEventById(eventId.toString());
      if (event && event.registrationFormId) {
        const formId = event.registrationFormId;
        const registrationForm = await getRegistrationFormById(Number(formId));
        const lineData = registrationForm?.formFields;
        if (lineData) {
          const transformedData = lineData.map((field: CustomField) => ({
            id: field.id,
            name: isHtmlComponent(field?.fieldName)
              ? stripHtmlTags(field?.fieldName)
              : field?.fieldName,
          }));
          setLineData(transformedData);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect((): void => {
    fetchData();

    if(eventData?.badgeSetting)
    {
      setImageSize(qrSizeStyle[Number(eventData?.badgeSetting?.qrCodeSize)]);
      setLineSize(fontSizeStyle[Number(reverseFontSizeMapping(eventData?.badgeSetting?.fontSize))]);
    }
  }, []);

  return (
    <div id="CheckInBadgeSettings">
      <CloseIconComponent onClick={handleDrawerClose} />

      <Form
        noValidate
        autoComplete="off"
        onSubmit={(values: React.FormEvent<HTMLFormElement>): void => {
          return formik.handleSubmit(values);
        }}
      >
        <div className="sidebar-container-image">
          <div className="bar-container">
            {formik.values.qrType !== 2 && (
              <div
                className="image-container"
                style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
              >
                <img src={connectQrCode} alt="Description" className="center-image" />
              </div>
            )}

            <div className="text-content">
              <p className="text-line" style={{ fontSize: `${lineSize}px` }}>
                {formik?.values?.line1 ? formik.values.line1.toUpperCase() : "FULL NAME"}
              </p>
              <p className="text-line" style={{ fontSize: `${lineSize}px` }}>
                {formik?.values?.line2 ? formik.values.line2.toUpperCase() : "COMPANY NAME"}
              </p>
              {formik.values.qrType === QrCodeDisplay.NO && formik.values.line3 && <p>
                <p className="text-line" style={{ fontSize: `${lineSize}px` }}>
                  {formik?.values?.line3 ? formik.values.line3.toUpperCase() : "DESIGNATION"}
                </p>
              </p>}
            </div>
          </div>
        </div>

        {/* Qr Code */}
        <div className="sidebar-container-spacing">
          <div className="approval-container">
            <div className="approval-icon-label-container">
              <FontAwesomeIcon icon={["fal", "qrcode"]} className="approval-icon" />
              <FormLabelComponent label={"QR Code"} noBottomMargin />
            </div>
            <FormControlLabel
              checked={formik.values.qrType === 1}
              control={<CustomSwitch onChange={handleSwitchChange} />}
              label={""}
            />
          </div>
        </div>

        {/* QR Size */}
        {formik.values.qrType === QrCodeDisplay.YES && <div className="sidebar-container-spacing ">
          <FormLabelComponent label={"QR Size"} required={true} />
          <RadioGroupComponent
            row
            options={qrSizeOptions}
            value={String(formik.values.qrSize)}
            onChange={handleQrSize}
          />
        </div>}

        {/* Font Size */}
        <div className="sidebar-container-spacing ">
          <FormLabelComponent label={"Text Size"} required={true} />
          <RadioGroupComponent
            row
            options={fontSizeOptions}
            value={String(formik.values.fontSize)}
            onChange={handleFontSize}
          />
        </div>

        {/* Line 1 */}
        <div className="sidebar-container-spacing">
          <FormLabelComponent label={"Line 1"} required={true} />
          <div className="event-category-container">
            <div className="w-100">
              <AutocompleteComponent
                value={formik.values.line1}
                onChange={handleLine1Change}
                keyToShow="name"
                optionsArr={lineData}
                placeholder="Select Line 1"
              />
            </div>
          </div>
          <div className="title-length-error-cont">
            {formik.errors.line1 && formik.touched.line1 ? (
              <div className="error-msg">{formik.errors.line1}</div>
            ) : (
              <div />
            )}
          </div>
        </div>

        {/* Line 2 */}
        <div className="sidebar-container-spacing">
          <FormLabelComponent label={"Line 2"} />
          <div className="event-category-container">
            <div className="w-100">
              <AutocompleteComponent
                value={formik.values.line2}
                onChange={handleLine2Change}
                keyToShow="name"
                optionsArr={lineData}
                placeholder="Select Line 2"
              />
            </div>
          </div>
        </div>

        {/* Line 3 */}
        {formik.values.qrType === QrCodeDisplay.NO && <div className="sidebar-container-spacing">
          <FormLabelComponent label={"Line 3"} />
          <div className="event-category-container">
            <div className="w-100">
              <AutocompleteComponent
                value={formik.values.line3}
                onChange={handleLine3Change}
                keyToShow="name"
                optionsArr={lineData}
                placeholder="Select Line 3"
              />
            </div>
          </div>
        </div>}

        <Box className="submit-btn-container">
          <Stack direction={"row"} spacing={2} display={"flex"} justifyContent={"flex-end"}>
            <CustomButton
              onClick={handleDrawerClose}
              btnType={"secondary"}
              name={CONTENT.SIDE_DRAWER.CLOSE_BTN}
            />
            <CustomButton type="submit" loading={spinner} name={"Save"} btnType={"primary"} />
          </Stack>
        </Box>
      </Form>
    </div>
  );
};

export default AddEventBadgeSettings;
