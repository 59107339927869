import axios from 'axios';
import { EventDetails, EventRegistrantInvoiceDetails } from '../../pages/Events/interfaces';
import { EventCoupon } from '../../pages/Events/interfaces/event-coupon_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getRegistrationFormToken = async (orgLink: string, eventLink: string, utm?: string):Promise<string> => 
{
    if (utm) 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/' + orgLink + '/' + eventLink + '/token?utm_source=' + utm;
        return triggerApi(API_URL, 'GET', '', undefined);
    }
    else 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/' + orgLink + '/' + eventLink + '/token';
        return triggerApi(API_URL, 'GET', '', undefined);
    }
};
export const getRegistrationFormDataWithToken = async (orgLink: string, eventLink: string, utm?: string, couponCode?: string):Promise<EventDetails> => 
{
    const query = convertJSONToGETParams({
        utm_source: utm,
        couponCode
    })
    
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/' + orgLink + '/' + eventLink + '/event-page?' + query;
    return triggerApi(API_URL, 'GET', '', undefined);
};
export const getRegistrationFormData = async (token: string):Promise<any> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/' + token + '/event-page';
    return triggerApi(API_URL, 'GET', '', undefined);
};

export const postRegistrationFormData = async (
    token: string,
    registrationFormData: any,
):Promise<any> => 
{
    const API_URL =
        APP_CONSTANTS.API_DOMAIN + '/registration-public/' + token;
    return triggerApi(API_URL, 'POST', registrationFormData, undefined);
};

export const checkInvoiceJWTValidity = async (token: string):Promise<EventRegistrantInvoiceDetails> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/invoice/token-info/' + token;
    return triggerApi(API_URL, 'GET', '');
};

export const createInvoiceForm = async (token: string, invoiceData: any, csrfTokenData: string):Promise<string> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/invoice/' + token;
    return triggerApi(API_URL, 'POST', invoiceData, csrfTokenData);
};

export const validateCoupon = async (data: { ticketId: string | number; couponCode: string; }, token: string): Promise<EventCoupon> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/validate-coupon/' + token;
    return triggerApi(API_URL, 'POST', data);
};

export const getFaceIdResponse = async (token: string, formData: FormData): Promise<any> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/selfie/' + token;
    return triggerApi(API_URL, 'POST', formData, '', false, undefined, true);
};

export const getRegistrantPhotos = async (token: string, faceId: string): Promise<any> =>
{
    const query = convertJSONToGETParams({ 
        faceId 
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/event-photos/' + token + '?' + query;
    return triggerApi(API_URL, 'GET', '', undefined);
};

export const downloadAllRegistrantPhotos = async (token: string, faceId: string): Promise<any> =>
{
    const query = convertJSONToGETParams({ 
        faceId,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/event-photos/'+ token + '/zip' + '?' + query;
    return axios.get(API_URL, {
        responseType: 'blob',
        withCredentials: true,
    });
};

export const getEventPhotos = async (token: string, pageSize: number, offset: number): Promise<any> =>
{
    const query = convertJSONToGETParams({ 
        pageSize,
        offset
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/event-photos/' + token + '?' + query;
    return triggerApi(API_URL, 'GET', '', undefined);
};

// export const getEventDataFromToken = async (token: string) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + '/registration-public/event-page/' + token;
//     return triggerApi(API_URL, 'GET', '', undefined);
// };