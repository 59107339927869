import { createSlice } from '@reduxjs/toolkit';

export const audienceRowSlice = createSlice ({
    name: 'audience',
    initialState: {
        value: {
            id: 0, firstName: '', lastName: '', email: '', contactNumber: 0, profilePicture: '', favourite: false, profileType: ''
        }
    },
    reducers: {
        audience: (state, action) => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        }
    },
});

export const { audience } = audienceRowSlice.actions;
export default audienceRowSlice.reducer;