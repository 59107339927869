import { useEffect, useState } from "react"

const useScreenDimension = () => { 

    const [screenType, setScreenType] = useState<string>('');

    useEffect(() => 
    {
        const handleResize = ():void => 
        {
            const width = window.innerWidth;

            if (width < 600) 
            {
                setScreenType('mobile');
            }
            else if (width > 600 && width <= 768) 
            {
                setScreenType('tablet');
            }
            else 
            {
                setScreenType('desktop');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return ():void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenType;
};

export default useScreenDimension;