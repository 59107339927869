import { Sponsor, SponsorGraph } from '../../pages/Sponsors/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const sponsorsCount = async (queryParams: [string, string][] = []):Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor/count' + '?' + query;;
    return triggerApi(API_URL, 'GET', '');
};

export const getSponsorGraphData = async ():Promise<SponsorGraph> => 
{
    const query = convertJSONToGETParams({
        icp: true,
        funding: true,
        arr: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllSponsors = async (pageSize: number, offset: number, queryParams: [string, any][] = []):Promise<Sponsor[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor?' + '&pageSize=' + pageSize + '&offset=' + offset + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getSponsorById = async (SPONSOR_ID):Promise<Sponsor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor/' + SPONSOR_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const createSponsor = async (SPONSOR_DATA, csrfToken: string):Promise<Sponsor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor';
    return triggerApi(API_URL, 'POST', SPONSOR_DATA, csrfToken);
};

export const uploadSponsorLogo = async (SPONSOR_ID: number, IMAGE_DATA, csrfToken: string):Promise<BinaryData> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor/' + SPONSOR_ID + '/upload-image';
    return triggerApi(API_URL, 'POST', IMAGE_DATA, csrfToken, false);
};

export const updateSponsor = async (SPONSOR_ID, SPONSOR_DATA):Promise<Sponsor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor/' + SPONSOR_ID;
    return triggerApi(API_URL, 'PUT', SPONSOR_DATA);
};

export const deleteSponsor = async (SPONSOR_ID):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/sponsor/' + SPONSOR_ID;
    return triggerApi(API_URL, 'DELETE', '');
};