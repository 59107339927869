/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { getAudienceGraphData } from '../../../scripts/apis/audience';
import { AnalyticsGraphContainer, AnalyticsGraphsBlock } from '../BudgetAnalytics';
import TrendsComponent from '../../../common/TrendsComponent';
import _ from 'lodash';
import './styles.scss';
import { Box, Typography } from '@mui/material';
import Charts from '../../../common/ECharts/Charts';
import CustomSpinner from '../../../common/CustomSpinner';
import PopUpView from '../../../common/PopupView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Data, GraphReady, IAudienceAnalyticsPieChart } from './IAudienceAnalyticsMain';
import { constructObjectForPieChart } from '../../../common/ECharts/helpers/charts.helper';
import APP_CONSTANTS from '../../../scripts/constants';
import TopPageAnalyticsContainer from '../../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';
const AudienceAnalyticsMain = (): React.JSX.Element => 
{

    const [audienceAnalyticsData, setAudienceAnalyticsData] = useState<Data>({
        trend: null,
        icp: [],
        buyerPersona: [],
        revenue: [],
        // audienceDistribution: [],
        employeeCount: [],
        // role: [],
        seniority: [],
        lifeCycleStage: [],
        dealStage: [],
        prospectCustomerSplitRevenue: [],
        prospectCustomerSplitEmpCount: [],
        revenueRecognizedByIcp: [],
        pipelineByIcp: [],
        icpToBpMapping: null
    });

    const [enlargeView, setEnlargeView] = useState({
        icp: false,
        buyerPersona: false,
        revenue: false,
        // audienceDistribution: false,
        employeeCount: false,
        // role: false,
        seniority: false,
        lifeCycleStage: false,
        dealStage: false,
        prospectCustomerSplitRevenue: false,
        prospectCustomerSplitEmpCount: false,
        revenueRecognizedByIcp: false,
        pipelineByIcp: false,
        icpToBpMapping: false
    });

    const [isGraphReady, setGraphReady] = useState({
        icp: false,
        buyerPersona: false,
        revenue: false,
        // audienceDistribution: false,
        employeeCount: false,
        // role: false,
        seniority: false,
        lifeCycleStage: false,
        dealStage: false,
        prospectCustomerSplitRevenue: false,
        prospectCustomerSplitEmpCount: false,
        revenueRecognizedByIcp: false,
        pipelineByIcp: false,
        icpToBpMapping: false
    });

    const arrGraphXaxisData = ['$0-$1M', '$1M-$10M', '$10M-$50M', '$50M-$100M', '$100M-$250M', '$250M-$500M', '$500M-$1B', '$1B-$10B', '$10B+'];
    const employeeCountXaxisData = ['1-10', '11-50', '51-250', '251-1K', '1K-5K', '5K-10K', '10K-50K', '50K-100K', '100K+'];

    const audienceLifecycleGraphOption = constructObjectForPieChart(audienceAnalyticsData.lifeCycleStage, ['50%', '72%']);

    const audienceDealStageGraphOption = constructObjectForPieChart(audienceAnalyticsData?.dealStage, ['50%', '72%']);

    const revenueXAxisKey = audienceAnalyticsData?.revenue && audienceAnalyticsData?.revenue?.length > 0 ? audienceAnalyticsData?.revenue[0] : null;

    const companiesRevenueGraphOption = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
        },
        dataset: {
            source: audienceAnalyticsData?.revenue
        },
        legend: {
            show: false,
            orient: 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 4],
        },
        xAxis: {
            type: 'category',
            data: revenueXAxisKey,
            axisTick: {
                show: false
            },
            name: 'Annual recurring revenue - ARR',
            nameLocation: 'middle',
            nameGap: 55,
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
                formatter: function (value) : string
                {
                    return value?.length > 10 ? value.substr(0, 10) + '...' : value;
                },
            },
        },
        yAxis: {
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'value',
                },
            },
            name: 'Number of signups',
            nameLocation: 'middle',
            nameGap: 35,
            axisLabel: {
                formatter: function (value) : string
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            type: 'line',
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            barWidth: 30,
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    color: '#2a2e34',
                    fontWeight: 'bold',
                },
            },
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            data: audienceAnalyticsData?.revenue && audienceAnalyticsData?.revenue?.length > 0 && audienceAnalyticsData?.revenue[1],
        }]
    };

    const employeeCountXAxisKey = audienceAnalyticsData?.employeeCount && audienceAnalyticsData?.employeeCount?.length > 0 ? audienceAnalyticsData?.employeeCount[0] : null;

    const companiesemployeeCountGraphOption = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis'
        },
        dataset: {
            source: audienceAnalyticsData?.employeeCount
        },
        legend: {
            show: false,
            orient: 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 4],
        },
        xAxis: {
            type: 'category',
            data: employeeCountXAxisKey,
            axisTick: {
                show: false
            },
            name: 'Employee count',
            nameLocation: 'middle',
            nameGap: 55,
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
                formatter: function (value) : string
                {
                    return value?.length > 10 ? value.substr(0, 10) + '...' : value;
                },
            },
        },
        yAxis: {
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'value',
                },
            },
            name: 'Number of signups',
            nameLocation: 'middle',
            nameGap: 35,
            axisLabel: {
                formatter: function (value) : string
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            type: 'line',
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            barWidth: 30,
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    color: '#2a2e34',
                    fontWeight: 'bold',
                },
            },
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            data: audienceAnalyticsData?.employeeCount && audienceAnalyticsData?.employeeCount?.length > 0 && audienceAnalyticsData?.employeeCount[1],
        }]
    };

    const icpToBpMappingChartOption = {
        color: APP_CONSTANTS.GRAPH_COLORS.SANKEY_CHART.DEFAULT,
        tooltip: {
            trigger: 'item'
        },
        series: {
            type: 'sankey',
            layout: 'none',
            draggable: false,
            emphasis: {
                focus: 'adjacency',
            },
            focusNodeAdjacency: 'allEdges',
            data: audienceAnalyticsData?.icpToBpMapping?.names,
            links: audienceAnalyticsData?.icpToBpMapping?.sourceTargetValue,
        }
    };

    const revenueRecognizedByIcpGraphOption = constructObjectForPieChart(audienceAnalyticsData?.revenueRecognizedByIcp, ['50%', '72%']);

    const icpGraphOption = constructObjectForPieChart(audienceAnalyticsData?.icp, ['50%','72%']);

    const pipelineByIcpGraphOption = constructObjectForPieChart(audienceAnalyticsData?.pipelineByIcp, ['50%', '72%']);

    const buyerPersonaGraphOption = constructObjectForPieChart(audienceAnalyticsData?.buyerPersona, ['50%','72%']);

    const seniorityGraphOption = constructObjectForPieChart(audienceAnalyticsData?.seniority, ['50%','72%']);

    const prospectCustomerSplitRevenuetotalData = [];
    for (let i = 0; i < audienceAnalyticsData?.prospectCustomerSplitRevenue[0]?.length; ++i) 
    {
        let sum = 0;
        for (let j = 0; j < audienceAnalyticsData?.prospectCustomerSplitRevenue?.length; ++j) 
        {
            sum += audienceAnalyticsData?.prospectCustomerSplitRevenue[j][i] as number;
        }
        prospectCustomerSplitRevenuetotalData.push(sum);
    }

    const prospectCustomerSplitRevenueGraphOption = {
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `${Math.round(value * 1000) / 10}%`; 
            },
        },
        grid: {
            right: 5
        },
        legend: {
            show: true,
            icon: 'circle',
        },
        xAxis: {
            type: 'category',
            data: arrGraphXaxisData,
            axisTick: {
                show: false
            },
            name: 'Annual recurring revenue - ARR',
            nameLocation: 'middle',
            nameGap: 55,
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
                formatter: function (value) : string
                {
                    return value?.length > 10 ? value.substr(0, 10) + '...' : value;
                },
            },
        },
        yAxis: {
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'value',
                },
            },
            name: 'Number of signups',
            nameLocation: 'middle',
            nameGap: 35,
            axisLabel: {
                formatter: function (value) : string
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: ['Customer', 'Prospect'].map((name, sid): object => 
        {
            return {
                name,
                dataset: {
                    source: audienceAnalyticsData?.prospectCustomerSplitRevenue
                },
                type: 'line',
                smooth: true,
                showSymbol: false,
                stack: 'total',
                containLabel: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series'
                },
                itemStyle: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[sid],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[sid] 
                    }
                },
                label: {
                    show: false,
                    formatter: (params): string => 
                    {
                        return Math.round(params.value * 1000) / 10 + '%'; 
                    }
                },
                data: audienceAnalyticsData?.prospectCustomerSplitRevenue[0]?.length > 0 && audienceAnalyticsData?.prospectCustomerSplitRevenue[1]?.length > 0 && audienceAnalyticsData?.prospectCustomerSplitRevenue[sid].map((d, did): number =>
                {
                    return prospectCustomerSplitRevenuetotalData[did] <= 0 ? 0 : d / prospectCustomerSplitRevenuetotalData[did]; 
                }
                )
            };
        })
    };

    const prospectCustomerSplitEmployeeCounttotalData = [];
    for (let i = 0; i < audienceAnalyticsData?.prospectCustomerSplitEmpCount[0]?.length; ++i) 
    {
        let sum = 0;
        for (let j = 0; j < audienceAnalyticsData?.prospectCustomerSplitEmpCount?.length; ++j) 
        {
            sum += audienceAnalyticsData?.prospectCustomerSplitEmpCount[j][i] as number;
        }
        prospectCustomerSplitEmployeeCounttotalData.push(sum);
    }

    const prospectCustomerSplitEmpCountGraphOption = {
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `${Math.round(value * 1000) / 10}%`; 
            },
        },
        legend: {
            show: true,
            icon: 'circle',
        },
        xAxis: {
            type: 'category',
            data: employeeCountXaxisData,
            axisTick: {
                show: false
            },
            name: 'Employee count',
            nameLocation: 'middle',
            nameGap: 55,
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
                formatter: function (value) : string
                {
                    return value?.length > 10 ? value.substr(0, 10) + '...' : value;
                },
            },
        },
        yAxis: {
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'value',
                },
            },
            name: 'Number of signups',
            nameLocation: 'middle',
            nameGap: 35,
            axisLabel: {
                formatter: function (value) : string
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: ['Customer', 'Prospect'].map((name, sid): object => 
        {
            return {
                name,
                dataset: {
                    source: audienceAnalyticsData?.prospectCustomerSplitEmpCount
                },
                type: 'line',
                smooth: true,
                showSymbol: false,
                stack: 'total',
                containLabel: false,
                emphasis: {
                    focus: 'series'
                },
                itemStyle: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[sid],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[sid]
                    }
                },
                barWidth: 30,
                label: {
                    show: false,
                    formatter: (params): string => 
                    {
                        return Math.round(params.value * 1000) / 10 + '%'; 
                    }
                },
                data: audienceAnalyticsData?.prospectCustomerSplitEmpCount[0]?.length > 0 && audienceAnalyticsData?.prospectCustomerSplitEmpCount[1]?.length > 0 && audienceAnalyticsData?.prospectCustomerSplitEmpCount[sid].map((d, did): number =>
                {
                    return prospectCustomerSplitRevenuetotalData[did] <= 0 ? 0 : d / prospectCustomerSplitRevenuetotalData[did]; 
                }
                )
            };
        })
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const graphData = await getAudienceGraphData();
            // trend
            if (graphData?.trend) 
            {
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, trend: graphData?.trend 
                    }; 
                });
            }

            // lifecycle stage
            if (graphData?.lifeCycleStage && !(_.isEmpty(graphData?.lifeCycleStage))) 
            {
                const sum = _.sumBy(_.values(graphData?.lifeCycleStage));
                const lifecycleStageArr = Object.entries(graphData?.lifeCycleStage)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name: _.capitalize(name),
                            percentage: Math.round((Number(value) / sum) * 100),
                            value
                        }; 
                    });

                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, lifeCycleStage: lifecycleStageArr 
                    }; 
                });
                setGraphReady((prevState) : GraphReady => 
                {
                    return {
                        ...prevState, lifeCycleStage: true 
                    }; 
                });
            }

            // deal stage
            if (graphData?.dealStage) 
            {
                const sum = _.sumBy(_.values(graphData?.dealStage));
                const dealStageArr = Object.entries(graphData?.dealStage)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name: _.capitalize(name),
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        }; 
                    });
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, dealStage: dealStageArr 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, dealStage: true 
                    }; 
                });
            }

            // revenue
            if (graphData?.revenue && !(_.isEmpty(graphData?.revenue))) 
            {
                const yAxisValues = Array(arrGraphXaxisData.length).fill(0);
                Object.entries(graphData?.revenue).forEach(([key, value]): void => 
                {
                    const index = arrGraphXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisValues[index] = value;
                    }
                });

                const resultArray = [arrGraphXaxisData, yAxisValues] as [string[], number[]];
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, revenue: resultArray 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, revenue: true 
                    }; 
                });
            }

            // employee count
            if (graphData?.employeeCount && !(_.isEmpty(graphData?.employeeCount))) 
            {
                const yAxisValues = Array(employeeCountXaxisData.length).fill(0);
                Object.entries(graphData?.employeeCount).forEach(([key, value]): void => 
                {
                    const index = employeeCountXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisValues[index] = value;
                    }
                });

                const resultArray = [employeeCountXaxisData, yAxisValues] as [string[], number[]];
                setAudienceAnalyticsData((prevState) : Data => 
                {
                    return {
                        ...prevState, employeeCount: resultArray 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, employeeCount: true 
                    }; 
                });
            }

            // icp to bp mapping
            if (graphData?.icpToBpMapping && !(_.isEmpty(graphData?.icpToBpMapping))) 
            {
                setAudienceAnalyticsData((prevState) : Data => 
                {
                    return {
                        ...prevState, icpToBpMapping: graphData?.icpToBpMapping 
                    }; 
                });
                setGraphReady((prevState) : GraphReady => 
                {
                    return {
                        ...prevState, icpToBpMapping: true 
                    }; 
                });
            }

            // revenue recognized by icp
            if (graphData?.revenueRecognizedByIcp && !(_.isEmpty(graphData?.revenueRecognizedByIcp))) 
            {
                const sum = _.sumBy(_.values(graphData?.revenueRecognizedByIcp));
                const revenueRecognizedByIcpArr = Object.entries(graphData?.revenueRecognizedByIcp)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        }; 
                    });
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, revenueRecognizedByIcp: revenueRecognizedByIcpArr 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, revenueRecognizedByIcp: true 
                    }; 
                });
            }

            // icp
            if (graphData?.icp && !(_.isEmpty(graphData?.icp))) 
            {
                const sum = _.sumBy(_.values(graphData?.icp));
                const revenueRecognizedByIcpArr = Object.entries(graphData?.icp)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        }; 
                    });
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, icp: revenueRecognizedByIcpArr 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, icp: true 
                    }; 
                });
            }

            // pipeline by icp
            if (graphData?.pipelineByIcp && !(_.isEmpty(graphData?.pipelineByIcp))) 
            {
                const sum = _.sumBy(_.values(graphData?.pipelineByIcp));
                const revenueRecognizedByIcpArr = Object.entries(graphData?.pipelineByIcp)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        }; 
                    });
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, pipelineByIcp: revenueRecognizedByIcpArr 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, pipelineByIcp: true 
                    }; 
                });
            }

            // buyer persona
            if (graphData?.buyerPersona && !(_.isEmpty(graphData?.buyerPersona))) 
            {
                const sum = _.sumBy(_.values(graphData?.buyerPersona));
                const buyerPersonaArr = Object.entries(graphData?.buyerPersona)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        }; 
                    });
                setAudienceAnalyticsData((prevState) : Data => 
                {
                    return {
                        ...prevState, buyerPersona: buyerPersonaArr 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, buyerPersona: true 
                    }; 
                });
            }

            // seniority
            if (graphData?.seniority && !(_.isEmpty(graphData?.seniority))) 
            {
                const sum = _.sumBy(_.values(graphData?.seniority));
                const seniorityData = Object.entries(graphData?.seniority)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([name, value]): boolean => 
                    {
                        return value !== 0; 
                    })
                    .map(([name, value]): IAudienceAnalyticsPieChart => 
                    {
                        return {
                            name: _.capitalize(name),
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        }; 
                    });
                    
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, seniority: seniorityData 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, seniority: true 
                    }; 
                });
            }

            // customer split revenue
            if (graphData) 
            {
                const yAxisAValues = Array(arrGraphXaxisData.length).fill(0);
                const yAxisBValues = Array(arrGraphXaxisData.length).fill(0);

                Object.entries(graphData?.prospectCustomerSplitRevenue?.customer).forEach(([key, value]): void => 
                {
                    const index = arrGraphXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisAValues[index] = value;
                    }
                });

                Object.entries(graphData?.prospectCustomerSplitRevenue?.prospect).forEach(([key, value]): void => 
                {
                    const index = arrGraphXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisBValues[index] = value;
                    }
                });

                const resultArray = [yAxisAValues, yAxisBValues] as [string[], number[]];
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, prospectCustomerSplitRevenue: resultArray 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, prospectCustomerSplitRevenue: true 
                    }; 
                });
            }

            // customer split employee count
            if (graphData?.prospectCustomerSplitEmpCount) 
            {
                const yAxisAValues = Array(employeeCountXaxisData.length).fill(0);
                const yAxisBValues = Array(employeeCountXaxisData.length).fill(0);

                Object.entries(graphData?.prospectCustomerSplitEmpCount?.customer).forEach(([key, value]): void => 
                {
                    const index = employeeCountXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisAValues[index] = value;
                    }
                });

                Object.entries(graphData?.prospectCustomerSplitEmpCount?.prospect).forEach(([key, value]): void => 
                {
                    const index = employeeCountXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisBValues[index] = value;
                    }
                });

                const resultArray = [yAxisAValues, yAxisBValues] as [string[], number[]];
                setAudienceAnalyticsData((prevState): Data => 
                {
                    return {
                        ...prevState, prospectCustomerSplitEmpCount: resultArray 
                    }; 
                });
                setGraphReady((prevState): GraphReady => 
                {
                    return {
                        ...prevState, prospectCustomerSplitEmpCount: true 
                    }; 
                });
            }
        }
        catch (error) 
        {
            //console.log(error);
        }
        finally 
        {
            setGraphReady((prevState): GraphReady => 
            {
                return {
                    ...prevState, buyerPersona: true, dealStage: true, employeeCount: true, icp: true, icpToBpMapping: true, lifeCycleStage: true, pipelineByIcp: true, prospectCustomerSplitEmpCount: true, prospectCustomerSplitRevenue: true, revenue: true, revenueRecognizedByIcp: true, seniority: true 
                }; 
            });
        }
    };

    useEffect((): void => 
    {
        fetchData();
    }, []);

    return (
        <div id="audienceAnalytics">

            <TopPageAnalyticsContainer
                TrendsComponent={ <TrendsComponent Trends={[{
                    title: 'Audience',
                    value: audienceAnalyticsData?.trend?.audience ? String(audienceAnalyticsData?.trend?.audience) : '-'
                },
                {
                    title: 'Customers',
                    value: audienceAnalyticsData?.trend?.customer ? String(audienceAnalyticsData?.trend?.customer) : '-',
                },
                {
                    title: 'Prospects',
                    value: audienceAnalyticsData?.trend?.prospect ? String(audienceAnalyticsData?.trend?.prospect) : '-',
                },
                {
                    title: 'Prospects in ICP',
                    value: audienceAnalyticsData?.trend?.prospectsInIcp ? String(audienceAnalyticsData?.trend?.prospectsInIcp) : '-',
                }
                ]} />}
                GraphComponentsArray={
                    [
                        {
                            GraphComponent: <AnalyticsGraphContainer
                                title="Audience Lifecycle Stage"
                                chartOptions={audienceLifecycleGraphOption}
                                isGraphReady={isGraphReady.lifeCycleStage}
                                isDataAvailable={audienceAnalyticsData?.lifeCycleStage?.length > 0}
                                enlargeOnclick={(): void => 
                                {
                                    setEnlargeView({
                                        icp: false,
                                        buyerPersona: false,
                                        revenue: false,
                                        // audienceDistribution: false,
                                        employeeCount: false,
                                        // role: false,
                                        seniority: false,
                                        lifeCycleStage: true,
                                        dealStage: false,
                                        prospectCustomerSplitRevenue: false,
                                        prospectCustomerSplitEmpCount: false,
                                        revenueRecognizedByIcp: false,
                                        pipelineByIcp: false,
                                        icpToBpMapping: false
                                    });
                                }}
                            />
                        },
                        {
                            GraphComponent: <AnalyticsGraphContainer
                                title="Audience Deal Stage"
                                chartOptions={audienceDealStageGraphOption}
                                isGraphReady={isGraphReady.dealStage}
                                isDataAvailable={audienceAnalyticsData?.dealStage?.length > 0}
                                enlargeOnclick={(): void => 
                                {
                                    setEnlargeView({
                                        icp: false,
                                        buyerPersona: false,
                                        revenue: false,
                                        // audienceDistribution: false,
                                        employeeCount: false,
                                        // role: false,
                                        seniority: false,
                                        lifeCycleStage: false,
                                        dealStage: true,
                                        prospectCustomerSplitRevenue: false,
                                        prospectCustomerSplitEmpCount: false,
                                        revenueRecognizedByIcp: false,
                                        pipelineByIcp: false,
                                        icpToBpMapping: false
                                    });
                                }}
                            />
                        }
                    ]
                }
                BlockStyling={{
                    padding: '16px !important'
                }}
            />

            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Audience by Revenue"
                        chartOptions={companiesRevenueGraphOption}
                        isGraphReady={isGraphReady.revenue}
                        isDataAvailable={audienceAnalyticsData?.revenue?.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setEnlargeView({
                                icp: false,
                                buyerPersona: false,
                                revenue: true,
                                // audienceDistribution: false,
                                employeeCount: false,
                                // role: false,
                                seniority: false,
                                lifeCycleStage: false,
                                dealStage: false,
                                prospectCustomerSplitRevenue: false,
                                prospectCustomerSplitEmpCount: false,
                                revenueRecognizedByIcp: false,
                                pipelineByIcp: false,
                                icpToBpMapping: false
                            });
                        }}
                    />,

                    <AnalyticsGraphContainer
                        title="Prospect / Customer split on Audience Revenue"
                        chartOptions={prospectCustomerSplitRevenueGraphOption}
                        isGraphReady={isGraphReady.prospectCustomerSplitRevenue}
                        isDataAvailable={audienceAnalyticsData?.prospectCustomerSplitRevenue?.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setEnlargeView({
                                icp: false,
                                buyerPersona: false,
                                revenue: false,
                                // audienceDistribution: false,
                                employeeCount: false,
                                // role: false,
                                seniority: false,
                                lifeCycleStage: false,
                                dealStage: false,
                                prospectCustomerSplitRevenue: true,
                                prospectCustomerSplitEmpCount: false,
                                revenueRecognizedByIcp: false,
                                pipelineByIcp: false,
                                icpToBpMapping: false
                            });
                        }}
                    />

                ]}
                style={{
                    gridTemplateColumns: '1fr 1fr',
                    padding: '0 16px' 
                }}
            />

            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Employee Count by Revenue"
                        chartOptions={companiesemployeeCountGraphOption}
                        isGraphReady={isGraphReady.employeeCount}
                        isDataAvailable={audienceAnalyticsData?.employeeCount?.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setEnlargeView({
                                icp: false,
                                buyerPersona: false,
                                revenue: false,
                                // audienceDistribution: false,
                                employeeCount: true,
                                // role: false,
                                seniority: false,
                                lifeCycleStage: false,
                                dealStage: false,
                                prospectCustomerSplitRevenue: false,
                                prospectCustomerSplitEmpCount: false,
                                revenueRecognizedByIcp: false,
                                pipelineByIcp: false,
                                icpToBpMapping: false
                            });
                        }}
                    />,

                    <AnalyticsGraphContainer
                        title="Prospect / Customer split on employee count"
                        chartOptions={prospectCustomerSplitEmpCountGraphOption}
                        isGraphReady={isGraphReady.prospectCustomerSplitEmpCount}
                        isDataAvailable={audienceAnalyticsData?.prospectCustomerSplitEmpCount?.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setEnlargeView({
                                icp: false,
                                buyerPersona: false,
                                revenue: false,
                                // audienceDistribution: false,
                                employeeCount: false,
                                // role: false,
                                seniority: false,
                                lifeCycleStage: false,
                                dealStage: false,
                                prospectCustomerSplitRevenue: false,
                                prospectCustomerSplitEmpCount: true,
                                revenueRecognizedByIcp: false,
                                pipelineByIcp: false,
                                icpToBpMapping: false
                            });
                        }}
                    />

                ]}
                style={{
                    gridTemplateColumns: '1fr 1fr',
                    padding: '16px'  
                }}
            />

            <Box className="graph-heading-container">
                <Typography className="graph-heading">{'Ideal Customer Profile & BP'}</Typography>
            </Box>

            <AnalyticsGraphsBlock
                elem={[
                    <Box className="graphCont" >
                        <Box className="header-block">
                            <Box className="graphTitle">{'Ideal Customer Profile to Buyer Persona Mapping'}</Box>
                            {audienceAnalyticsData.icpToBpMapping?.names && audienceAnalyticsData.icpToBpMapping?.names?.length > 0 && <Box className="enlarge-view" onClick={(): void => 
                            {
                                setEnlargeView({
                                    icp: false,
                                    buyerPersona: false,
                                    revenue: false,
                                    // audienceDistribution: false,
                                    employeeCount: false,
                                    // role: false,
                                    seniority: false,
                                    lifeCycleStage: false,
                                    dealStage: false,
                                    prospectCustomerSplitRevenue: false,
                                    prospectCustomerSplitEmpCount: false,
                                    revenueRecognizedByIcp: false,
                                    pipelineByIcp: false,
                                    icpToBpMapping: true
                                });
                            }}>
                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                            </Box>}
                        </Box>
                        <Box className="graph">
                            {isGraphReady.icpToBpMapping ? (audienceAnalyticsData.icpToBpMapping?.names && audienceAnalyticsData.icpToBpMapping?.names?.length > 0) ? (<Charts height={`${50}vh`} chartOption={icpToBpMappingChartOption} chartHeight={`${50}vh`} chartWidth={'100'} boxMargin="16px" />) : <Box className="noData" sx={{
                                height: `${50}vh` 
                            }}>{'No data available'}</Box> : (<CustomSpinner height={`${50}vh`} />)}
                        </Box>
                    </Box>
                    ,
                    <AnalyticsGraphsBlock
                        elem={[

                            <AnalyticsGraphContainer
                                title="Revenue Recognised by ICP"
                                chartOptions={revenueRecognizedByIcpGraphOption}
                                isGraphReady={isGraphReady.revenueRecognizedByIcp}
                                isDataAvailable={audienceAnalyticsData?.revenueRecognizedByIcp?.length > 0}
                                enlargeOnclick={(): void => 
                                {
                                    setEnlargeView({
                                        icp: false,
                                        buyerPersona: false,
                                        revenue: false,
                                        // audienceDistribution: false,
                                        employeeCount: false,
                                        // role: false,
                                        seniority: false,
                                        lifeCycleStage: false,
                                        dealStage: false,
                                        prospectCustomerSplitRevenue: false,
                                        prospectCustomerSplitEmpCount: false,
                                        revenueRecognizedByIcp: true,
                                        pipelineByIcp: false,
                                        icpToBpMapping: false
                                    });
                                }}
                            />,

                            <AnalyticsGraphContainer
                                title="ICP"
                                chartOptions={icpGraphOption}
                                isGraphReady={isGraphReady.icp}
                                isDataAvailable={audienceAnalyticsData?.icp?.length > 0}
                                enlargeOnclick={(): void => 
                                {
                                    setEnlargeView({
                                        icp: true,
                                        buyerPersona: false,
                                        revenue: false,
                                        // audienceDistribution: false,
                                        employeeCount: false,
                                        // role: false,
                                        seniority: false,
                                        lifeCycleStage: false,
                                        dealStage: false,
                                        prospectCustomerSplitRevenue: false,
                                        prospectCustomerSplitEmpCount: false,
                                        revenueRecognizedByIcp: false,
                                        pipelineByIcp: false,
                                        icpToBpMapping: false
                                    });
                                }}
                            />,

                            <AnalyticsGraphContainer
                                title="Pipeline by ICP"
                                chartOptions={pipelineByIcpGraphOption}
                                isGraphReady={isGraphReady.pipelineByIcp}
                                isDataAvailable={audienceAnalyticsData?.pipelineByIcp?.length > 0}
                                enlargeOnclick={(): void => 
                                {
                                    setEnlargeView({
                                        icp: false,
                                        buyerPersona: false,
                                        revenue: false,
                                        // audienceDistribution: false,
                                        employeeCount: false,
                                        // role: false,
                                        seniority: false,
                                        lifeCycleStage: false,
                                        dealStage: false,
                                        prospectCustomerSplitRevenue: false,
                                        prospectCustomerSplitEmpCount: false,
                                        revenueRecognizedByIcp: false,
                                        pipelineByIcp: true,
                                        icpToBpMapping: false
                                    });
                                }}
                            />,
                        ]}
                        style={{
                            gridTemplateColumns: '1fr 1fr',
                        }}
                    />

                ]}
                style={{
                    gridTemplateColumns: '1fr 1fr',
                    padding: '16px'  
                }}
            />

            <AnalyticsGraphsBlock
                elem={[
                    // eslint-disable-next-line react/jsx-key
                    <AnalyticsGraphContainer
                        title="Buyer Persona Breakdown"
                        chartOptions={buyerPersonaGraphOption}
                        isGraphReady={isGraphReady.buyerPersona}
                        isDataAvailable={audienceAnalyticsData?.buyerPersona?.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setEnlargeView({
                                icp: false,
                                buyerPersona: true,
                                revenue: false,
                                // audienceDistribution: false,
                                employeeCount: false,
                                // role: false,
                                seniority: false,
                                lifeCycleStage: false,
                                dealStage: false,
                                prospectCustomerSplitRevenue: false,
                                prospectCustomerSplitEmpCount: false,
                                revenueRecognizedByIcp: false,
                                pipelineByIcp: false,
                                icpToBpMapping: false
                            });
                        }}
                    />,

                    <AnalyticsGraphContainer
                        title="Seniority"
                        chartOptions={seniorityGraphOption}
                        isGraphReady={isGraphReady.seniority}
                        isDataAvailable={audienceAnalyticsData?.seniority?.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setEnlargeView({
                                icp: false,
                                buyerPersona: false,
                                revenue: false,
                                // audienceDistribution: false,
                                employeeCount: false,
                                // role: false,
                                seniority: true,
                                lifeCycleStage: false,
                                dealStage: false,
                                prospectCustomerSplitRevenue: false,
                                prospectCustomerSplitEmpCount: false,
                                revenueRecognizedByIcp: false,
                                pipelineByIcp: false,
                                icpToBpMapping: false
                            });
                        }}
                    />,

                ]}
                style={{
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    padding: '0 16px'  
                }}
            />

            {enlargeView.lifeCycleStage ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Audience Lifecycle Stage'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={audienceLifecycleGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.dealStage ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Audience Deal Stage'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={audienceDealStageGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.revenue ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Audience by Revenue'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={companiesRevenueGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.prospectCustomerSplitRevenue ? (
                <PopUpView onClose={():void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Prospect / Customer split on Audience Revenue'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={prospectCustomerSplitRevenueGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.employeeCount ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Employee Count by Revenue'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={companiesemployeeCountGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.prospectCustomerSplitEmpCount ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Prospect / Customer split on employee count'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={prospectCustomerSplitEmpCountGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.icpToBpMapping ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Ideal Customer Profile to Buyer Persona Mapping'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={icpToBpMappingChartOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.revenueRecognizedByIcp ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Revenue Recognized by ICP'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={revenueRecognizedByIcpGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.icp ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'ICP'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={icpGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.pipelineByIcp ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Pipeline by ICP'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={pipelineByIcpGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.buyerPersona ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Buyer Persona Breakdown'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={buyerPersonaGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {enlargeView.seniority ? (
                <PopUpView onClose={(): void => 
                {
                    setEnlargeView({
                        icp: false,
                        buyerPersona: false,
                        revenue: false,
                        // audienceDistribution: false,
                        employeeCount: false,
                        // role: false,
                        seniority: false,
                        lifeCycleStage: false,
                        dealStage: false,
                        prospectCustomerSplitRevenue: false,
                        prospectCustomerSplitEmpCount: false,
                        revenueRecognizedByIcp: false,
                        pipelineByIcp: false,
                        icpToBpMapping: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Seniority'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={seniorityGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

        </div>
    );
};

export default AudienceAnalyticsMain;