export enum EventRegistrantStatus
    {
    INVITE = 1,
    APPROVED = 2,
    REJECTED = 3,
    WAITLIST = 4,
    DELETED = 5,
    CONFIRMED = 6,
    INVITED = 7,
}

export enum EventRegistrantAttendedStatus 
    {
    NO = 1,
    YES = 2,
}

export enum EventRegistrantPaymentStatus 
    {
    UNKNOWN = 1,
    PAID = 2,
    YET_TO_PAY = 3,
    CANCELLED = 4,
    EXPIRED = 5,
}

export enum EventRegistrantNotify
    {
    NO = 1,
    YES = 2
}