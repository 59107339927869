export enum RegistrationFormStatus 
    {
    PUBLISHED = 1,
    DELETED = 2
}

export enum RegistrationFormCaptcha 
    {
    FALSE = 1,
    TRUE = 2
}