import APP_CONSTANTS from "../constants";
import { triggerApi } from "../helpers";

export const syncZoomWebinar = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/zoom/sync';
    return triggerApi(API_URL, 'GET', '');
};

export const deleteZoomWebinar = async () => { 
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/zoom';
    return triggerApi(API_URL, 'DELETE', '');
}
