import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import './styles.scss';
import moment from "moment-timezone";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import { EventPreview } from "../../../Events/interfaces";
import { CONTENT } from "../../../../scripts/i18n";
import { uniqueTimezoneArr } from "../../../../components/Events/timezoneGenerateFunction";
import useScreenDimension from "../../../../hooks/screenDimensions";
import { LandingPageLayout } from "../../../Events/enum";
import { getFaceIdResponse, getRegistrationFormDataWithToken } from "../../../../scripts/apis/registrationPublic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "../../../../scripts/LocalStorage";
import DefaultPhotosComponent from "./DefaultPhotos";
import YourPhotosComponent from "./YourPhotos";
import { CustomButton } from "../../../../common/FormComponents/Buttons";

type BrandColorKey = keyof typeof CONTENT.BRAND_COLOR_OBJ;

const PhotosPage: React.FC = () => {
    
    const camera = useRef<any>(null);
    const [eventData, setEventData] = useState<EventPreview | undefined>(undefined);
    const [photo, setPhoto] = useState<any>(null);
    const [faceId, setFaceId] = useState<string | null>(null);
    const [openCamera, setOpenCamera] = useState<boolean>(false);
    const [isCameraActive, setCameraActive] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [ctaHover, setCtaHover] = useState<boolean>(false);
    const [component, setComponent] = useState<'photos' | 'your-photos'>(LocalStorage.get('@FaceId') ? 'your-photos' : 'photos');

    const screenType = useScreenDimension();

    const brandColorKey = eventData?.brandColor as BrandColorKey;
    const brandColor = CONTENT.BRAND_COLOR_OBJ[brandColorKey];
    // const router = useRouter()

    // const params = useParams<{ eventLink: string }>()

    const fetchFaceId = async (imageSrc: any) => {
        const blob = await fetch(imageSrc).then(res => res.blob());
        const file = new File([blob], 'webcam_image.jpg', { type: 'image/jpeg' });

        const formData = new FormData();
        formData.append('file', file);

        try 
        {
            const faceIdResp = await getFaceIdResponse(eventData?.token as string, formData);
            if (faceIdResp)
            {
                setFaceId(faceIdResp);
                LocalStorage.set('@FaceId', faceIdResp);
                setComponent('your-photos');
                setSpinner(false);
            }
            else
            {
                setSpinner(false);
            }
        } 
        catch (error) 
        {
            setSpinner(false);
            toast.error((error as Error)?.message || 'Error in fetching face id');
        }
    };

    const capture = useCallback(() => {
        const imageSrc = camera?.current?.getScreenshot();
        setPhoto(imageSrc);
        setOpenCamera(false);
        setCameraActive(false);
        setSpinner(true);
        fetchFaceId(imageSrc)
    }, [camera, setPhoto, eventData]);


    const [eventDateInfo, setEventDateInfo] = useState<string>('');
    const [eventTimeInfo, setEventTimeInfo] = useState<string>('');

    const fetchData = async () =>
    {

        const orgLink = window.location.pathname.split('/')[1];
        const eventLink = window.location.pathname.split('/')[2];

        try 
        {
            const eventDetails = await getRegistrationFormDataWithToken(orgLink, eventLink);
            if (eventDetails)
            {
                setEventData(eventDetails);

                if (moment.unix(Number(eventDetails?.eventStartDateTime)).format('dddd, MMMM D') === moment.unix(Number(eventDetails?.eventEndDateTime)).format('dddd, MMMM D')) {
                    setEventDateInfo(moment.unix(Number(eventDetails?.eventStartDateTime)).tz(eventDetails?.timezone).format('dddd, MMMM D'));
                }
                else {
                    setEventDateInfo(`${moment.unix(Number(eventDetails?.eventStartDateTime)).tz(eventDetails?.timezone).format('MMM D')} - ${moment.unix(Number(eventDetails?.eventEndDateTime)).format('MMM D')}`);
                }
    
                const startTime = moment.unix(Number(eventDetails?.eventStartDateTime)).tz(eventDetails?.timezone);
                const endTime = moment.unix(Number(eventDetails?.eventEndDateTime)).tz(eventDetails?.timezone);
                const defaultTimezone = uniqueTimezoneArr && uniqueTimezoneArr?.filter((elem: { name: string; value: string; }) => {
                    return elem.value === eventDetails?.timezone;
                });
                let timezone: string = '';
                if (defaultTimezone && defaultTimezone[0]?.name && defaultTimezone[0]?.name !== '') {
                    timezone = defaultTimezone[0]?.name?.split(' ')[0];
                }
                const formattedTimeRange = `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')} ${timezone}`;
                setEventTimeInfo(formattedTimeRange);
            }
        } 
        catch (error) 
        {
            
        }
    };

    useEffect(() => 
    {
        fetchData();
    }, [])

    // const fetchPhotos = async () =>
    // {
    //     const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/photo-sharing-public/${orgLink}/${params.eventLink}/photos/${faceId}`);
    //     const photos = await response.json();
    //     console.log(photos);
    // }

    const handleUserMedia = () => {
        setCameraActive(true);
    };

    const handleUserMediaError = (error) => {
        console.error('Webcam error:', error);
        setCameraActive(false);
        toast.error('Error in opening camera');
        // Optionally, handle the error (e.g., show an error message)
    };

    const renderPhotosPageForDesktop = () => 
    {
        return (
            <div className="desktop-main-container">
                <div className="layout-1-1-container">
                    <div className="layout-1-1-banner">
                        {eventData?.eventBanner && <img src={eventData?.eventBanner} alt='event-banner' className="photo-search-page-banner" />}
                    </div>
                    <div className="scan-component-mobile" style={{
                        border: `1px solid #0000001A`, backgroundColor: '#FFFFFF'
                    }}>
                        {spinner ? <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" role="status" /></div> : <div className="heading-division">
                            <div className="icon-block">
                                <FontAwesomeIcon 
                                    icon={['fal', openCamera ? 'camera' : 'image']}
                                />
                            </div>
                            <div>
                                <h3 className="heading">{openCamera ? 'Smile at the camera' : `${eventData?.title} Photos`}</h3>
                                <p className="description">{'Scan your face to get your photos'}</p>
                            </div>
                        </div>}
                        <div className="scan-container">
                            {photo ? (
                                <img style={{ padding: '16px', width: '100%', borderRadius: '24px' }} src={photo} alt="Captured" />
                            ) : openCamera && (
                                <Webcam
                                    audio={false}
                                    ref={camera}
                                    screenshotFormat="image/jpeg"
                                    height={180}
                                    width={200}
                                    style={{ borderRadius: '12px', }}
                                    onUserMedia={handleUserMedia}
                                    onUserMediaError={handleUserMediaError}
                                    minScreenshotHeight={180}
                                    minScreenshotWidth={200}
                                    mirrored={true}
                                />
                            )}
                            {!openCamera && !photo &&
                                <div className="p-3 w-100">
                                    <CustomButton 
                                        name={'Scan your face'}
                                        btnType="primary"
                                        onClick={() => setOpenCamera(true)}
                                        width="100%"
                                        style={{ width: '100%' }}
                                    />
                                </div> 
                            }
                        </div>
                        {openCamera && !photo && isCameraActive && 
                            <div className="retake-submit">
                                <CustomButton 
                                    name={'Close'}
                                    btnType="secondary"
                                    onClick={() => {
                                        setPhoto(null);
                                        setOpenCamera(false);
                                        setCameraActive(false);
                                    }}
                                    width="100%"
                                    style={{ width: '100%' }}
                                />
                                <CustomButton 
                                    name={'Take a photo'}
                                    btnType="primary"
                                    onClick={capture}
                                    width="100%"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const renderPhotosPageForFullWidth = () =>
    {
        return (
            <>
                <div className="scan-component-mobile" style={{
                    border: `1px solid #0000001A`, backgroundColor: '#FFFFFF'
                }}>
                    {spinner ? <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" role="status" /></div> : <div className="heading-division">
                        <div className="icon-block">
                            <FontAwesomeIcon 
                                icon={['fal', openCamera ? 'camera' : 'image']}
                            />
                        </div>
                        <div>
                            <h3 className="heading">{openCamera ? 'Smile at the camera' : `${eventData?.title} Photos`}</h3>
                            <p className="description">{'Scan your face to get your photos'}</p>
                        </div>
                    </div>}
                    <div>
                        {photo ? (
                            <img style={{ padding: '16px', width: '100%', borderRadius: '24px' }} src={photo} alt="Captured" />
                        ) : openCamera && (
                            <Webcam
                                audio={false}
                                ref={camera}
                                screenshotFormat="image/jpeg"
                                height={200}
                                width={180}
                                style={{ borderRadius: '12px', }}
                                onUserMedia={handleUserMedia}
                                onUserMediaError={handleUserMediaError}
                                minScreenshotHeight={200}
                                minScreenshotWidth={180}
                                mirrored={true}
                            />
                        )}
                    </div>
                    {openCamera && !photo && isCameraActive && <div className="retake-submit">
                        <CustomButton 
                            name={'Close'}
                            btnType="secondary"
                            onClick={() => {
                                setPhoto(null);
                                setOpenCamera(false);
                                setCameraActive(false);
                            }}
                            width="100%"
                            style={{ width: '100%' }}
                        />
                        <CustomButton 
                            name={'Take a photo'}
                            btnType="primary"
                            onClick={capture}
                            width="100%"
                            style={{ width: '100%' }}
                        />
                    </div>}
                    {!openCamera && !photo && 
                        <div className="p-3 w-100">
                            <CustomButton 
                                name={'Scan your face'}
                                btnType="primary"
                                onClick={() => setOpenCamera(true)}
                                width="100%"
                                style={{ width: '100%' }}
                            />
                        </div> 
                    }
                </div>
            </>
        )
    };

    const renderFullWidthPhotosPage = () =>
    {
        return (
            <div className="full-width-desktop-main-container">
                <div className="layout-16-9-container">
                    <div className="layout-16-9-banner">
                        {eventData?.eventBanner && <img src={eventData?.eventBanner} alt='event-banner' className="photo-search-page-banner" />}
                    </div>
                    <div className="scan-component-mobile" style={{
                        border: `1px solid #0000001A`, backgroundColor: '#FFFFFF'
                    }}>
                        {spinner ? <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" role="status" /></div> : <div className="heading-division">
                            <div className="icon-block">
                                <FontAwesomeIcon 
                                    icon={['fal', openCamera ? 'camera' : 'image']}
                                />
                            </div>
                            <div>
                                <h3 className="heading">{openCamera ? 'Smile at the camera' : `${eventData?.title} Photos`}</h3>
                                <p className="description">{'Scan your face to get your photos'}</p>
                            </div>
                        </div>}
                        <div>
                            {photo ? (
                                <img style={{ padding: '16px', width: '100%', borderRadius: '24px' }} src={photo} alt="Captured" />
                            ) : openCamera && (
                                <Webcam
                                    audio={false}
                                    ref={camera}
                                    screenshotFormat="image/jpeg"
                                    height={272}
                                    width={272}
                                    style={{ borderRadius: '12px', }}
                                    onUserMedia={handleUserMedia}
                                    onUserMediaError={handleUserMediaError}
                                    minScreenshotHeight={300}
                                    minScreenshotWidth={300}
                                    mirrored={true}
                                />
                            )}
                        </div>
                        {!openCamera && !photo && <div className="p-3 w-100">
                            <CustomButton 
                                name={'Scan your face'}
                                btnType="primary"
                                onClick={() => setOpenCamera(true)}
                                width="100%"
                                style={{ width: '100%' }}
                            />
                        </div>}
                        {openCamera && !photo && isCameraActive && <div className="retake-submit">
                            <CustomButton 
                                name={'Close'}
                                btnType="secondary"
                                onClick={() => {
                                    setPhoto(null);
                                    setOpenCamera(false);
                                    setCameraActive(false);
                                }}
                                width="100%"
                                style={{ width: '100%' }}
                            />
                            <CustomButton 
                                name={'Take a photo'}
                                btnType="primary"
                                onClick={capture}
                                width="100%"
                                style={{ width: '100%' }}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        );
    };

    const renderPhotosPage = () =>
    {
        return (
            <>
                {eventData?.landingPageLayout === LandingPageLayout.ONE_TO_ONE && screenType === 'desktop' ?
                    renderPhotosPageForDesktop()
                    : (eventData?.landingPageLayout === LandingPageLayout.ONE_TO_ONE || eventData?.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE) && (screenType === 'mobile' || screenType === 'tablet') ?
                        renderPhotosPageForFullWidth()
                        : eventData?.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE && screenType === 'desktop' && 
                        renderFullWidthPhotosPage()
                }
            </>
        )
    };

    const renderDefaultAndYourPhotos = () =>
    {
        return (
            <>
                <YourPhotosComponent eventData={eventData} />
                <DefaultPhotosComponent eventData={eventData} />
            </>
        );
    };

    return (
        <div id="photosPage">
            {
                component === 'photos' ? renderPhotosPage() : renderDefaultAndYourPhotos()
            }
        </div>
    );
};

export default PhotosPage;