/* eslint-disable react/jsx-key */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AnalyticsGraphContainer, AnalyticsGraphsBlock, toTitleCase } from './BudgetAnalytics';
import TrendsComponent from '../../common/TrendsComponent';
import Charts from '../../common/ECharts/Charts';
import './styles.scss';
import { getEventsGraph } from '../../scripts/apis/events';
import _ from 'lodash';
import PopUpView from '../../common/PopupView';
import { NumberFormatter } from '../../scripts/helpers';
import { IEventAnalyticsPieChart } from './EAInterface';
import { EventsTrend } from '../Events/interfaces/event-trend_interface';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';

const EventAnalytics = (): React.JSX.Element => 
{

    const [graphData, setGraphData] = useState<{
        trendData: EventsTrend,
        eventsTypeData: IEventAnalyticsPieChart[],
        eventsLocationData: IEventAnalyticsPieChart[],
        eventsQuarterData: IEventAnalyticsPieChart[],
        eventModeData: IEventAnalyticsPieChart[],
        audienceTypeData: IEventAnalyticsPieChart[],
        ticketTypeData: IEventAnalyticsPieChart[],
    }>({
        trendData: {
            count: 0,
            avgRoi: 0,
            pipeline: 0,
            revenue: 0,
            avgCost: 0
        },
        eventsTypeData: [],
        eventsLocationData: [],
        eventsQuarterData: [],
        eventModeData: [],
        audienceTypeData: [],
        ticketTypeData: [],
    });

    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        eventTypeGraph: false,
        eventLocationGraph: false,
        eventQuarterGraph: false,
        eventModeGraph: false,
        audienceTypeGraph: false,
        eventTicketTypeGraph: false,
    });

    const [isChartReady, setChartReady] = useState<{
        eventTypeGraphReady?: boolean;
        eventLocationGraphReady?: boolean;
        eventQuarterGraphReady?: boolean;
        eventModeGraphReady?: boolean;
        audienceTypeGraphReady?: boolean;
        ticketTypeGraphReady?: boolean;
    }>({
        eventTypeGraphReady: false,
        eventLocationGraphReady: false,
        eventQuarterGraphReady: false,
        eventModeGraphReady: false,
        audienceTypeGraphReady: false,
        ticketTypeGraphReady: false
    });



    const fetchGraphData = async (): Promise<void> => 
    {
        try 
        {
            const graphData = await getEventsGraph();
            if (graphData) 
            {
                if (graphData.type) 
                {
                    const eventTypesum = _.sumBy(_.values(graphData.type));
                    const eventType = Object.entries(graphData.type)
                        .filter(([, value]) : boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / eventTypesum) * 100),
                                value: String(value),
                            }; 
                        });
                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, eventsTypeData: eventType 
                        }; 
                    });
                }
                if (graphData.location) 
                {
                    const eventLocationSum = _.sumBy(_.values(graphData.location));
                    const eventLocation = Object.entries(graphData.location)
                        .filter(([, value]): boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / eventLocationSum) * 100),
                                value: String(value)
                            }; 
                        });

                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, eventsLocationData: eventLocation 
                        }; 
                    });
                }
                if (graphData.trend) 
                {
                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, trendData: graphData.trend 
                        }; 
                    });
                }
                if (graphData.quarter) 
                {
                    const sum = _.sumBy(_.values(graphData?.quarter));
                    const transformed = Object.keys(graphData.quarter).map((item):{
                        value: string;
                        percentage: number;
                        name: string;
                    } => 
                    {
                        return {
                            value: String(graphData.quarter[item]), name: toTitleCase(item), percentage: Math.round((Number(graphData.quarter[item]) / sum) * 100) 
                        }; 
                    });
                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, eventsQuarterData: transformed 
                        }; 
                    });
                }
                if(graphData.mode && !(_.isEmpty(graphData.mode)))
                {
                    const eventModesum = _.sumBy(_.values(graphData.mode));
                    const eventMode = Object.entries(graphData.mode)
                        .filter(([, value]) : boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / eventModesum) * 100),
                                value: String(value),
                            }; 
                        });
                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, eventModeData: eventMode 
                        }; 
                    });
                }
                if(graphData.audienceType && !(_.isEmpty(graphData.audienceType)))
                {
                    const audienceTypesum = _.sumBy(_.values(graphData.audienceType));
                    const audienceType = Object.entries(graphData.audienceType)
                        .filter(([, value]) : boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / audienceTypesum) * 100),
                                value: String(value),
                            }; 
                        });
                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, audienceTypeData: audienceType 
                        }; 
                    });
                }
                if(graphData.audienceType && !(_.isEmpty(graphData.ticketType)))
                {
                    const ticketTypesum = _.sumBy(_.values(graphData.ticketType));
                    const ticketType = Object.entries(graphData.ticketType)
                        .filter(([, value]) : boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / ticketTypesum) * 100),
                                value: String(value),
                            }; 
                        });
                    setGraphData((prev):{
                        trendData: EventsTrend;
                        eventsTypeData: IEventAnalyticsPieChart[];
                        eventsLocationData: IEventAnalyticsPieChart[];
                        eventsQuarterData: IEventAnalyticsPieChart[];
                        eventModeData: IEventAnalyticsPieChart[];
                        audienceTypeData: IEventAnalyticsPieChart[];
                        ticketTypeData: IEventAnalyticsPieChart[];
                    } => 
                    {
                        return {
                            ...prev, ticketTypeData: ticketType 
                        }; 
                    });
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally 
        {
            setChartReady((prevState):{
                eventTypeGraphReady?: boolean;
                eventLocationGraphReady?: boolean;
                eventQuarterGraphReady?: boolean;
                eventModeGraphReady?: boolean;
                audienceTypeGraphReady?: boolean;
                ticketTypeGraphReady?: boolean;
            } => 
            {
                return {
                    ...prevState,
                    eventTypeGraphReady: true,
                    eventLocationGraphReady: true,
                    eventQuarterGraphReady: true,
                    eventModeGraphReady: true,
                    audienceTypeGraphReady: true,
                    ticketTypeGraphReady: true,
                }; 
            });
        }
    };

    useEffect(():void => 
    {
        fetchGraphData();
    }, []);

    const eventsTypeGraph = constructObjectForPieChart(graphData.eventsTypeData, ['50%','72%']);

    const AllEventsTypeGraph = constructObjectForPieChart(graphData.eventsTypeData, ['50%','72%'], 'vertical');

    const eventsLocationGraph = constructObjectForPieChart(graphData.eventsLocationData, ['50%','72%']);

    const allEventsLocationGraph = constructObjectForPieChart(graphData.eventsLocationData, ['50%','72%'], 'vertical');

    const eventsQuarterGraph = constructObjectForPieChart(graphData.eventsQuarterData, ['50%','72%']);

    const eventsModeGraph = constructObjectForPieChart(graphData?.eventModeData, ['50%','72%']);

    const allEventsModeGraph = constructObjectForPieChart(graphData?.eventModeData, ['50%','72%'], 'vertical');

    const audienceTypeGraph = constructObjectForPieChart(graphData?.audienceTypeData, ['50%','72%']);

    const allAudienceTypeGraph = constructObjectForPieChart(graphData?.audienceTypeData, ['50%','72%'], 'vertical');

    const eventTicketTypeGraph = constructObjectForPieChart(graphData?.ticketTypeData, ['50%','72%']);

    const allEventTicketTypeGraph = constructObjectForPieChart(graphData?.ticketTypeData, ['50%','72%'], 'vertical');
    
    const allEventsQuarterGraph = constructObjectForPieChart(graphData.eventsQuarterData, ['50%','72%'], 'vertical');

    return (
        <Box id="eventAnalytics">
            <TopPageAnalyticsContainer
                TrendsComponent={<TrendsComponent
                    Trends={
                        [
                            {
                                title: 'Total Events',
                                value: `${Number(graphData.trendData.count) !== 0 ? NumberFormatter(graphData.trendData.count) : '-'}`
                            },
                            {
                                title: 'Avg ROI',
                                value: `${Number(graphData.trendData.avgRoi) !== 0 ? NumberFormatter(graphData.trendData.avgRoi) : '-'}`
                            },
                            {
                                title: 'Pipeline',
                                value: `${Number(graphData.trendData.pipeline) !== 0 ? '$' + NumberFormatter(graphData.trendData.pipeline) : '-'}`
                            },
                            {
                                title: 'Revenue',
                                value: `${Number(graphData.trendData.revenue) !== 0 ? '$' + NumberFormatter(graphData.trendData.revenue) : '-'}`
                            }
                        ]
                    }
                />}
                GraphComponentsArray={[
                    {
                        GraphComponent: <AnalyticsGraphContainer
                            title="Type"
                            chartOptions={eventsTypeGraph}
                            isGraphReady={isChartReady.eventTypeGraphReady}
                            isDataAvailable={graphData.eventsTypeData.length > 0}
                            enlargeOnclick={(): void => 
                            {
                                setOpenEnlargeGraphView({
                                    eventTypeGraph: true,
                                    eventLocationGraph: false,
                                    eventQuarterGraph: false,
                                    eventModeGraph: false,
                                    audienceTypeGraph: false,
                                    eventTicketTypeGraph: false
                                });
                            }}

                        />
                    },
                    {
                        GraphComponent:  <AnalyticsGraphContainer
                            title="Location"
                            chartOptions={eventsLocationGraph}
                            isGraphReady={isChartReady.eventLocationGraphReady}
                            isDataAvailable={graphData.eventsTypeData.length > 0}
                            enlargeOnclick={(): void => 
                            {
                                setOpenEnlargeGraphView({
                                    eventTypeGraph: false,
                                    eventLocationGraph: true,
                                    eventQuarterGraph: false,
                                    eventModeGraph: false,
                                    audienceTypeGraph: false,
                                    eventTicketTypeGraph: false
                                });
                            }}
    
                        />
                    },
                    {
                        GraphComponent: <AnalyticsGraphContainer
                            title="Event Split by Quarter"
                            chartOptions={eventsQuarterGraph}
                            isGraphReady={isChartReady.eventQuarterGraphReady}
                            isDataAvailable={graphData.eventsQuarterData.length > 0}
                            enlargeOnclick={(): void => 
                            {
                                setOpenEnlargeGraphView({
                                    eventTypeGraph: false,
                                    eventLocationGraph: false,
                                    eventQuarterGraph: true,
                                    audienceTypeGraph: false,
                                    eventModeGraph: false,
                                    eventTicketTypeGraph: false
                                });
                            }}
                        />
                    }
                ]}
                BlockStyling={{
                    padding: '0px !important',
                    height: 'auto !important'
                }}
            />

            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Audience Type"
                        chartOptions={audienceTypeGraph}
                        isGraphReady={isChartReady.audienceTypeGraphReady}
                        isDataAvailable={graphData.audienceTypeData.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                eventTypeGraph: false,
                                eventLocationGraph: false,
                                eventQuarterGraph: false,
                                eventModeGraph: false,
                                audienceTypeGraph: true,
                                eventTicketTypeGraph: false
                            });
                        }} 
                        // chartHeight='440px'
                    />,

                    <AnalyticsGraphContainer
                        title="Event Ticket Type"
                        chartOptions={eventTicketTypeGraph}
                        isGraphReady={isChartReady.ticketTypeGraphReady}
                        isDataAvailable={graphData.ticketTypeData.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                eventTypeGraph: false,
                                eventLocationGraph: false,
                                eventQuarterGraph: false,
                                eventModeGraph: false,
                                audienceTypeGraph: false,
                                eventTicketTypeGraph: true
                            });
                        }} 
                        // chartHeight='440px'
                    />,

                    <AnalyticsGraphContainer
                        title="Event Mode"
                        chartOptions={eventsModeGraph}
                        isGraphReady={isChartReady.eventModeGraphReady}
                        isDataAvailable={graphData.eventModeData.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                eventTypeGraph: false,
                                eventLocationGraph: false,
                                eventQuarterGraph: false,
                                eventModeGraph: true,
                                audienceTypeGraph: false,
                                eventTicketTypeGraph: false
                            });
                        }} 
                        // chartHeight='440px'
                    />
                ]} 
                style={{
                    gridTemplateColumns: '1fr 1fr 1fr',
                }}                
            />
            
            {openEnlargeGraphView.eventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventTypeGraph: false,
                        eventLocationGraph: false,
                        eventQuarterGraph: false,
                        eventModeGraph: false,
                        audienceTypeGraph: false,
                        eventTicketTypeGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllEventsTypeGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.eventLocationGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventTypeGraph: false,
                        eventLocationGraph: false,
                        eventQuarterGraph: false,
                        eventModeGraph: false,
                        audienceTypeGraph: false,
                        eventTicketTypeGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Location'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allEventsLocationGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.eventQuarterGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventTypeGraph: false,
                        eventLocationGraph: false,
                        eventQuarterGraph: false,
                        eventModeGraph: false,
                        audienceTypeGraph: false,
                        eventTicketTypeGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Event Split by Quarter'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allEventsQuarterGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.eventModeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventTypeGraph: false,
                        eventLocationGraph: false,
                        eventQuarterGraph: false,
                        eventModeGraph: false,
                        audienceTypeGraph: false,
                        eventTicketTypeGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Event Mode'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allEventsModeGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.audienceTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventTypeGraph: false,
                        eventLocationGraph: false,
                        eventQuarterGraph: false,
                        eventModeGraph: false,
                        audienceTypeGraph: false,
                        eventTicketTypeGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Audience Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allAudienceTypeGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.eventTicketTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventTypeGraph: false,
                        eventLocationGraph: false,
                        eventQuarterGraph: false,
                        eventModeGraph: false,
                        audienceTypeGraph: false,
                        eventTicketTypeGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Event Ticket Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allEventTicketTypeGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

        </Box>
    );
};

export default EventAnalytics;