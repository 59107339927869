import React, { useContext } from 'react';
import { IFormComponentsContext } from '../../../../contexts/FormComponentsContext';
import RegistrationsTopBar from '../../../Registration/Common/RegistrationsTopBar';
import RegistrationFormContent from '../../../Registration/RegistrationFormContent/RegistrationFormContent';
import { PortalContext } from '../../../../contexts/Portal/PortalContext';
import { PortalFormsContext } from '../../../../contexts/Portal/PortalFormsContext';

const LeadCaptureRegistrations = (): React.JSX.Element => 
{

    const { setRefresh, formName, formId, registrationData } = useContext<IFormComponentsContext>(PortalFormsContext);

    const { handleModuleChange } = useContext(PortalContext);

    return (
        <>
            <RegistrationsTopBar name={formName} handleModuleChange={handleModuleChange} formId={formId} setRefresh={setRefresh} registrationData={registrationData} />
            <RegistrationFormContent leadCaptureFormId={registrationData?.id} setRefresh={setRefresh} RegistrationFormData={registrationData}/>
        </>
    );
};

export default LeadCaptureRegistrations;