import { useEffect, useMemo, useState } from "react";
import { Avatar, Grid } from "@mui/material";
import CardComponent from "../../../../components/Events/Registrations/CardComponent";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import AddTeamMember from "./AddTeamMember";
import PortalSidedrawer from "../Common/PortalSidedrawer";
import { getSponsorPortalEventData, getTeamMembers } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { EventRegistrant } from "../../../Events/interfaces/event-registrant_interface";
import { EventRegistrantStatus } from "../../../Events/enum/event-registrant.enum";
import CustomTooltip from "../../../../common/Tooltip";
import { Event } from "../../../Events/interfaces";

import './styles.scss';

const PortalTeam: React.FC = (): React.JSX.Element => 
{

    const [teams, setTeams] = useState<EventRegistrant[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [addMemberSidebarOpen, setAddMemberSidebarOpen] = useState<boolean>(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState<EventRegistrant | undefined>(undefined);
    const [eventDetails, setEventDetails] = useState<Event>();

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const teamsData = await getTeamMembers();
            if (teamsData)
            {
                setTeams(teamsData);
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching teams data', error);
        }
    };

    const fetchEventData = async (): Promise<void> =>
    {
        try 
        {
            const eventDetails = await getSponsorPortalEventData();
            if(eventDetails)
            {
                setEventDetails(eventDetails)
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching event data', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchEventData();
    }, []);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <div id="teams">
            <div className="teams-card-container">
                <div className="heading-div">
                    <div className="heading-wrapper">
                        <h2 className="heading">{'Teams'}</h2>
                        <MuiChip chipColor='grey' label={teams?.length || '-'} />
                    </div>
                    {/* <CustomButton name='' btnType='secondary' startIcon={<FontAwesomeIcon icon={['fal', 'table-list']} />} onClick={() => { undefined } } /> */}
                </div>
                <Grid container spacing={2}>
                    {
                        teams && teams?.length > 0 && teams?.map((item, index) => {

                            const userLogo = (
                                <div className="team-avatar-container">
                                    <Avatar src={item?.clearbitData && item?.clearbitData?.companyLogo} className="teams-user-avatar"><p>{item?.firstName?.charAt(0)?.toUpperCase()}{item?.lastName?.charAt(0)?.toUpperCase() || ''}</p></Avatar>
                                    {item?.status === EventRegistrantStatus.INVITED && <MuiChip label="Invited" chipColor="grey" />}
                                </div>
                            );
                            const userNameAndEmail = (<div className="teams-user-name-email">
                                <p className="name">
                                    {item?.status === EventRegistrantStatus.INVITED ? '-' : `${item?.firstName} ${item?.lastName || ''}`}
                                </p>
                                <p className="email">{item.email}</p>
                            </div>)

                            return (
                                <Grid item xl={4} lg={6} md={6} sm={12} xs={12} key={index}>
                                    <CardComponent 
                                        cardMinHeight="134px"
                                        header={userLogo}
                                        contentHeading={userNameAndEmail}
                                        onClick={() => { 
                                            setSelectedTeamMember(item);
                                            setAddMemberSidebarOpen(true);
                                        }}
                                    />   
                                </Grid>
                            )
                        })
                    }
                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                        <CustomTooltip title={!eventDetails?.addTeamMember ? "Registration closed" : ""}>
                            <div>
                                <CardComponent 
                                    emptyText="+ Add Member"
                                    emptyCardHeight="112px"
                                    onClick={() => {
                                        if(eventDetails?.addTeamMember)
                                        {
                                            setAddMemberSidebarOpen(true); 
                                            setSelectedTeamMember(undefined);
                                        }
                                    }}
                                />
                            </div>
                        </CustomTooltip>
                    </Grid>
                </Grid>
            </div>

            {
                addMemberSidebarOpen &&
                    <PortalSidedrawer 
                        component={<AddTeamMember handleClose={() => setAddMemberSidebarOpen(false)} setRefresh={setRefresh} existingMemberData={selectedTeamMember} />}
                        handleClose={() => { setAddMemberSidebarOpen(false); }}
                        heading={selectedTeamMember ? "Remove Member" : "Add Team Member"}
                        open={addMemberSidebarOpen}
                        containerWidth="440px"
                    />
            }
        </div>
    )
};

export default PortalTeam;