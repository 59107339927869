export enum EventBudgetTags
    {
    MARKETING = 1,
    SALES = 2,
    DESIGN = 3,
    BOOTHS = 4,
    VENDORS = 5,
}

export enum EventBudgetStatus
    {
    ACTIVE = 1,
    DELETED = 2
}

export enum EventBudgetAnalysis
{
    UNDER_SPEND = 1,
    OVER_SPEND = 2,
    IN_BUDGET = 3
}

