import CouponsProvider from "../../../../../contexts/EventCoupons/EventCouponsContext";
import TicketsPageProvider from "../../../../../contexts/EventDetailsRegistration/TicketsPageContext";
import CouponsPage from "../../EventTicketCoupons/CouponsPage";
import EventRegistrationDetails from "../EventRegDetails";
import Ticket from "../Tickets/Ticket";
import UtmComponent from "../EventShareLink/UTMTable";
import React, { useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import { Container } from "@mui/material";
import { UserViewType } from "../../../../Settings/enum/users.enum";

import './styles.scss';

const BasicRegistrationsPage: React.FC<{eventId: string | number}> = ({ eventId }): React.JSX.Element =>
{

    const {
        userDetails
    } = useContext(UserContext);

    const renderRegistrationsContent = (): React.JSX.Element => {
        return (
            <>
                <EventRegistrationDetails eventId={Number(eventId)} />
                <TicketsPageProvider eventId={eventId}><Ticket eventId={eventId} /></TicketsPageProvider>
                <CouponsProvider eventId={eventId}><CouponsPage /></CouponsProvider>
                <UtmComponent eventId={eventId} />
            </>
        )
    };

    const renderMinimalViewForRegistrations = (): React.JSX.Element => { 
        return (
            <div id="basicRegistrationsMinimal">
                <Container>
                    <div className="registrations-container">
                        {renderRegistrationsContent()}
                    </div>
                </Container>
            </div>
        )
    };

    const renderNormalViewForRegistrations = (): React.JSX.Element => { 
        return (
            <div id="basicRegistrations">
                <div className="registrations-container">
                    {renderRegistrationsContent()}
                </div>
            </div>
        )
    };

    return (
        <>
            {
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForRegistrations() : renderNormalViewForRegistrations()
            }
        </>
    )
};

export default BasicRegistrationsPage;