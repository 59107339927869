import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Box, Stack, Typography } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import { CONTENT } from '../../scripts/i18n';
import APP_CONSTANTS from '../../scripts/constants';
import { createUser, editUser } from '../../scripts/apis/users';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { CloseIconComponent, FormControlComponent, FormLabelComponent, SelectComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { IManualProps } from './IManual';
import _ from 'lodash';

const Manual : React.FC<IManualProps>=(props): React.JSX.Element => 
{

    const csrfTokenData = useSelector((state): string => 
    { 
        return state['csrfTokenValue'].value.csrfToken;
    });

    const roleOptions = {
        1: 'Member',
        2: 'Admin',
        3: 'Owner',
    };

    const userRoleOptions = [
        {
            id: 1, name: 'Member',
        },
        {
            id: 2, name: 'Admin'
        },
        {
            id: 3, name: 'Owner'
        }
    ];

    const [spinner, setSpinner] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Enter a valid email'),
        role: Yup.number()
            .required('Specify the role of the member')
    });

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: validationSchema,
        initialValues: {
            email: props?.userInfo?.email ? props.userInfo.email : '',
            role: props?.userInfo?.role ? props.userInfo.role : undefined,
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);
        
            try 
            {
                if (!_.isEmpty(props?.userInfo) && props?.userInfo?.role !== formik.values?.role) 
                {
                    const userDetails = {
                        userIds: [String(props?.userInfo.id)],
                        role: formik.values?.role,
                    };
        
                    await editUser(userDetails);
                }
                else 
                {
                    const userDetails = {
                        email: formik.values.email,
                        role: formik.values.role,
                    };
        
                    await createUser(userDetails, csrfTokenData);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally 
            {
                setSpinner(false);
                handleDrawerClose();
                props.setRefresh(true);
            }
        }
    });

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    
        eventBus.dispatch('selected-row-id', null);
    };

    return (
        <Box id='manual'>

            <CloseIconComponent onClick={handleDrawerClose} />

            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Member Email' />
                    <FormControlComponent
                        value={formik.values.email}
                        type="email"
                        placeholder={CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.PLACEHOLDER.EMAIL}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('email', event.target.value?.toLowerCase());
                        }}
                        disabled={!_.isEmpty(props.userInfo)}
                        required
                    />
                    {formik.errors.email && formik.touched.email ? <Typography className="error-msg">{formik.errors.email}</Typography> : null}
                </Box>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Role' />
                    <SelectComponent 
                        value={formik.values.role ? formik.values.role : ''} 
                        options={!props?.userInfo ? userRoleOptions?.filter((item) => item?.name !== 'Owner') : userRoleOptions}
                        defaultPlaceholder={CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.PLACEHOLDER.ROLE}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('role', event.target.value);
                        }} 
                        optionLabels={roleOptions}
                    />
                    {formik.errors.role && formik.touched.role ? <Typography className="error-msg">{formik.errors.role}</Typography> : null}
                </Box>

                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={!(_.isEmpty(props?.userInfo)) ? 'Update Role' : 'Invite'} btnType={'primary'} />
                    </Stack>
                </Box>
            </Form >
        </Box >
    );
};

export default Manual;


{ /* Commented out code needed for reference */ }
{ /* <Grid container>
                                <Grid item xs={12}>
                                    <Box className="d-flex justify-content-between align-items-center mb-0">
                                        <Typography variant="h5" className="form-label">{CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.LABEL.EMAIL}</Typography>
                                    </Box>
                                </Grid>
                            </Grid> */ }

{ /* <Grid container className="container-spacing">
                        <Grid item xs={12}>
                            <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="label-name">{CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.LABEL.FIRST_NAME}</Typography>{<Typography className="required-icon">{'*'}</Typography>}</Box></Form.Label>
                            <Form.Control
                                className="rounded-end text-box"
                                type="text"
                                size="lg"
                                placeholder={CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.PLACEHOLDER.FIRST_NAME}
                                defaultValue={formik.values.firstName}
                                onChange={(event) => {
                                    formik.setFieldValue('firstName', event.target.value);
                                    dispatch(addUsers({
                                        firstName: event.target.value
                                    }));
                                }}
                                name={'firstName'}
                                required
                            />
                        </Grid>
                    </Grid> */ }

{ /* <Grid container className="container-spacing">
                        <Grid item xs={12}>
                            <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="label-name">{CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.LABEL.LAST_NAME}</Typography>{<Typography className="required-icon">{'*'}</Typography>}</Box></Form.Label>
                            <Form.Control
                                className="rounded-end text-box"
                                type="text"
                                size="lg"
                                placeholder={CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.PLACEHOLDER.LAST_NAME}
                                defaultValue={formik.values.lastName}
                                onChange={(event) => {
                                    formik.setFieldValue('lastName', event.target.value);
                                    dispatch(addUsers({
                                        lastName: event.target.value
                                    }));
                                }}
                                name={'lastName'}
                                required
                            />
                        </Grid>
                    </Grid> */ }

{ /* <Grid container className="container-spacing">
                        <Grid item xs={12}>
                            <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="label-name">{CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.LABEL.EMAIL}</Typography>{<Typography className="required-icon">{'*'}</Typography>}</Box></Form.Label>
                            <Form.Control
                                size="lg"
                                className="rounded-end text-box"
                                type="email"
                                defaultValue={formik.values.email}
                                placeholder={CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.PLACEHOLDER.EMAIL}
                                onChange={(event) => {
                                    formik.setFieldValue('email', event.target.value);
                                    dispatch(addUsers({
                                        email: event.target.value
                                    }));
                                }}
                                name={"email"}
                                required />
                        </Grid>
                    </Grid> */ }

{ /* <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="label-name">{CONTENT.SETTINGS_PAGE.USERS.MANUAL.FORMS.LABEL.ROLE}</Typography>{<Typography className="required-icon">{'*'}</Typography>}</Box></Form.Label> */ }

{ /* <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button className='cancel-btn' onClick={handleDrawerClose}>{CONTENT.SIDE_DRAWER.CLOSE_BTN}</Button>
                        <Button type="submit" className="submit-btn">
                            {spinner ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden"></span>
                                </Spinner> : CONTENT.SETTINGS_PAGE.USERS.MANUAL.BUTTONS.SAVE
                            }
                        </Button>
                    </Stack>
                </Box> */ }
