import { createSlice } from '@reduxjs/toolkit';

export const marketingSubSlice = createSlice({
    name: 'marketingSub',
    initialState: {
        value: {
            subject: '',
            emailContent: '',
        }
    },
    reducers: {
        addSubMarketing: (state, actions) => {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetSubMarketing: (state) => {
            state.value = {
                subject: '',
                emailContent: '',
            }
        },
    },
});

export const { addSubMarketing, resetSubMarketing } = marketingSubSlice.actions;
export default marketingSubSlice.reducer;