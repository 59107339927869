import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Button } from '@mui/material';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { CustomButton } from './FormComponents/Buttons';

interface DeletePopupProps {
    modalTitle: string;
    modalContent: string | React.JSX.Element | React.JSX.Element[];
    acceptClick: () => void;
    acceptBtn: string;
    rejectBtn: string;
    cancelClick: () => void;
    show: boolean;
    extraActions?: React.JSX.Element[];
    modalHeaderIcon?: IconName;
}

const DeletePopup: React.FC<DeletePopupProps> = (props): React.JSX.Element => {
    const { modalTitle, modalContent, acceptClick, acceptBtn, rejectBtn, cancelClick, show, extraActions, modalHeaderIcon } = props;

    const cancelButtonStyles: React.CSSProperties = 
    {
        height: '44px',
        maxHeight: '44px',
        fontSize: '16px',
        fontWeight: 600,
        width: '100%'
    };

    const deleteButtonStyles: React.CSSProperties =
    {
        height: '44px',
        maxHeight: '44px',
        fontSize: '16px',
        fontWeight: 600,
        width: '100%'
    };

    return (
        <>
            <Modal dialogClassName="delete-modal" contentClassName="delete-modal-content" show={show} onHide={cancelClick} centered>
                <Modal.Header className="delete-modal-header">
                    {
                        modalHeaderIcon &&
                            <div className="modal-header-icon-container">
                                <div className="header-icon">
                                    <FontAwesomeIcon icon={['fal', modalHeaderIcon]} />
                                </div>
                                <div className="modal-close-icon">
                                    <FontAwesomeIcon icon={['fal', 'xmark']} onClick={cancelClick} />
                                </div>
                            </div>
                    }
                    <div className="delete-modal-title-container">
                        <h3 className="delete-modal-title">{modalTitle}</h3>
                        {typeof (modalContent) === 'string' ? <p className="content">{modalContent}</p> : modalContent}
                    </div>
                </Modal.Header>
                {acceptBtn || rejectBtn || extraActions ?
                    <Modal.Footer className="delete-modal-footer">
                        {rejectBtn ? <CustomButton width="100%" style={cancelButtonStyles} btnType="secondary" name={rejectBtn} onClick={cancelClick} /> : ''}
                        {acceptBtn ? <CustomButton width="100%" style={deleteButtonStyles} btnType="danger" name={acceptBtn} onClick={acceptClick} /> : ''}
                        {
                            extraActions ? extraActions : ''
                        }
                    </Modal.Footer> : ''}
            </Modal>
        </>
    );
};
export default DeletePopup;
