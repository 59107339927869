import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, DroppableProvided } from "react-beautiful-dnd";
import { RegistrationForm } from "../interfaces/registration-form_interface";
import { FormControlComponent, SelectComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { Form } from "react-bootstrap";
import { CustomField } from "../interfaces/custom-field_interface";
import { CustomFieldMandatory, CustomFieldType } from "../enum/custom-field.enum";
import eventBus from "../../../scripts/event-bus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateRegistrationForm } from "../../../scripts/apis/registrationForm";
import _ from "lodash";
import { updateLeadCaptureForm } from "../../../scripts/apis/sponsorPortal/sponsorPortal";
import toast from "react-hot-toast";
import DatePicker from "../../../common/FormComponents/DatePicker";
import { StrictModeDroppable } from "../Common/StrictModeDroppable";

interface ISelectedFormContentProps
{
    formId: string | number;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    registrationFormData: RegistrationForm;
    leadCaptureFormId?: string | number;
}

interface IDraggableItem {
    index: number;
    movedIndex: number;
    id: string | number;
    data: CustomField;
};

const SelectedFormContent: React.FC<ISelectedFormContentProps> = (props): React.JSX.Element =>
{

    const { formId, setRefresh, registrationFormData } = props;
    const components = registrationFormData?.formFields;

    const [items, setItems] = useState<IDraggableItem[] | [] | undefined>([]);
    const [selectedField, setSelectedField] = useState<IDraggableItem | null>(null);

    const reorder = (list: IDraggableItem[] | [], startIndex: number, endIndex: number) => 
    {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result.map((r, k) => 
        {
            const data = r;
            data['index'] = k;
            return data;
        });
    };

    const onDragEnd = async (result: DropResult): Promise<void> =>
    {
        const updatedItems = reorder(
            items,
            result.source?.index,
            result.destination?.index
        );
        const fields = _.map(updatedItems, 'id');

        const updateFieldPositionInRegistrationForm = async (): Promise<void> =>
        {
            try 
            {
                const formPositionUpdated = await updateRegistrationForm(formId, { fields });
                if(formPositionUpdated)
                {
                    setItems(updatedItems);
                    setRefresh(true);
                    eventBus.dispatch('event-open-form-Component-drawer', {
                        componentType: 0,
                    });
                    setSelectedField(null);
                    toast.success('Component position updated');
                }
            } 
            catch (error) 
            {
                toast.error('Error in updating component position');
            }
        };

        const updateFieldPositionInLeadCaptureForm = async (): Promise<void> =>
        {
            try 
            {
                const fieldPositionUpdated = await updateLeadCaptureForm(props?.leadCaptureFormId, { fields });
                if(fieldPositionUpdated)
                {
                    setItems(updatedItems);
                    setRefresh(true);
                    eventBus.dispatch('event-open-form-Component-drawer', {
                        componentType: 0,
                    });
                    setSelectedField(null);
                    toast.success('Component position updated');
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in updating component position');
            }
        };

        if(props?.leadCaptureFormId)
        {
            updateFieldPositionInLeadCaptureForm();
        }
        else
        {
            updateFieldPositionInRegistrationForm();
        }
    };

    const handleFieldSelect = (element: IDraggableItem) =>
    {
        if(Number(element?.data?.type) >= CustomFieldType?.GDPR_COMMUNICATION_PROCESSING)
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: Number(element?.data?.type),
                data: element?.data,
                setSelectedField: setSelectedField,
            });
            setSelectedField(element);
        }
        else
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: element?.data?.type,
                data: element?.data,
                setSelectedField: setSelectedField,
            });
            setSelectedField(element);
        }
    };

    const handleFieldDelete = async (id: string | number): Promise<void> =>
    {
        const filteredComponentArr = components?.filter((obj) => 
        {
            return obj.id !== id; 
        })?.map((item) => {
            return item.id;
        });

        const deleteFieldInRegistrationForm = async (): Promise<void> =>
        {
            try 
            {
                const registrationFormUpdated = await updateRegistrationForm(formId, { fields: filteredComponentArr });
                if(registrationFormUpdated)
                {
                    setRefresh(true);
                    eventBus.dispatch('event-open-form-Component-drawer', {
                        componentType: 0,
                    });
                    setSelectedField(null);
                    toast.success('Component removed');
                }
            } 
            catch (error) 
            {
                console.log(error);
            }
        };

        const deleteFieldInLeadCaptureForm = async (): Promise<void> =>
        {
            try 
            {
                const fieldDeleted = await updateLeadCaptureForm(props?.leadCaptureFormId, { fields: filteredComponentArr });
                if(fieldDeleted)
                {
                    setRefresh(true);
                    eventBus.dispatch('event-open-form-Component-drawer', {
                        componentType: 0,
                    });
                    setSelectedField(null);
                    toast.success('Component removed');
                }
            } 
            catch (error) 
            {
                toast.error('Error in deleting component');
            }
        };

        if(props?.leadCaptureFormId)
        {
            deleteFieldInLeadCaptureForm();
        }
        else
        {
            deleteFieldInRegistrationForm();
        }
    };

    const handleGdprDelete = async (): Promise<void> =>
    {
        try 
        {
            const gdprDeleted = await updateRegistrationForm(formId, { formGDPR: null });
            if(gdprDeleted)
            {
                setRefresh(true);
                eventBus.dispatch('event-open-form-Component-drawer', {
                    componentType: 0,
                });
                setSelectedField(null);
                toast.success('GDPR deleted');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        const filteredComponentArr = components?.filter((obj): boolean => 
        {
            return (obj.type !== 11 && obj.type !== 12 && obj.type !== 13); 
        });

        const data = filteredComponentArr?.map((item, index): {
            index: number;
            movedIndex: number;
            id: string|number;
            data: any;
        } => 
        {
            return {
                index: index,
                movedIndex: index,
                id: String(item.id),
                data: item,
            }; 
        });
        setItems(data);

        eventBus.on('event-update-form-component', (): void => {
            setSelectedField(null);
        })
    }, [components]);

    return (
        <div id="selectedFormContent">
            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                {(provided: DroppableProvided): React.ReactElement => 
                {
                    const { innerRef, droppableProps } = provided;
                    
                    return(
                        <div
                            id="selectedFormComponents"
                            // padding={gdprComponentOptions?.length > 0 ? '32px 32px 0 32px' : '32px'}
                            ref={innerRef}
                            {...droppableProps}
                        >
                            {items?.map((element, index): React.ReactElement => 
                                {
                                    return(
                                        <Draggable key={index} draggableId={String(index)} index={index}>
                                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot): React.ReactElement => 
                                                {
                                                    const {
                                                        innerRef,
                                                        draggableProps,
                                                        dragHandleProps
                                                    } = provided;

                                                    const label = !(element?.data?.type === CustomFieldType.HEADER || element?.data?.type === CustomFieldType.PARAGRAPH) ? element?.data?.label : '';

                                                    return(
                                                        <div
                                                        ref={innerRef}
                                                        {...draggableProps}
                                                        {...dragHandleProps}
                                                        // style={getItemStyle(
                                                        //     snapshot.isDragging,
                                                        //     draggableProps.style
                                                        // )}
                                                        >
                                                            <div className="field-card-box">
                                                                <div onClick={() => { handleFieldSelect(element) }}>
                                                                    <div className="field-card-content">
                                                                        <div className={selectedField?.index === index ? 'active-content-box' : 'content-box'}>
                                                                            
                                                                            {/* Drag icon */}
                                                                            {
                                                                                selectedField?.index === index ?
                                                                                    <div className="component-drag-container">
                                                                                        <FontAwesomeIcon icon={['fal', 'grip-dots-vertical']} className="component-drag-icon" />
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{ height: '16px', width: '16px' }} />
                                                                            }
                                                                            <div className="selected-field-content">
                                                                                {
                                                                                    label && 
                                                                                    <div className="label-container">
                                                                                        <div className="label" dangerouslySetInnerHTML={{
                                                                                            __html: element?.data?.updatedLabels && element?.data?.updatedLabels[0]?.formId == formId && element?.data?.updatedLabels[0]?.label ? element?.data?.updatedLabels[0]?.label : element.data.label ? element.data.label : ''
                                                                                        }} />
                                                                                        {((element?.data?.fieldMandatory === CustomFieldMandatory.MANDATORY) || (element?.data?.updatedLabels && element?.data?.updatedLabels[0]?.formId == formId && Number(element?.data?.updatedLabels[0]?.fieldMandatory) === CustomFieldMandatory.MANDATORY)) && <p className="required">*</p>}
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    element?.data?.helpText && 
                                                                                    <div className="description" dangerouslySetInnerHTML={{
                                                                                        __html: element?.data?.updatedLabels && element?.data?.updatedLabels[0]?.formId == formId && element?.data?.updatedLabels[0]?.helpText ? element?.data?.updatedLabels[0]?.helpText : element.data?.helpText ? element.data?.helpText : ''
                                                                                    }} />
                                                                                }
                                                                                {/* SingleLine */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.SINGLE_LINE &&
                                                                                    <FormControlComponent type="text" value='' placeholder={element?.data?.updatedLabels && element?.data?.updatedLabels[0]?.formId == formId && element?.data?.updatedLabels[0]?.placeholder ? element?.data?.updatedLabels[0]?.placeholder :  element?.data?.placeholder} disabled />
                                                                                }

                                                                                {/* MultiLine */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.MULTI_LINE &&
                                                                                    <FormControlComponent type="text" value='' placeholder={element?.data?.placeholder} disabled rows={4} as="textarea" />
                                                                                }

                                                                                {/* Checkbox */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.CHECKBOXES &&
                                                                                    <div>
                                                                                        {
                                                                                            element?.data?.fieldOptions && element?.data?.fieldOptions?.length > 0 && element?.data?.fieldOptions.map((option, key): React.ReactElement =>
                                                                                            {
                                                                                                return(
                                                                                                    <React.Fragment key={key}>
                                                                                                        <Form.Check 
                                                                                                            type="checkbox"
                                                                                                            checked={false}
                                                                                                            label={option?.name}
                                                                                                        />
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                }

                                                                                {/* Dropdown */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.DROPDOWN &&
                                                                                    <div>
                                                                                        {
                                                                                            element?.data?.fieldOptions && 
                                                                                            <SelectComponent onChange={() => {}} disabled options={element?.data?.fieldOptions} value={''} defaultPlaceholder={element?.data?.placeholder} />
                                                                                        }
                                                                                    </div>
                                                                                }

                                                                                {/* Date */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.DATE &&
                                                                                    // <MuiDatePicker value="" onChange={() => {}} disabled />
                                                                                    <DatePicker value={null} onChange={() => {}} disabled />
                                                                                }

                                                                                {/* Numeric */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.NUMBER &&
                                                                                    <FormControlComponent type="number" value='' placeholder={element?.data?.placeholder} disabled />
                                                                                }

                                                                                {/* Header */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.HEADER &&
                                                                                    <div dangerouslySetInnerHTML={{
                                                                                        __html: element.data?.heading ? element.data?.heading : ''
                                                                                    }} />
                                                                                }

                                                                                {/* Paragraph */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.PARAGRAPH &&
                                                                                    <div dangerouslySetInnerHTML={{
                                                                                        __html: element.data?.paragraph ? element.data?.paragraph : ''
                                                                                    }} />
                                                                                }

                                                                                {/* Radio */}
                                                                                {
                                                                                    element?.data?.type === CustomFieldType.RADIO &&
                                                                                    <div>
                                                                                        {
                                                                                            element?.data?.fieldOptions && element?.data?.fieldOptions?.length > 0 && element?.data?.fieldOptions.map((option, key): React.ReactElement =>
                                                                                            {
                                                                                                return(
                                                                                                    <React.Fragment key={key}>
                                                                                                        <Form.Check 
                                                                                                            type="radio"
                                                                                                            label={option?.name}
                                                                                                            disabled
                                                                                                        />
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {/* Delete icon */}
                                                                            {
                                                                                selectedField?.index === index && !(selectedField?.data?.default === 'firstName' || selectedField?.data?.default === 'email') ?
                                                                                    <div className="component-delete-container">
                                                                                        <FontAwesomeIcon icon={['fal', 'trash']} className="component-delete-icon" onClick={(event) => { handleFieldDelete(element?.data?.id ?? '') }} />
                                                                                    </div>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        </Draggable>
                                    )
                                }
                            )}
                            {provided?.placeholder}

                            {/* GDPR content */}
                            {registrationFormData?.formGDPR?.gdpr && <div style={{ margin: '24px' }}>
                                <div onClick={() => { handleFieldSelect({ data: registrationFormData?.formGDPR }) }} className={[CustomFieldType.GDPR_COMMUNICATION_PROCESSING, CustomFieldType.GDPR_FORM_SUBMIT_AS_CONSENT, CustomFieldType.GDPR_LEGITIMATE_INTEREST].includes(Number(selectedField?.data?.type)) ? 'active-gdpr-content-box' : 'gdpr-content-box'}>
                                    {
                                        registrationFormData?.formGDPR?.gdpr && registrationFormData?.formGDPR?.gdpr?.length > 0 && registrationFormData?.formGDPR?.gdpr?.map((gdprComponent, index): React.ReactElement =>
                                        {
                                            return(
                                                <div key={index} >
                                                    {gdprComponent?.paragraph && gdprComponent?.paragraph !== '' &&
                                                        <div
                                                            className="gdpr-paragraph"
                                                            dangerouslySetInnerHTML={{
                                                                __html: gdprComponent?.paragraph 
                                                            }}
                                                        />}
                                                    {gdprComponent?.fieldOptions && gdprComponent?.fieldOptions.name !== '' &&
                                                        <Form.Check 
                                                        type="checkbox"
                                                        className="gdpr-checkbox"
                                                        label={<div
                                                            dangerouslySetInnerHTML={{
                                                                __html: gdprComponent?.fieldOptions?.name
                                                            }}
                                                        />}
                                                        checked={false}
                                                    />
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {/* Delete icon */}
                                    {
                                        [CustomFieldType.GDPR_COMMUNICATION_PROCESSING, CustomFieldType.GDPR_FORM_SUBMIT_AS_CONSENT, CustomFieldType.GDPR_LEGITIMATE_INTEREST].includes(Number(selectedField?.data?.type)) ?
                                            <div className="component-delete-container">
                                                <FontAwesomeIcon icon={['fal', 'trash']} cursor={'pointer'} className="component-delete-icon" onClick={(event) => { handleGdprDelete() }} />
                                            </div>
                                            :
                                            <div />
                                    }
                                </div>
                            </div>}
                        </div>
                    )
                }}
                </StrictModeDroppable>
            </DragDropContext>
        </div>
    );
};

export default SelectedFormContent;