import { createSlice } from '@reduxjs/toolkit';

// import moment from 'moment';

// const defaultDate = new Date();
// const defaultDateTimeStamp = moment(defaultDate).format('X');

export const registrationDetailsSlice = createSlice({
    name: 'registrationDetails',
    initialState: {
        value: {
            closeDate: '',
            closeTime: '',
            maximumRegistration: 50,
            message: '',
            // maximumTickets: 0,
            enableWaitlistStatus: 2,
            enableAuthenticationStatus: 2,
            timezone: '',
            waitListCloseDate: '',
            waitListCloseTime: '',
            maximumWaitlist: 50,
            waitlistSuccessMessage: '',
            waitlistUnSuccessMessage: '',
            registerOptions: {
                redirectionUrl: '',
                message: '',
            },
        }
    },
    reducers: {
        addRegistrationDetails: (state, actions):void => 
        {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        }
    },
});

export const { addRegistrationDetails } = registrationDetailsSlice.actions;
export default registrationDetailsSlice.reducer;