import { Avatar, Box, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import './styles.scss';
import useLandingPageData from '../../hooks/registrationPublicData';
import MeetupsLandingPageRegisterComponent from '../MeetupsLandingPage/Components/MeetupsLandingPageRegisterComponent';
// import CustomSpinner from '../../common/CustomSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormPublicAlerts } from '../Registration/RegistrationSubPages/Preview/CheckPreviewLogic';
import MeetupsLandingPageFooter from '../MeetupsLandingPage/Components/MeetupsLandingPageFooter';
import { CONTENT } from '../../scripts/i18n';

import { Toaster } from 'react-hot-toast';

// lexical styles

import '../../lexical/index.css';
import '../../lexical/themes/CommentEditorTheme.css';
import '../../lexical/themes/PlaygroundEditorTheme.css';
import '../../lexical/themes/StickyEditorTheme.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
import logoImg from '../../../public/light/eventhq_light.png';
import InvoiceAndPaymentSuccessPage from '../InvoiceAndPaymentSuccess/InvoiceAndPaymentSuccessPage';
import APP_CONSTANTS from '../../scripts/constants';
import NotFoundPage from '../Registration/Preview/NotFoundPage';

const RegistrationLandingPage = ():React.JSX.Element => 
{
    const [eventData, isPageReady, registrationFormData, newToken, errorPage, screenType, showAlert, eventDateInfo, eventTimeInfo, invoicePage, paymentSuccessPage, invoiceJWT, coupon] = useLandingPageData();
 
    const brandColor = CONTENT.BRAND_COLOR_OBJ[eventData?.brandColor];

    useEffect(() => {
        if(!(isPageReady && (invoicePage || paymentSuccessPage)))
        {
            if(eventData)
            {
                const queryParams = window.location.search;
                const newUrl = `${APP_CONSTANTS.PROTOCOL}://${eventData?.org?.link}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventData?.link}${queryParams}`;
                window.location.href = newUrl;
            }
        }
    }, [eventData, isPageReady, invoicePage, paymentSuccessPage]);

    const RegistrationLandingPageSkeleton = (): React.JSX.Element =>
    {
        return (
            <Box id="registrationLandingPage">
                {screenType === 'desktop' ? 
                    <Box className="desktop-main-container">
                        <Box className="desktop-lp-cont">
                            <Box className="landing-page-content">
                                <Box className="landing-page-heading-cont">
                                    <Skeleton variant="text" width={300} height={20} />
                                    <Skeleton variant="text" width={300} height={20} />
                                    <Box className="venue-details">
                                        <Box className="meetups-timing-cont">
                                            <Box className="date-icon">
                                                <Skeleton variant="rounded" width={30} height={30} />
                                            </Box>
                                            <Box className="meetup-time">
                                                <Box className="meetup-date">
                                                    <Skeleton variant="text" width={100} height={20} />
                                                </Box>
                                                <Box className="meetup-time">
                                                    <Skeleton variant="text" width={100} height={20} />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="meetups-location-cont">
                                            <Box className="location-icon">
                                                <Skeleton variant="rounded" width={30} height={30} />
                                            </Box>
                                            <Box className="meetup-time">
                                                <Skeleton variant="text" width={100} height={20} />
                                                <Skeleton variant="text" width={100} height={20} />
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                                <Box className="landing-page-about lp-about-skeleton-desktop">
                                    <Box className="about-heading-cont">
                                        <Skeleton variant="text" width={300} height={20} />
                                    </Box>
                                    <Box className="lp-about-header-skeleton">
                                        {[...Array(12)]?.map((_, index): React.JSX.Element => 
                                        {
                                            return (
                                                <Box key={index}>
                                                    <Skeleton variant="text" width={300} height={20} className="lp-about-content-skeleton"/>
                                                </Box>
                                            ); 
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="landing-page-banner-block">
                                <Box className="landing-page-banner-cont">
                                    <Skeleton variant="rounded" width={300} height={300} />
                                </Box>
                                <Box className="landing-page-host-and-register lp-host-register-skeleton-wrap">
                                    <Box className="host-component">
                                        <Stack direction="row" spacing={1}>
                                            <Box className="hostLogo">
                                                <Skeleton variant="circular" width={50} height={50} />
                                            </Box>
                                            <Box className="hostDetails">
                                                <Skeleton variant="text" width={215} height={20} />
                                                <Skeleton variant="text" width={215} height={20} />
                                            </Box>
                                        </Stack>
                                        <Skeleton variant="rounded" width={275} height={38} className="lp-register-button-skeleton"/>
                                    </Box>
                                </Box>
                                       
                            </Box>
                        </Box>
                        <Box className="landing-page-footer">
                            <div className="meetupsLandingPageFooterSkeleton">
                                <Grid container>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Box className="meetups-landing-page-footer-container">
                                            <Box className="footer-section">
                                                <Box className="footer-policies-container">
                                                    {
                                                        [...Array(2)]?.map((item, index: number): React.ReactElement => 
                                                        {
                                                            return (
                                                                <Box key={index}>
                                                                    <Skeleton variant="text" width={120} height={20} />
                                                                </Box>
                                                            );
                                                        })
                                                    }
                                                </Box>

                                                <Box className="footer-socials-container">
                                                    <Box className="socials-wrapper">
                                                        {
                                                            [...Array(3)]?.map((item, index: number): React.ReactElement => 
                                                            {
                                                                return (
                                                                    <Box key={index}>
                                                                        <Skeleton variant="circular" width={30} height={30} />
                                                                    </Box>
                                                                );
                                                            })
                                                        }
                                                    </Box>
                                                    <Box className="eventhq-hyperlink-container">
                                                        <Skeleton variant="text" width={200} height={20} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                    </Box> : null}
                {screenType === 'mobile' || screenType === 'tablet' ?
                    <Box className={'mob-main-container'}>
                        <Box className="mobile-lp-cont">
                            <Box className="landing-page-banner-block-mob">
                                <Box className="landing-page-banner-cont-mob">
                                    <Skeleton variant="rounded" width={'100%'} height={300} />
                                </Box>
                            </Box>
                            <Box className="landing-page-content-mob">
                                <Box className="landing-page-heading-cont-mob">
                                    <Skeleton variant="text" width={'100%'} height={20} />
                                    <Skeleton variant="text" width={'100%'} height={20} />
                                    <Box className="venue-details-mob">
                                        <Box className="meetups-timing-cont">
                                            <Box className="date-icon">
                                                <Skeleton variant="rounded" width={30} height={30} />
                                            </Box>
                                            <Box className="meetup-time">
                                                <Box className="meetup-date">
                                                    <Skeleton variant="text" width={200} height={20} />
                                                </Box>
                                                <Box className="meetup-time">
                                                    <Skeleton variant="text" width={200} height={20} />
                                                </Box>
                                            </Box>

                                        </Box>
                                        <Box className="meetups-location-cont">
                                            <Box className="location-icon">
                                                <Skeleton variant="rounded" width={30} height={30} />
                                            </Box>
                                            <Box className="meetup-time">
                                                <Box className="meetup-time">
                                                    <Skeleton variant="text" width={200} height={20} />
                                                </Box>
                                                <Box className="meetup-time">
                                                    <Skeleton variant="text" width={200} height={20} />
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>

                                </Box>
                                <Box className="register-component-mob">
                                    <Skeleton variant="rounded" width={'100%'} height={38} className="lp-register-button-skeleton"/>
                                </Box>

                                <Box className="host-component-mob lp-host-register-skeleton-wrap-mob">
                                    <Stack direction="row" spacing={1}>
                                        <Box className="hostLogo">
                                            <Skeleton variant="circular" width={50} height={50} />
                                        </Box>
                                        <Box className="hostDetails">
                                            <Skeleton variant="text" width={215} height={20} />
                                            <Skeleton variant="text" width={215} height={20} />
                                        </Box>
                                    </Stack>
                                </Box>
                                
                                <Box className="landing-page-about-mob">
                                    <Box className="about-heading-cont-mob">
                                        <Skeleton variant="text" width={300} height={20} />
                                    </Box>
                                    <Box className="lp-about-header-skeleton">
                                        {[...Array(5)]?.map((_, index): React.JSX.Element => 
                                        {
                                            return (
                                                <Box key={index}>
                                                    <Skeleton variant="text" width={300} height={20} className="lp-about-content-skeleton"/>
                                                </Box>
                                            ); 
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="landing-page-footer">
                            <div className="meetupsLandingPageFooterSkeleton">
                                <Grid container>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Box className="meetups-landing-page-footer-container">
                                            <Box className="footer-section">
                                                <Box className="footer-policies-container">
                                                    {
                                                        [...Array(2)]?.map((item, index: number): React.ReactElement => 
                                                        {
                                                            return (
                                                                <Box key={index}>
                                                                    <Skeleton variant="text" width={80} height={20} />
                                                                </Box>
                                                            );
                                                        })
                                                    }
                                                </Box>

                                                <Box className="footer-socials-container">
                                                    <Box className="socials-wrapper">
                                                        {
                                                            [...Array(2)]?.map((item, index: number): React.ReactElement => 
                                                            {
                                                                return (
                                                                    <Box key={index}>
                                                                        <Skeleton variant="circular" width={30} height={30} />
                                                                    </Box>
                                                                );
                                                            })
                                                        }
                                                    </Box>
                                                    <Box className="eventhq-hyperlink-container">
                                                        <Skeleton variant="text" width={100} height={20} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                    </Box>:null}
            </Box>
        );
    };

    // return (
    //     <>
    //         {errorPage ? <NotFoundPage /> : !invoicePage && !paymentSuccessPage &&
    //             <Box id='registrationLandingPage' style={{
    //                 backgroundColor: brandColor?.pageBackground 
    //             }}>
    //                 {isPageReady ? <>
    //                     {eventData?.landingPageLayout === 1 && screenType === 'desktop' ?
    //                         <Box className="desktop-main-container">
    //                             <Box className="logo-header" style={{
    //                                 backgroundColor: brandColor?.pageBackground 
    //                             }}>
    //                                 {eventData?.org?.additionalInfo?.image ? <img src={eventData?.org?.additionalInfo?.image} className="org-logo-image" /> : <img src={logoImg} className="org-logo-image" />}
    //                             </Box>
    //                             <Box className="desktop-lp-cont">
    //                                 <Box className="landing-page-content">
    //                                     <Box className="landing-page-heading-cont" style={{
    //                                         backgroundColor: brandColor?.cardBackground, border: `1px solid ${brandColor?.cardBorder}` 
    //                                     }}>
    //                                         {eventData?.title && <Typography style={{
    //                                             color: brandColor?.title 
    //                                         }} className="landing-page-heading">{eventData.title}</Typography>}
    //                                         {eventData?.description && <Typography style={{
    //                                             color: brandColor?.description 
    //                                         }} className="landing-page-desc">{eventData.description}</Typography>}
    //                                         <Box className="venue-details">
    //                                             <Box className="meetups-timing-cont">
    //                                                 <Box style={{
    //                                                     border: `1px solid ${brandColor?.cardBorder}` 
    //                                                 }} className="date-icon">
    //                                                     <FontAwesomeIcon style={{
    //                                                         color: brandColor?.locationAndCalenderIcon 
    //                                                     }} icon={['fal', 'calendar']} />
    //                                                 </Box>
    //                                                 <Box className="meetup-time">
    //                                                     <Box className="meetup-date"><Typography style={{
    //                                                         color: brandColor?.dateLine1 
    //                                                     }} className="meetups-date-text">{eventDateInfo}</Typography></Box>
    //                                                     <Box className="meetup-time"> <Typography style={{
    //                                                         color: brandColor?.dateLine2 
    //                                                     }} className="meetups-time-text">{eventTimeInfo}</Typography></Box>
    //                                                 </Box>

    //                                             </Box>
    //                                             <Box className="meetups-location-cont">
    //                                                 <Box className="location-icon" style={{
    //                                                     border: `1px solid ${brandColor?.cardBorder}` 
    //                                                 }}>
    //                                                     {eventData.locationType === 2 ? <FontAwesomeIcon style={{
    //                                                         color: brandColor?.locationAndCalenderIcon 
    //                                                     }} icon={['fal', 'video']} /> : <FontAwesomeIcon style={{
    //                                                         color: brandColor?.locationAndCalenderIcon 
    //                                                     }} icon={['fal', 'location-dot']} />}

    //                                                 </Box>
    //                                                 <Box className="meetup-time">
    //                                                     <Tooltip title={eventData?.locationDetails?.name ? eventData?.locationDetails?.name : null}><a style={{
    //                                                         color: brandColor?.addressLine1 
    //                                                     }} className="meetups-venue-main-text" href={eventData?.locationDetails?.url ? eventData?.locationDetails?.url : '-'} target='_blank' rel="noreferrer">
    //                                                         {eventData?.locationDetails?.name ? <p>{eventData?.locationDetails?.name?.substring(0, 36)}{eventData?.locationDetails?.name?.length > 36 && '...'}</p> : '-'}
    //                                                     </a></Tooltip>
    //                                                     <Typography style={{
    //                                                         color: brandColor?.addressLine2 
    //                                                     }} className="meetups-venue-sub-text">{eventData?.country ? eventData?.country : '-'}</Typography>
    //                                                 </Box>

    //                                             </Box>
    //                                         </Box>

    //                                     </Box>
    //                                     {eventData.about &&
    //                                         <Box className="landing-page-about" style={{
    //                                             backgroundColor: brandColor?.cardBackground, border: `1px solid ${brandColor?.cardBorder}` 
    //                                         }}>
    //                                             <Box className="about-heading-cont" style={{
    //                                                 borderBottom: `1px solid ${brandColor?.cardBorder}` 
    //                                             }}>
    //                                                 <Typography style={{
    //                                                     color: brandColor?.aboutCardTitle 
    //                                                 }} className="about-text">{'About Event'}</Typography>
    //                                             </Box>
    //                                             <div className="about-content" dangerouslySetInnerHTML={{
    //                                                 __html: eventData.about 
    //                                             }}
    //                                             style={{
    //                                                 color: `${brandColor?.aboutContent}`
    //                                             }}
    //                                             >
    //                                             </div>
    //                                         </Box>
    //                                     }

    //                                 </Box>
    //                                 <Box className="landing-page-banner-block">
    //                                     <Box className="landing-page-banner-cont">
    //                                         {eventData?.eventBanner && <img src={eventData?.eventBanner} className="landing-page-banner" />}
    //                                     </Box>
    //                                     <Box className="landing-page-host-and-register">
    //                                         <Box className="host-component"  sx={{
    //                                             border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
    //                                         }}>
    //                                             <HostComp org={eventData?.org} brandColor={brandColor} />
    //                                         </Box>
    //                                         <Box className="register-component"  sx={{
    //                                             border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
    //                                         }}>
    //                                             <MeetupsLandingPageRegisterComponent coupon={coupon} eventData={eventData} registrationFormData={registrationFormData} token={newToken} />
    //                                         </Box>
    //                                     </Box>
                                       
    //                                 </Box>

    //                             </Box>

    //                             <Box className="landing-page-footer">
    //                                 <MeetupsLandingPageFooter brandColor={eventData?.brandColor} footerSocials={eventData?.org?.social} footerLinks={eventData?.eventFooterLinks} screenType={screenType} />
    //                             </Box>
    //                         </Box>
    //                         : (eventData?.landingPageLayout === 1 || eventData?.landingPageLayout === 2) && (screenType === 'mobile' || screenType === 'tablet') ?
    //                             <Box className={'mob-main-container'}>
    //                                 <Box className="logo-header" style={{
    //                                     backgroundColor: brandColor?.pageBackground 
    //                                 }}>
    //                                     {eventData?.org?.additionalInfo?.image ? <img src={eventData?.org?.additionalInfo?.image} className="org-logo-image" /> : <img src={logoImg} className="org-logo-image" />}
    //                                 </Box>
    //                                 <Box className="mobile-lp-cont">
    //                                     <Box className="landing-page-banner-block-mob">
    //                                         <Box className={eventData?.landingPageLayout === 1 ? 'landing-page-banner-cont-mob' : 'landing-page-banner-cont-mob-16-9'}>
    //                                             {eventData?.eventBanner && <img src={eventData?.eventBanner} className={eventData?.landingPageLayout === 1 ? 'landing-page-banner-mob' : 'landing-page-banner-mob-16-9'} />}
    //                                         </Box>

    //                                     </Box>
    //                                     <Box className="landing-page-content-mob">
    //                                         <Box className="landing-page-heading-cont-mob" style={{
    //                                             backgroundColor: brandColor?.cardBackground, border: `1px solid ${brandColor?.cardBorder}` 
    //                                         }}>
    //                                             {eventData?.title && <Typography style={{
    //                                                 color: brandColor?.title 
    //                                             }} className="landing-page-heading-mob">{eventData.title}</Typography>}
    //                                             {eventData?.description && <Typography style={{
    //                                                 color: brandColor?.description 
    //                                             }} className="landing-page-desc-mob">{eventData.description}</Typography>}
    //                                             <Box className="venue-details-mob">
    //                                                 <Box className="meetups-timing-cont">
    //                                                     <Box style={{
    //                                                         border: `1px solid ${brandColor?.cardBorder}` 
    //                                                     }} className="date-icon">
    //                                                         <FontAwesomeIcon style={{
    //                                                             color: brandColor?.locationAndCalenderIcon 
    //                                                         }} icon={['fal', 'calendar']} />
    //                                                     </Box>
    //                                                     <Box className="meetup-time">
    //                                                         <Box className="meetup-date"><Typography style={{
    //                                                             color: brandColor?.dateLine1 
    //                                                         }} className="meetups-date-text">{eventDateInfo}</Typography></Box>
    //                                                         <Box className="meetup-time"> <Typography style={{
    //                                                             color: brandColor?.dateLine2 
    //                                                         }} className="meetups-time-text">{eventTimeInfo}</Typography></Box>
    //                                                     </Box>

    //                                                 </Box>
    //                                                 <Box className="meetups-location-cont">
    //                                                     <Box style={{
    //                                                         border: `1px solid ${brandColor?.cardBorder}` 
    //                                                     }} className="location-icon">
    //                                                         <FontAwesomeIcon style={{
    //                                                             color: brandColor?.locationAndCalenderIcon 
    //                                                         }} icon={['fal', 'location-dot']} />
    //                                                     </Box>
    //                                                     <Box className="meetup-time">
    //                                                         <Tooltip title={eventData?.locationDetails?.name ? eventData?.locationDetails?.name : null}><a style={{
    //                                                             color: brandColor?.addressLine1 
    //                                                         }} className="meetups-venue-main-text" href={eventData?.locationDetails?.url ? eventData?.locationDetails?.url : '-'} target='_blank' rel="noreferrer">
    //                                                             {eventData?.locationDetails?.name ? <p>{eventData?.locationDetails?.name?.substring(0, 36)}{eventData?.locationDetails?.name?.length > 36 && '...'}</p> : '-'}
    //                                                         </a></Tooltip>
    //                                                         <Typography style={{
    //                                                             color: brandColor?.addressLine2 
    //                                                         }} className="meetups-venue-sub-text">{eventData?.country ? eventData?.country : '-'}</Typography>
    //                                                     </Box>

    //                                                 </Box>
    //                                             </Box>

    //                                         </Box>
    //                                         <Box className="register-component-mob" sx={{
    //                                             border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
    //                                         }}>
    //                                             <MeetupsLandingPageRegisterComponent coupon={coupon} eventData={eventData} registrationFormData={registrationFormData} token={newToken} />
    //                                         </Box>

    //                                         <Box className="host-component-mob" sx={{
    //                                             border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
    //                                         }}>
    //                                             <HostComp org={eventData?.org} brandColor={brandColor} />
    //                                         </Box>
    //                                         {eventData.about &&
    //                                             <Box className="landing-page-about-mob" style={{
    //                                                 backgroundColor: brandColor?.cardBackground, border: `1px solid ${brandColor?.cardBorder}` 
    //                                             }}>
    //                                                 <Box className="about-heading-cont-mob" style={{
    //                                                     borderBottom: `1px solid ${brandColor?.cardBorder}` 
    //                                                 }}>
    //                                                     <Typography style={{
    //                                                         color: brandColor?.aboutCardTitle 
    //                                                     }} className="about-text">{'About Event'}</Typography>
    //                                                 </Box>
    //                                                 <div className="about-content-mob" dangerouslySetInnerHTML={{
    //                                                     __html: eventData.about 
    //                                                 }}>

    //                                                 </div>
    //                                             </Box>
    //                                         }

    //                                     </Box>

    //                                 </Box>
    //                                 <Box className="landing-page-footer-mob">
    //                                     <MeetupsLandingPageFooter brandColor={eventData?.brandColor} footerSocials={eventData?.org?.social} footerLinks={eventData?.eventFooterLinks} screenType={screenType} />
    //                                 </Box>

    //                             </Box>
    //                             : eventData?.landingPageLayout === 2 && screenType === 'desktop' &&
    //                             <Box className="full-width-desktop-main-container">
    //                                 <Box className="logo-header" style={{
    //                                     backgroundColor: brandColor?.pageBackground 
    //                                 }}>
    //                                     {eventData?.org?.additionalInfo?.image ? <img src={eventData?.org?.additionalInfo?.image} className="org-logo-image" /> : <img src={logoImg} className="org-logo-image" />}
    //                                 </Box>
    //                                 <Box className="full-width-desktop-lp-cont">
    //                                     <Box className="landing-page-banner-block-full">
    //                                         <Box className="landing-page-banner-cont-full">
    //                                             {eventData?.eventBanner && <img src={eventData?.eventBanner} className="landing-page-banner-desktop-full" />}
    //                                         </Box>
    //                                     </Box>
    //                                     <Box className="landing-page-content-full">
    //                                         <Box className="landing-page-heading-cont-full">
    //                                             <Box className="landing-page-heading-sub-cont" style={{
    //                                                 backgroundColor: brandColor?.cardBackground, border: `1px solid ${brandColor?.cardBorder}` 
    //                                             }}>
    //                                                 {eventData?.title && <Typography style={{
    //                                                     color: brandColor?.title 
    //                                                 }} className="landing-page-heading">{eventData.title}</Typography>}
    //                                                 {eventData?.description && <Typography style={{
    //                                                     color: brandColor?.description 
    //                                                 }} className="landing-page-desc">{eventData.description}</Typography>}
    //                                                 <Box className="venue-details">
    //                                                     <Box className="meetups-timing-cont">
    //                                                         <Box style={{
    //                                                             border: `1px solid ${brandColor?.cardBorder}` 
    //                                                         }} className="date-icon">
    //                                                             <FontAwesomeIcon style={{
    //                                                                 color: brandColor?.locationAndCalenderIcon 
    //                                                             }} icon={['fal', 'calendar']} />
    //                                                         </Box>
    //                                                         <Box className="meetup-time">
    //                                                             <Box className="meetup-date"><Typography style={{
    //                                                                 color: brandColor?.dateLine1 
    //                                                             }} className="meetups-date-text">{eventDateInfo}</Typography></Box>
    //                                                             <Box className="meetup-time"> <Typography style={{
    //                                                                 color: brandColor?.dateLine2 
    //                                                             }} className="meetups-time-text">{eventTimeInfo}</Typography></Box>
    //                                                         </Box>

    //                                                     </Box>
    //                                                     <Box className="meetups-location-cont">
    //                                                         <Box style={{
    //                                                             border: `1px solid ${brandColor?.cardBorder}` 
    //                                                         }} className="location-icon">
    //                                                             {eventData.locationType === 2 ? <FontAwesomeIcon style={{
    //                                                                 color: brandColor?.locationAndCalenderIcon 
    //                                                             }} icon={['fal', 'video']} /> : <FontAwesomeIcon style={{
    //                                                                 color: brandColor?.locationAndCalenderIcon 
    //                                                             }} icon={['fal', 'location-dot']} />}

    //                                                         </Box>
    //                                                         <Box className="meetup-time">
    //                                                             <Tooltip title={eventData?.locationDetails?.name ? eventData?.locationDetails?.name : null}><a style={{
    //                                                                 color: brandColor?.addressLine1 
    //                                                             }} className="meetups-venue-main-text" href={eventData?.locationDetails?.url ? eventData?.locationDetails?.url : '-'} target='_blank' rel="noreferrer">
    //                                                                 {eventData?.locationDetails?.name ? <p>{eventData?.locationDetails?.name?.substring(0, 36)}{eventData?.locationDetails?.name?.length > 36 && '...'}</p> : '-'}
    //                                                             </a></Tooltip>
    //                                                             <Typography style={{
    //                                                                 color: brandColor?.addressLine2 
    //                                                             }} className="meetups-venue-sub-text">{eventData?.country ? eventData?.country : '-'}</Typography>
    //                                                         </Box>

    //                                                     </Box>
    //                                                 </Box>
    //                                             </Box>

    //                                             {eventData.about &&
    //                                                 <Box className="landing-page-about-16-9" style={{
    //                                                     backgroundColor: brandColor?.cardBackground, border: `1px solid ${brandColor?.cardBorder}` 
    //                                                 }}>
    //                                                     <Box className="about-heading-cont-16-9" style={{
    //                                                         borderBottom: `1px solid ${brandColor?.cardBorder}` 
    //                                                     }}>
    //                                                         <Typography style={{
    //                                                             color: brandColor?.aboutCardTitle 
    //                                                         }} className="about-text">{'About Event'}</Typography>
    //                                                     </Box>
    //                                                     <div className="about-content-16-9" dangerouslySetInnerHTML={{
    //                                                         __html: eventData.about 
    //                                                     }}>

    //                                                     </div>
    //                                                 </Box>
    //                                             }
    //                                         </Box>
    //                                         <Box className="landing-page-host-and-register">
    //                                             <Box className="host-component-16-9" sx={{
    //                                                 border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
    //                                             }}>
    //                                                 <HostComp org={eventData?.org} brandColor={brandColor} />
    //                                             </Box>
                                                
    //                                             <Box className="register-component-16-9" sx={{
    //                                                 border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
    //                                             }}>
    //                                                 <MeetupsLandingPageRegisterComponent coupon={coupon} eventData={eventData} registrationFormData={registrationFormData} token={newToken} />
    //                                             </Box>
    //                                         </Box>
    //                                     </Box>

    //                                 </Box>
    //                                 <Box className="landing-page-footer-16-9">
    //                                     <MeetupsLandingPageFooter brandColor={eventData?.brandColor} footerSocials={eventData?.org?.social} footerLinks={eventData?.eventFooterLinks} screenType={screenType} />
    //                                 </Box>
    //                             </Box>

    //                     }


    //                 </> : <RegistrationLandingPageSkeleton />}

    //                 {/* {showAlert?.show && (
    //                     <FormPublicAlerts
    //                         open={true}
    //                         message={showAlert.message}
    //                         severity={showAlert.severity}
    //                         positionVertical={'bottom'}
    //                         positionHorizontal={'center'}
    //                     />
    //                 )} */}

    //             </Box>
    //         }

    //         {isPageReady && (invoicePage || paymentSuccessPage) && <div style={{ height: '100%' }}>
    //             <InvoiceAndPaymentSuccessPage paymentSuccessPage={paymentSuccessPage} invoiceJWT={invoiceJWT} eventData={eventData} eventTimeInfo={eventTimeInfo} eventDateInfo={eventDateInfo} screenType={screenType} />
    //         </div>}
        
    //     <Toaster />
    //     </>
    // );
    return (
        <>
            {
                errorPage ? <NotFoundPage /> : 
                <>
                    {isPageReady && (invoicePage || paymentSuccessPage) && <div style={{ height: '100%' }}>
                        <InvoiceAndPaymentSuccessPage paymentSuccessPage={paymentSuccessPage} invoiceJWT={invoiceJWT} eventData={eventData} eventTimeInfo={eventTimeInfo} eventDateInfo={eventDateInfo} screenType={screenType} />
                    </div>}
                </>
            }
        </>
    )
};

export default RegistrationLandingPage;

const HostComp = (props):React.JSX.Element => 
{
    const fetchOrgLogo = props?.org?.social && props?.org?.social?.website && props?.org?.social?.website !== null ? `https://www.google.com/s2/favicons?sz=64&domain_url=${props.org?.social?.website}` : null;
    const brandColor = props?.brandColor;

    return (
        <Box id="hostComp" 
        // style={{
        //     border: `1px solid ${brandColor?.cardBorder}`, backgroundColor: brandColor?.cardBackground
        // }}
        >
            <Box className="hostLogo">
                {fetchOrgLogo && fetchOrgLogo !== null ?
                    <Avatar className="host-logo-img" src={fetchOrgLogo} /> :
                    <Avatar className="host-logo-img">{props?.org?.name?.charAt(0)?.toUpperCase()}</Avatar>
                }
            </Box>
            <Box className="hostDetails">
                <Box style={{
                    color: brandColor?.hostedText 
                }} className="hostText">{'Hosted by'}</Box>
                <Box style={{
                    color: brandColor?.companyName 
                }} className="hostName">{props?.org?.name}</Box>
            </Box>
        </Box>
    );
};