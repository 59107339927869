import React from 'react';
import './styles.scss';

interface SquareTypeProps {
    selected:boolean;
}

const SquareType = ({ selected }: SquareTypeProps): React.JSX.Element => 
{
    return (
        <div id="squareType">
            <div className="content-box">
                <div className="content-text">{'Your title will be here'}</div>
            </div>
            <div className="event-banner-box">
                <div className={selected ? 'selected-event-banner-image':'event-banner-image'}>
                    <div className="dimension-text">{'Poster 1:1'}</div>
                </div>
                <div className="register-btn-box">{'Register'}</div>
            </div>
        </div>
    );
};

export default SquareType;