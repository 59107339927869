import { EventTargetListSourceType, EventTargetListType } from "../../../enum/event-target-list.enum";

export const sourceTypeMapping: Record<number, string> = {
    [EventTargetListSourceType.MANUAL]: 'Manual',
    [EventTargetListSourceType.IMPORT_CSV]: 'Import CSV',
    [EventTargetListSourceType.PAST_EVENT]: 'Previous Events',
    [EventTargetListSourceType.APOLLO]: 'Apollo',
    [EventTargetListSourceType.HUBSPOT]: 'HubSpot',
    [EventTargetListSourceType.AUDIENCE]: 'Audience'
};

export const sourceColorMapping = {
    [EventTargetListSourceType.MANUAL]: 'pink',
    [EventTargetListSourceType.IMPORT_CSV]: 'green',
    [EventTargetListSourceType.PAST_EVENT]: 'violet',
    [EventTargetListSourceType.AUDIENCE]: 'red',
    [EventTargetListSourceType.APOLLO]: 'yellow',
    [EventTargetListSourceType.HUBSPOT]: 'orange',
};

export const audienceFilterColumns = [
    {
        header: 'First Name',
        id: 'firstName',
        meta: {
            type: 'string'
        }
    },
    {
        header: 'Last Name',
        id: 'lastName',
        meta: {
            type: 'string'
        }
    },
    {
        header: 'Email',
        id: 'email',
        meta: {
            type: 'string'
        }
    },
];

export const targetListFilterColoumns = [
    {
        header: 'List Name',
        id: 'name',
        meta: 
        {
            type: 'string',
        },
    },
    {
        header: 'Source',
        id: 'sourcesArr',
        meta: 
        {
            type: 'dropdown',
            filterOptions: Object.values(EventTargetListSourceType)
                .filter((value) => typeof value === 'number' && value !== EventTargetListSourceType.MANUAL)
                .map((value) => 
                {
                    return {name: sourceTypeMapping[value], value: value.toString() };
                }),
        },
    },
    {
        header: 'Type',
        id: 'type',
        meta: 
        {
            type: 'dropdown',
            filterOptions: Object.values(EventTargetListType)
                .filter((value) => typeof value === 'number')
                .map((value) => 
                {
                    return {name: value === EventTargetListType.STATIC ? 'Static' : 'Dynamic', value: value.toString()};
                }),
        },
    },
];