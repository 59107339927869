import { createContext, useEffect, useState } from "react";
import { useTablePagination } from "../TablePaginationContext";
import { deleteCustomField, getCustomField, getCustomFieldCount } from "../../scripts/apis/customField";
import { CustomField } from "../../pages/Registration/interfaces/custom-field_interface";
import toast from "react-hot-toast";
import { getEncodedParams } from "../../scripts/helpers";

interface ICustomFieldPageContext { 
    isEmpty: boolean;
    customFieldCount: number;
    rows: CustomField[];
    setRows: React.Dispatch<React.SetStateAction<CustomField[]>>;
    customFields: CustomField[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    deleteCustomFieldFromTable: (customField: CustomField) => void;
    refresh: boolean;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (value: number) => void;
    updatePageSize: (value: number) => void;
    showSpinner: boolean;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomField: CustomField | null;
    setSelectedCustomField: React.Dispatch<React.SetStateAction<CustomField | null>>;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomFieldPageContext = createContext<ICustomFieldPageContext>({
    isEmpty: false,
    customFieldCount: 0,
    rows: [],
    setRows: () => {},
    customFields: [],
    setRefresh: () => {},
    deleteCustomFieldFromTable: () => {},
    refresh: false,
    pageSize: 0,
    currentPage: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    selectedCustomField: null,
    setSelectedCustomField: () => {},
    setCurrentUrl: () => {}
});

const CustomFieldPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [customFieldCount, setCustomFieldCount] = useState<number>(0);
    const [customFields, setCustomField] = useState<CustomField[]>([]);
    const [rows, setRows] = useState<CustomField[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedCustomField, setSelectedCustomField] = useState<CustomField | null>(null);
    const [currentUrl, setCurrentUrl] = useState<string>('');

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, customFieldCount])

    useEffect(() => {
        if (refresh) {
            fetchData();
        }
    }, [refresh])

    useEffect((): void =>
    {
        fetchTotalCustomFieldCount();
    }, [currentUrl]); 

    const fetchTotalCustomFieldCount = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getCustomFieldCount(undefined, encodedParams);
            if (count) 
            {
                setCustomFieldCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async () =>
    {
        if (customFieldCount)
        {
            try 
            {
                const encodedParams = getEncodedParams(currentUrl);
                const customFieldData = await getCustomField(pageSize, currentPage - 1, undefined, undefined, encodedParams);
                if (customFieldData) {
                    setCustomField([...customFieldData]);
                    setRows([...customFieldData]);
                    setRefresh(false);
                }
            }
            catch (error) 
            {
                console.log(error)
            }
            finally 
            {
                setShowSpinner(false);
            }
        } 
    }

    const deleteCustomFieldFromTable = async (customField: CustomField) => 
    {
        try 
        {
            const customFieldDeleted = await deleteCustomField(customField.id as string);
            if (customFieldDeleted) {
                setShowDeletePopup(false);
                setSelectedCustomField(null);
                toast.success('Custom Field deleted successfully');
                setRefresh(true);
            }
        } 
        catch (error)
        {
            console.log(error);
            if((error as Error)?.message === 'CUSTOM FIELD IS IN USE IN FORMS')
            {
                toast.error("Can't delete custom field as it is used in a form");
            }
            else
            {
                toast.error((error as Error)?.message);
            }
        }           
    };
    
    return (
        <CustomFieldPageContext.Provider 
            value={{ 
                isEmpty, 
                customFieldCount, 
                rows,
                setRows,
                customFields,
                setRefresh, 
                deleteCustomFieldFromTable, 
                refresh, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                showSpinner,
                showDeletePopup,
                setShowDeletePopup,
                selectedCustomField,
                setSelectedCustomField,
                setCurrentUrl 
            }}
        >
            {children}
        </CustomFieldPageContext.Provider>
    );
}

export default CustomFieldPageProvider;