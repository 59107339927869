import { useEffect, useState } from "react";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { getAllLeadsInSponsorPortal, getSponsorPortalLeadsCount } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import _ from "lodash";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from 'file-saver';
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { convertKeysToTitleCase } from "../../../../scripts/helpers";
import { LocalStorage } from "../../../../scripts/LocalStorage";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { registrantFilterColumnOptions, registrantInitialColumnData, useRegistrantColumnHelper } from "../../../Events/EventDetails/People/EventRegistrants/registrantColumn.helper";
import { EventLeadCapture } from "../../../Events/interfaces/event-lead-capture_interface";
import { useLocation, useNavigate } from "react-router-dom";

import './styles.scss';
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";

const PortalLeads: React.FC = (): React.JSX.Element => {

    const searchParams = new URLSearchParams();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [leadCount, setLeadCount] = useState<number>(0);
    const [leadRows, setLeadRows] = useState<EventLeadCapture[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [currentUrl, setCurrentUrl] = useState<string>('');

    const [csvConversionSpinner, setCsvConversionSpinner] = useState<boolean>(false);

    const columns =
        useRegistrantColumnHelper(
            registrantInitialColumnData.filter(
                item => !['email', 'status', 'paymentStatus', 'attendeeStatus', 'ticket', 'shareLink', 'actions'].includes(item.field)), undefined, undefined, undefined, undefined, undefined, leadRows);

    const sponsorData = LocalStorage.get('@SponsorDetails');

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const LeadsToolbar = () => {
        const [selectedTab, setSelectedTab] = useState<'all'>('all');
        const tabs = [
            {
                tabName: 'All',
                selectedTab: selectedTab === 'all',
                count: leadCount,
                navigation: (): void => {
                    setSelectedTab('all');
                }
            },
        ];

        return (
            <div className="leads-toolbar">
                {/* <FormTabs tabs={tabs} /> */}
                {sponsorData && <CustomButton loading={csvConversionSpinner} onClick={fetchAllLeadsData} name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'file-export']} />} />}
                {
                    <TanstackToolbarWithServerFilter
                        columns={columns}
                        filterColumns={registrantFilterColumnOptions(undefined, undefined, undefined, true, true)}
                        handleApplyFilters={handleApplyFilters}
                        setCurrentUrl={setCurrentUrl}
                        minimalView={true}
                    />
                }
            </div>
        )
    };

    const fetchData = async (): Promise<void> => {
        if (!pageSize) return;
        try {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query.forEach((value, key) => {
                params.push([key, value]);
            });

            const encodedParams: [string, any][] = params?.map(([key, value]) => [key, encodeURIComponent(value)]);

            const count = await getSponsorPortalLeadsCount(...encodedParams);
            if (count) {
                setLeadCount(count);

                const leads = await getAllLeadsInSponsorPortal(pageSize, (currentPage - 1), undefined, undefined, undefined, ...encodedParams);

                if (leads) {
                    setIsEmpty(leads.length === 0);
                    setLeadRows(leads);

                    const initialColumnKeys = ['firstName', 'lastName'];
                    const initialColumns = initialColumnKeys.map((field) => ({
                        field,
                        headerName: _.startCase(field),
                        disableColumnMenu: true,
                    }));

                    if (leads?.length > 0 && leads.some(obj => 'leadData' in obj) || leads.some(obj => 'registrantData' in obj)) {
                        const uniqueKeysSet = new Set();

                        leads.forEach(obj => {
                            if (obj?.leadData) {
                                Object.keys(obj.leadData).forEach(key => {
                                    uniqueKeysSet.add(key); // Adds key to set, ensuring uniqueness
                                });
                            }

                            if (obj?.registrantData) {
                                Object.keys(obj.registrantData).forEach(key => {
                                    uniqueKeysSet.add(key); // Adds key to set, ensuring uniqueness
                                });
                            }
                        });

                        const uniqueKeys = Array.from(uniqueKeysSet);

                        // const leadData = leads.map(obj => obj?.leadData);
                        // const leadDataKeys = Object.keys(leadData[0]);
                        // const leadDataColumns = leadDataKeys.map((field) => ({
                        //     field,
                        //     headerName: _.startCase(field),
                        //     disableColumnMenu: true,
                        // }));
                    }
                }
            }
        }
        catch (error) {

        }
        finally {
            setShowSpinner(false);
        }
    };

    const fetchAllLeadsData = async (): Promise<void> => {
        setCsvConversionSpinner(true);
        try {

            const count = await getSponsorPortalLeadsCount();
            if (count) {
                const leads = await getAllLeadsInSponsorPortal(count, 0);

                if (leads) {
                    const mergedArr = _.map(leads, (obj): any => {
                        const { leadData, registrantData, ...prevItems } = obj;

                        const registrantDataWithoutGdpr = _.omit(registrantData, ['gdprData']);

                        const capturedKey = {
                            capturedAt: moment(obj?.created).format('DD/MM/YYYY HH:mm A'),
                        }

                        const mergedObj = _.merge(prevItems, capturedKey, registrantDataWithoutGdpr, obj?.leadData);

                        const omittedKeys = ['id', 'sponsorId', 'capturedAudienceId', 'additionalData', 'userId', 'type', 'audienceId', 'eventTicketId', 'eventId', 'utmId', 'icp', 'buyerPersona', 'modified', 'created', 'orgId', 'status', 'gdprData', 'refundSuccess', 'refundStatus', 'refund', 'paymentSuccess', 'paymentStatus', 'paymentLink', 'lumaRegistrantId', 'eventSpeakerIdMap', 'eventSponsorIdMap', 'confirmedId', 'activityLog', 'couponId', 'checkedInUserId', 'checkedInAccessControlId'];
                        const objAfterOmittedKeys = _.omit(mergedObj, omittedKeys);
                        return convertKeysToTitleCase(objAfterOmittedKeys);
                    });

                    if (mergedArr) {

                        const allKeys = mergedArr.reduce((keys, obj) => {
                            Object.keys(obj).forEach(key => {
                                keys.add(key);
                            });
                            return keys;
                        }, new Set());

                        // Ensure each object has all keys
                        const completeData = mergedArr.map(obj => {
                            allKeys.forEach((key: string | number) => {
                                if (!obj.hasOwnProperty(key)) {
                                    obj[key] = ''; // Add missing keys as empty strings
                                }
                            });
                            return obj;
                        });

                        // function toNormalCaseWithSpaces(key: any) {
                        //     // Convert snake_case to camelCase for uniformity, then to normal case with spaces
                        //     return _.startCase(_.camelCase(key));
                        // }

                        // // Function to process the array of objects (e.g., leads)
                        // const processArray = (array: any) => {
                        //     return array.map((item: string) => {
                        //         // Convert each key of the object
                        //         return _.mapKeys(item, (value, key) => toNormalCaseWithSpaces(key));
                        //     });
                        // };

                        // const caseConvertedData = processArray(completeData);

                        // if(caseConvertedData)
                        // {
                        //     const csvData = jsonToCSV(caseConvertedData);
                        //     const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                        //     saveAs(blob, 'leads.csv');
                        // }

                        const csvData = jsonToCSV(completeData);
                        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                        saveAs(blob, 'leads.csv');

                    }
                }
            }

        }
        catch (error) {

        }
        finally {
            setCsvConversionSpinner(false);
        }
    };

    useEffect((): void => {
        fetchData();
    }, [currentPage, pageSize, currentUrl]);

    return (
        <div id="portalLeads">
            <div style={{ height: 'calc(100vh - 300px)' }}>
                {/* {!isEmpty ? ( */}
                <div className="portal-table">
                    <LeadsToolbar />
                    <TanstackTable
                        data={leadRows}
                        initialColumns={columns}
                        checkboxSelection
                        rowCount={leadCount}
                        pageSize={pageSize}
                        currentPage={currentPage - 1}
                        updatePageSize={updatePageSize}
                        updateCurrentPage={updateCurrentPage as any}
                        leftPinnedColumns={['companyLogo', 'firstName']}
                        showSpinner={showSpinner}
                        height="calc(100vh - 340px)"
                    />
                </div>
                {/* ) : (
                    <TableEmptyComponent
                        infoText={'No Leads available'}
                    />
                )
                } */}
            </div>
        </div>
    );
};

export default PortalLeads;