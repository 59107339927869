'use client'

import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { EventPreview } from "../../../Events/interfaces";
import { useLocation } from "react-router-dom";
import { ImageType } from "../../../Events/interfaces/event-photo-sharing_interface";
import useScreenDimension from "../../../../hooks/screenDimensions";
import CustomSkeleton from "../../../../common/CustomSkeleton";
import PopUpView from "../../../../common/PopupView";
import EmblaCarousel from "../../Components/embla/EmblaCarousel";
import { DownloadImage } from "../../Components/Photos/DownloadImageButton";
import APP_CONSTANTS from "../../../../scripts/constants";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import './styles.scss';
import { getEventPhotos } from "../../../../scripts/apis/registrationPublic";

interface DefaultPhotosComponentProps
{
    eventData: EventPreview | undefined;
}

const DefaultPhotosComponent: React.FC<DefaultPhotosComponentProps> = (props) =>
{

    const { eventData } = props;

    const isUploadsPath = useLocation()?.pathname?.includes('your-photos');

    const [defaultPhotos, setDefaultPhotos] = useState<ImageType[]>([]);
    const [offset, setOffset] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [imgPreview, setImgPreview] = useState<ImageType | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const screenType = useScreenDimension();

    const fetchDefaultInitialPhotos = async () =>
    {
        if (eventData && eventData?.token)
        {
            try 
            {
                const data = await getEventPhotos(eventData?.token as string, 50, 0);
                if(data)
                {
                    setDefaultPhotos(data);
                }
            } 
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setIsLoading(false);
            }
        }
    };

    const handlePopupClose = () =>
    {   
        setPopupOpen(false);
    };

    const fetchMoreDefaultPhotos = async (): Promise<void> =>
    {
        try 
        {
            const data = await getEventPhotos(eventData?.token as string, 50, offset);
            if(data)
            {
                setHasMore(data?.length === 50);
                let currentOffset = offset;
                currentOffset = currentOffset + 1;
                setOffset(currentOffset);
                setDefaultPhotos((prevItems) => {
                    const photos = [...prevItems, ...data];
                    const newData = _.union(photos);
                    return newData;
                });
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => 
    {
        fetchDefaultInitialPhotos();
    }, [eventData]);

    return(
        <>
            {isLoading ? <div /> : defaultPhotos?.length > 0 ? <div id="defaultPhotos" style={{ paddingBottom: (screenType === 'mobile' && isUploadsPath) ? '112px' : '' }}>
                <h3 className="heading">{!isLoading && `${eventData?.title} Photos`}</h3>
                <InfiniteScroll
                    dataLength={defaultPhotos?.length}
                    pullDownToRefreshThreshold={50}
                    next={fetchMoreDefaultPhotos}
                    hasMore={hasMore}
                    loader={<div/>}
                    scrollableTarget="defaultPhotos"
                    height={'calc(100vh - 64px)'}
                    // endMessage={'No more photos to show'}
                >
                    <div className="photos-container">
                        {defaultPhotos?.length > 0 && defaultPhotos?.map((image, index) => {
                            return (
                                <div className="image-wrapper" key={index}>
                                    <LazyLoadImage effect="blur" placeholder={<CustomSkeleton />} className="default-img" onClick={() => { setImgPreview(image); setPopupOpen(true); setSelectedIndex(index); }} style={{ maxHeight: screenType === 'mobile' ? '116px' : '160px' }} src={image?.webUrl || ''} key={index} alt='' />
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll> 
            </div> : 
            <div className="empty-state">
                {/* <h3>
                    No photos present
                </h3> */}
            </div>}
            {popupOpen && 
                <PopUpView message={'Long press the photo to save.'} extraButtons={screenType === 'mobile' ? undefined : [<DownloadImage downloadableUrl={imgPreview?.downloadableUrl || ''} fileName={`${eventData?.title} photo`} key={Math.random()} />]} screenType={screenType as 'mobile'|'desktop'|'tablet'} onClose={() => { setPopupOpen(false); }}>
                    <EmblaCarousel screenType={screenType as 'mobile' | 'desktop' | 'tablet'} handlePopupClose={handlePopupClose} eventData={eventData} images={defaultPhotos} initialIndex={selectedIndex} /> 
                </PopUpView>
            }
        </>
    )
};

export const Skeleton: React.FC<{ screenType: string }> = ({ screenType }): React.JSX.Element =>
{
    return (
        <div className="skeleton-container">
            {Array.from({ length: screenType === 'mobile' ? 8 : 16 }).map((_, index) => ( 
                <CustomSkeleton key={index} animation='pulse' height={screenType === 'mobile' ? 120 : 160} variant='rect' width={screenType === 'mobile' ? 80 : 200} />
            ) )}
        </div>
    )
};

export default DefaultPhotosComponent;