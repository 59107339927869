import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Navbar } from 'react-bootstrap';

import eventHqLogo from '../../public/faviconLight.png';

const DemoAppBar = (): React.JSX.Element => 
{
    return (
        <div id="demoAppBar">
            <Navbar expand="lg" className="demo-nav-bar">
                <Box className="logo-info-cont">
                    <Box className="logo-img-cont">
                        <Box component="img" src={eventHqLogo} className="demo-logo-img" />
                        <Typography className="title">{'DEMO'}</Typography>
                    </Box>
                    <Typography className="demo-header-text">{'Welcome to the EventHQ Demo.'}</Typography>
                </Box>
                <Box className="demo-nav-button-cont">
                    <Typography className="demo-content-text">{'PS: This is a demo instance, application might be slower than actual.'}</Typography>
                    <Button className="demo-nav-button" onClick={(): void => 
                    {
                        window.open('https://www.eventhq.com/demo'); 
                    }}>{'Get In Touch'}</Button>
                </Box>
            </Navbar>
        </div>
    );
};

export default DemoAppBar;