import React, { useEffect, useMemo, useState } from "react";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { Grid } from "@mui/material";
import CardComponent from "../../../../components/Events/Registrations/CardComponent";
import { getAllBuyerPersonaSponsorPortal, getbuyerPersonaSponsorPortalCount } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { useDispatch } from "react-redux";
import { resetBuyerPersona } from "../../../../redux/settings/BuyerPersona";
import BuyerPersona from "../../../../components/BuyerPersona/AddBuyerPersona";
import PortalSidedrawer from "../../Components/Common/PortalSidedrawer";

import './styles.scss'

const SponsorsBPPage: React.FC = (): React.JSX.Element =>
{

    const dispatch = useDispatch();

    const [bpCount, setBpCount] = useState<number>(0);
    const [bpData, setBpData] = useState<any[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedBpData, setSelectedBpData] = useState<any>(undefined);
    const [openBpSidebar, setOpenBpSidebar] = useState<boolean>(false);

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const buyerPersonaCount = await getbuyerPersonaSponsorPortalCount();
            if(buyerPersonaCount)
            {
                setBpCount(buyerPersonaCount);
                const bpData = await getAllBuyerPersonaSponsorPortal(buyerPersonaCount, 0);
                if(bpData)
                {
                    setBpData(bpData);
                }
            }
        } 
        catch (error) 
        {
            
        }
        finally
        {

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh])

    return (
        <div id="sponsorsBpPage">
            <div className="bp-card-container">
                <div className="heading-div">
                    <div className="heading-wrapper">
                        <h2 className="heading">{'Buyer Persona'}</h2>
                        <MuiChip chipColor='grey' label={bpCount || '-'} />
                    </div>
                    {/* <CustomButton name='' btnType='secondary' startIcon={<FontAwesomeIcon icon={['fal', 'table-list']} />} onClick={() => { undefined } } /> */}
                </div>
                <Grid container spacing={2}>
                    {
                        bpData && bpData?.length > 0 && bpData?.map((item, index) => {

                            return (
                                <Grid item xl={3} lg={4} md={4} sm={12} xs={12} key={index}>
                                    <CardComponent 
                                        cardMinHeight="84px"
                                        header={item?.name}
                                        headerBold
                                        contentHeading={item?.description}
                                        onClick={() => { 
                                            setSelectedBpData(item);
                                            dispatch(resetBuyerPersona());
                                            setOpenBpSidebar(true);
                                        }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                        <CardComponent 
                            emptyText="+ Add Buyer Persona"
                            emptyCardHeight="50px"
                            cardMinHeight="62px"
                            onClick={() => { 
                                setOpenBpSidebar(true); 
                                dispatch(resetBuyerPersona());
                                setSelectedBpData(undefined);
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            {
                openBpSidebar &&
                    <PortalSidedrawer 
                        component={<BuyerPersona setRefresh={setRefresh} buyerPersonaData={selectedBpData} handleDrawerClose={() => setOpenBpSidebar(false)} sponsorPortal={true} />}
                        handleClose={() => { setOpenBpSidebar(false); }}
                        heading="Add Buyer Persona"
                        open={openBpSidebar}
                        containerWidth="440px"
                    />
            }
        </div>
    )
};

export default SponsorsBPPage;