import { Tooltip } from "@mui/material";
import APP_CONSTANTS from "../scripts/constants";

interface CustomTooltipProps { 
    title: string | React.ReactNode; 
    children: React.ReactElement<any, any>; 
}

const CustomTooltip: React.FC<CustomTooltipProps> = (props): React.JSX.Element =>
{

    const { title, children } = props;

    return (
        <Tooltip title={title} arrow placement={APP_CONSTANTS.TOOLTIP_PLACEMENT}>
            {children}
        </Tooltip>
    )
};

export default CustomTooltip;