import { createSlice } from '@reduxjs/toolkit';

export const formFieldObj = {
    id: 0,
    type: 0,
    subType: 0,
    label: '',
    helpText: '',
    default: null,
    placeholder: '',
    typeAttribute: '',
    numericLimitAccept: false,
    componentGDPR: [],
    componentNumeric: {
        defaultValue: '',
        min: 0,
        max: 0,
    },
    componentMandatory: 0,
    componentDisabled: 0,
    componentOptions: [],
    heading: '',
    paragraph: '',
};

export const formComponentDisplayField = createSlice({
    name: 'formComponentDisplayField',
    initialState: {
        value: {
            index: null,
            attribute: formFieldObj,
            isGdprActive: false,
        }
    },
    reducers: {
        addFormField: (state, action):void => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        updateComponentIndex: (state, action):void => 
        {
            state.value.index = action.payload.index;
        },
        resetFormField: (state):void => 
        {
            state.value = {
                index: null,
                attribute: formFieldObj,
                isGdprActive: false
            };
        },
        updateGDPRComponent: (state, action):void => 
        {
            const data = state.value.attribute.componentGDPR[action.payload.index];
            if (action.payload.name === 'paragraph') 
            {
                data[action.payload.name] = action.payload.value;
            }
            else if (action.payload.name === 'componentOptions') 
            {
                data[action.payload.name].name = action.payload.value;
                data[action.payload.name].value = action.payload.value;
            }
        },
        addComponentOption: (state):void => 
        {
            state.value.attribute.componentOptions.push({
                name: '',
                value: '',
            });
        },
        updateComponentOption: (state, action):void => 
        {
            const options = state.value.attribute.componentOptions;
            options[action.payload.optionIndex][action.payload.name] = action.payload.value;
            // options[action.payload.optionIndex][action.payload.name] = action.payload.value;
        },
        removeComponentOption: (state, actions):void => 
        {
            state.value.attribute.componentOptions.splice(actions.payload.optionIndex, 1);
        },
    },
});



export const { addFormField, resetFormField, updateGDPRComponent, updateComponentOption, addComponentOption, removeComponentOption, updateComponentIndex } = formComponentDisplayField.actions;
export default formComponentDisplayField.reducer;