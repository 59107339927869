/* eslint-disable import/named */

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { addRegistrationDetails } from '../redux/events/eventRegistrations/RegistrationDetails';
import moment from 'moment';
import { getEventById } from '../scripts/apis/events';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { Event } from '../pages/Events/interfaces';
import { getAlleventTickets, ticketsCount } from '../scripts/apis/eventTickets';

interface IEventRegistrationDetailHookProps {
    eventId: string | number;
}

export const registerOptions = [
    {
        id: 1, option: 'Display Message', name: 'message'
    },
    {
        id: 2, option: 'Redirect to URL', name: 'redirectionUrl'
    }
];
//type1 = free type 2 = paid
//privateticket 1 = private , 2 = public
// enableapproval 1 = true , 2 = false

const useEventRegisreationDetailsData = (props: IEventRegistrationDetailHookProps): [Event, boolean, boolean, boolean, boolean] => 
{


    const eventId = props.eventId;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [eventDetails, setEventDetails] = useState(null);
    const [spinner, setSpinner] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [isEventTicketPresent, setIsEventTicketPresent] = useState(false);
    const [isTicketApprovalOn, setIsTicketApprovalOn] = useState(false);
    const [isTicketPaid, setIsTicketPaid] = useState(false);

    const fetchTicketCount = async (): Promise<void> => 
    {
        try 
        {
            const ticketCount = await ticketsCount(String(eventId));
            if (ticketCount && ticketCount > 0) 
            {
                setIsEventTicketPresent(true);
                const eventTickets = await getAlleventTickets(ticketCount, 0, eventId);
                if(eventTickets)
                {
                    eventTickets.forEach((ticket):void => 
                    {
                        if(Number(ticket.enableApproval) === 1)
                        {
                            setIsTicketApprovalOn(true);
                        }
                        if(Number(ticket.type) === 2)
                        {
                            setIsTicketPaid(true);
                        }
                    });
                }
            }
        }
        catch (error) 
        {
            console.log(error);
            setIsEventTicketPresent(false);
        }
    
    };

    useMemo((): () => void => 
    {
        const fetchData = async (): Promise<Event> => 
        {
            setSpinner(true);
            try 
            {
                const eventsData = await getEventById(String(eventId));

                if (eventsData) 
                {
                    setEventDetails(eventsData);
                    setSpinner(false);
                    setRefresh(false);
                    dispatch(addRegistrationDetails({
                        maximumRegistration: eventsData.maximumRegistration,
                        // maximumTickets: eventsData.maximumTickets,
                        enableWaitlistStatus: eventsData.enableWaitlistStatus,
                        enableAuthenticationStatus: eventsData.enableAuthenticationStatus,
                        timezone: eventsData.timezone,
                        maximumWaitlist: eventsData.maximumWaitlist,
                        closeDate: eventsData.closingDateTime !== null ? moment.unix(eventsData.closingDateTime).format('YYYY-MM-DD') : '',
                        closeTime: eventsData.closingDateTime !== null ? moment.unix(eventsData.closingDateTime).format('HH:mm') : '',
                        waitListCloseDate: eventsData.waitlistClosingDateTime !== 0 ? moment.unix(eventsData.waitlistClosingDateTime).format('YYYY-MM-DD') : '',
                        waitListCloseTime: eventsData.waitlistClosingDateTime !== 0 ? moment.unix(eventsData.waitlistClosingDateTime).format('HH:mm') : '',
                        waitlistSuccessMessage: eventsData.waitlistSuccessMessage,
                        waitlistUnSuccessMessage: eventsData.waitlistUnSuccessMessage,
                        status: eventsData.status,
                        registerOptions: eventsData?.registerOptions,
                        unsuccessMessage: eventsData.registerUnSuccessMessage,
                    }));

                    return eventsData;

                }
            }
            catch (error) 
            {
                console.log(error);
            }
        };
        if(refresh)
        {
            fetchData();
            fetchTicketCount();
        }
        return fetchData;
    },
    [eventId, refresh]
    );

    useEffect((): void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });
        eventBus.on('event-details-refreshed', (data): void => 
        {
            if (data.refresh) 
            {
                setRefresh(data.refresh);
            }
        });
    }, []);


    return [eventDetails, spinner, isEventTicketPresent, isTicketApprovalOn, isTicketPaid];

};

export default useEventRegisreationDetailsData;