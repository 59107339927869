import { createContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteVendor, getAllVendors, updateVendor, vendorsCount } from '../scripts/apis/vendors';
import { useTablePagination } from './TablePaginationContext';
import { IVendors } from '../components/Vendors/IVendors';
import toast from 'react-hot-toast';
import { eventVendorCount, getAllEventVendors, removeEventVendor } from '../scripts/apis/eventVendor';
import { useLocation } from 'react-router-dom';
import { Vendor } from '../pages/Vendors/interfaces';
import { getEncodedParams } from '../scripts/helpers';

interface IVendorsContextProps {
    rows: IVendors[] | Vendor[];
    vendorsDataCount: number;
    isEmpty: boolean;
    vendors: IVendors[] | Vendor[];
    setRows: React.Dispatch<React.SetStateAction<IVendors[]>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    deleteVendorsFromTable: (vendorData: IVendors) => void;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (value: number) => void;
    updatePageSize: (value: number) => void;
    showSpinner: boolean;
    editVendorsFromTable: (vendorsData: any) => void;
    selectedVendor: IVendors | null;
    showDeletePopup: boolean;
    setSelectedVendor: React.Dispatch<React.SetStateAction<IVendors | null>>;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    cardView?: boolean;
    isTableView?: boolean;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const VendorsContext = createContext<IVendorsContextProps>({
    rows: [],
    vendorsDataCount: 0,
    isEmpty: true,
    vendors: [],
    setRows: () => {},
    setRefresh: () => {},
    deleteVendorsFromTable: () => {},
    pageSize: 0,
    currentPage: 1,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    editVendorsFromTable: () => {},
    selectedVendor: null,
    showDeletePopup: false,
    setSelectedVendor: () => {},
    setShowDeletePopup: () => {},
    cardView: false,
    isTableView: false,
    setCurrentUrl: () => {},
});

const VendorsProvider: React.FC<{ children: React.ReactNode; eventId?: string | number; cardView?: boolean; }> = ({ children, eventId, cardView }):React.JSX.Element => 
{
    const [rows, setRows] = useState<IVendors[] | Vendor[]>([]);
    const [vendorsDataCount, setVendorsDataCount] = useState<number>(0);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [vendors, setVendors] = useState<IVendors[] | Vendor[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [selectedVendor, setSelectedVendor] = useState<IVendors | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const currentpath = useLocation().pathname;
    const isTableView = ((currentpath?.includes('vendors') && eventId && !cardView)) as boolean;

    const fetchTotalVendorCount = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await vendorsCount(encodedParams);
            if (count) 
            {
                setVendorsDataCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchTotalEventVendorCount = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await eventVendorCount(eventId as string, undefined, undefined, encodedParams);
            if (count) 
            {
                setVendorsDataCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        setShowSpinner(true);
        if (vendorsDataCount) 
        {
            try 
            {
                const encodedParams = getEncodedParams(currentUrl);
                const vendorsData = await getAllVendors(pageSize, (currentPage - 1), encodedParams);
                if (vendorsData) 
                {
                    setRows([...vendorsData]);
                    setVendors([...vendorsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setShowSpinner(false);
            }
        }
    };

    const fetchEventVendorData = async ():Promise<void> => 
    {
        setShowSpinner(true);
        if (vendorsDataCount) 
        {
            try 
            {
                const encodedParams = getEncodedParams(currentUrl);
                if(isTableView)
                {
                    const eventVendorData = await getAllEventVendors(pageSize, currentPage - 1, eventId as string, undefined, undefined, encodedParams);
                    if (eventVendorData) 
                    {
                        if (eventVendorData) 
                        {
                            setVendors([...eventVendorData]);
                            setRows([...eventVendorData]);
                            setShowSpinner(false);
                            setRefresh(false);
                        }
                    }
                }
                else
                {
                    const eventVendorData = await getAllEventVendors(8, 0, eventId as string);
                    if (eventVendorData) 
                    {
                        if (eventVendorData) 
                        {
                            setRows([...eventVendorData]);
                            setVendors([...eventVendorData]);
                            setShowSpinner(false);
                            setRefresh(false);
                        }
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setShowSpinner(false);
            }
        }
    };

    useEffect(():void => 
    {
        if (eventId)
        {
            fetchEventVendorData();
        }
        else
        {
            fetchData();
        }
    }, [currentPage, pageSize, vendorsDataCount]);

    useEffect(():void => 
    {
        if (eventId)
        {
            fetchEventVendorData();
        }
        else
        {
            fetchData();
        }
    }, [refresh]);

    useEffect((): void =>
    {
        if (eventId)
        {
            fetchTotalEventVendorCount();
        }
        else
        {
            fetchTotalVendorCount();
        }
    }, [currentUrl]); 

    const deleteVendorsFromTable = async (vendorData: IVendors):Promise<void> => 
    {
        if (eventId)
        {
            try 
            {
                const vendorRemoved = await removeEventVendor(eventId, vendorData?.id);
                if (vendorRemoved) 
                {
                    toast.success(`Vendor ${vendorData.name} deleted successfully`);
                    setSelectedVendor(null);
                    setShowDeletePopup(false);
                    setRefresh(true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error((error as Error)?.message);
            }
        }
        else
        {
            try 
            {
                const vendorDeleted = await deleteVendor(vendorData?.id);
                if (vendorDeleted) 
                {
                    toast.success(`Vendor ${vendorData.name} deleted successfully`);
                    setSelectedVendor(null);
                    setShowDeletePopup(false);
                    setRefresh(true);
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error((error as Error)?.message);
            }           
        }
    };

    const editVendorsFromTable = async (vendorsData):Promise<void> => 
    {
        const linkedInUrlRegex = new RegExp(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/);
        if(vendorsData?.linkedIn && !(linkedInUrlRegex.test(vendorsData.linkedIn)))
        {
            toast.error('Please enter a valid LinkedIn URL');
            return;

        }
        else
        {
            const vendorDetails = {
                name: vendorsData.name,
                email: vendorsData.email,
                vendorName: vendorsData.vendorName,
                description: vendorsData.description,
                social:{
                    linkedIn: vendorsData.linkedIn? vendorsData.linkedIn : '' 
                },
            };
           
            try 
            {
                const vendorsUpdated = await updateVendor(vendorDetails, vendorsData.id);
                if (vendorsUpdated) 
                {
                    setRefresh(true);
                }
    
            }
            catch (error) 
            {
                console.log(error);
            }
        }
       
    };

    return (
        <VendorsContext.Provider 
            value={{
                rows, 
                vendorsDataCount, 
                isEmpty, 
                vendors, 
                setRows, 
                setRefresh, 
                deleteVendorsFromTable, 
                pageSize, 
                currentPage, 
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                editVendorsFromTable,
                selectedVendor,
                showDeletePopup,
                setSelectedVendor,
                setShowDeletePopup,
                cardView,
                isTableView, 
                setCurrentUrl 
            }}
        >
            {children}
        </VendorsContext.Provider>
    );
};

export default VendorsProvider;