import APP_CONSTANTS from "../constants";
import { triggerApi } from "../helpers";

export const deleteEventbriteIntegration = async () => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/eventbrite';
    return triggerApi(API_URL, 'DELETE', '');
};

export const syncEventbriteData = async () =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/eventbrite/sync';
    return triggerApi(API_URL, 'GET', '');
};