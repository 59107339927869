import React from "react";
import Charts from "../../../../../../common/ECharts/Charts";
import CustomSpinner from "../../../../../../common/CustomSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface GraphComponentProps {
    chartOption: object;
    height: string;
    title: string;
    graphReady?: boolean;
    isDataAvailable?: boolean;
}

export const GraphComponent: React.FC<GraphComponentProps> = ({ chartOption, height, title, graphReady, isDataAvailable }): React.JSX.Element => {
    return (
        <div className="analytics-graph-component">
            <h3 className="graph-heading">{title}</h3>
            {graphReady ? (
                isDataAvailable ? (
                    <Charts
                        chartOption={chartOption}
                        height={height}
                    />
                ) : (
                    <div style={{ height }} className="noData">
                        <FontAwesomeIcon icon={['fal', 'chart-line']} />
                        <p>No data available</p>
                    </div>
                )
            ) : (
                <CustomSpinner height={height} />
            )}
        </div>
    )
};