import { Col, Row } from "react-bootstrap";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";
import { IRevenueGraphObject } from "./revenueAnalytics.interface";
import EventAnalyticsTable from "../Common/EventAnalyticsTable";

import './styles.scss';

interface RevenuePerformanceProps { 
    performanceDataInDays: IRevenueGraphObject[];
    performanceDataInPercentage: IRevenueGraphObject[];
}

const RevenuePerformance: React.FC<RevenuePerformanceProps>  = (props): React.JSX.Element =>
{

    const { performanceDataInDays, performanceDataInPercentage } = props;

    const transformPerformanceData = (data: { [key: string]: any }[], specialChar?: string): { [key: string]: any }[] => {
        if (data.length === 0) {
            return [];
        }
    
        const keys = Object.keys(data[0]).filter(key => key !== 'name');
        const transformedData = keys.map(key => {
            const value = data[0][key];
            return {
                name: key,
                total: value > 0 ? `${value} ${specialChar || ''}` : '-'
            };
        });
        return transformedData;
    };

    const transformOpportunityToClosedWonData = (data: { [key: string]: any }[]): { name: string, total: string }[] => {
        const opportunityToClosedWon = data.find(item => item?.name === "Opportunity to Closed Won");
    
        if (!opportunityToClosedWon) {
            return [];
        }
    
        const keys = Object.keys(opportunityToClosedWon).filter(key => key !== 'name');
        const transformedData = keys.map(key => {
            const value = opportunityToClosedWon[key];
            return {
                name: key,
                total: value > 0 ? `${value} days` : '-'
            };
        });
    
        return transformedData;
    };

    const addSpecialCharToValues = (data: { [key: string]: any }[], specialChar: string): { [key: string]: any }[] => {
        return data.map(item => {
            const newItem: { [key: string]: any } = {};
            Object.keys(item).forEach(key => {
                if (typeof item[key] === 'number' && item[key] > 0) {
                    newItem[key] = `${item[key]} ${specialChar}`;
                } else if (typeof item[key] === 'number') {
                    newItem[key] = '-';
                } else {
                    newItem[key] = item[key];
                }
            });
            return newItem;
        });
    };

    return (
        <div id="eventPerformanceAnalytics">
            <AnalyticsPageHeader 
                header='Performance'
                description='Understand you deal performance and win rates'
            />

            {/* Tables containing performance analytics in days */}
            <Row className="custom-row">
                <Col className="px-0" xl={7} lg={7}>
                    <EventAnalyticsTable 
                        columns={['Name', 'New', 'Influenced', 'Others']}
                        rows={addSpecialCharToValues(performanceDataInDays, 'days')}
                        notATable={false}
                    />
                </Col>

                <Col className="px-0">
                    <EventAnalyticsTable 
                        rows={transformOpportunityToClosedWonData(performanceDataInDays)}
                        tableHeader='Opportunity to Closed Won Timeline'
                        notATable={true}
                    />
                </Col>
            </Row>

            {/* Tables containing performance analytics in percentage */}
            <Row className="custom-row">
                <Col className="px-0">
                    <EventAnalyticsTable 
                        notATable={true}
                        tableHeader='Win Rate'
                        rows={transformPerformanceData(performanceDataInPercentage, '%')}
                    />
                </Col>

                <Col className="px-0" xl={7} lg={7}>
                    <EventAnalyticsTable 
                        columns={['Name', 'New', 'Influenced', 'Others']}
                        rows={addSpecialCharToValues(performanceDataInPercentage, '%')}
                        notATable={false}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RevenuePerformance;