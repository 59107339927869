import { CustomFieldLocations, CustomFieldOrder } from '../../pages/Registration/enum/custom-field.enum';
import { CustomField } from '../../pages/Registration/interfaces/custom-field_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getCustomFieldCount = async (fieldLocation?: number, queryParams: [string, string][] = []) => {

    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    if (fieldLocation) {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/custom-field/count?&fieldLocation=' + fieldLocation + '&' + query;
        return triggerApi(API_URL, 'GET', '');
    } else {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/custom-field/count' + '?' + query;
        return triggerApi(API_URL, 'GET', '');
    }

};

export const getCustomFieldFromFieldId = async (fieldId: number) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/custom-field/' + fieldId;
    return triggerApi(API_URL, 'GET', '');
};

export const getCustomField = async (pageSize: number, offset: number, fieldLocation?: CustomFieldLocations, orderBy?: CustomFieldOrder, queryParams: [string, any][] = []) => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        fieldLocation,
        orderBy,
        ...filteredParams,
    })
    
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/custom-field?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const addCustomField = async (fieldData: any, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + "/custom-field";
    return triggerApi(API_URL, "POST", fieldData, csrfToken);
};

export const updateCustomField = async (fieldId: number, fieldData: any) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + "/custom-field/" + fieldId;
    return triggerApi(API_URL, "PUT", fieldData);
};

export const deleteCustomField = async (fieldId: string | number) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + "/custom-field/" + fieldId;
    return triggerApi(API_URL, 'DELETE', '');
};

export const searchCustomField = async (propertyKey: string, fieldLocation?: CustomFieldLocations): Promise<CustomField[]> =>
{
    const query = convertJSONToGETParams({
        propertyKey,
        fieldLocation
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/custom-field/search?' + query;
    return triggerApi(API_URL, 'GET', '');
};