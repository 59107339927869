import { createSlice } from '@reduxjs/toolkit';

export const icpRowSlice = createSlice({
    name: 'icp',
    initialState: {
        value: {
            name: '', description: '', subIndustry: null, employeesRange: null, estimatedAnnualRevenue: null, country: null,
        }
    },
    reducers: {
        addICP: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetICP: (state) => {
            state.value = {
                name: '', description: '', subIndustry: null, employeesRange: null, estimatedAnnualRevenue: null, country: null,
            }
        }
    },
});

export const { addICP, resetICP } = icpRowSlice.actions;
export default icpRowSlice.reducer;