import React, { createContext, useEffect, useState } from 'react';
import { LocalStorage } from '../../scripts/LocalStorage';
import { EventRegistrant } from '../../pages/Events/interfaces/event-registrant_interface';
import { registrantMe } from '../../scripts/apis/sponsorPortal/sponsorPortal';
import { useLocation, useNavigate } from 'react-router-dom';

type PortalModules = 'login' | 'dashboard' | 'form' | 'audience' | 'icp' | 'tasks' | 'formFieldEdit' | 'photos' | 'profile';

interface IPortalContext {
    selectedModule: PortalModules;
    setSelectedModule: React.Dispatch<React.SetStateAction<PortalModules>>;
    registrantData: EventRegistrant | null;
    handleModuleChange: (module: PortalModules) => void;
}

export const PortalContext = createContext<IPortalContext>({
    selectedModule: 'login',
    setSelectedModule: () => { },
    registrantData: null,
    handleModuleChange: () => { }
});

const PortalProvider: React.FC<{ children: React.ReactNode }> = ({ children }): React.JSX.Element => {
    const navigate = useNavigate();
    const currentPath = useLocation().pathname;
    const userDetails = LocalStorage.get('@SponsorDetails');

    const queryParams = window.location.search;

    const [selectedModule, setSelectedModule] = useState<PortalModules>(userDetails ? queryParams ? 'audience' : 'dashboard' : 'login');
    const [registrantData, setRegistrantData] = useState<EventRegistrant | null>(null);

    const handleModuleChange = (module: PortalModules): void => {
        setSelectedModule(module);
        navigate(`${currentPath}`);
    }

    const fetchUserData = async (): Promise<void> => {
        try {
            const orgLink = window.location.pathname.split('/')[1];
            const eventLink = window.location.pathname.split('/')[2];

            const userData = await registrantMe(orgLink, eventLink);
            if (userData) {
                setRegistrantData(userData);
            }
        }
        catch (error) {

        }
    };

    useEffect(() => {
        if (userDetails) fetchUserData();
    }, []);

    return (
        <PortalContext.Provider
            value={{
                selectedModule,
                setSelectedModule,
                registrantData,
                handleModuleChange
            }}
        >
            {children}
        </PortalContext.Provider>
    )

};

export default PortalProvider;