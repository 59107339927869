import { createSlice } from '@reduxjs/toolkit';

// import moment from 'moment';

// const defaultDate = new Date();
// const defaultDateTimeStamp = moment(defaultDate).format('X');

export const eventsRowSlick = createSlice({
    name: 'events',
    initialState: {
        value: {
            title: '',
            description: '',
            eventStartDate: '',
            eventStartTime: '',
            eventStartDateTime: '',
            eventEndDateTime: '',
            eventEndDate: '',
            eventEndTime: '',
            closingDateTime: '',
            timeZone: '',
            logo: '',
            type: 1,
            locationType: 1,
            registrationType: 1,
            link: '',
            eventAreas: {
                stage: false,
                session: false,
                // peopleArea: false,
                expo: false
            },
            banner: '',
            brandColor: null,
            country: '',
            state: '',
            city: '',
            status: '',
            eventCategoryId: '',
            registerOptions: {
                redirectionUrl: '',
                message: '',
            },
            about: '',
            enableWaitlistStatus: 2,
            locationName: '',
            locationURL: '',
            integrationType: 1,
            leadCaptureFormId: '',
            invoiceDetails: undefined,
            vcardSettings: ''
        }
    },
    reducers: {
        addEvent: (state, actions):void => 
        {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetEvents: (state):void => 
        {
            state.value = {
                title: '',
                description: '',
                eventStartDate: '',
                eventStartTime: '',
                eventEndDate: '',
                eventEndTime: '',
                eventStartDateTime: '',
                eventEndDateTime: '',
                closingDateTime: '',
                timeZone: '',
                logo: '',
                type: 1,
                locationType: 1,
                registrationType: 1,
                link: '',
                eventAreas: {
                    stage: false,
                    session: false,
                    // peopleArea: false,
                    expo: false
                },
                banner: '',
                brandColor: null,
                country: '',
                state: '',
                city: '',
                status: '',
                eventCategoryId: '',
                registerOptions: {
                    redirectionUrl: '',
                    message: '',
                },
                about: '',
                enableWaitlistStatus: 2,
                locationName: '',
                locationURL: '',
                integrationType: 1,
                leadCaptureFormId: '',
                invoiceDetails: undefined,
                vcardSettings: ''
            };
        }
    },
});

export const { addEvent, resetEvents } = eventsRowSlick.actions;
export default eventsRowSlick.reducer;