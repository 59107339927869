import { createSlice } from '@reduxjs/toolkit';

export const teamSlice = createSlice({
    name: 'teams',
    initialState: {
        value: {
            name: '', 
            additionalInfo: {
                message: '',
            },
            users: []
        }
    },
    reducers: {
        updateTeam: (state, action) => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        teamUserCheck: (state, action) => 
        {
            state.value.users.map((element) => 
            {
                if(element.id === action.payload)
                {
                    element.checkbox = !element.checkbox;
                }
                return element;
            });
        },
        resetTeam: (state) =>
        {
            state.value.name = '';
            state.value.additionalInfo.message = '';
            state.value.users = [];
        }
    },
});

export const { updateTeam } = teamSlice.actions;
export const { teamUserCheck } = teamSlice.actions;
export const { resetTeam } = teamSlice.actions;
export default teamSlice.reducer;