import React, { useEffect, useState } from "react";
import { AutocompleteMultiSelectComponentWithFilterOption, AutocompletewithTags, CloseIconComponent, CreatableAutocomplete, FormControlComponent, FormLabelComponent, RadioGroupComponent } from "../../../common/FormComponents/ReusableFormComponents";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { Box, createFilterOptions, Stack } from "@mui/material";
import { getAllStage, getStageCount } from "../../../scripts/apis/eventStage";
import { EventStage } from "../../../pages/Events/interfaces";
import { EventAccessControl } from "../../../pages/Events/interfaces/event-access-control_interface";
import _ from "lodash";
import { createEventAccessControl, updateEventAccessControl } from "../../../scripts/apis/eventAccessControl";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import { Form } from "react-bootstrap";

interface IAddEventAccessControlProps { 
    eventId: string | number;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    accessControlData?: EventAccessControl;
}

const AddEventAccessControl: React.FC<IAddEventAccessControlProps> = (props): React.JSX.Element => 
{  

    const { setRefresh, eventId, accessControlData } = props;
    const filter = createFilterOptions();

    const [spinner, setSpinner] = useState(false);
    const [eventStages, setEventStages] = useState<EventStage[]>([]);
    const [accessControlTags, setAccessControlTags] = useState<string[]>(props?.accessControlData?.other || []);

    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const stageTypeOptions = [
        {  
            name: 'All', value: 1, description: 'All Stages will be available for this device'
        },
        {
            name: 'Custom', value: 2, description: 'Select specific stages for this device'
        }
    ];

    const handleDrawerClose = (): void => {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleStageChange = (event, newValue): void => 
    {
        formik.setFieldValue('eventStageId', newValue);

        if(newValue?.length === eventStages?.length)
        {
            formik.setFieldValue('eventStageType', 1);
        }
    };

    const handleStageRemove = (index): void => 
    {
        const updatedStages = formik.values?.eventStageId?.filter((_, i): boolean => 
        {
            return i !== index; 
        });
        formik.setFieldValue('eventStageId', updatedStages);
    };

    const handleTags = (event, newValue: string[]): void => {
        const newTags = newValue.map((item) => {
            if (item.includes('Add :')) {
                return item.split('Add : ')[1];
            }
            return item;
        });

        setAccessControlTags(newTags);
        formik.setFieldValue('other', newTags);
    };

    const handleTagsFilterOptions = (options, params):unknown[] => 
    {
        const filtered = filter(options, params);

        if (params.inputValue !== '') 
        {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        stageType: Yup.number(),
        eventStageId: Yup.array().of(Yup.mixed()).test('is-required', 'Stage is required', (value): boolean => {
            if(formik.values.stageType === 2)
            {
                return value.length > 0;
            }
            return true;
        }),
        other: Yup.array().of(Yup.string()).nullable()
    });
    
    const formik = useFormik({
        initialValues: {
            name: accessControlData?.deviceName || '',
            stageType: 2,
            eventStageId: [],
            other: accessControlData?.other || []
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setSpinner(true);

            const allStageIds = eventStages?.map((item): number => 
            {
                return item.id;
            });

            const convertStageNameToId = _.filter(eventStages, (item): boolean => 
            {
                return _.includes(formik.values.eventStageId, item.title); 
            }).map((item) => 
            {
                return item.id; 
            });

            const accessControlFormData = {
                deviceName: values.name,
                stageIds: eventStages?.length > 0 ? (formik.values.stageType === 1 ? allStageIds : convertStageNameToId) : [],
                other: values.other || []
            };

            if(accessControlData)
            {
                // Update

                try 
                {
                    const accessControlUpdated = await updateEventAccessControl(accessControlFormData, eventId, accessControlData?.id);
                    if(accessControlUpdated)
                    {
                        setSpinner(false);
                        setRefresh(true);
                        handleDrawerClose();
                    }
                } 
                catch (error) 
                {
                    console.log('Error in updating Access Control', error);
                }
            }
            else
            {
                // Create
                
                try 
                {
                    const accessControlCreated = await createEventAccessControl(accessControlFormData, eventId, csrfTokenData);
                    if(accessControlCreated)
                    {
                        setSpinner(false);
                        setRefresh(true);
                        handleDrawerClose();
                    }
                } 
                catch (error) 
                {
                    console.log('Error in creating Access Control', error);
                }
            }
        },
    });

    const fetchEventStage = async ():Promise<void> => {
        try 
        {
            const count = await getStageCount(eventId);
            if(count)
            {
                const eventStageData = await getAllStage(eventId, count, 0);
                if(eventStageData)
                {
                    setEventStages(eventStageData);

                    if(accessControlData)
                    {
                        const eventStageNames = eventStageData?.length > 0 && accessControlData?.stageIds && accessControlData?.stageIds?.length > 0
                            ? accessControlData?.stageIds?.map((item) => 
                            {
                                const ids = _.find(eventStageData, ['id', String(item)]);
                                return ids ? ids.title : null;
                            }).filter(Boolean) || []
                            : [];

                        formik.setFieldValue('eventStageId', eventStageNames);
                    }
                }
            }
        } catch (error) 
        {
            console.log('Error in fetching Stage data', error);
        }
    };

    useEffect(() =>
    {
        fetchEventStage();  
    }, []);

    useEffect(() => {
        if (accessControlData?.stageIds?.length === eventStages?.length) {
            formik.setFieldValue('stageType', 1);
        } else {
            formik.setFieldValue('stageType', 2);
        }
    }, [accessControlData, eventStages]);

    return(
        <div id="addEventAccessControl">
            <CloseIconComponent onClick={handleDrawerClose} />

            <Form noValidate autoComplete='off' onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>
                {/* Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Name" required />
                    <FormControlComponent 
                        name="name" 
                        value={formik.values.name} 
                        placeholder="John Doe"
                        type="text"
                        required
                        onChange={formik.handleChange} 
                        error={formik.touched.name && !!formik.errors.name} 
                    />
                    {formik.touched.name && formik.errors.name && <p className="error-msg">{formik.errors.name}</p>}
                </Box>

                {/* Stage Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Stage Type" required />
                    <RadioGroupComponent value={formik.values.stageType} onChange={function (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void 
                        {
                            formik.setFieldValue('stageType', Number(event.target.value));
                        } } options={stageTypeOptions}  />
                </Box>

                {/* Event Stage dropdown */}
                {formik.values.stageType === 2 && <Box className="sidebar-container-spacing">
                        <FormLabelComponent label='Select Stage' required />
                        <AutocompletewithTags
                            defaultValue={formik.values.eventStageId} 
                            value={formik.values.eventStageId} 
                            options={eventStages} 
                            keyToShow='title' 
                            onChange={handleStageChange} 
                            placeholder='Select Stage' 
                            onRemoveClick={(index): void => 
                            {
                                handleStageRemove(index); 
                            }} />
                        {formik.touched.eventStageId && formik.errors.eventStageId && <p className="error-msg">{formik.errors.eventStageId}</p>}
                    </Box>}

                {/* Places other than stages */}
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label="Others" />
                    <AutocompleteMultiSelectComponentWithFilterOption
                        value={formik.values.other}
                        onChange={handleTags}
                        optionsArr={accessControlTags}
                        placeholder="Select or type to add"
                        filterOptions={handleTagsFilterOptions}
                        onRemoveClick={(option): void => { 
                            const updatedTags = formik.values.other?.filter((item): boolean => {
                                return item !== option;
                            });
                            setAccessControlTags(updatedTags);
                            formik.setFieldValue('other', updatedTags);
                        }}
                    />
                </div>

                {/* Submit button */}
                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" disabled={spinner} loading={spinner} name={'Save'} btnType={'primary'} />
                    </Stack>
                </Box>
            </Form>
        </div>
    )
};

export default AddEventAccessControl;