import './styles.scss';

interface CardProps {
    cardMinHeight?: string;
    emptyCardHeight?: string;
    header?: string | number | React.ReactNode;
    headerBold?: boolean;
    iconsLeft?: React.ReactNode[];
    iconsRight?: React.ReactNode[] | string | number;
    iconRightBorder?: boolean;
    chip?: React.JSX.Element;
    contentHeading?: string | number | React.JSX.Element;
    contentHeadingBold?: boolean;
    footerLeft?: string | number | React.ReactNode | React.JSX.Element;
    footerStringBoldLeft?: boolean;
    footerRight?: string | number | React.ReactNode | React.JSX.Element;
    emptyText?: string;
    footerWidth?: string;
    onClick?: () => void;
    gapNotRequired?: boolean;
}

const CardComponent: React.FC<CardProps> = ({
    cardMinHeight,
    emptyCardHeight,
    header,
    headerBold,
    iconsLeft,
    iconsRight,
    iconRightBorder,
    chip,
    contentHeading,
    contentHeadingBold,
    footerLeft,
    footerStringBoldLeft,
    footerRight,
    emptyText,
    footerWidth,
    onClick,
    gapNotRequired
}) => (
    <div style={{ minHeight: cardMinHeight || '', gap: gapNotRequired ? '' : '12px !important' }} className="card-component" onClick={onClick}>
        {header && (
            <div className="header-div">
                <div className="card-header-title">
                    <div className="heading">
                        <div className="card-header-text" style={{ fontWeight: headerBold ? '500' : '400', color: headerBold ? 'var(--colors-text-text-primary-900, #101828)' : 'var(--colors-text-text-secondary-700, #344054)' }}>
                            {header}
                        </div>
                        {iconsLeft && <div className="card-header-icons-left">{iconsLeft}</div>}
                    </div>

                    {contentHeading && (
                        <div className="card-content-heading" style={{ fontWeight: contentHeadingBold ? '500' : '400', color: contentHeadingBold ? 'var(--colors-text-text-primary-900, #101828)' : 'var(--colors-text-text-secondary-700, #344054)' }}>
                            {contentHeading}
                        </div>
                    )}
                </div>

                <div className="card-header-actions">
                    {iconsRight && (
                        <div className="card-header-icons-right" style={{ border: iconRightBorder ? '1px solid #EAECF0' : '', boxShadow: iconRightBorder ? '0px 1px 2px rgba(16, 24, 40, 0.05)' : '' }}>
                            {iconsRight}
                        </div>
                    )}
                    {chip && <div className="card-header-chip">{chip}</div>}
                </div>
            </div>
        )}
        {(footerLeft || footerRight) && <div className="footer-div">
            {footerLeft && <div style={{ width: footerWidth || '', fontWeight: footerStringBoldLeft ? 500 : 'normal', fontSize: '14px' }} className="card-footer-text">{footerLeft}</div>}
            {footerRight && <div className="card-footer-text card-footer-text-right">{footerRight}</div>}
        </div>}

        {
            emptyText && (
                <div className="empty-card" style={{ height: emptyCardHeight || '' }}>
                    <h3 className="empty-card-content">{emptyText}</h3>
                </div>
            )
        }
    </div>
);

export default CardComponent;