import React from 'react';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IPopup {
    children: React.JSX.Element | React.JSX.Element[];
    onClose: () => void;
    popupContentStyle?: React.CSSProperties;
    popupStyle?: React.CSSProperties;
    extraButtons?: React.JSX.Element[];
    screenType?: 'mobile' | 'desktop' | 'tablet';
    message?: string;
}

const PopUpView = ({ children, onClose, popupContentStyle, popupStyle, extraButtons, message, screenType }: IPopup) : React.JSX.Element => 
{
    return (
        <Box id="PopUpCont" style={popupStyle}>
            {screenType ?
            <>
                <div className="photos-popupContentBlock" style={{ maxWidth: screenType === 'mobile' ? '90%' : '75%' }}>
                    {
                        extraButtons && extraButtons?.map((button, index) => {
                            return (
                                <div className='popup-actions' key={index}>
                                    {button}
                                </div>
                            );
                        })
                    }
                    {children}
                </div>
                {screenType === 'mobile' && message}
            </> :
            <Box className="popUpContentBlock" style={popupContentStyle}>
                {
                    extraButtons && extraButtons?.map((button, index) => {
                        return (
                            <div className='popup-actions'>
                                {button}
                            </div>
                        );
                    })
                }
                <Box className="closeIcon" onClick={onClose}>
                    <FontAwesomeIcon icon={['fal', 'arrows-minimize']} className="graph-collapse-icon" />
                </Box>
                {children}
            </Box>}
        </Box>
    );
};

export default PopUpView;