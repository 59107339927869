/* eslint-disable @next/next/no-img-element */
'use client'

import { useState } from "react";

import './styles.scss';

import ehqLoader from '../../../../assets/ehqlogo_loader.gif';

interface EmblaLazyLoadImageProps { 
    src: string;
    alt: string;
    inView: boolean;
    screenType?: 'mobile' | 'desktop' | 'tablet';
}

const EmblaLazyLoadImage: React.FC<EmblaLazyLoadImageProps> = (props): React.JSX.Element => 
{

    const { src, alt, inView, screenType } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const setLoaded = () => setIsLoading(false);

    return (
        <div className="embla-slide">
            <div className={'embla-lazy-load'.concat(
                !isLoading ? ' embla-lazy-load-has-loaded' : ''
            )}>
                {/* {!hasLoaded && <div className="embla-spinner-container"><Spinner animation="border" role="status" /></div>} */}
                {isLoading && <div className="embla-lazy-load-spinner"><img src={ehqLoader} height={20} width={20} alt='' /></div>}
                <img
                    src={src}
                    alt={alt}
                    width={screenType === 'mobile' ? '100%' : 'fit-content'}
                    className="embla-slide-img embla-lazy-load-img"
                    onLoad={setLoaded}
                    style={{ display: isLoading ? 'none' : 'block' }} // Hide image while loading
                />
            </div>
            {
                !isLoading && screenType === 'mobile' && <p className="img-help-text">Long press the photo to save</p>
            }
        </div>
    )
};

export default EmblaLazyLoadImage;