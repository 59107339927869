import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IAlertProps } from '../pages/Registration/RegistrationSubPages/Preview/FormPreview';
import {  checkInvoiceJWTValidity, getRegistrationFormDataWithToken } from '../scripts/apis/registrationPublic';
import { getEventPreview } from '../scripts/apis/events';
import eventBus from '../scripts/event-bus';
import moment from 'moment';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Pirsch } from 'pirsch-sdk/web';
import { uniqueTimezoneArr } from '../components/Events/timezoneGenerateFunction';
// eslint-disable-next-line import/no-unresolved
import { EventPreview, EventRegistrantInvoiceDetails } from '../pages/Events/interfaces';
import { LocalStorage } from '../scripts/LocalStorage';
import { EventCoupon } from '../pages/Events/interfaces/event-coupon_interface';

const useLandingPageData = ():[EventPreview, boolean, EventPreview, string, boolean, string, IAlertProps, string, string, boolean, boolean, string, EventCoupon | undefined] => 
{
    const [eventData, setEventData] = useState<EventPreview | EventRegistrantInvoiceDetails>();
    const [isPageReady, setPageReady] = useState<boolean>(false);
    const [registrationFormData, setRegistrationFormData] = useState(null);
    const [newToken, setNewToken] = useState(null);
    const [errorPage, setErrorPage] = useState<boolean>(false);
    const [screenType, setScreenType] = useState<string>('');
    const [eventDateInfo, setEventDateInfo] = useState<string>('');
    const [eventTimeInfo, setEventTimeInfo] = useState<string>('');
    const [paymentSuccessPage, setPaymentSuccessPage] = useState<boolean>(false);
    const [invoicePage, setInvoicePage] = useState<boolean>(false);
    const [invoiceJWT, setInvoiceJWT] = useState<string>('');
    
    const [coupon, setCoupon] = useState<EventCoupon | undefined>(undefined);

    const { pathname, search } = useLocation();
    const params = pathname.split('/').filter(Boolean);

    const orgLink = params.length >= 2 ? params[0] : null;

    const eventLink = params.length >= 2 ? params[1] : params[1];

    const searchParams = new URLSearchParams(window.location.search);

    const isPreviewLink = window.location.pathname.includes('preview');

    const [showAlert, setShowAlert] = useState<IAlertProps>({
        show: false 
    });

    const fetchLandingPageData = async ():Promise<void> => 
    {
        // const isValidUTMLink = [...searchParams.keys()];

        const isValidInvoiceLink = searchParams?.get('invoice');

        const couponCode = searchParams?.get('coupon');
        
        let utmLink = searchParams?.get('utm_source');
        // if (isValidUTMLink.length > 0 && !(isValidUTMLink.includes('utm_source'))) 
        // {
        //     setErrorPage(true);
        // }
        // else 
        if(params.length < 2 && !isPreviewLink)
        {
            setErrorPage(true);
            return;
        }

        if(isValidInvoiceLink || (params?.length === 4 && params?.includes('payment-success')))
        {
            if(isValidInvoiceLink)
            {
                try 
                {
                    const isJWTValid = await checkInvoiceJWTValidity(isValidInvoiceLink);
                    if(isJWTValid)
                    {
                        setInvoiceJWT(isValidInvoiceLink);
                        setEventData(isJWTValid);
                        setInvoicePage(true);
                        setPageReady(true);
                    }
                } 
                catch (error)
                {
                    setErrorPage(true);
                    console.log(error);
                }
            }
            else
            {
                try 
                {
                    const eventDetails = await getRegistrationFormDataWithToken(orgLink, eventLink);
                    if(eventDetails)
                    {
                        setEventData(eventDetails);
                        setPaymentSuccessPage(true);
                        setPageReady(true);
                    }
                } 
                catch (error) 
                {
                    setErrorPage(true);
                    console.log(error);
                }
            }
            return;
        }

        if(couponCode)
        {
            try 
            {
                const eventDetails = await getRegistrationFormDataWithToken(orgLink ?? '', eventLink, undefined, couponCode);
                if(eventDetails)
                {
                    setEventData(eventDetails);
                    setRegistrationFormData(eventDetails);
                    setPageReady(true);
                    setNewToken(eventDetails.token);

                    if(eventDetails?.coupon)
                    {
                        setCoupon(eventDetails?.coupon);
                    }
                }
            } 
            catch (error) 
            {
                console.log(error);
                setErrorPage(true);
            }
            return;
        }

        if (eventLink && !isPreviewLink && !utmLink) 
        {
            try 
            {

                const eventDetails = await getRegistrationFormDataWithToken(orgLink, eventLink);
                if (eventDetails) 
                {
                    setEventData(eventDetails);
                    setRegistrationFormData(eventDetails);
                    setPageReady(true);
                    setNewToken(eventDetails.token);
                }
            }
            catch (error) 
            {
                console.log(error);
                setErrorPage(true);
            }
            // try 
            // {
            //     const tokenData = await getRegistrationFormToken(orgLink, eventLink);
            //     if (tokenData) 
            //     {
            //         setNewToken(tokenData);
            //         try 
            //         {

            //             const eventDetails = await getRegistrationFormData(tokenData);
            //             if (eventDetails) 
            //             {
            //                 setEventData(eventDetails);
            //                 setRegistrationFormData(eventDetails);
            //                 setPageReady(true);
            //             }
            //         }
            //         catch (error) 
            //         {
            //             console.log(error);
            //             setErrorPage(true);
            //         }
            //     }
            //     else 
            //     {
            //         setPageReady(true);
            //     }
            // }
            // catch (error) 
            // {
            //     console.log(error);
            //     setErrorPage(true);
            // }
            if (!(import.meta.env.VITE_NODE_ENV === 'local')) 
            {
                const client = new Pirsch({
                    identificationCode: import.meta.env.VITE_PIRSCH_ID_TOKEN
                });
                await client.hit();
            }
        }
        else if (eventLink && utmLink && !isPreviewLink) 
        {
            try 
            {
                const eventDetails = await getRegistrationFormDataWithToken(orgLink, eventLink, utmLink);
                if (eventDetails) 
                {
                    setEventData(eventDetails);
                    setRegistrationFormData(eventDetails);
                    setPageReady(true);
                    setNewToken(eventDetails.token);
                }
            }
            catch (error) 
            {
                console.log(error);
                setErrorPage(true);
            }
            // try 
            // {
            //     const tokenData = await getRegistrationFormToken(orgLink, eventLink, utmLink);
            //     if (tokenData) 
            //     {
            //         setNewToken(tokenData);
            //         try 
            //         {
            //             const eventDetails = await getRegistrationFormData(tokenData);
            //             if (eventDetails) 
            //             {
            //                 setEventData(eventDetails);
            //                 setRegistrationFormData(eventDetails);
            //                 setPageReady(true);
            //             }
            //         }
            //         catch (error) 
            //         {
            //             console.log(error);
            //             setErrorPage(true);
            //         }
            //     }
            //     else 
            //     {
            //         setPageReady(true);
            //     }
            // }
            // catch (error) 
            // {
            //     console.log(error);
            //     setErrorPage(true);
            // }


            // commenting out to avoid hits happening during redirection
            // if (!(import.meta.env.VITE_NODE_ENV === 'local')) 
            // {
            //     const client = new Pirsch({
            //         identificationCode: import.meta.env.VITE_PIRSCH_ID_TOKEN
            //     });
            //     await client.hit();
            // }
        }
        else 
        {
            if(eventLink && LocalStorage.get('@UserMe'))
            {
                try 
                {
                    const eventDataFromEventLink = await getEventPreview(eventLink);
                    if (eventDataFromEventLink) 
                    {
                        setEventData(eventDataFromEventLink);
                        setRegistrationFormData(eventDataFromEventLink);
                        setPageReady(true);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setErrorPage(true);
                }
            }
            else
            {
                setErrorPage(true);
            }
           
        }
    };

    useEffect(():()=>void => 
    {
        fetchLandingPageData();

        eventBus.on('form-submitted', (data):void => 
        {
            setShowAlert((prevItems):{
                show: true;
                message: string;
                severity: string;
            } => 
            {
                return {
                    ...prevItems,
                    show: true,
                    message: data.message,
                    severity: data.severity,
                }; 
            });
        });

        const handleResize = ():void => 
        {
            const width = window.innerWidth;

            if (width < 600) 
            {
                setScreenType('mobile');
            }
            else if (width > 600 && width <= 768) 
            {
                setScreenType('tablet');
            }
            else 
            {
                setScreenType('desktop');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return ():void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(():void => 
    {
        if (eventData && eventData?.eventStartDateTime && eventData?.eventEndDateTime) 
        {

            if (moment.unix(Number(eventData?.eventStartDateTime)).format('dddd, MMMM D') === moment.unix(Number(eventData?.eventEndDateTime)).format('dddd, MMMM D')) 
            {
                setEventDateInfo(moment.unix(Number(eventData?.eventStartDateTime)).format('dddd, MMMM D'));
            }
            else 
            {
                setEventDateInfo(`${moment.unix(Number(eventData?.eventStartDateTime)).format('MMM D')} - ${moment.unix(Number(eventData?.eventEndDateTime)).format('MMM D')}`);
            }

            const startTime = moment.unix(Number(eventData?.eventStartDateTime));
            const endTime = moment.unix(Number(eventData?.eventEndDateTime));
            // const startTimezoneValue = moment.tz(moment.unix(Number(eventData.eventStartDateTime)).format(), eventData.timezone).format('z');
            const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem):boolean 
            {
                return elem.value === eventData?.timezone;
            });
            let timezone: string;
            if (defaultTimezone && defaultTimezone[0]?.name && defaultTimezone[0]?.name !== '') 
            {
                timezone = defaultTimezone[0]?.name?.split(' ')[0];
            }
            // const endTimeZoneValue = moment.tz(moment.unix(Number(eventData.eventEndDateTime)).format(), eventData.timezone).format('z');

            const formattedTimeRange = `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')} ${timezone}`;
            setEventTimeInfo(formattedTimeRange);

        }
    }, [eventData]);

    return [eventData, isPageReady, registrationFormData, newToken, errorPage, screenType, showAlert, eventDateInfo, eventTimeInfo, invoicePage, paymentSuccessPage, invoiceJWT, coupon];

};

export default useLandingPageData;