import { Form, Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { FormControlComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isImageBuffer } from '../../../../scripts/helpers';
import toast from 'react-hot-toast';
import { addSponsorInfoImages, deleteSponsorInfoImages, updateSponsorInfo } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';
import { Sponsor } from '../../../Sponsors/interfaces';
import React, { useState } from 'react';
import CustomSpinner from '../../../../common/CustomSpinner';
import { Stack } from '@mui/material';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { CONTENT } from '../../../../scripts/i18n';

import './styles.scss';

interface AddSponsorInfoProps { 
    existingSponsorInfo?: Sponsor;
    handleClose: () => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddSponsorInfo: React.FC<AddSponsorInfoProps> = (props): React.JSX.Element =>
{

    const { existingSponsorInfo, handleClose, setRefresh } = props;

    const [spinnerValues, setSpinnerValues] = useState<{
        primaryLogos: boolean | number;
        alternateLogos: boolean | number;
        boothAssets: boolean | number;
    }>({ 
        primaryLogos: false,
        alternateLogos: false,
        boothAssets: false
    });
    const [spinner, setSpinner] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .required('Company description is required'),
        primaryLogos: Yup.array().of(Yup.mixed()).test('is-required', 'Primary logos are required', (value): boolean => { 
            return value ? value?.length > 0 : false;
        }),
        alternateLogos: Yup.array().of(Yup.mixed()).test('is-required', 'Alternate logos are required', (value): boolean => { 
            return value ? value?.length > 0 : false;
        }),
        boothAssets: Yup.array().of(Yup.mixed()).test('is-required', 'Booth Assets are required', (value): boolean => { 
            return value ? value?.length > 0 : false;
        }),
    });

    const processFilesForSponsorInfo = async (files: File[], values: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{}>>, field: string) => 
    { 
        const validFiles: File[] = [];
        const newFiles: string[] = [];

        const processFile = async (file: File) => {
            const fileSize = file.size;
            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            return new Promise<void>((resolve, reject) => {
                reader.onloadend = async (): Promise<void> => {
                    const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                    const isImage = isImageBuffer(buffer);

                    if (isImage && fileSizeInMB < 10) {
                        validFiles.push(file);
                        const formData = new FormData();
                        formData.append('files', file);
                        try {
                            const imageUploaded = await addSponsorInfoImages(formData, field);
                            if (imageUploaded) {
                                newFiles.push(URL.createObjectURL(file));
                            }
                            resolve();
                        } catch (error) {
                            console.log(error);
                            toast.error((error as Error)?.message || 'Error in uploading image');
                            reject(error);
                        }
                    } else {
                        toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image');
                        reject(new Error('Invalid file'));
                    }
                };

                reader.readAsArrayBuffer(file);
            });
        };

        try {
            for (const file of files) {
                await processFile(file);
            }
            const currentFiles = values[field] || [];
            await setFieldValue(field, [...currentFiles, ...newFiles]);
            return newFiles;
        } catch (error) {
            console.log('Error processing files:', error);
            return [];
        }
    };

    const handleDeleteImages = async (index: number, values: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{}>>, field: string) => 
    { 
        setSpinnerValues({ ...spinnerValues, [field]: index });
        try 
        {
            const imageRemoved = await deleteSponsorInfoImages(field, values[field][index]);
            if (imageRemoved) {
                const updatedImages = values[field].filter((_: string, i: number) => i !== index);
                setFieldValue(field, updatedImages);
                toast.success('Image removed successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error in removing image');
        } 
        finally 
        {
            setSpinnerValues({ ...spinnerValues, [field]: false });
        }
    };

    const renderDropzone = (label: string, field: string, values: any, errors: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{}>>, spinnerValues: any, handleDeleteImages: (index: number, values: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{}>>, field: string) => Promise<void>) => 
    {
        return (
            <div className="sidebar-container-spacing">
                <FormLabelComponent label={label} required />
                <Dropzone 
                    multiple 
                    accept={{
                        'image/png': [],
                        'image/jpeg': []
                    }}
                    onDrop={async (acceptedFiles) => {
                        processFilesForSponsorInfo(acceptedFiles, values, setFieldValue, field);
                    }}
                >
                    {({ getRootProps, getInputProps }) => ( 
                        <div {...getRootProps()} className="sponsor-info-dropzone">
                            <div className="upload-icon-div">
                                <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                            </div>
                            <div className="dropzone-upload-content">
                                <h2 className="btn-text">
                                    <input {...getInputProps()} />
                                    {'Click to upload'}
                                </h2>
                                <p className="info-text">Add your {label.toLowerCase()}</p>
                            </div>
                        </div>
                    )}
                </Dropzone>
                {
                    values?.[field]?.length > 0 && 
                    <div className="images-container">
                        {
                            values?.[field]?.map((logo: string, index: number) => {
                                return (
                                    <div className="logo-wrapper" key={index}>
                                        <img className="primary-logo" src={logo} alt={`${label} ${index}`} />
                                        { 
                                            spinnerValues?.[field] === index ?
                                            <CustomSpinner height="100%" size="sm" /> :
                                            <FontAwesomeIcon 
                                                className="remove-icon" 
                                                onClick={async () => {handleDeleteImages(index, values, setFieldValue, field)}} 
                                                icon={['fal', 'trash-can']} 
                                            />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {errors[field] && <div className="error-msg">{errors[field]}</div>}
            </div>
        );
    };

    const handleFormSubmit = async (values: any) =>
    {
        const data = {
            companyDescription: values.description,
        };

        try 
        {
            const infoUpdated = await updateSponsorInfo(data);
            if (infoUpdated) 
            {
                toast.success('Sponsor info updated successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error in updating sponsor info');
        }
        finally 
        {
            setSpinner(false);
            setRefresh(true);
            handleClose();
        }
    };

    return (
        <div id="addSponsorInfo">
            <Formik
                initialValues={{
                    description: existingSponsorInfo?.companyDescription || '',
                    primaryLogos: existingSponsorInfo?.primaryLogos || [],
                    alternateLogos: existingSponsorInfo?.alternateLogos || [],
                    boothAssets: existingSponsorInfo?.boothAssets || [],
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setSpinner(true);
                    handleFormSubmit(values);
                }}
            >
                {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }): React.ReactElement => { 
                    return (
                        <Form onSubmit={handleSubmit} className="h-100">
                            <div className="add-sponsorInfo-form">
                                {/* Company description */}
                                <div className="sidebar-container-spacing">
                                    <FormLabelComponent label='Company Description' required />
                                    <FormControlComponent 
                                        type='text'
                                        as='textarea'
                                        rows={4}
                                        name='description'
                                        value={values.description}
                                        onChange={handleChange}
                                        required
                                        isInvalid={(errors.description && touched.description) as boolean}
                                    />
                                    {errors.description && touched.description && <div className="error-msg">{errors.description}</div>}
                                </div>

                                {/* Primary logos */}
                                {renderDropzone('Primary Logos', 'primaryLogos', values, errors, setFieldValue, spinnerValues, handleDeleteImages)}

                                {/* Alternate logos */}
                                {renderDropzone('Alternate Logos', 'alternateLogos', values, errors, setFieldValue, spinnerValues, handleDeleteImages)}

                                {/* Booth Assets */}
                                {renderDropzone('Booth Assets', 'boothAssets', values, errors, setFieldValue, spinnerValues, handleDeleteImages)}

                                <div className="submission-container">
                                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                                        <CustomButton onClick={handleClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                        <CustomButton type="submit" loading={spinner} disabled={spinner} name={"Save"} btnType={"primary"} />
                                    </Stack>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
};

export default AddSponsorInfo;