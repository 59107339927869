
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import fileDownload from 'js-file-download'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IDownloadButtonProps {
    downloadableUrl: string;
    fileName: string;
}

export const DownloadImage: React.FC<IDownloadButtonProps> = (props): React.JSX.Element =>
{

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownload = () => {
        setIsDownloading(true);
        fetch(props.downloadableUrl)
          .then(response => response.blob())
          .then(blob => {
            fileDownload(blob, props.fileName, 'image/jpeg');
            setIsDownloading(false);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
            setIsDownloading(false);
          });
    };


    return(
        <button className="download-button" onClick={handleDownload}>
            {isDownloading ? (
                <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (<FontAwesomeIcon icon={['fal', 'arrow-down-to-bracket']} />)}
        </button>
    )
};