import './styles.scss';

interface AnalyticsPageHeaderProps 
{ 
    header: string | number | React.ReactNode;
    description?: string | number | React.ReactNode;
    actions?: React.ReactNode | React.JSX.Element;
}

const AnalyticsPageHeader: React.FC<AnalyticsPageHeaderProps> = (props): React.JSX.Element =>
{

    const { header, description, actions } = props;

    return (
        <div className="analytics-page-header">
            <div className="content">
                {header && <h3 className="heading">{header}</h3>}
                {description && <p className="description">{description}</p>}
            </div>
            {
                actions &&
                <div className="actions">
                    {actions}
                </div>
            }
        </div>
    )
};

export default AnalyticsPageHeader;