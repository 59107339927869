import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import TrendsComponent from '../../common/TrendsComponent';
import CustomSpinner from '../../common/CustomSpinner';
import Charts from '../../common/ECharts/Charts';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpView from '../../common/PopupView';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';

interface ISpeakersGraph {
    speakerCount: number;
    isChartReady: {
        speakersIcpGraphReady?: boolean,
        speakersBuyerPersonaGraphReady?: boolean,
        speakerRevenueGraphReady?: boolean
    };
    speakersIcpData: { name: string, percentage: string | number; value: string | number; }[];
    speakerBuyerPersonaData: { name: string, percentage: string | number; value: string | number; }[];
    speakerRevenueData: { name: string, percentage: string | number; value: string | number; }[];
    eventSpeakerGraphs?: boolean;
    trendData?:{[key: string]: number | string;};
}

const SpeakersGraph = (props: ISpeakersGraph): React.JSX.Element => 
{
    // const [graphContainerHeight, setgraphContainerHeight] = useState(0);
    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        speakersIcpGraph: false,
        speakersBuyerPersonaGraph: false,
        speakerRevenueGraph: false,
    });

    const isChartReady = props.isChartReady;
    const speakersIcpData = props.speakersIcpData;
    const speakerBuyerPersonaData = props.speakerBuyerPersonaData;
    const speakerRevenueData = props.speakerRevenueData;

    const isDemoInstance = import.meta.env.VITE_NODE_ENV === 'demo';

    // useEffect(():()=>void => 
    // {
    //     const handleResize = ():void => 
    //     {
    //         const screenHeight = window.innerHeight;

    //         if (props?.eventSpeakerGraphs) 
    //         {
    //             const graphHeight = 0.83 * screenHeight;

    //             const containerHeight = screenHeight - graphHeight;
    //             setgraphContainerHeight(containerHeight);
    //         }
    //         else 
    //         {
    //             const seventyPercentHeight = 0.76 * screenHeight;

    //             const containerHeight = screenHeight - seventyPercentHeight;
    //             setgraphContainerHeight(containerHeight);
    //         }
    //     };

    //     handleResize();

    //     window.addEventListener('resize', handleResize);
    //     return ():void => 
    //     {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [graphContainerHeight]);

    const speakersICPGraph = constructObjectForPieChart(speakersIcpData, ['50%','72%']);

    const AllSpeakersICPGraph = constructObjectForPieChart(speakersIcpData, ['50%','72%'], 'vertical');

    const speakersBuyerPersonaGraph = constructObjectForPieChart(speakerBuyerPersonaData, ['50%', '72%']);

    const AllSpeakersBuyerPersonaGraph = constructObjectForPieChart(speakerBuyerPersonaData, ['50%', '72%'], 'vertical');

    const speakersRevenueGraph = constructObjectForPieChart(speakerRevenueData, ['50%', '72%']);

    const AllSpeakersRevenueGraph = constructObjectForPieChart(speakerRevenueData, ['50%', '72%'], 'vertical');
    
    return (
        <Box id="speakersGraph" sx={{
            height: props?.eventSpeakerGraphs ? 'calc(44% - 60px)' : '40%'
        }}>
            <TopPageAnalyticsContainer
                TrendsComponent={
                    <TrendsComponent
                        Trends={[{
                            title: 'Speakers',
                            value: `${Number(props?.speakerCount) !== 0 ? props.speakerCount : '-'}`
                        },
                        {
                            title: 'Customers',
                            value: isDemoInstance && props?.trendData?.customer ? `${props?.trendData?.customer}` : '-',
                        },
                        {
                            title: 'Prospects',
                            value: isDemoInstance && props?.trendData?.prospect ? `${props?.trendData?.prospect}` : '-',
                        },
                        {
                            title: 'Speakers in ICP',
                            value: isDemoInstance && props?.trendData?.icp ? `${props?.trendData?.icp}` : '-',
                        }
                        ]}
                    />
                }
                GraphComponentsArray={
                    [
                        {
                            GraphComponent: <Box className="speakersICP" key={'speakers-icp'}>
                                <Box className="titleContainer">
                                    <Typography className="graph-title">{'Ideal Customer Profile (ICP)'}</Typography>
                                    {speakersIcpData.length > 0 &&
                            <Box className="enlarge-view" onClick={():void => 
                            {
                                setOpenEnlargeGraphView({
                                    speakersIcpGraph: true, speakersBuyerPersonaGraph: false, speakerRevenueGraph: false 
                                }); 
                            }}>
                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                            </Box>
                                    }
                                </Box>
                                {isChartReady.speakersIcpGraphReady ? speakersIcpData.length > 0 ? (<Charts height={'100%'} chartOption={speakersICPGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                            </Box>
                        },
    
                        {
                            GraphComponent: <Box className="speakersBuyerPersona" key={'speakers-bp'}>
                                <Box className="titleContainer">
                                    <Typography className="graph-title">{'Buyer Persona (BP)'}</Typography>
                                    {speakerBuyerPersonaData.length > 0 &&
                            <Box className="enlarge-view" onClick={():void => 
                            {
                                setOpenEnlargeGraphView({
                                    speakersIcpGraph: false, speakersBuyerPersonaGraph: true, speakerRevenueGraph: false 
                                }); 
                            }}>
                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                            </Box>
                                    }
                                </Box>
                                {isChartReady.speakersBuyerPersonaGraphReady ? speakerBuyerPersonaData.length > 0 ? (<Charts height={'100%'} chartOption={speakersBuyerPersonaGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                            </Box>
                        },
    
                        {
                            GraphComponent:   <Box className="speakersRevenue" key={'speaker-revenue'}>
                                <Box className="titleContainer">
                                    <Typography className="graph-title">{'Speaker by Annual Revenue'}</Typography>
                                    {speakerRevenueData.length > 0 &&
                        <Box className="enlarge-view" onClick={():void => 
                        {
                            setOpenEnlargeGraphView({
                                speakersIcpGraph: false, speakersBuyerPersonaGraph: false, speakerRevenueGraph: true 
                            }); 
                        }}>
                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                        </Box>
                                    }
                                </Box>
                                {isChartReady.speakerRevenueGraphReady ? speakerRevenueData.length > 0 ? (<Charts height={'100%'} chartOption={speakersRevenueGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}

                            </Box>
                        }
                    ]
                }
            />
            {openEnlargeGraphView.speakersIcpGraph ? (
                <PopUpView onClose={():void => 
                {
                    setOpenEnlargeGraphView({
                        speakersIcpGraph: false, speakersBuyerPersonaGraph: false, speakerRevenueGraph: false 
                    }); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Ideal Customer Profile (ICP)'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllSpeakersICPGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.speakersBuyerPersonaGraph ? (
                <PopUpView onClose={():void => 
                {
                    setOpenEnlargeGraphView({
                        speakersIcpGraph: false, speakersBuyerPersonaGraph: false, speakerRevenueGraph: false 
                    }); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Buyer Persona (BP)'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllSpeakersBuyerPersonaGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.speakerRevenueGraph ? (
                <PopUpView onClose={():void => 
                {
                    setOpenEnlargeGraphView({
                        speakersIcpGraph: false, speakersBuyerPersonaGraph: false, speakerRevenueGraph: false 
                    }); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Speaker by Annual Revenue'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllSpeakersRevenueGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

        </Box>
    );
};

export default SpeakersGraph;