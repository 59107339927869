import { createSlice } from '@reduxjs/toolkit';

export const sponsorsRowSlice = createSlice({
    name: 'sponsors',
    initialState: {
        value: {
            name: '',
            pocName: '',
            pocEmail: '',
            websiteLink: '',
            logo: '',
            type: 0,
            amount: '',
            tierId: '',
            social: {
                linkedIn: '',
                twitter: ''
            }
        }
    },
    reducers: {
        addSponsors: (state, action):void => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetSponsors: (state):void => 
        {
            state.value = {
                name: '',
                pocName: '',
                pocEmail: '',
                websiteLink: '',
                logo: '',
                type: 0,
                amount: '',
                tierId: '',
                social: {
                    linkedIn: '',
                    twitter: ''
                }
            };
        }
    },
});

export const { addSponsors, resetSponsors } = sponsorsRowSlice.actions;
export default sponsorsRowSlice.reducer;