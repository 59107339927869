import { useState } from "react";
import ButtonGroup from "../../../../common/ButtonGroup";
import PortalAppBar from "../../Components/Common/PortalAppBar";
import PortalRegistrants from "./PortalRegistrants";
import PortalTargetList from "./PortalTargetList";
import PortalLeads from "./PortalLeads";
import { useLocation, useNavigate } from "react-router-dom";

import './styles.scss';

const PortalAudiencePage: React.FC = (): React.JSX.Element => 
{
    const currenPath = useLocation().pathname;
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState<'registrants' | 'targetList' | 'leads'>('registrants');

    const handleTabChange = (tab: 'registrants' | 'targetList' | 'leads'): void =>
    {
        navigate(`${currenPath}`);
        setSelectedTab(tab);
    };

    const sponsorPortalAudienceTabs = [
        {
            tabName: 'Registrants',
            selectedTab: selectedTab === 'registrants',
            navigation: (): void => handleTabChange('registrants') 
        },
        {
            tabName: 'Target List',
            selectedTab: selectedTab === 'targetList',
            navigation: (): void => handleTabChange('targetList')
        },
        {
            tabName: 'Leads',
            selectedTab: selectedTab === 'leads',
            navigation: (): void => handleTabChange('leads')
        }
    ];

    return (
        <div id="portalAudiencePage">
            <PortalAppBar title="Audience" />
            <div className="button-group-container">
                <ButtonGroup tabs={sponsorPortalAudienceTabs} />
            </div>
            {
                selectedTab === 'registrants' && <PortalRegistrants />
            }
            {
                selectedTab === 'targetList' && <PortalTargetList />
            }
            {
                selectedTab === 'leads' && <PortalLeads />
            }
        </div>
    );
};

export default PortalAudiencePage;