import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import TrendsComponent from '../../common/TrendsComponent';
import CustomSpinner from '../../common/CustomSpinner';
import Charts from '../../common/ECharts/Charts';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpView from '../../common/PopupView';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';

const SponsorsGraph = (props): React.JSX.Element => 
{
    // const [graphContainerHeight, setgraphContainerHeight] = useState(0);
    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        sponsorsIcpGraph: false,
        sponsorsFundingAmountGraph: false,
        sponsorsRevenueGraph: false,
    });

    const isDemoInstance = import.meta.env.VITE_NODE_ENV === 'demo';

    const sponsorsICPData = props.sponsorsIcpData;

    const sponsorsFundingAmountData = props.sponsorFundingData;

    const sponsorsRevenueData = props.sponsorRevenueData;

    const sponsorsICPGraph = constructObjectForPieChart(sponsorsICPData, ['50%', '72%']);

    const AllSponsorsICPGraph = constructObjectForPieChart(sponsorsICPData, ['50%','74%'], 'vertical');

    const sponsorsFundingAmountGraph = constructObjectForPieChart(sponsorsFundingAmountData, ['50%', '72%']);

    const AllSponsorFundingAmountGraph = constructObjectForPieChart(sponsorsFundingAmountData, ['50%', '72%'], 'vertical');

    const sponsorsRevenueGraph = constructObjectForPieChart(sponsorsRevenueData, ['50%', '72%']);

    const AllSponsorsRevenueGraph = constructObjectForPieChart(sponsorsRevenueData, ['50%', '72%'], 'vertical');

    return (
        <Box id="sponsorsGraph" sx={{
            height: props?.eventSponsorsGraph ? 'calc(44% - 60px)' : '44%'
        }}>
            <TopPageAnalyticsContainer
                TrendsComponent={
                    <TrendsComponent
                        Trends={[{
                            title: 'Sponsors',
                            value: `${Number(props?.sponsorsCount) !== 0 ? props.sponsorsCount : '-'}`
                        },
                        {
                            title: 'Customers',
                            value: isDemoInstance && props?.trendData?.customer ? `${props?.trendData?.customer}` : '-',
                        },
                        {
                            title: 'Prospects',
                            value: isDemoInstance && props?.trendData?.prospect ? `${props?.trendData?.prospect}` : '-',
                        },
                        {
                            title: 'Prospects in ICP',
                            value: isDemoInstance && props?.trendData?.icp ? `${props?.trendData?.icp}` : '-',
                        }
                        ]}
                    />
                }
                GraphComponentsArray={
                    [
                        {
                            GraphComponent: <Box className="sponsorsICP" key={'sponsors-icp'}>
                                <Box className="titleContainer">
                                    <Typography className="graph-title">{'Ideal Customer Profile (ICP)'}</Typography>
                                    {sponsorsICPData?.length > 0 &&
                                        <Box className="enlarge-view" onClick={(): void => 
                                        {
                                            setOpenEnlargeGraphView({
                                                sponsorsIcpGraph: true, sponsorsFundingAmountGraph: false, sponsorsRevenueGraph: false
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                        </Box>
                                    }
                                </Box>
                                {props.isChartReady.sponsorsIcpGraphReady ? sponsorsICPData?.length > 0 ? (<Charts height={'100%'} chartOption={sponsorsICPGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : <div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div> : (<CustomSpinner height={'100%'} />)}
                            </Box>
                        },

                        {
                            GraphComponent: <Box className="sponsorFundingAmount" key={'sponsors-funding-amount'}>
                                <Box className="titleContainer">
                                    <Typography className="graph-title">{'Funding Amount '}</Typography>
                                    {sponsorsFundingAmountData?.length > 0 &&
                                        <Box className="enlarge-view" onClick={(): void => 
                                        {
                                            setOpenEnlargeGraphView({
                                                sponsorsIcpGraph: false, sponsorsFundingAmountGraph: true, sponsorsRevenueGraph: false
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                        </Box>
                                    }
                                </Box>
                                {props.isChartReady.sponsorsFundingAmountGraphReady ? sponsorsFundingAmountData?.length > 0 ? (<Charts height={'100%'} chartOption={sponsorsFundingAmountGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                            </Box>
                        },
                        {
                            GraphComponent:
                                <Box className="sponsorsRevenue" key={'sponsors-revenue'}>
                                    <Box className="titleContainer">
                                        <Typography className="graph-title">{'Sponsor by Annual Revenue'}</Typography>
                                        {sponsorsRevenueData?.length > 0 &&
                                            <Box className="enlarge-view" onClick={(): void => 
                                            {
                                                setOpenEnlargeGraphView({
                                                    sponsorsIcpGraph: false, sponsorsFundingAmountGraph: false, sponsorsRevenueGraph: true
                                                });
                                            }}>
                                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                            </Box>
                                        }
                                    </Box>
                                    {props.isChartReady.sponsorsRevenueGraphReady ? sponsorsRevenueData?.length > 0 ? (<Charts height={'100%'} chartOption={sponsorsRevenueGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : <div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div> : (<CustomSpinner height={'100%'} />)}

                                </Box>
                        }
                    ]
                }
            />




            {openEnlargeGraphView.sponsorsIcpGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        sponsorsIcpGraph: false, sponsorsFundingAmountGraph: false, sponsorsRevenueGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Ideal Customer Profile (ICP)'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllSponsorsICPGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.sponsorsFundingAmountGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        sponsorsIcpGraph: false, sponsorsFundingAmountGraph: false, sponsorsRevenueGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Funding Amount '}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllSponsorFundingAmountGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.sponsorsRevenueGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        sponsorsIcpGraph: false, sponsorsFundingAmountGraph: false, sponsorsRevenueGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Sponsor by Annual Revenue'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllSponsorsRevenueGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

        </Box>
    );
};

export default SponsorsGraph;