import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const getImageSignature = async (DATA) =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN  + `/api/v1/image/upload-signature?dataToBeSigned=${JSON.stringify(DATA)}`;
    return triggerApi(API_URL, 'GET', '', null);
};

export const uploadLexicalImage = async (images: FormData, csrfToken: string) =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/image/lexical-image';
    return triggerApi(API_URL, 'POST', images, csrfToken, false);
};

