import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { createIntegrationWithApiKey, deleteGoogleCalendarIntegration,
    deleteLumaIntegration,
    deleteRazorpayIntegration,
    getIntegrationByType,
    getIntegrationsData, } from '../../../scripts/apis/integration';
import { getHubspotIntegrationProperties, deleteHubspotIntegrationProperties } from '../../../scripts/apis/hubspot';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import IntegrationMappingPopUp from '../common/IntegrationsMappingPopup';
import { IIntegration, Integrations } from '../interface/integration_interface';
import { IntegrationSyncStatus, IntegrationTypes } from '../enum/integrations.enum';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { deleteZoomWebinar } from '../../../scripts/apis/zoomWebinar';
import { UserContext } from '../../../contexts/UserContext';
import { UserViewType } from '../enum/users.enum';
import { deleteStripeIntegration } from '../../../scripts/apis/stripe';
import { deleteEventbriteIntegration } from '../../../scripts/apis/eventbrite';


const IntegrationsPage = (props):React.JSX.Element => 
{

    const {
        userDetails
    } = useContext(UserContext);

    const [integrationsData, setIntegrationsData] = useState<Integrations[]>([]);
    const [isMappingAvailable, setIsMappingAvailable] = useState<{ [key: number]: boolean }>();
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const navigate = useNavigate();

    const getIntegrations = async ():Promise<void> => 
    {
        try 
        {
            const data = await getIntegrationsData();
            if (data) 
            {
                setIntegrationsData(data);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(():void => {
        if(refresh)
        {
            getIntegrations();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.HEADING,
            closedChartsButton: false,
        });
        getIntegrations();
    }, []);

    useEffect(():void => 
    {

        if (integrationsData) 
        {

            integrationsData.map((item):void => 
            {
                if (item?.type === IntegrationTypes.HUBSPOT) 
                {

                    setIsMappingAvailable((prevState):{[x: number]: boolean} => 
                    {
                        return {
                            ...prevState,
                            [item.type]: true
                        }; 
                    });

                    fetchExistingIntegrationDataByType(item.type);
                }
            });

        }
        else 
        {
            setIsMappingAvailable({
            });
        }

    }, [integrationsData]);

    const fetchExistingIntegrationDataByType = async (type: number):Promise<void> => 
    {

        if (type === IntegrationTypes.HUBSPOT) 
        {

            try 
            {
                const ExistingHubspotProperties = await getHubspotIntegrationProperties();

                if (ExistingHubspotProperties) 
                {
                    handleClosePopup();
                }
                else 
                {
                    setIsPopupVisible(true);
                }

            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const handleConnectClick = async (type: number, noRedirect?: boolean):Promise<void> => 
    {
        if(!noRedirect)
        {
            try 
            {
                const response = await getIntegrationByType(type);
    
                if (response) 
                {
                    window.open(response, '_self');
                }
            }
            catch (error) 
            {
                console.error('Error connecting to integration:', error);
            }
        }
        else
        {
            const IntegrationAPIHeaderComponent = () =>
            {
                return(
                    <Box id="integration-apiKey-header">
                        <p className="title">{'Lu.ma Integration'}</p>
                        <p className="description">{'Please enter your API key.'}</p>
                    </Box>
                );
            };

            setTimeout((): void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    heading: <IntegrationAPIHeaderComponent />,
                    component: <IntegrationAPIKeyComponent setRefresh={setRefresh} type={type} />,
                    componentWidth: '480px',
                    componentHeight: '250px'
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true
                });
            });
        }
    };

    const handleDisconnectClick = async ():Promise<void> => 
    {

        eventBus.on('delete-integration', async (object):Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            if (acceptObj.type === IntegrationTypes.HUBSPOT) 
            {
                try 
                {
                    const response = await deleteHubspotIntegrationProperties();

                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                }
                catch (error) 
                {
                    console.error(error);
                }

            }
            else if (acceptObj.type === IntegrationTypes.RAZORPAY) 
            {
                try 
                {
                    const response = await deleteRazorpayIntegration();

                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                }
                catch (error) 
                {
                    console.error(error);
                }

            }
            else if (acceptObj.type === IntegrationTypes.LUMA)
            {
                try 
                {
                    const response = await deleteLumaIntegration();
                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                } 
                catch (error) 
                {
                    console.error(error);
                }
            }
            else if (acceptObj.type === IntegrationTypes.GOOGLE_CALENDAR)
            {
                try 
                {
                    const response = await deleteGoogleCalendarIntegration();
                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                }
            }
            else if (acceptObj.type === IntegrationTypes.ZOOM)
            {
                try 
                {
                    const response = await deleteZoomWebinar();
                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                }
            }
            else if (acceptObj.type === IntegrationTypes.STRIPE)
            {
                try 
                {
                    const response = await deleteStripeIntegration();
                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                }
            }
            else if (acceptObj.type === IntegrationTypes.EVENTBRITE)
            {
                try 
                {
                    const response = await deleteEventbriteIntegration();
                    if (response) 
                    {
                        getIntegrations();
                        setIsMappingAvailable({
                        });
                        navigate(0);
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                }
            }
        });
    };

    const handleClosePopup = ():void => 
    {
        setIsPopupVisible(false);
    };

    const handleEditClick = ():void => 
    {
        setIsPopupVisible(true);
    };

    const integrations: IIntegration[] = props.integrationsArr;
    // {
    //   name: "Salesforce",
    //   description:
    //     "",
    //   type: 2,
    // },

    return (
        <Box id={userDetails?.viewType === UserViewType.MINIMAL ? "integrationsPageMinimalView" : "integrationsPage"}>
            <Grid container spacing={2.5} className="integrations-content">
                {integrations.map((item, index):React.JSX.Element => 
                {
                    const connected = integrationsData?.some(
                        (integration):boolean => 
                        {
                            return integration.type === item.type; 
                        }
                    );
                    return (
                        <IntegrationMethod
                            key={index}
                            name={item.name}
                            icon={item.website}
                            description={item.description}
                            type={item.type}
                            connected={connected}
                            onConnectClick={() => {handleConnectClick(item.type, item?.noRedirect);}}
                            onDisconnectClick={handleDisconnectClick}
                            onEditClick={handleEditClick}
                            handleClosePopup={handleClosePopup}
                            isComingSoon={item.comingSoon}
                            isEditVisible={isMappingAvailable?.[item.type] ? isMappingAvailable[item?.type] : false}
                            isPopupVisible={isPopupVisible && isMappingAvailable?.[item.type]}
                            syncStatus={integrationsData?.find((integration):boolean => { return integration.type === item.type; })?.syncStatus || undefined}
                        />
                    );
                })}
            </Grid>
        </Box>
    );
};

export default IntegrationsPage;

const IntegrationMethod = (props: {
  name: string;
  icon: string;
  description: string;
  type: number;
  connected: boolean;
  onConnectClick: (type: number, noRedirect?: boolean) => void;
  onDisconnectClick: (type: number) => void;
  onEditClick: () => void;
  handleClosePopup: () => void;
  isEditVisible: boolean;
  isPopupVisible: boolean;
  isComingSoon: boolean;
  noRedirect?: boolean;
  syncStatus?: IntegrationSyncStatus;
}):React.JSX.Element => 
{


    const userMe = LocalStorage.get('@UserMe');
    const role = userMe.role;

    const handleDelete = ():void => 
    {

        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: 'Delete Integration',
            content: `Are you sure you want to delete ${props.name}  Integration ?`,
            acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
            acceptEvent: 'delete-integration',
            rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
            acceptObj: {
                type: props.type
            },
        });

        props.onDisconnectClick(props.type);

    };

    return (
        <>
            <Grid item xl={4} lg={4} md={4} position={'relative'}>
                <div className="integration-card">
                    <div className="integration-card-heading">
                        <Box className="integration-heading-icon-container">
                            <div className="integration-card-icon">
                                <img src={`https://www.google.com/s2/favicons?sz=64&domain_url=${props.icon}`} alt={props.name} />
                            </div>
                            <Box className="integration-name-container">
                                <div className="integration-name">{props.name}</div>
                                {props.description ? (<div className="integration-description">{props.description}</div>) : null}
                            </Box>
                        </Box>
                        {/* {props.connected ?
                            (<div className={'disconnect-block'}>
                                {props.isEditVisible ? (
                                    <div className={role === 3 ? 'integration-edit' : 'integration-edit-disable'} onClick={():void => 
                                    {
                                        if (role === 3) 
                                        {
                                            props.onEditClick();
                                        }

                                    }}>
                                        <FontAwesomeIcon icon={['fal', 'pen']} className={role === 3 ? 'edit-icon' : 'edit-icon-disable'} />
                                    </div>
                                ) : null}
                                <button
                                    className={role === 3 ? 'integration-card-button-disconnect' : 'integration-card-button-disconnect-disable'}
                                    disabled={role !== 3}
                                    onClick={():void => 
                                    {
                                        if (role === 3) 
                                        {
                                            handleDelete();
                                        }
                                    }}
                                >{'Disconnect'}</button>
                            </div>) : !props.connected ?
                                (
                                    <button
                                        className="integration-card-button"
                                        onClick={():void => 
                                        {
                                            props.onConnectClick(props.type); 
                                        }}
                                    >{'Connect'}</button>
                                ) : null
                        } */}
                    </div>
                    
                    <div className="view-integration-container">
                        {props?.isComingSoon ? (<Typography className="coming-soon-text">{'Coming Soon...'}</Typography>) : (props.isEditVisible && role === 3) ? (
                            <FontAwesomeIcon className="settings-icon" onClick={() => { props.onEditClick(); }} icon={['fal', 'gear-complex']} />
                        ) : <Typography height={'18px'}>{''}</Typography>}
                        {!props?.isComingSoon && <Box className="integration-toggle-block">
                            <FormControlLabel
                                checked={props?.connected}
                                disabled={role !== 3}
                                control={<CustomSwitch
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        if (role === 3) 
                                        {
                                            if (event.target.checked) 
                                            {
                                                props.onConnectClick(props.type, props?.noRedirect);
                                            }
                                            else 
                                            {
                                                handleDelete();
                                            }
                                        }
                                    }}
                                />}
                                label={''}
                            />
                        </Box>}
                    </div>

                    {props.isPopupVisible ? (
                        <IntegrationMappingPopUp
                            CloseButtonFunction={props.handleClosePopup}
                            type={props.type}
                            syncStatus={props?.syncStatus}
                            popUpHeading={
                                <div className="heading-content">
                                    <img className="integration-icon" src={`https://www.google.com/s2/favicons?sz=64&domain_url=${props.icon}`} alt={props.name} />
                                    <p className="integration-name">{props?.name}</p>
                                </div>
                            }
                        />
                    ) : null}

                </div>
                {/* {props.isComingSoon &&
          <Box className="overlay-box">
              <Typography className="overlay-text">{'Coming soon'}</Typography>
          </Box>} */}
            </Grid>
        </>
    );
};

const IntegrationAPIKeyComponent = (props) =>
{
    const { type, setRefresh } = props;

    const navigate = useNavigate();
    
    const [apiKey, setApiKey] = useState<string>('');

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const handleSubmit = async ():Promise<void> =>
    {
        try 
        {
            const response = await createIntegrationWithApiKey(type, { apiKey }, csrfTokenData);
            if(response)
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, true);
                toast.success('Integration Successfull');
                setRefresh(true);
                toast.success('Fetching Luma events is in progress. This may take a few seconds.');
            }
        } 
        catch (error) 
        {
            toast.error('Something went wrong. Please try again.');
        }
    };

    return(
        <Box id="integrationAPIKeycomponent">
            <Box margin={'16px 0'}>
                <FormLabelComponent label='API Key' />
                <FormControlComponent 
                    type='text'
                    value={apiKey}
                    onChange={(event):void => setApiKey(event.target.value)}
                    placeholder='Enter API Key'
                />
            </Box>
            <Box className="apiKey-submit-cont">
                <CustomButton btnType='secondary' name='Cancel' onClick={() => {eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, true)}} style={{ width: '210px', fontSize: '16px', fontWeight: 600, padding: '10px var(--spacing-xl, 16px)', maxHeight: '44px', height: 'unset' }} />
                <CustomButton btnType='primary' name='Save' onClick={handleSubmit} style={{ width: '210px', fontSize: '16px', fontWeight: 600, padding: '10px var(--spacing-xl, 16px)', maxHeight: '44px' }} />
            </Box>
        </Box>
    );
};

