import React from 'react';
import _ from 'lodash';

import './styles.scss';

interface EventAnalyticsTableProps {
    columns?: string[];
    rows: { [key: string]: any }[];
    notATable?: boolean;
    tableHeader?: string;
}

const EventAnalyticsTable: React.FC<EventAnalyticsTableProps> = ({ columns, rows, notATable, tableHeader }) => 
{
    return (
        <div id="eventAnalyticsTable">
            <div className="content-div">
                <div className="event-analytics-table-container">    
                    {notATable ? 
                        <div className="flex-table">
                            <div className="table-header-div">
                                <h3>{tableHeader}</h3>
                            </div>
                            {rows.map((row, index) => (
                                <div key={index} className="row">
                                    <div className="cell">{_.capitalize(row?.name)}</div>
                                    <div className="cell">{row.total}</div>
                                </div>
                            ))}
                        </div> :
                        <table className="table">
                            <thead className="table-head">
                                <tr className="table-head-row">
                                    {columns && columns?.length > 0 && columns.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="table-body">
                                {rows.map((row, index) => {
                                    return (
                                        <tr key={index} className="table-body-row">
                                            {columns && columns?.length > 0 && columns.map((column, colIndex) => (
                                                <td key={colIndex}>
                                                    {row[column?.toLowerCase()]}
                                                </td>
                                            ))}
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    );
};

export default EventAnalyticsTable;