import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { CONTENT } from '../scripts/i18n';

const ColorPicker: React.FC<{ selectedColor: string, setSelectedColor: React.Dispatch<React.SetStateAction<string>> }> = (props: { selectedColor: string, setSelectedColor: React.Dispatch<React.SetStateAction<string>> }): React.JSX.Element => 
{

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>):void => 
    {
        props.setSelectedColor((event.target as HTMLInputElement).value);
    };

    return (
        <div id="colorPicker">
            <RadioGroup row value={props.selectedColor} onChange={handleColorChange}>
                {
                    Object.keys(CONTENT.BRAND_COLOR_OBJ).map((item, index):React.JSX.Element => 
                    {
                        return (
                            <FormControlLabel className="color-picker-control" key={index} value={item} control={<Radio className="picker-radio" sx={{
                                color: item, '&.Mui-checked': {
                                    color: item 
                                }, 
                            }} />} label="" />
                        );
                    })

                }
            </RadioGroup>
        </div>
    );
};

export default ColorPicker;