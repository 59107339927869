import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormControlComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { Stack } from '@mui/material';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { CONTENT } from '../../../../scripts/i18n';
import { useContext, useState } from 'react';
import { addTeamMember, removeTeamMember } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeletePopup from '../../../../common/DeletePopup';
import { EventRegistrant } from '../../../Events/interfaces/event-registrant_interface';
import { PortalContext } from '../../../../contexts/Portal/PortalContext';

import './styles.scss'

interface AddTeamMemberProps 
{
    existingMemberData: EventRegistrant | undefined;
    handleClose: () => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTeamMember: React.FC<AddTeamMemberProps> = (props): React.JSX.Element =>
{

    const { registrantData } = useContext(PortalContext);
    const { existingMemberData, handleClose, setRefresh } = props;
    const [spinner, setSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required'),
    });

    const handleFormSubmit = async (values: any): Promise<void> =>
    {
        try 
        {
            const data = {
                email: values.email,
            };

            const teamMemberAdded = await addTeamMember(data);
            if (teamMemberAdded) 
            {
                setRefresh(true);
                handleClose();
                toast.success('Team member added successfully');
            }
        } 
        catch (error) 
        {
            console.log('Error in adding team member', error);
            toast.error((error as Error)?.message);
        }
        finally
        {
            setSpinner(false);
        }
    };

    const handleDeleteMember = async (): Promise<void> =>
    {
        try 
        {
            const memberRemoved = await removeTeamMember(existingMemberData?.id);
            if (memberRemoved)
            {
                setRefresh(true);
                handleClose();
                toast.success('Team member removed successfully');
            }
        } 
        catch (error) 
        {
            console.log('Error in removing team member', error);
            toast.error((error as Error)?.message || 'Error in removing team member');
        }
    };

    return (
        <div id="addTeamMember">
            <Formik
                initialValues={{
                    email: existingMemberData?.email || '',
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setSpinner(true);
                    handleFormSubmit(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched }): React.ReactElement => {
                    return (
                        <Form className="h-100" onSubmit={handleSubmit}>
                            <div className="add-teamMember-form">
                                {/* Email */}
                                <div className="sidebar-container-spacing">
                                    <FormLabelComponent label='Email' required />
                                    <FormControlComponent 
                                        type='email'
                                        name='email'
                                        value={values.email}
                                        onChange={handleChange}
                                        required
                                        isInvalid={(errors.email && touched.email) as boolean}
                                        disabled={existingMemberData}
                                    />
                                    {errors.email && touched.email && <div className="error-msg">{errors?.email}</div>}
                                </div>

                                <div className="submission-container">
                                    {!existingMemberData ? 
                                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                                            <CustomButton onClick={handleClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                            <CustomButton type="submit" loading={spinner} disabled={spinner} name={"Save"} btnType={"primary"} />
                                        </Stack>
                                        :
                                        <>
                                        {
                                            existingMemberData?.email !== registrantData?.email && 
                                            <div>
                                                <CustomButton 
                                                    btnType="danger"
                                                    name="Remove"
                                                    startIcon={<FontAwesomeIcon icon={['fal', 'trash']} />}
                                                    onClick={() => setShowDeletePopup(true)}
                                                />
                                            </div>
                                        }
                                        </>
                                    }
                                </div>
                            </div>

                            {
                                showDeletePopup &&
                                <DeletePopup 
                                    modalTitle="Remove Member"
                                    modalContent="Are you sure you want to remove this member?"
                                    acceptBtn="Remove"
                                    rejectBtn="Cancel"
                                    acceptClick={handleDeleteMember}
                                    cancelClick={() => setShowDeletePopup(false)}
                                    show={showDeletePopup}
                                />
                            }
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
};

export default AddTeamMember;