import * as React from 'react';
import _ from 'lodash';
import { Tabs, Tab, Typography, Box, AppBar, Chip, Popover } from '@mui/material';
import { Link } from 'react-router-dom';
import './styles.scss';

const TabPanel = (props): React.JSX.Element => 
{
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            display={'flex'}
            flexDirection={'column'}
            flex={'1'}
            {...other}>
            {value === index && (
                <Box className="tab-content-box" >
                    {/* <Box sx={{height:"100%", border: '5px solid green'}}>{children}</Box> */}
                    {children}
                </Box>
            )}
        </Box>
    );
};

`TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};`

const a11yProps = (index) => 
{
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const PortalTabs = (pageContext): React.JSX.Element => 
{
    const position = _.findIndex(pageContext.tabData, (tab: { selected?: number | boolean }): boolean => 
    {
        return tab.selected === true;
    });

    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect((): void => 
    {
        setValue(position !== -1 ? position : 0);
    }, [position]);

    const handleChange = (event, newValue): void => 
    {
        setValue(newValue);
    };

    const handlePopoverOpen = (event): void => 
    {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (): void => 
    {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Box id="portalTabs">
            <AppBar elevation={0} position="static" className="app-bar">
                <Tabs
                    TabIndicatorProps={{
                        style: {
                        //     // background: '#007AFF',
                            borderBottom: '2px solid #1570EF'
                        }
                        
                    }}
                    value={value}
                    className="navigation-tabs"
                    onChange={handleChange}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    textColor="inherit"
                    aria-label="full width tabs example">
                    {
                        pageContext.tabData.map((item, key): React.JSX.Element => 
                        {
                            if (item?.disabled) 
                            {
                                return (
                                    <Box key={key}>
                                        <Tab className="disabled-tab-button" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} disableRipple icon={item.count ? <Chip className="tab-chip" label={item.count} /> : ''} iconPosition="end" label={item.title} {...a11yProps(key)}></Tab>
                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <Typography sx={{
                                                p: 1, fontSize: '12px' 
                                            }}>{'You can\'t access the tickets module as the event access type is open.'}</Typography>
                                        </Popover>
                                    </Box>
                                );
                            }
                            else if (item?.onClick)
                            {
                                return (
                                    <Tab className="navigation-tab-button" key={key} disableRipple icon={item.count ? <Chip className="tab-chip" label={item.count} /> : ''} iconPosition="end" label={item.title} onClick={item?.onClick} {...a11yProps(key)}></Tab>
                                );
                            }
                            else 
                            {
                                return (
                                    <Tab className="navigation-tab-button" key={key} disabled={item?.disabled} disableRipple icon={item.count ? <Chip className="tab-chip" label={item.count} /> : ''} iconPosition="end" label={item.title} component={Link} to={item.to} {...a11yProps(key)}></Tab>
                                );
                            }
                        })
                    }
                </Tabs>
            </AppBar>
            {/* <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}> */}
            <Box className="tabContentContainer">
                {
                    pageContext.tabData.map((item, key): React.JSX.Element => 
                    {
                        return (
                            // <Box className="tab-content" key={key} >
                            <TabPanel value={value} index={key} key={key} >{item.data}</TabPanel>
                            // </Box>
                        );
                    })
                }
            </Box>

            {/* </SwipeableViews> */}
        </Box>
    );
};
export default PortalTabs;