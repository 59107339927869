import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import TrendsComponent from '../../common/TrendsComponent';
import CustomSpinner from '../../common/CustomSpinner';
import Charts from '../../common/ECharts/Charts';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpView from '../../common/PopupView';
import { NumberFormatter } from '../../scripts/helpers';
// import { CONTENT } from '../../scripts/i18n';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';

interface IEventsChartReady {
    eventsTypeGraphReady?: boolean;
    eventsStatusGraphReady?: boolean;
    eventsLocationGraphReady?: boolean;
}

interface IEventsGraph {
    isChartReady: IEventsChartReady;
    eventsTypeData: { name: string, percentage: string | number, value: string | number }[];
    eventStatusData: { name: string, percentage: string | number, value: string | number  }[];
    eventLocationData: { name: string, percentage: string | number, value: string | number  }[];
    eventsTrendData: {
        count: number,
        avgRoi: number,
        pipeline: number,
        revenue: number
    };
}

const EventsGraph: React.FC<IEventsGraph> = (props: IEventsGraph): React.JSX.Element => 
{
    // const [graphContainerHeight, setgraphContainerHeight] = useState(0);
    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        eventsTypeGraph: false,
        eventStatusGraph: false,
        eventLocationGraph: false,
    });

    // useEffect((): () => void => 
    // {
    //     const handleResize = (): void => 
    //     {
    //         const screenHeight = window.innerHeight;

    //         const seventyPercentHeight = 0.75 * screenHeight;

    //         const containerHeight = screenHeight - seventyPercentHeight;
    //         setgraphContainerHeight(containerHeight);
    //     };

    //     handleResize();

    //     window.addEventListener('resize', handleResize);
    //     return (): void => 
    //     {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [graphContainerHeight]);

    const eventsTypeGraph = constructObjectForPieChart(props?.eventsTypeData, ['50%','72%']);

    const AllEventsTypeGraph = constructObjectForPieChart(props?.eventsTypeData, ['50%','72%'], 'vertical', '');

    const eventStatusGraph = constructObjectForPieChart(props?.eventStatusData, ['50%', '72%']); 

    const allEventStatusGraph = constructObjectForPieChart(props?.eventStatusData, ['50%', '72%'], 'vertical', '');

    const eventsLocationGraph = constructObjectForPieChart(props?.eventLocationData, ['50%', '72%']);

    const allEventLocationGraph = constructObjectForPieChart(props?.eventLocationData, ['50%', '72%'], 'vertical', '');

    return (
        <Box id="eventsGraph">

            <TopPageAnalyticsContainer
                TrendsComponent={
                    <TrendsComponent
                        Trends={[{
                            title: 'Total Events',
                            value: props.eventsTrendData?.['count'] ? `${Number(props.eventsTrendData?.['count']) !== 0 ? props.eventsTrendData['count'] : '-'}`:'-'
                        },
                        {
                            title: 'Avg ROI',
                            value: props.eventsTrendData?.['avgRoi'] ? `${Number(props.eventsTrendData?.['avgRoi']) !== 0 ? Number(props.eventsTrendData['avgRoi']) : '-'}`:'-',
                        },
                        {
                            title: 'Pipeline',
                            value: props.eventsTrendData?.['pipeline'] ? `${Number(props.eventsTrendData?.['pipeline']) !== 0 ? `$${NumberFormatter(props.eventsTrendData['pipeline'])}` : '-'}`: '-',
                        },
                        {
                            title: 'Revenue',
                            value: props.eventsTrendData?.['revenue'] ? `${Number(props.eventsTrendData?.['revenue']) !== 0 ? `$${NumberFormatter(props.eventsTrendData['revenue'])}` : '-'}`:'-',
                        }
                        ]}
                    />
                }
                GraphComponentsArray={[
                    {
                        GraphComponent: <Box className="eventsType">
                            <Box className="titleContainer">
                                <Typography className="graph-title">{'Type'}</Typography>
                                {props.eventsTypeData.length > 0 &&
                                <Box className="enlarge-view" onClick={(): void => 
                                {
                                    setOpenEnlargeGraphView({
                                        eventsTypeGraph: true,
                                        eventLocationGraph: false,
                                        eventStatusGraph: false 
                                    }); 
                                }}>
                                    <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                </Box>
                                }
                            </Box>
                            {props.isChartReady.eventsTypeGraphReady ? props.eventsTypeData.length > 0 ? (<Charts height={'100%'} chartOption={eventsTypeGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                        </Box>
                    },
                    {
                        GraphComponent:  <Box className="eventsStatus">
                            <Box className="titleContainer">
                                <Typography className="graph-title">{'Status'}</Typography>
                                {props.eventStatusData.length > 0 && 
                            (<Box className="enlarge-view" onClick={(): void => 
                            {
                                setOpenEnlargeGraphView({
                                    eventsTypeGraph: false,
                                    eventLocationGraph: false,
                                    eventStatusGraph: true 
                                }); 
                            }}>
                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                            </Box>)}
                            </Box>
                            {props.isChartReady.eventsStatusGraphReady ? props.eventStatusData.length > 0 ? (<Charts height={'100%'} chartOption={eventStatusGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                        </Box>
                    },
                    {
                        GraphComponent:  <Box className="eventsLocation">
                            <Box className="titleContainer">
                                <Typography className="graph-title">{'Event Format'}</Typography>
                                {props?.eventLocationData?.length > 0 && (<Box className="enlarge-view" onClick={(): void => 
                                {
                                    setOpenEnlargeGraphView({
                                        eventsTypeGraph: false,
                                        eventLocationGraph: true,
                                        eventStatusGraph: false 
                                    }); 
                                }}>
                                    <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                </Box>)}
                            </Box>
                            {props.isChartReady.eventsLocationGraphReady ? props.eventLocationData.length > 0 ? (<Charts height={'100%'} chartOption={eventsLocationGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
        
                        </Box>
                    }
                ]}
            />
            {openEnlargeGraphView.eventsTypeGraph && (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventsTypeGraph: false,
                        eventLocationGraph: false,
                        eventStatusGraph: false 
                    }); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllEventsTypeGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            )}

            {openEnlargeGraphView.eventStatusGraph &&
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventsTypeGraph: false,
                        eventLocationGraph: false,
                        eventStatusGraph: false 
                    }); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Status'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allEventStatusGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            }

            {openEnlargeGraphView.eventLocationGraph &&
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        eventsTypeGraph: false,
                        eventLocationGraph: false,
                        eventStatusGraph: false 
                    }); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Event Format'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allEventLocationGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            }


        </Box>
    );
};

export default EventsGraph;