import { createSlice } from '@reduxjs/toolkit';

export const ExpoRowSlice = createSlice({
    name: 'expo',
    initialState: {
        value: {
            name: '',
            description: '',
            email: '',
            headline: '',
            expoSize: '',
            expoPriority: 0,
            logo: '',
            banner: '',
            about: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            tags: [],
            socialLink: '',
            buttonText: '',
            buttonAction: '',
            additionalInfo: '',
            scheduled: 2,
        }
    },
    reducers: {
        addExpo: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetExpo: (state) => {
            state.value = {
                name: '',
                description: '',
                email: '',
                headline: '',
                expoSize: '',
                expoPriority: 0,
                logo: '',
                banner: '',
                about: '',
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                tags: [],
                socialLink: '',
                buttonText: '',
                buttonAction: '',
                additionalInfo: '',
                scheduled: 2,
            }
        },
    },
});

export const { addExpo, resetExpo } = ExpoRowSlice.actions;
export default ExpoRowSlice.reducer;