export enum EventSessionStatus 
    {
    ACTIVE = 1,
    REMOVED = 2,
}


export enum EventSessionTypes
{
    SESSION = 1,
    KEY_NOTE = 2,
    BREAK = 3
}
