import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormControlComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { accessSponsor, authorizeSponsor } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APP_CONSTANTS from '../../../../scripts/constants';
import { LocalStorage } from '../../../../scripts/LocalStorage';
import toast from 'react-hot-toast';
import { PortalContext } from '../../../../contexts/Portal/PortalContext';

import eventHqLogo from '../../../../assets/eventhq.png';
import authPageBackgroundImgWithLogo from '../../../../assets/authbg-with-logo.svg';
import ehqSpinner from '../../../../assets/ehqlogo_loader.gif';

import './styles.scss';

const PortalAuthPage: React.FC = (): React.JSX.Element =>
{
    const pathname = useLocation().pathname;
    const userDetails = LocalStorage.get('@SponsorDetails');

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [componentConditions, setComponentConditions] = useState<'login' | 'mailSent'>('login');
    const [showSpinner, setShowSpinner] = useState<boolean>(token ? true : false);
    const [validationSpinner, setValidationSpinner] = useState<boolean>(false);

    const { handleModuleChange } = useContext(PortalContext);

    const params = pathname.split('/').filter(Boolean);

    const orgLink = params.length >= 2 ? params[0] : null;

    const eventLink = params.length >= 2 ? params[1] : params[1];

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    const validateMagicLink = async (): Promise<void> =>
    {

        const data = {
            orgLink,
            eventLink,
            token,
        };

        try 
        {
            const userAuthorized = await authorizeSponsor(data);
            if(userAuthorized)
            {
                // Redirect to dashboard
                LocalStorage.set('@SponsorDetails', userAuthorized);

                if(LocalStorage.get('@SponsorDetails'))
                {
                    handleModuleChange('dashboard');
                    const url = `${APP_CONSTANTS.PROTOCOL}://${APP_CONSTANTS.MAIN_DOMAIN}/${orgLink}/${eventLink}/portal`;
                    window.location.replace(url);
                    setShowSpinner(false);
                }
                else
                {
                    setTimeout(() => {
                        window.location.replace(`${APP_CONSTANTS.PROTOCOL}://${APP_CONSTANTS.MAIN_DOMAIN}/${orgLink}/${eventLink}/portal`);
                    }, 1000);
                }
            }
        } 
        catch (error) 
        {
            console.log('Error validating magic link', error);
            setShowSpinner(false);
            toast.error('Invalid token. Please login again.')

            setTimeout(() => {
                window.location.replace(`${APP_CONSTANTS.PROTOCOL}://${APP_CONSTANTS.MAIN_DOMAIN}/${orgLink}/${eventLink}/portal`);
            }, 1000);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        if(!userDetails && token)
        {
            validateMagicLink();
        }
        else if(token && userDetails)
        {
            setShowSpinner(false);
            window.location.replace(`${APP_CONSTANTS.PROTOCOL}://${APP_CONSTANTS.MAIN_DOMAIN}/${orgLink}/${eventLink}/portal`);
        }
    }, []);

    return (
        <div id="portalAuthPage">
            <img src={authPageBackgroundImgWithLogo} alt="background" className="loginPage-bg-image" />
            <img src={eventHqLogo} alt="eventHqLogo" className="header-logo-img" />

            {(!showSpinner) && <Formik
                initialValues={{
                    email: '',

                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    setValidationSpinner(true);
                    const data = {
                        email: values.email,
                        orgLink,
                        eventLink
                    };

                    try 
                    {
                        const authenticated = await accessSponsor(data);
                        if(authenticated)
                        {
                            setComponentConditions('mailSent');
                        }
                    } 
                    catch (error) 
                    {
                        
                    }
                    finally
                    {
                        setValidationSpinner(false);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }): React.ReactElement => { 
                    return (
                        <>
                            {componentConditions === 'login' ? <Form onSubmit={handleSubmit}>
                                <div className="portal-auth-page-container">
                                    <div className="sidebar-container-spacing">
                                        <FormLabelComponent label='Email' required />
                                        <FormControlComponent 
                                            type='email' 
                                            name='email' 
                                            placeholder='Enter your email'
                                            value={values.email} 
                                            onChange={handleChange} 
                                            isInvalid={(errors.email && touched.email) as boolean}
                                            required
                                        />
                                        {errors.email && touched.email && <div className="error-msg">{errors.email}</div>}
                                    </div>

                                    <CustomButton loading={validationSpinner} style={{ width: '100%', margin: '16px 0' }} type='submit' name='Continue' btnType='primary' />

                                </div>
                            </Form> 
                            : 
                            <div className="portal-auth-page-container">
                                <div className="title-container">
                                    <h2 className="heading">Check your email</h2>
                                    <p className="content">{`We have sent a link to ${values.email}. Click on the link to access your account.`}</p>
                                </div>
                                <div className="back-button">
                                    <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} name='Back to Login' btnType='secondary' onClick={() => setComponentConditions('login')} />
                                </div>
                            </div>
                            }
                        </>
                    )
                }}
            </Formik>}

            {
                showSpinner &&
                <div className="token-spinner-container">
                    <img src={ehqSpinner} className="auth-spinner" />
                    <h2>{'Validating the magic link...'}</h2>
                </div>
            }
        </div>
    )
};

export default PortalAuthPage;