import React, { useState, useEffect } from 'react';
import { Form, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FormControlComponent, FormLabelComponent } from './ReusableFormComponents';

const PlaceholderAutoCompleteInput: React.FC<{options: string[]; initialInputValue: string; onChange?: (value: any) => void}> = (props) => {

  const { options, initialInputValue, onChange } = props;

  const [inputValue, setInputValue] = useState(initialInputValue || '');
  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const optionPlaceholders = options.map(option => `{{${option}}}`); // Transform options to placeholders

  useEffect(() => {
    setInputValue(initialInputValue || '');
  }, [initialInputValue]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    props?.onChange(value);
    setShowDropdown(value.endsWith('{{'));
  };

  const handleSelect = (variable) => {
    const placeholder = `{{${variable}}}`;
    const newValue = `${inputValue.slice(0, -2)}${placeholder} `;
    setInputValue(newValue);
    props?.onChange(newValue);
    setShowDropdown(false);
    setFocusedOptionIndex(null); // Reset focus state after selection
  };

  const handleKeyDown = (e) => {
    if (showDropdown) {
      // Only allow arrow navigation when dropdown is shown
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedOptionIndex(prevIndex =>
          prevIndex === null || prevIndex >= options.length - 1 ? 0 : prevIndex + 1
        );
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedOptionIndex(prevIndex =>
          prevIndex === null || prevIndex <= 0 ? options.length - 1 : prevIndex - 1
        );
      } else if (e.key === 'Enter' && focusedOptionIndex !== null) {
        e.preventDefault();
        handleSelect(options[focusedOptionIndex]);
      }
    }

    if (e.key === 'Backspace') {
      // Check if the cursor is at the end of an option placeholder
      const placeholderIndex = optionPlaceholders.findIndex(placeholder =>
        inputValue.endsWith(placeholder)
      );
      if (placeholderIndex > -1) {
        // Prevent default backspace behavior
        e.preventDefault();
        // Remove the option placeholder
        const placeholderLength = optionPlaceholders[placeholderIndex].length;
        setInputValue(inputValue.slice(0, -placeholderLength));
        setShowDropdown(false);
        setFocusedOptionIndex(null); // Reset focused option
      }
    }
  };

  return (
    <Form.Group controlId="autocompleteInputGroup">
      <FormLabelComponent label='Subject Line' />
      <FormControlComponent
        type="text"
        as={'textarea'}
        rows={2}
        required={true}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Subject Line"
      />
      {showDropdown && (
        <Dropdown as={ButtonGroup} show>
          <Dropdown.Menu show>
            {options.map((option, idx) => (
              <Dropdown.Item
                key={idx}
                onClick={() => handleSelect(option)}
                active={idx === focusedOptionIndex} // Highlight the active item
              >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Form.Group>
  );
};

export default PlaceholderAutoCompleteInput;