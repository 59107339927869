import React, { useContext, useEffect, useRef, useState } from 'react';
import { getBudgetCount, getBudgetFromEventId, getEventBudgetGraph } from '../../../scripts/apis/eventBudget';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import { Box, Stack } from '@mui/material';
import EventBudgetChartsContainer from './EventBudgetChart';
import EventBudgetDataGrid, { uniqueId } from './EventBudgetDataGrid';
import CreateBudgetCategory from '../../../components/Budgets/CreateBudgetCategory';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import { refreshBudgetDetails } from '../../../redux/events/eventBudget/EventBudget';
import { useTablePagination } from '../../../contexts/TablePaginationContext';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { BudgetExpenditure, BudgetGraphObject } from '../../Budget/interfaces';
import { EventBudgetGraphObject } from '../interfaces';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/UserContext';
import { UserViewType } from '../../Settings/enum/users.enum';
import HeaderBar from '../../../common/Headerbar';
import { getEncodedParams } from '../../../scripts/helpers';
// import _ from 'lodash';

const getUniqueName = (name, existingNames):string => 
{

    let uniqueName = name;
    let suffix = 1;

    while (existingNames.has(uniqueName)) 
    {
        uniqueName = `${name} -${suffix}`;
        suffix++;
    }
    return uniqueName;
};

export interface IRefreshEventBudget {
    eventBudget: {
        value: {
            refreshBudget: boolean;
        }
    }
}

const categoriesGraphXaxis = ['SALES', 'MARKETING', 'DESIGN', 'BOOTHS', 'VENDORS'];

const EventBudget = (property):React.JSX.Element => 
{

    const {
        userDetails
    } = useContext(UserContext);

    const [budgetDetails, setEventBudgetDetails] = useState([]);
    const [chartReady, isChartReady] = useState<{
        budgetSummaryGraph: boolean;
        expenditureGraph: boolean;
        budgetCategoryGraph: boolean;
    }>({
        budgetSummaryGraph: false,
        expenditureGraph: false,
        budgetCategoryGraph: false,
    });
    const [budgetGraphData, setBudgetGraphData] = useState<{
        trend?: BudgetGraphObject | {};
        budgetSummaryGraph?: EventBudgetGraphObject[] | [];
        expenditureGraph?: EventBudgetGraphObject[] | [];
        budgetCategoryGraph?: [string[], number[]] | [];
    
    }>({
        trend: null,
        budgetSummaryGraph: [],
        expenditureGraph: [],
        budgetCategoryGraph: [],
    });
    
    const [graphBudgetDetails, setGraphBudgetDetails] = useState([]);
    const dispatch = useDispatch();
    const TableContainerRef = useRef<HTMLDivElement>();
    const [eventBudgetCount, setEventBudgetCount] = useState<number>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    const [chartVisibility, setChartVisibility] = useState(false);

    const refreshBudgetDetailsValue = useSelector((state: IRefreshEventBudget):boolean => 
    {
        return state.eventBudget.value.refreshBudget;
    });

    let eventBudgetValue = 0;
    let actualSpendValue = 0;
    const existingNames = new Set();

    const getBudgetDataCount = async ():Promise<void> => 
    {
        try 
        {
            const budgetCount = await getBudgetCount(property['data-id']);

            if (budgetCount) 
            {
                setEventBudgetCount(budgetCount);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const getBudgetData = async ():Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const data = await getBudgetFromEventId(property['data-id'], pageSize, currentPage - 1, 1, encodedParams);

            if (data) 
            {
                setEventBudgetDetails(data);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchEventBudgetGraph = async (): Promise<void> =>
    {
        try {
            const eventBudgetGraph = await getEventBudgetGraph(property['data-id']);
            if(eventBudgetGraph)
            {
                if(eventBudgetGraph?.trend)
                {
                    // const eventBudgetSummaryGraphData = [
                    //     {
                    //         name: 'Spent',
                    //         percentage: Math.round((eventBudgetGraph?.trend?.spent / eventBudgetGraph?.trend?.budget) * 100),
                    //         value: actualSpendValue
                    //     },
                    //     {
                    //         name: 'Remaining',
                    //         percentage: Math.round(((eventBudgetGraph?.trend?.budget - eventBudgetGraph?.trend?.spent) / eventBudgetGraph?.trend?.budget) * 100),
                    //         value: eventBudgetValue - actualSpendValue,
                    //     }
                    // ];

                    setBudgetGraphData((prevState): { 
                        trend: any;
                    } => 
                    {
                        return {
                            ...prevState,
                            trend: eventBudgetGraph?.trend,
                        };
                    });
                }
                if(eventBudgetGraph?.expenditure) {
                    const newExpenditure = eventBudgetGraph?.expenditure?.map((item: BudgetExpenditure) => { 
                        return {
                            name: item.name,
                            value: item.actualSpend,
                            percentage: item.percentage,
                        };
                    })

                    if(newExpenditure)
                    {
                        setBudgetGraphData((prevState): { 
                            budgetSummaryGraph: any; 
                            expenditureGraph: any; 
                            budgetCategoryGraph: any; 
                        } => 
                        {
                            return {
                                ...prevState,
                                expenditureGraph: newExpenditure,
                            };
                        })
                    }
                }
                if(eventBudgetGraph?.categoryBudget)
                {
                    const yAxisValues = Array(categoriesGraphXaxis.length).fill(0);
                    Object.entries(eventBudgetGraph?.categoryBudget).forEach(([key, value]): void => 
                    {
                        const index = categoriesGraphXaxis.indexOf(key);
                        if (index !== -1) 
                        {
                            yAxisValues[index] = value;
                        }
                    });
                    const resultArray = [categoriesGraphXaxis.map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()) , yAxisValues];

                    setBudgetGraphData((prevState): 
                    { 
                        budgetSummaryGraph: any; 
                        expenditureGraph: any; 
                        budgetCategoryGraph: any; 
                    } => 
                    {
                        return {
                            ...prevState,
                            budgetCategoryGraph: resultArray,
                        };
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
        finally
        {
            isChartReady((prevState): { 
                budgetSummaryGraph: boolean; 
                expenditureGraph: boolean; 
                budgetCategoryGraph: boolean; 
            } => 
            {
                return {
                    ...prevState,
                    budgetSummaryGraph: true,
                    expenditureGraph: true,
                    budgetCategoryGraph: true,
                };
            
            });
        }
    };

    useEffect(():void => 
    {
        if (TableContainerRef.current) 
        {
            const availableHeightForTable = TableContainerRef.current?.clientHeight;
            const availableHeightForRow = availableHeightForTable - 108;
            if (availableHeightForRow > 0) 
            {
                const availablePageSize = Math.round(availableHeightForRow / 52);
                updatePageSize(availablePageSize);
            }
        }
    }, [TableContainerRef.current]);

    useEffect(():void => 
    {
        if (currentPage > 0 && pageSize) 
        {
            getBudgetData();
            fetchEventBudgetGraph();
        }
    }, [pageSize, currentPage, currentUrl]);

    useEffect(():void => 
    {
        if (budgetDetails.length == 0) 
        {
            getBudgetDataCount();
        }

        if (refreshBudgetDetailsValue) 
        {
            getBudgetDataCount();
            getBudgetData();
            fetchEventBudgetGraph();
            dispatch(refreshBudgetDetails({
                refreshBudget: false
            }));
            toast.success('Budget Updated');
        }
    }, [refreshBudgetDetailsValue]);

    const budgetRow = [];

    let expenditureGraph = [];

    const uniqueIds = new Set();
    const subBudgetUniqueIds = new Set();

    budgetDetails.forEach((budget):void => 
    {
        let parentActualSpend = Number(budget.actualSpend);
        const parentBudgetValue = {
            parentActualBudget: 0,
            subBudgets: [],
        };

        if (budget.subBudget && budget.subBudget.length > 0) 
        {
            parentActualSpend = 0;
            budget.subBudget.forEach((item):void => 
            {
                parentActualSpend = parentActualSpend + Number(item.actualSpend);
                parentBudgetValue.subBudgets.push(item.budget);
            });
        }

        // Generate a unique name for the budget
        const uniqueBudgetName = getUniqueName(budget.name, existingNames);

        if (!uniqueIds.has(budget.id)) 
        {
            const expenditure = {
                name: '',
                value: 0,
            };

            expenditure.name = uniqueBudgetName; // Use the unique name
            expenditure.value = Number(budget.actualSpend);
            // eventBudgetValue += Number(budget.budget);
            // actualSpendValue = actualSpendValue + parentActualSpend;

            uniqueIds.add(budget.id);

            // Add the unique name to the set of existing names
            existingNames.add(uniqueBudgetName);

            const parentBudget = {
                budgetId: budget.id,
                eventId: property['data-id'],
                id: uniqueId(),
                category: [uniqueBudgetName], // Use the unique name
                tags: budget.tags,
                budget: budget.budget,
                itemName: null,
                actualSpending: parentActualSpend,
                onClick: ():void => 
                {
                    openDrawer({
                        createComp: false,
                        updateComp: true,
                        budgetDetails: budget,
                        parentBudgetData: true,
                    });
                },
            };

            budgetRow.push(parentBudget);

            if (budget.subBudget && budget.subBudget.length > 0) 
            {
                budget.subBudget.forEach((subBudget,index):void => 
                {
                    // Generate a unique name for the sub-budget
                    const uniqueSubBudgetName = getUniqueName(subBudget.name, existingNames);

                    if (!subBudgetUniqueIds.has(index)) 
                    {
                        expenditure.value = actualSpendValue;

                        // Use the unique name for the sub-budget
                        const childBudget = {
                            ...parentBudget, subBudgetId: index, category: [uniqueSubBudgetName] 
                        };
                        const childCategory = [...parentBudget.category];
                        childBudget.id = uniqueId();
                        childCategory.push(uniqueSubBudgetName); // Use the unique name
                        childBudget.category = childCategory;
                        childBudget.itemName = uniqueSubBudgetName; // Use the unique name
                        childBudget.budget = subBudget.budget;
                        childBudget.actualSpending = subBudget.actualSpend;
                        childBudget.tags = null;
                        childBudget.onClick = ():void => 
                        {
                            openDrawer({
                                createComp: false,
                                updateComp: true,
                                budgetDetails: subBudget,
                                subBudgetData: true,
                                parentBudgetValue: {
                                    parentActualBudget: budget.budget,
                                    parentBudgetDetails: budget,
                                    subBudgets: budget.subBudget,
                                    currentSubBudgetId: subBudget.id? subBudget.id : index,
                                },
                            });
                        };

                        budgetRow.push(childBudget);
                        subBudgetUniqueIds.add(`${index + uniqueId()}`);

                        // Add the unique sub-budget name to the set of existing names
                        existingNames.add(uniqueSubBudgetName);
                    }
                });
            }

        }
    });

    if (graphBudgetDetails.length > 0) 
    {
        let totalPercentagesum = 0;
       
        totalPercentagesum = graphBudgetDetails.reduce((total, item):number => 
        {
            if(item?.subBudget?.length > 0)
            {
                const subBudgetActualSpend = item.subBudget.reduce((subtotal, subItem):number => 
                {
                    return subtotal + Number(subItem.actualSpend); 
                }, 0);
              
                return ((total + ((Number(subBudgetActualSpend)/Number(item.budget)) * 100)));
            }
            else
            {
                return (total + ((Number(item.actualSpend)/Number(item.budget)) * 100)); 
            }
            
        }, 0);
       
       
        expenditureGraph = graphBudgetDetails.map((item): {
            name: string;
            value: number;
            percentage?: number;
        } => 
        {
            let value = item.actualSpend;
            let sum = 0;

            // percentage
            if(item['actualSpend'] && item?.subBudget?.length === 0 )
            {
                sum += (Number(item['actualSpend'])/Number(item.budget)) * 100;
            }
            else
            {
                const subBudgetPercentage = typeof(item?.subBudget) !== 'string' && item?.subBudget?.reduce((total, subItem):number => 
                {
                    return total + Number(subItem.actualSpend); 
                }, 0);

                sum = ((subBudgetPercentage/Number(item.budget))*100);
            }
            
            // value for subBudget
            if (item?.subBudget && item?.subBudget?.length > 0) 
            {
                value = typeof(item?.subBudget) !== 'string' && item?.subBudget.reduce((total, subItem):number => 
                {
                    return total + Number(subItem.actualSpend); 
                }, 0);
            }

            return {
                name: item.name,
                value,
                percentage: Math.round((Number(sum) / totalPercentagesum) * 100)
            };
        });

        eventBudgetValue = graphBudgetDetails.reduce((total, item):number => 
        {
            return total + Number(item.budget); 
        }, 0);
        actualSpendValue = expenditureGraph.reduce((total, item):number => 
        {
            return total + Number(item.value); 
        }, 0);

    }

    const openDrawer = (props: {
        createComp: boolean;
        updateComp?: boolean;
        budgetDetails?: object;
        parentBudgetData?: boolean;
        subBudgetData?: boolean;
        parentBudgetValue?: object;
    }):void => 
    {
        if (props.createComp) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading:
                    CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT.SIDE_DRAWER_HEADING,
                component: (
                    <CreateBudgetCategory
                        eventId={property['data-id']}
                        budgetDetails={props.budgetDetails ? props.budgetDetails : null}
                        parentBudgetData={
                            props.parentBudgetData ? props.parentBudgetData : null
                        }
                        subBudgetData={props.subBudgetData ? props.subBudgetData : null}
                        parentBudgetValue={
                            props.parentBudgetValue ? props.parentBudgetValue : null
                        }
                    />
                ),
            });
        }
        if (props.updateComp) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading:
                    CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT.UPDATE_SIDE_DRAWER_HEADING,
                component: (
                    <CreateBudgetCategory
                        eventId={property['data-id']}
                        budgetDetails={props.budgetDetails ? props.budgetDetails : null}
                        parentBudgetData={
                            props.parentBudgetData ? props.parentBudgetData : null
                        }
                        subBudgetData={props.subBudgetData ? props.subBudgetData : null}
                        parentBudgetValue={
                            props.parentBudgetValue ? props.parentBudgetValue : null
                        }
                    />
                ),
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    return (
        // <div style={{ height: "100%", width: "100%", flex: 1 }}>
        <Box id="budgetModule" width={userDetails?.viewType === UserViewType.MINIMAL ? 'calc(100vw - 187px)' : 'calc(100vw - 247px)'}>
            {/* <div className="budget-header">
                    <Box className="label-text">
                        {CONTENT.EVENTS_MODULE.BUDGET.TITLE_TEXT}
                    </Box>
                </div> */}
            {userDetails?.viewType === UserViewType.NORMAL &&
                <HeaderBar 
                    title={'Budget'}
                    buttons={[
                        <CustomButton key={1} btnType='secondary' name='Open Charts' type='button' onClick={():void => setChartVisibility(!chartVisibility)}/>,
                        <CustomButton key={2} btnType='secondary' name={CONTENT.EVENTS_MODULE.BUDGET.CREATE_CATEGORY_BTN} type='button' onClick={():void => openDrawer({ createComp: true })} />
                    ]}
                /> 
            }
            <div className="budget-content">
                {chartVisibility ?  <EventBudgetChartsContainer
                    chartReady={chartReady}
                    trend={budgetGraphData?.trend || {}}
                    budgetSummaryGraphData={budgetGraphData.budgetSummaryGraph || []}
                    expenditureGraphData={budgetGraphData.expenditureGraph || []}
                    budgetCategoryGraphData={budgetGraphData.budgetCategoryGraph || []}
                /> : null}
                <Box className="budget-metrics-table" ref={TableContainerRef} key={chartVisibility ? 'event-budget-page-chart-table' : 'event-budget-page-table'}  sx={chartVisibility?{
                    flex: 1,
                    height: '56% !important',
                }:{
                    height: '100% !important',
                    maxHeight: '100% !important',
                }}>
                    <EventBudgetDataGrid
                        rows={budgetDetails}
                        rowCount={eventBudgetCount}
                        budgetDetails={budgetDetails[0]}
                        openDrawer={openDrawer}
                        eventId={property['data-id']}
                        setCurrentUrl={setCurrentUrl}
                    />
                </Box>
            </div>
        </Box>
        // </div>
    );
};

export default EventBudget;