export enum RegistrationFormComponentType
    {
    SINGLE_LINE = 1,
    MULTI_LINE = 2,
    CHECKBOXES = 3,
    DROPDOWN = 4,
    DATE = 5,
    NUMBER = 6,
    HEADER = 7,
    PARAGRAPH = 8,
    RADIO = 9,
    IMAGE = 10,
    GDPR_COMMUNICATION_PROCESSING = 11,
    GDPR_LEGITIMATE_INTEREST = 12,
    GDPR_FORM_SUBMIT_AS_CONSENT = 13,
}

export enum RegistrationFormComponentOptionSubType
    {
    COUNTRY = 1,
    TIMEZONE = 2,
}

export enum RegistrationFormComponentMandatory
    {
    MANDATORY = 1,
    NOT_MANDATORY = 2,
}

export enum RegistrationFormComponentDisabled
    {
    NOT_DISABLED = 1,
    DISABLED = 2,
}