import { getCustomField, getCustomFieldCount } from '../../../scripts/apis/customField';

export enum CustomFieldLocations {
    EVENT = 1,
    SPONSOR = 2,
    SPEAKER = 3,
    VENDOR = 4
}
export enum CustomFieldType {
    SINGLE_LINE = 1,
    MULTI_LINE_TEXT = 2,
    CHECKBOXES = 3,
    DROPDOWN = 4,
    DATE = 5,
    NUMBER = 6,
    RADIO = 9
}


export const customFieldCountByLocation = async (fieldLocation: number):Promise<number> => 
{
    try 
    {
        const customFieldCount = await getCustomFieldCount(fieldLocation);
        if (customFieldCount) 
        {
            return customFieldCount;
        }
    }
    catch (error) 
    {
        console.log(error);
    }
};

export const customFieldsByLocation = async (pageSize: number, offset: number, fieldLocation: number):Promise<{[key:string]:string|number|string[]|number[]|boolean|{[key:string]:string|number|string[]|number[]|boolean}}[]> => 
{
    try 
    {
        const customFields = await getCustomField(pageSize, offset, fieldLocation);
        if (customFields) 
        {
            return customFields;
        }
    }
    catch (error) 
    {
        console.log(error);
    }
};