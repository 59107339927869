// import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { RegistrationFormCaptcha, RegistrationFormStatus } from '../enum/registration-status.enum';
import { CustomField } from './custom-field_interface';
import { RegistrationFormComponent, RegistrationFormComponentGDPR, RegistrationFormGDPR } from './registration-form-component_interface';

export interface RegistrationFormOptions
{
    
    redirectionUrl: string;
    
    message: string;
}

export interface RegistrationForm
{
    
    name: string;
    
    description?: string;
    
    link?: string;
    
    componentPosition: string[];

    fields: string[];
    
    captcha: RegistrationFormCaptcha;

    formGDPR?: RegistrationFormGDPR;

    type: RegistrationFormType;
    
    options?: RegistrationFormOptions;

    status: RegistrationFormStatus;
    components?: RegistrationFormComponent[];
    formFields?: CustomField[];
    id?: string;
    modified?: Date;
    created?: Date;
    userId?: string;
    orgId?: string;
}

export interface RegistrationFormComponents
{
    
    components: RegistrationFormComponent[];

    id?: number;
    modified?: Date;
    created?: Date;
    userId?: string;
    orgId?: string;
}

export enum RegistrationFormType
{
    REGISTRATION_FORM = 1,
    SPEAKERS = 2,
    SPONSORS = 3,
    LEAD_CAPTURE = 4,
}


