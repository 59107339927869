import APP_CONSTANTS from '../../../scripts/constants';
import { NumberFormatter } from '../../../scripts/helpers';
import { IPieChartData, IPieChartDataValues, IPieChartOption } from '../ICharts';

export const constructObjectForPieChart = (graphData: IPieChartDataValues[], radius: string | string[], legendOrientation?: string, specialChar?: string): IPieChartOption => 
{
    return ({
        tooltip: {
            trigger: 'item',
            formatter: (params): string => 
            {
                return `${params?.marker} ${params?.name}: ${specialChar ? specialChar : ''}${NumberFormatter(graphData[params?.dataIndex]?.value)}`;
            }
        },
        legend: {
            type: 'scroll',
            show: true,
            orient: legendOrientation ? legendOrientation : 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 4],
            data: graphData,
        },
        series: [
            {
                type: 'pie',
                radius: radius,
                avoidLabelOverlap: false,
                color: APP_CONSTANTS.GRAPH_COLORS.PIE_CHART.DEFAULT,
                itemStyle: {
                    borderRadius: 3,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                data: graphData && graphData.length > 0
                    ? graphData.map((item, index): IPieChartData => 
                    {
                        return {
                            value: Number(item.percentage) < 0 ? 0 : item.percentage,
                            name: item.name,
                            label: {
                                position: 'outside',
                                margin: '-20%',
                                rich: {
                                    custom: {
                                        lineHeight: 15,
                                        align: 'center',
                                    },
                                },
                                formatter: `${item.percentage}%`,
                                show: false,
                            },
                            labelLine: {
                                show: false
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    position: 'inside',
                                },
                                labelLine: {
                                    show: false
                                }
                            },
                            itemStyle: {
                                emphasis: {
                                    //borderWidth: 5,
                                    color: APP_CONSTANTS.GRAPH_COLORS.PIE_CHART.HOVER[index]
                                },
                            },
                        }; 
                    })
                    : null,
            },
        ],
    });
};

// export const constructBarChartOption = (dataSource, xAxisData, data, keyToShowXaxis, keyToShowInDataObj) =>
// {
//     return ({
//         color: APP_CONSTANTS.GRAPH_COLORS.BAR_CHART.DEFAULT,
//         tooltip: {
//             trigger: 'item'
//         },
//         dataset: {
//             source: dataSource,
//         },
//         calculable: true,
//         legend: {
//             type: 'scroll',
//             show: true,
//             orient: 'horizontal',
//             left: 'left',
//             padding: [0, 16, 10, 4],
//         },
//         xAxis: {
//             type: 'category',
//             data: xAxisData.length > 0 && xAxisData.map((item):string |number => 
//             {
//                 return (item[keyToShowXaxis]); 
//             }),
//             axisTick: {
//                 show: false
//             },
//             axisLabel: {
//                 interval: 0,
//                 rotate: 45,
//                 textStyle: {
//                     color: '#2a2e34',
//                     fontSize: 8,
//                     fontWeight: 400,
//                     width: 50,
//                 },
//             },
//         },
//         yAxis: {
//             splitLine: {
//                 show: true,
//                 lineStyle: {
//                     type: 'dotted',
//                 },
//             },
//             axisLabel: {
//                 formatter: function (value) : string
//                 {
//                     if (value >= 1000000) 
//                     {
//                         return (value / 1000000) + 'M';
//                     }
//                     else if (value >= 1000) 
//                     {
//                         return (value / 1000) + 'K';
//                     }
//                     else 
//                     {
//                         return value;
//                     }
//                 },
//             }
//         },
//         series: [{
//             name: 'Budget',
//             type: 'bar',
//             seriesLayoutBy: 'row',
//             itemStyle: {
//                 borderRadius: [4, 4, 0, 0]
//             },
//             emphasis: {
//                 focus: 'series',
//                 label: {
//                     show: true,
//                     fontSize: 12,
//                     fontWeight: 'bold',
//                     color: '#2a2e34',
//                 },
//             },
//             data: dataSource.length > 0 && dataSource.map((item):string|number => 
//             {
//                 return item[keyToShowInDataObj]; 
//             }),
//         }, {
//             name: 'Expense',
//             type: 'bar',
//             seriesLayoutBy: 'row',
//             itemStyle: {
//                 borderRadius: [4, 4, 0, 0]
//             },
//             emphasis: {
//                 focus: 'series',
//                 label: {
//                     show: true,
//                     fontSize: 12,
//                     fontWeight: 'bold',
//                     color: '#2a2e34',
//                 },
//             },
//             data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item):string|number => 
//             {
//                 return item['actualSpend']; 
//             }),
//         }]
//     });
// };