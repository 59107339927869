import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Form } from 'react-bootstrap';
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import { createSponsorTier, deleteSponsorTier, getAllSponsorTiers, getSponsorTierByTierId, sponsorTierCount, updateSponsorTier } from '../../../../../scripts/apis/eventSponsors';
import _ from 'lodash';
import './styles.scss';
import { useSelector } from 'react-redux';
import { FormControlComponent, FormLabelComponent } from '../../../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';

interface ICreateSponsorGroupProps {
    dataId: string | number;
    groupData?: any;
    tierName: string;
    setSponsorTierData: (sponsorTierData: any) => void;
    setTierSelectValue: (tierSelectValue: string) => void;
    setSelectedTierId: (selectedTierId: string) => void;
    setRefresh?: (refresh: boolean) => void;
    isEdit?: boolean;
    selectedTierId?: string;
}

const CreateSponsorGroup: React.FC<ICreateSponsorGroupProps> = ({ dataId, groupData, tierName, setSponsorTierData, setTierSelectValue, setSelectedTierId, setRefresh, isEdit, selectedTierId }):React.JSX.Element => 
{

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const eventId = dataId;
    const [validated, setValidated] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [groupName, setGroupName] = useState(tierName);
    const [sponsoredAmount, setSponsoredAmount] = useState<number>(0);

    const formSubmit = async (event):Promise<void> => 
    {
        setSpinner(true);
        event.preventDefault();
        setValidated(true);
        const sponsorGroupData = {
            name: groupName,
            amount: sponsoredAmount,
        };

        if (isEdit) {
            try 
            {
                const sponsorTierUpdated = await updateSponsorTier(eventId, selectedTierId, sponsorGroupData);
                if(sponsorTierUpdated)
                {
                    const tierCount = await sponsorTierCount(eventId);
                    const tierData = await getAllSponsorTiers(tierCount, 0, eventId);
                    setSponsorTierData(tierData);
                    const tier = _.find(tierData, ['id', String(selectedTierId)]);
                    
                    setTierSelectValue(tier ? tier.name : '');
                    setSelectedTierId(tier?.id ? tier?.id : '');
                    setSpinner(false);
                    toast.success('Sponsor Tier Updated');
                    handleDialogClose();
                }
            } 
            catch (error) 
            {
                
            }
        }
        else {
            try 
            {
                const sponsorTierCreated = await createSponsorTier(eventId, sponsorGroupData, csrfTokenData);
                if (sponsorTierCreated) 
                {
                    const tierCount = await sponsorTierCount(eventId);
                    const tierData = await getAllSponsorTiers(tierCount, 0, eventId);
                    setSponsorTierData(tierData);
                    
                    const tier = _.find(tierData, ['id', String(sponsorTierCreated.id)]);
                    
                    setTierSelectValue(tier ? tier.name : '');
                    setSelectedTierId(tier ? tier.id : '');
                    setSpinner(false);
                    toast.success('Sponsor Tier Created');
                    setRefresh(true);
                    handleDialogClose();
                    // handleDrawerClose();
                }

            }
            catch (error) 
            {
                setSpinner(false);
                console.log(error);

            }
        }
    };

    const handleDelete = async (): Promise<void> =>
    {
        try 
        {
            const sponsorTierDeleted = await deleteSponsorTier(eventId, selectedTierId);
            if(sponsorTierDeleted)
            {
                const tierCount = await sponsorTierCount(eventId);
                const tierData = await getAllSponsorTiers(tierCount, 0, eventId);
                setSponsorTierData(tierData);
                setSpinner(false);
                setTierSelectValue('')
                setSelectedTierId('');
                toast.success('Sponsor Tier Deleted');
                handleDialogClose();
            }
        } 
        catch (error) 
        {
            
        }  
    };

    const handleDialogClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, {
            open: false,
        });
    };

    const fetchSponsorTierData = async (): Promise<void> =>
    {
        try 
        {
            const defaultSponsorData = await getSponsorTierByTierId(eventId, selectedTierId);
            if(defaultSponsorData)
            {
                setSponsoredAmount(defaultSponsorData?.amount);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => {
        if(isEdit)
        {
            fetchSponsorTierData();
        }
    }, []);
    
    return (
        <Box id="createSponsorGroup">

            <div className="heading-container">
                <h2 className="title">{isEdit ? 'Edit Sponsor Tier' : 'Create Sponsor Tier'}</h2>
                <p className="description">{'Please enter sponsor tier name and amount for this event.'}</p>
            </div>            
            
            <Form noValidate validated={validated} onSubmit={formSubmit} autoComplete='off'>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Sponsor Tier Name' required />
                    <FormControlComponent 
                        value={groupName}
                        type='text'
                        placeholder='eg. Gold Sponsor'
                        defaultValue={groupName ? groupName : ''}
                        name='ticket-group'
                        onChange={(event):void => 
                        {
                            setGroupName(event.target.value);
                        }}
                        required
                    />
                </Box>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Sponsored Amount' />
                    <FormControlComponent 
                        value={sponsoredAmount}
                        type='number'
                        asInputGroup
                        inputGroupText='₹'
                        placeholder='00000'
                        onChange={(event):void => {
                            const value = event.target.value;
                            setSponsoredAmount(value === '' ? null : Number(value));
                        }}
                        onKeyDown={(evt): void => 
                        {
                            return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                        }}
                    />
                </Box>


                <Box className="dialog-submit-button-container" marginTop={'48px'} justifyContent={'space-between'}>
                    {isEdit ? <CustomButton disabled={spinner} btnType='danger' name='Delete' onClick={handleDelete} /> : <div />}
                    <Box className="dialog-submit-inner-container">
                        <CustomButton btnType='secondary' name='Cancel' onClick={() => 
                            {
                                setTierSelectValue(''); 
                                handleDialogClose();
                            }} />
                        <CustomButton btnType='primary' name={isEdit ? 'Edit Sponsor Tier' : 'Create Sponsor Tier'} disabled={spinner || groupName === ''} onClick={formSubmit} type='submit' />
                    </Box>
                </Box>
            </Form>
        </Box>
    );
};

export default CreateSponsorGroup;