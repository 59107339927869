import { createSlice } from '@reduxjs/toolkit';

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState: {
        value: {
            name: '',
            description: '',
            type: 0,
            totalTicketsAvailable: 0,
            ticketAvailability: 1,
            saleStartDate: '',
            saleStartTime: '',
            saleEndDate: '',
            saleEndTime: '',
            maximumPeopleWaitlist: 0,
            privateTicket: 2,
            ticketGroupId: '',
            stageIds: [],
            enableWaitlistStatus: '',
            enableApproval: '',
            enableInvoice: 2,
            saleStartDateTime: '',
            saleEndDateTime: ''
        }
    },
    reducers: {
        addTicket: (state, actions):void => 
        {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetTicket: (state):void => 
        {
            state.value = {
                name: '',
                description: '',
                type: 0,
                totalTicketsAvailable: 0,
                ticketAvailability: 1,
                saleStartDate: '',
                saleStartTime: '',
                saleEndDate: '',
                saleEndTime: '',
                maximumPeopleWaitlist: 0,
                privateTicket: 1,
                ticketGroupId: '',
                stageIds: [],
                enableWaitlistStatus: '',
                enableApproval: '',
                enableInvoice: 2,
                saleStartDateTime: '',
                saleEndDateTime: ''
            };
        },
    },
});

export const { addTicket, resetTicket } = ticketsSlice.actions;
export default ticketsSlice.reducer;