/* eslint-disable react/jsx-key */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import TrendsComponent from '../../common/TrendsComponent';
import Charts from '../../common/ECharts/Charts';
import CustomSpinner from '../../common/CustomSpinner';
import { getBudgetAnalyticsGraphData, getBudgetGraphData } from '../../scripts/apis/budget';
import { NumberFormatter } from '../../scripts/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpView from '../../common/PopupView';
import { getEventsCategoriesCount } from '../../scripts/apis/events';
import { BudgetGraphObject, IBudgetPieChartObject } from '../Budget/interfaces';
import _ from 'lodash';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import APP_CONSTANTS from '../../scripts/constants';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';

const BudgetAnalytics = (): React.JSX.Element => 
{
    const [graphData, setGraphData] = useState<{
        budgetSpentData: {
            budget: number;
            remaining: number,
            actualSpend: number,
        },
        quarterlyData: BudgetGraphObject[],
        budgetSpentEventType: IBudgetPieChartObject[],
        avgCostPerEventType: BudgetGraphObject[],
        budgetSpentEventLocation: IBudgetPieChartObject[],
        avgCostPerEventLocation: BudgetGraphObject[],
        topExpensesData: BudgetGraphObject[],
        actualVsSpendData: BudgetGraphObject[]
    }>({
        budgetSpentData: {
            'budget': 0,
            'actualSpend': 0,
            'remaining': 0
        },
        quarterlyData: [],
        budgetSpentEventType: [],
        avgCostPerEventType: [],
        budgetSpentEventLocation: [],
        avgCostPerEventLocation: [],
        topExpensesData: [],
        actualVsSpendData: []
    });

    const [budgetCounts, setBudgetCounts] = useState({
        event: 0,
        budget: 0,
        remaining: 0,
        actualSpent: 0,
    });

    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        budgetSpentGraph: false,
        quarterlyGraph: false,
        budgetSpentOnEventTypeGraph: false,
        avgCostPerEventTypeGraph: false,
        budgetSpentOnEventLocationGraph: false,
        avgCostPerEventLocationGraph: false,
        topExpensesGraph: false,
        actualVsSpendGraph: false
    });

    const [isChartReady, setChartReady] = useState<{
        budgetSpentGraphReady?: boolean,
        quarterlyGraphReady?: boolean,
        budgetSpentOnEventTypeGraphReady?: boolean,
        avgCostPerEventTypeGraphReady?: boolean,
        budgetSpentOnEventLocationGraphReady?: boolean,
        avgCostPerEventLocationGraphReady?: boolean,
        topExpensesGraphReady?: boolean,
        actualVsSpendGraphReady?: boolean
    }>({
        budgetSpentGraphReady: false,
        quarterlyGraphReady: false,
        budgetSpentOnEventTypeGraphReady: false,
        avgCostPerEventTypeGraphReady: false,
        budgetSpentOnEventLocationGraphReady: false,
        avgCostPerEventLocationGraphReady: false,
        topExpensesGraphReady: false,
        actualVsSpendGraphReady: false

    });

    const fetchBudgetAnalyticsGraphData = async (): Promise<void> => 
    {
        try 
        {
            const budgetGraphData = await getBudgetAnalyticsGraphData();

            if (budgetGraphData) 
            {
                if (budgetGraphData.budgetSpent) 
                {
                    setGraphData((prev): {
                        budgetSpentData: {
                            budget: number;
                            remaining: number;
                            actualSpend: number;
                        };
                        quarterlyData: BudgetGraphObject[];
                        budgetSpentEventType: IBudgetPieChartObject[];
                        avgCostPerEventType: BudgetGraphObject[];
                        budgetSpentEventLocation: IBudgetPieChartObject[];
                        avgCostPerEventLocation: BudgetGraphObject[];
                        topExpensesData: BudgetGraphObject[];
                        actualVsSpendData: BudgetGraphObject[];
                    } => 
                    {
                        return {
                            ...prev,
                            budgetSpentData: {
                                budget: budgetGraphData.budgetSpent.budget,
                                actualSpend: budgetGraphData.budgetSpent.actualSpend,
                                remaining: budgetGraphData.budgetSpent.budget - budgetGraphData.budgetSpent.actualSpend,
                            },
                        };
                    });
                }

                const transformAndSet = (key, data): void => 
                {
                    if (data) 
                    {
                        const transformed = Object.keys(data).map((item): {
                            value: string | number;
                            name: string;
                            percentage?: number;
                        } => 
                        {
                            //calculation
                            let percentages = 0;
                            const sum = _.sumBy(_.values(budgetGraphData[key]));

                            if (sum > 0) 
                            {
                                percentages = Math.round((Number(data[item]) / sum) * 100);
                            }

                            if (percentages > 0) 
                            {
                                return {
                                    value: data[item], name: toTitleCase(item), percentage: percentages
                                };
                            }
                            else 
                            {
                                return {
                                    value: data[item], name: toTitleCase(item)
                                };
                            }

                        });
                        setGraphData((prev): {
                            budgetSpentData: {
                                budget: number;
                                remaining: number;
                                actualSpend: number;
                            };
                            quarterlyData: BudgetGraphObject[];
                            budgetSpentEventType: IBudgetPieChartObject[];
                            avgCostPerEventType: BudgetGraphObject[];
                            budgetSpentEventLocation: IBudgetPieChartObject[];
                            avgCostPerEventLocation: BudgetGraphObject[];
                            topExpensesData: BudgetGraphObject[];
                            actualVsSpendData: BudgetGraphObject[];
                        } => 
                        {
                            return {
                                ...prev, [key]: transformed
                            };
                        });
                    }
                };

                transformAndSet('budgetSpentEventType', budgetGraphData.budgetSpentEventType);
                transformAndSet('budgetSpentEventLocation', budgetGraphData.budgetSpentEventLocation);
                transformAndSet('avgCostPerEventType', budgetGraphData.avgCostEventType);
                transformAndSet('avgCostPerEventLocation', budgetGraphData.avgCostEventLocation);

                if (budgetGraphData.quarterly) 
                {

                    const quarterlyData = Object.keys(budgetGraphData.quarterly).length > 0 && Object.keys(budgetGraphData.quarterly).map((item: string): {
                        name: string;
                        budget: number;
                        actualSpend: number;
                    } => 
                    {
                        const i = {
                            name: item, budget: budgetGraphData.quarterly[item]['budget'], actualSpend: budgetGraphData.quarterly[item]['actualSpend']
                        }; return i;
                    });
                    setGraphData((prev): {
                        budgetSpentData: {
                            budget: number;
                            remaining: number;
                            actualSpend: number;
                        };
                        quarterlyData: BudgetGraphObject[];
                        budgetSpentEventType: IBudgetPieChartObject[];
                        avgCostPerEventType: BudgetGraphObject[];
                        budgetSpentEventLocation: IBudgetPieChartObject[];
                        avgCostPerEventLocation: BudgetGraphObject[];
                        topExpensesData: BudgetGraphObject[];
                        actualVsSpendData: BudgetGraphObject[];
                    } => 
                    {
                        return {
                            ...prev, quarterlyData: quarterlyData
                        };
                    });

                }

                if (budgetGraphData.categoryBudgetActualSpending) 
                {
                    setGraphData((prev): {
                        budgetSpentData: {
                            budget: number;
                            remaining: number;
                            actualSpend: number;
                        };
                        quarterlyData: BudgetGraphObject[];
                        budgetSpentEventType: IBudgetPieChartObject[];
                        avgCostPerEventType: BudgetGraphObject[];
                        budgetSpentEventLocation: IBudgetPieChartObject[];
                        avgCostPerEventLocation: BudgetGraphObject[];
                        topExpensesData: BudgetGraphObject[];
                        actualVsSpendData: BudgetGraphObject[];
                    } => 
                    {
                        return {
                            ...prev, actualVsSpendData: budgetGraphData.categoryBudgetActualSpending
                        };
                    });
                }

                if (budgetGraphData.topExpenses) 
                {
                    setGraphData((prev): {
                        budgetSpentData: {
                            budget: number;
                            remaining: number;
                            actualSpend: number;
                        };
                        quarterlyData: BudgetGraphObject[];
                        budgetSpentEventType: IBudgetPieChartObject[];
                        avgCostPerEventType: BudgetGraphObject[];
                        budgetSpentEventLocation: IBudgetPieChartObject[];
                        avgCostPerEventLocation: BudgetGraphObject[];
                        topExpensesData: BudgetGraphObject[];
                        actualVsSpendData: BudgetGraphObject[];
                    } => 
                    {
                        return {
                            ...prev, topExpensesData: budgetGraphData.topExpenses
                        };
                    });
                }
            }
        }
        catch (error) 
        {
            console.error(error);
        }
        finally 
        {
            setChartReady((prevState): {
                budgetSpentGraphReady?: boolean;
                quarterlyGraphReady?: boolean;
                budgetSpentOnEventTypeGraphReady?: boolean;
                avgCostPerEventTypeGraphReady?: boolean;
                budgetSpentOnEventLocationGraphReady?: boolean;
                avgCostPerEventLocationGraphReady?: boolean;
                topExpensesGraphReady?: boolean;
                actualVsSpendGraphReady?: boolean;
            } => 
            {
                return {
                    ...prevState,
                    budgetSpentGraphReady: true,
                    quarterlyGraphReady: true,
                    budgetSpentOnEventTypeGraphReady: true,
                    avgCostPerEventTypeGraphReady: true,
                    budgetSpentOnEventLocationGraphReady: true,
                    avgCostPerEventLocationGraphReady: true,
                    topExpensesGraphReady: true,
                    actualVsSpendGraphReady: true,
                };
            });
        }
    };

    const fetchEventsCategoriesCount = async (): Promise<void> => 
    {
        try 
        {
            const events = await getEventsCategoriesCount(1);
            if (events) 
            {
                setBudgetCounts((prevCounts): {
                    event: number;
                    budget: number;
                    remaining: number;
                    actualSpent: number;
                } => 
                {
                    return {
                        ...prevCounts,
                        event: events,
                    };
                });
            }

        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchBudgetTrendsData = async (): Promise<void> => 
    {
        try 
        {
            const budgetGraphData = await getBudgetGraphData();
            if (budgetGraphData) 
            {
                if (budgetGraphData.trend) 
                {
                    setBudgetCounts((prevItems): {
                        budget: number;
                        actualSpent: number;
                        remaining: number;
                        event: number;
                    } => 
                    {
                        return {
                            ...prevItems,
                            budget: Number(budgetGraphData.trend.spent + budgetGraphData.trend.remaining),
                            actualSpent: Number(budgetGraphData.trend.spent),
                            remaining: Number(budgetGraphData.trend.remaining),
                        };
                    });
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const budgetSummaryData = [
        {
            name: 'Spent',
            percentage: Math.round((graphData?.budgetSpentData?.actualSpend / graphData?.budgetSpentData?.budget) * 100),
            value: graphData?.budgetSpentData?.actualSpend
        },
        {
            name: 'Remaining',
            percentage: Math.round((graphData?.budgetSpentData?.remaining / graphData?.budgetSpentData?.budget) * 100),
            value: graphData?.budgetSpentData?.remaining,
        }
    ];

    const budgetSummaryGraphData = constructObjectForPieChart(budgetSummaryData, ['50%', '72%'], null, '$');

    const enlargeBudgetSummaryGraphData = constructObjectForPieChart(budgetSummaryData, ['50%', '72%'], null, '$');

    const quarterlyChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        dataset: {
            source: graphData?.quarterlyData,
        },
        calculable: true,
        legend: {
            type: 'scroll',
            show: true,
            icon: 'circle',
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 8],
        },
        xAxis: {
            type: 'category',
            data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item): string | number => 
            {
                return (item.name);
            }),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            name: 'Budget',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item): string | number => 
            {
                return item['budget'];
            }),
        }, {
            name: 'Expense',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[1]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item): string | number => 
            {
                return item['actualSpend'];
            }),
        }]
    };

    const enlargeQuarterlyChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        dataset: {
            source: graphData?.quarterlyData,
        },
        calculable: true,
        legend: {
            type: 'scroll',
            orient: 'horizontal',
            left: 0,
            icon: 'circle',
            top: 10,
            bottom: 20,
            padding: [0, 16, 10, 8],
        },
        xAxis: {
            type: 'category',
            data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item): string | number => 
            {
                return (item.name);
            }),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            name: 'Budget',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item): string | number => 
            {
                return item['budget'];
            }),
        }, {
            name: 'Expense',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[1]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.quarterlyData.length > 0 && graphData.quarterlyData.map((item): string | number => 
            {
                return item['actualSpend'];
            }),
        }]
    };

    const budgetSpentEventType = constructObjectForPieChart(graphData?.budgetSpentEventType, ['50%','72%'], null, '$');

    const enlargeBudgetSpentOnEventType = constructObjectForPieChart(graphData?.budgetSpentEventType, ['50%','72%'], 'vertical', '$');

    const avgCostPerEventType = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            show: true,
            icon: 'circle',
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 4],
        },
        dataset: {
            source: graphData?.avgCostPerEventType
        },
        xAxis: {
            nameLocation: 'middle',
            nameGap: 30,
            type: 'category',
            data: graphData?.avgCostPerEventType?.length > 0 && graphData?.avgCostPerEventType.map((item): string | number => 
            {
                return item.name;
            }),
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.avgCostPerEventType?.length > 0 && graphData?.avgCostPerEventType.map((item): string | number => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                        formatter: function (params): string 
                        {
                            return `$${NumberFormatter(params?.value)}`;
                        },
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const enlargeAvgCostPerEventType = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            left: 0,
            top: 10,
            icon: 'circle',
            bottom: 20,
            padding: [0, 16, 10, 8],
        },
        dataset: {
            source: graphData?.avgCostPerEventType
        },
        xAxis: {
            name: 'Event Type',
            nameLocation: 'middle',
            nameGap: 30,
            type: 'category',
            data: graphData?.avgCostPerEventType?.length > 0 && graphData.avgCostPerEventType.map((item): string | number => 
            {
                return item.name;
            }),
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            name: 'Event Cost',
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.avgCostPerEventType?.length > 0 && graphData.avgCostPerEventType.map((item): string | number => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                        formatter: function (params): string 
                        {
                            return `${NumberFormatter(params?.value)}`;
                        },
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const avgCostPerEventLocation = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            show: true,
            orient: 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 4],
        },
        dataset: {
            source: graphData?.avgCostPerEventLocation
        },
        xAxis: {
            nameLocation: 'middle',
            nameGap: 25,
            type: 'category',
            data: graphData?.avgCostPerEventLocation?.length > 0 && graphData.avgCostPerEventLocation.map((item): string | number => 
            {
                return item.name;
            }),
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.avgCostPerEventLocation?.length > 0 && graphData.avgCostPerEventLocation.map((item): string | number => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                        formatter: function (params): string 
                        {
                            return `$${NumberFormatter(params?.value)}`;
                        },
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const enlargeAvgCostPerEventLocation = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            left: 0,
            top: 10,
            bottom: 20,
            icon: 'circle',
            padding: [0, 16, 10, 8],
        },
        dataset: {
            source: graphData?.avgCostPerEventLocation
        },
        xAxis: {
            name: 'Event Location',
            nameLocation: 'middle',
            nameGap: 30,
            type: 'category',
            data: graphData?.avgCostPerEventLocation?.length > 0 && graphData.avgCostPerEventLocation.map((item): string | number => 
            {
                return item.name;
            }),
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            name: 'Event Spend',
            nameLocation: 'middle',
            nameGap: 35,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [
            {
                data: graphData?.avgCostPerEventLocation?.length > 0 && graphData.avgCostPerEventLocation.map((item): string | number => 
                {
                    return item.value;
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                barWidth: 30,
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#2a2e34',
                        formatter: function (params): string 
                        {
                            return `$${NumberFormatter(params?.value)}`;
                        },
                    },
                },
                itemStyle: {
                    borderRadius: [4, 4, 0, 0],
                    emphasis: {
                        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                    }
                },
            }
        ]
    };

    const budgetSpentEventLocation = constructObjectForPieChart(graphData?.budgetSpentEventLocation, ['50%', '72%'], null, '$');

    const enlargeBudgetSpentOnEventLocation = constructObjectForPieChart(graphData?.budgetSpentEventLocation, ['50%', '72%'], 'vertical', '$');

    const topExpenseSum = _.sumBy(graphData?.topExpensesData, 'actualSpend');
    const topExpenseData = Object.values(graphData?.topExpensesData).map((item): {
        name: string;
        value: string;
        percentage: number;
    } => 
    {
        return ({
            name: String(item.name),
            value: String(item.actualSpend),
            percentage: Math.round((Number(item.actualSpend) / topExpenseSum) * 100),
        });
    });

    const topExpenses = constructObjectForPieChart(topExpenseData, ['50%','72%'], null, '$'); 

    const enlargeTopExpenses = constructObjectForPieChart(topExpenseData, ['50%','72%'], 'vertical', '$');

    const actualVsSpend = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        dataset: {
            source: graphData?.actualVsSpendData
        },
        calculable: true,
        legend: {
            show: true,
            type: 'scroll',
            orient: 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 8],
        },
        xAxis: {
            type: 'category',
            nameGap: 35,
            data: graphData.actualVsSpendData?.length > 0 && graphData.actualVsSpendData.map((item): string | number => 
            {
                return (item.name);
            }),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameGap: 55,
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            name: 'Budget',
            type: 'line',
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            barWidth: 30,
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.actualVsSpendData?.length > 0 && graphData.actualVsSpendData.map((item): string | number => 
            {
                return item['budget'];
            }),
        }, {
            name: 'Expense',
            type: 'line',
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            barWidth: 30,
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[1]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.actualVsSpendData?.length > 0 && graphData.actualVsSpendData.map((item): string | number => 
            {
                return item['actualSpend'];
            }),
        }]
    };

    const enlargeActualVsSpend = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        dataset: {
            source: graphData?.actualVsSpendData
        },
        calculable: true,
        legend: {
            type: 'scroll',
            orient: 'horizontal',
            left: 0,
            top: 10,
            icon: 'circle',
            bottom: 20,
            padding: [0, 16, 10, 8],
        },
        xAxis: {
            type: 'category',
            nameGap: 35,
            data: graphData.actualVsSpendData?.length > 0 && graphData.actualVsSpendData.map((item): string | number => 
            {
                return (item.name);
            }),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameGap: 35,
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            name: 'Budget',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.actualVsSpendData?.length > 0 && graphData.actualVsSpendData.map((item): string | number => 
            {
                return item['budget'];
            }),
        }, {
            name: 'Expense',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[1]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: graphData.actualVsSpendData?.length > 0 && graphData.actualVsSpendData.map((item): string | number => 
            {
                return item['actualSpend'];
            }),
        }]
    };

    useEffect((): void => 
    {
        fetchBudgetAnalyticsGraphData();
        fetchEventsCategoriesCount();
        fetchBudgetTrendsData();
    }, []);

    return (
        <Box id="budgetAnalytics">

            <TopPageAnalyticsContainer
                TrendsComponent={<TrendsComponent
                    Trends={
                        [
                            {
                                title: 'Event Categories',
                                value: `${budgetCounts.event && budgetCounts.event !== 0 ? budgetCounts.event : '-'}`
                            },
                            {
                                title: 'Budget',
                                value: `${budgetCounts.budget ? '$' + NumberFormatter(budgetCounts.budget ? budgetCounts.budget : 0) : '-'}`
                            },
                            {
                                title: 'Spent',
                                value: `${budgetCounts.actualSpent ? '$' + NumberFormatter(budgetCounts.actualSpent ? budgetCounts.actualSpent : 0) : '-'}`
                            },
                            {
                                title: 'Remaining',
                                value: `${budgetCounts.remaining ? '$' + NumberFormatter(budgetCounts.remaining ? budgetCounts.remaining : 0) : '-'}`
                            }
                        ]
                    }
                />}
                GraphComponentsArray={[
                    {
                        GraphComponent: <AnalyticsGraphContainer
                            title="Budget Spent"
                            chartOptions={budgetSummaryGraphData}
                            isGraphReady={isChartReady.budgetSpentGraphReady}
                            isDataAvailable={graphData?.budgetSpentData?.actualSpend > 0}
                            enlargeOnclick={(): void => 
                            {
                                setOpenEnlargeGraphView({
                                    budgetSpentGraph: true,
                                    quarterlyGraph: false,
                                    budgetSpentOnEventTypeGraph: false,
                                    avgCostPerEventTypeGraph: false,
                                    budgetSpentOnEventLocationGraph: false,
                                    avgCostPerEventLocationGraph: false,
                                    topExpensesGraph: false,
                                    actualVsSpendGraph: false
                                });
                            }}
                        />
                    },
                    {
                        GraphComponent: <AnalyticsGraphContainer
                            title="Quarterly"
                            chartOptions={quarterlyChartOptions}
                            isGraphReady={isChartReady.budgetSpentGraphReady}
                            isDataAvailable={graphData.quarterlyData.length > 0}
                            enlargeOnclick={(): void => 
                            {
                                setOpenEnlargeGraphView({
                                    budgetSpentGraph: false,
                                    quarterlyGraph: true,
                                    budgetSpentOnEventTypeGraph: false,
                                    avgCostPerEventTypeGraph: false,
                                    budgetSpentOnEventLocationGraph: false,
                                    avgCostPerEventLocationGraph: false,
                                    topExpensesGraph: false,
                                    actualVsSpendGraph: false
                                });
                            }}

                        />
                    }
                ]
                }
                BlockStyling={{
                    padding: '0px !important',
                }}
            />

            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Budget spent on Event Type"
                        chartOptions={budgetSpentEventType}
                        isGraphReady={isChartReady.budgetSpentGraphReady}
                        isDataAvailable={graphData.budgetSpentEventType.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                budgetSpentGraph: false,
                                quarterlyGraph: false,
                                budgetSpentOnEventTypeGraph: true,
                                avgCostPerEventTypeGraph: false,
                                budgetSpentOnEventLocationGraph: false,
                                avgCostPerEventLocationGraph: false,
                                topExpensesGraph: false,
                                actualVsSpendGraph: false
                            });
                        }}

                    />,

                    <AnalyticsGraphContainer
                        title="Avg Cost per event Type"
                        chartOptions={avgCostPerEventType}
                        isGraphReady={isChartReady.budgetSpentGraphReady}
                        isDataAvailable={graphData.avgCostPerEventType.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                budgetSpentGraph: false,
                                quarterlyGraph: false,
                                budgetSpentOnEventTypeGraph: false,
                                avgCostPerEventTypeGraph: true,
                                budgetSpentOnEventLocationGraph: false,
                                avgCostPerEventLocationGraph: false,
                                topExpensesGraph: false,
                                actualVsSpendGraph: false
                            });
                        }}

                    />

                ]}
                style={{
                    gridTemplateColumns: '1.2fr 1.8fr 0.6fr'
                }}
            />
            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Budget spent by Event Location"
                        chartOptions={budgetSpentEventLocation}
                        isGraphReady={isChartReady.budgetSpentGraphReady}
                        isDataAvailable={graphData.budgetSpentEventLocation.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                budgetSpentGraph: false,
                                quarterlyGraph: false,
                                budgetSpentOnEventTypeGraph: false,
                                avgCostPerEventTypeGraph: false,
                                budgetSpentOnEventLocationGraph: true,
                                avgCostPerEventLocationGraph: false,
                                topExpensesGraph: false,
                                actualVsSpendGraph: false
                            });
                        }}

                    />,

                    <AnalyticsGraphContainer
                        title="Avg Spending by Event Location"
                        chartOptions={avgCostPerEventLocation}
                        isGraphReady={isChartReady.budgetSpentGraphReady}
                        isDataAvailable={graphData.avgCostPerEventLocation.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                budgetSpentGraph: false,
                                quarterlyGraph: false,
                                budgetSpentOnEventTypeGraph: false,
                                avgCostPerEventTypeGraph: false,
                                budgetSpentOnEventLocationGraph: false,
                                avgCostPerEventLocationGraph: true,
                                topExpensesGraph: false,
                                actualVsSpendGraph: false
                            });
                        }}

                    />

                ]}
                style={{
                    gridTemplateColumns: '1.2fr 1.8fr 0.6fr'
                }}
            />
            <AnalyticsGraphsBlock
                elem={[
                    <AnalyticsGraphContainer
                        title="Top Expenses"
                        chartOptions={topExpenses}
                        isGraphReady={isChartReady.budgetSpentGraphReady}
                        isDataAvailable={graphData.topExpensesData.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                budgetSpentGraph: false,
                                quarterlyGraph: false,
                                budgetSpentOnEventTypeGraph: false,
                                avgCostPerEventTypeGraph: false,
                                budgetSpentOnEventLocationGraph: false,
                                avgCostPerEventLocationGraph: false,
                                topExpensesGraph: true,
                                actualVsSpendGraph: false
                            });
                        }}

                    />,

                    <AnalyticsGraphContainer
                        title="Spending Category budget and actual spending "
                        chartOptions={actualVsSpend}
                        isGraphReady={isChartReady.budgetSpentGraphReady}
                        isDataAvailable={graphData.actualVsSpendData.length > 0}
                        enlargeOnclick={(): void => 
                        {
                            setOpenEnlargeGraphView({
                                budgetSpentGraph: false,
                                quarterlyGraph: false,
                                budgetSpentOnEventTypeGraph: false,
                                avgCostPerEventTypeGraph: false,
                                budgetSpentOnEventLocationGraph: false,
                                avgCostPerEventLocationGraph: false,
                                topExpensesGraph: false,
                                actualVsSpendGraph: true
                            });
                        }}

                    />

                ]}
                style={{
                    gridTemplateColumns: '1.2fr 1.8fr 0.6fr'
                }}
            />

            {openEnlargeGraphView.budgetSpentGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Budget Spent'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeBudgetSummaryGraphData} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.quarterlyGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Quarterly'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeQuarterlyChartOptions} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.budgetSpentOnEventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Budget spent on Event Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeBudgetSpentOnEventType} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.avgCostPerEventTypeGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Avg Cost per event Type'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeAvgCostPerEventType} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.budgetSpentOnEventLocationGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Budget spent by Event Location'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeBudgetSpentOnEventLocation} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.avgCostPerEventLocationGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Avg Spending by Event Location'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeAvgCostPerEventLocation} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.topExpensesGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Top Expenses'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeTopExpenses} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.actualVsSpendGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetSpentGraph: false,
                        quarterlyGraph: false,
                        budgetSpentOnEventTypeGraph: false,
                        avgCostPerEventTypeGraph: false,
                        budgetSpentOnEventLocationGraph: false,
                        avgCostPerEventLocationGraph: false,
                        topExpensesGraph: false,
                        actualVsSpendGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Spending Category budget and actual spending '}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={enlargeActualVsSpend} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

        </Box>
    );
};

export default BudgetAnalytics;



export const AnalyticsGraphsBlock = (props: { elem: JSX.Element[], style: React.CSSProperties }): React.JSX.Element => 
{
    return (
        <Box className="graph-Block" sx={props.style ? props.style : {
            gridTemplateColumns: `repeat(${props.elem.length},1fr)`
        }}>
            {props.elem.length > 0 ? props.elem.map((item: JSX.Element): JSX.Element => 
            {
                return item;
            }) : null}
        </Box>
    );
};

export const AnalyticsGraphContainer = (props: { title: string, chartOptions: object, isGraphReady: boolean, isDataAvailable: boolean, enlargeOnclick?: () => void, chartHeight?: string }): React.JSX.Element => 
{

    const [graphHeight, setGraphHeight] = useState(0);

    useEffect((): () => void => 
    {
        const handleResize = (): void => 
        {
            const screenHeight = window.innerHeight;
            const seventyPercentHeight = 0.75 * screenHeight;
            const containerHeight = screenHeight - seventyPercentHeight;
            setGraphHeight(containerHeight);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return (): void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, [graphHeight]);

    return (
        <Box className="graphCont">
            <Box className="header-block">
                <Box className="graphTitle">{props.title}</Box>
                {props.enlargeOnclick && props.isDataAvailable ? <Box className="enlarge-view" onClick={props.enlargeOnclick}>
                    <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                </Box> : null}
            </Box>
            <Box className="graph">
                {props.isGraphReady ? props.isDataAvailable ? (<Charts height={`${props.chartHeight ? `${props.chartHeight}` : `${graphHeight}px`}`} chartOption={props.chartOptions} chartHeight={`${props.chartHeight ? `${props.chartHeight}` : `${graphHeight}px`}`} chartWidth={'100'} boxMargin="16px 16px 16px 16px" />) : <Box className="noData" sx={{
                    height: `${graphHeight}px`
                }}><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></Box> : (<CustomSpinner height={`${props.chartHeight ? `${props.chartHeight}` : `${graphHeight}px`}`} />)}
            </Box>
        </Box>
    );
};

export function toTitleCase(str: string): string 
{
    return str.toLowerCase().split(' ').map(function (word: string): string 
    {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}