import { createSlice } from '@reduxjs/toolkit';

export const formComponentsSlice = createSlice({
    name: 'formComponents',
    initialState: {
        value: []
    },
    reducers: {
        updateFormComponent: (state, action):void => 
        {
            if (action.payload.subName) 
            {
                const list = [...state.value];
                list[action.payload.index][action.payload.name][action.payload.subName] = action.payload.value;
            }
            else 
            {
                const list = [...state.value];
                list[action.payload.index][action.payload.name] = action.payload.value;
            }
        },
        addFormComponent: (state, action):void => 
        {
            state.value.push({
                category: action.payload.category,
                default: action.payload.default,
                id: action.payload.id,
                type: action.payload.type,
                subType: action.payload.subType,
                label: action.payload.label,
                helpText: action.payload.helpText,
                placeholder: action.payload.placeholder,
                typeAttribute: action.payload.typeAttribute,
                numericLimitAccept: action.payload.numericLimitAccept,
                componentNumeric: action.payload.componentNumeric,
                componentMandatory: action.payload.componentMandatory,
                componentDisabled: action.payload.componentDisabled,
                componentOptions: action.payload.componentOptions,
                regionOptions: action.payload.regionOptions,
                heading: action.payload.heading,
                paragraph: action.payload.paragraph,
                componentGDPR: action.payload.componentGDPR,
            });
        },
        resetFormComponents: (state):void => 
        {
            state.value = [];
        },
        removeFormComponent: (state, actions):void => 
        {
            state.value.splice(actions.payload.index, 1);
        },
        updateGDPRFormComponent: (state, action):void => 
        {
            const data = state.value[action.payload.index].componentGDPR[action.payload.gdprComponentIndex];
            if (action.payload.name === 'paragraph') 
            {
                data[action.payload.name] = action.payload.value;
            }
            else if (action.payload.name === 'componentOptions') 
            {
                data[action.payload.name]['name'] = action.payload.value;
                data[action.payload.name]['value'] = action.payload.value;
            }
        },
        updateFormComponentOption: (state, action):void => 
        {
            const options = [...state.value[action.payload.index].componentOptions];
            options[action.payload.optionIndex][action.payload.name] = action.payload.value;
            options[action.payload.optionIndex][action.payload.optionName] = action.payload.optionValue;
        },
        addFormComponentOption: (state, action):void => 
        {
            state.value[action.payload.index].componentOptions.push({
                name: '',
                value: '',
            });
        },
        removeFormComponentOption: (state, actions):void => 
        {
            state.value[actions.payload.index].componentOptions.splice(actions.payload.optionIndex, 1);
        },
    },
});

export const { addFormComponent, updateFormComponent, updateFormComponentOption, resetFormComponents, removeFormComponent, addFormComponentOption, removeFormComponentOption, updateGDPRFormComponent } = formComponentsSlice.actions;
export default formComponentsSlice.reducer;