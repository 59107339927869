import { useState } from "react";

import './styles.scss';

interface EditableHeaderProps {
    text: string;
    placeholder: string;
    handleSave: () => void;
    onChange: (value: string) => void;
}

const EditableHeader: React.FC<EditableHeaderProps> = (props): React.JSX.Element =>
{
    const { text, placeholder, handleSave, onChange } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);

    return (
        <div id="editableHeader" onClick={() => setIsEditing(true)}>
            {
                isEditing ? (
                    <input
                        type="text"
                        autoFocus
                        value={text}
                        placeholder={placeholder}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={() => 
                        {
                            handleSave();
                            setIsEditing(false);
                        }}
                        style={{ width: `${text.length}ch` }}
                        className="header-edit-mode"
                    />
                ) : (
                    <h3 className="header-view-mode">{text}</h3>
                )
            }
        </div>
    )
};

export default EditableHeader;