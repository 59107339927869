import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultUserAvatar from '../../../../assets/images/default-user-avatar.svg';
import moment from "moment";
import { PortalContext } from "../../../../contexts/Portal/PortalContext";
import { Popover } from "@mui/material";

import './styles.scss';
import DeletePopup from "../../../../common/DeletePopup";
import { unauthorizeSponsor } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import toast from "react-hot-toast";
import PortalSidedrawer from "./PortalSidedrawer";
import PortalUserProfile from "../../Pages/Profile/PortalUserProfile";

const PortalHeader = () =>
{

    const {
        registrantData,
        handleModuleChange
    } = useContext(PortalContext);

    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
    const [showProfileSidebar, setShowProfileSidebar] = useState<boolean>(false);

    const handleOpenDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>): void => 
    {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleCloseDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => 
    {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handlePopoverActions = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, action?: 'profile' | 'logout'): void =>
    {
        if(action === 'profile')
        {
            setShowProfileSidebar(true);
        }
        else if(action === 'logout')
        {
            setShowLogoutModal(true);
        }
        handleCloseDropdown(event);
    };

    const handleLogout = async (): Promise<void> =>
    {
        try 
        {
            const logoutComplete = await unauthorizeSponsor();
            if (logoutComplete) 
            {
                setShowLogoutModal(false);
                handleModuleChange('login');
                window.location.reload();
            }
        } 
        catch (error) 
        {
            console.error('Error logging out:', error);
            toast.error((error as Error)?.message || 'Error logging out');
        }
    };

    const PortalPopover = () =>
    {
        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        style: {
                            boxShadow: 'unset',
                            marginTop: '8px'
                        }
                    }
                }}
            >
                <div className="portal-profile-popover">
                    <div className="user-name-email-container">
                        <h3 className="name">{`${registrantData?.firstName} ${registrantData?.lastName || ''}`}</h3>
                        <p className="email">{registrantData?.email}</p>
                    </div>
                    <div className="portal-profile-popover-actions-container">
                        <div className="portal-profile-popover-actions" onClick={(event) => handlePopoverActions(event, 'profile')}>
                            <FontAwesomeIcon className="action-item-icon" icon={['fal', 'user']} />
                            <p className="action-item-text">Your details</p>
                        </div>
                        <div className="portal-profile-popover-actions" onClick={(event) => handlePopoverActions(event, 'logout')}>
                            <FontAwesomeIcon className="action-item-icon" icon={['fal', 'sign-out']} />
                            <p className="action-item-text">Logout</p>
                        </div>
                    </div>
                </div>
            </Popover>
        );
    };

    return (
        <div id="portalHeader">
            <div className="portal-header-container">
                <div className="user-info-container">
                    <div className="user-avatar">
                        <img 
                            src={registrantData?.clearbitData?.companyLogo || defaultUserAvatar}
                            alt="user-avatar"
                        />
                    </div>
                    <div className="user-details">
                        <h3 className="name">Welcome, {registrantData?.firstName}</h3>
                        <p className="timing-info">{moment().format('dddd, MMMM YYYY')}</p>
                    </div>
                </div>
                <div className="settings-icon">
                    <FontAwesomeIcon 
                        icon={['fal', 'gear-complex']} 
                        onClick={(event) => handleOpenDropdown(event)}
                    />
                    <PortalPopover />
                </div>

                {
                    showProfileSidebar &&
                    <PortalSidedrawer 
                        component={<PortalUserProfile />}
                        handleClose={() => setShowProfileSidebar(false)}
                        heading={"Your Details"}
                        open={showProfileSidebar}
                        containerWidth="440px"
                    />
                }

                {
                    showLogoutModal &&
                    <DeletePopup 
                        show={showLogoutModal}
                        modalTitle="Logout"
                        modalContent="Are you sure you want to logout?"
                        acceptBtn="Logout"
                        rejectBtn="Cancel"
                        acceptClick={() => handleLogout()}
                        cancelClick={() => setShowLogoutModal(false)}
                        modalHeaderIcon="sign-out"
                    />
                }
            </div>
        </div>
    );
};

export default PortalHeader;