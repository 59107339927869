import './ContentEditable.css';

import {ContentEditable} from '@lexical/react/LexicalContentEditable';

export default function LexicalContentEditable({
  className,
  minHeight,
  maxHeight,
  padding
}: {
  className?: string;
  minHeight?: string;
  maxHeight?: string;
  padding?: string;
}): JSX.Element {
  return <ContentEditable style={{ minHeight: minHeight || '', maxHeight: maxHeight || '', padding: padding || '' }} className={className || 'ContentEditable__root'} />;
}
