export const flattenObjectWithNestedKeys = (obj: object, prefix = '') => {
    let items = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          Object.assign(items, flattenObjectWithNestedKeys(value, newKey));
        } else {
          items[newKey] = value;
        }
      }
    }
    return items;
};