import React from "react";
import { Col, Row } from "react-bootstrap";
import CardComponent from "../../../../components/Events/Registrations/CardComponent";

import './styles.scss';

interface PortalDashboardTrends {
    name: string;
    value: number | string | undefined;
}

interface PortalDashboardTrendsProps {
    trendsData: PortalDashboardTrends[];
}

const PortalDashboardTrends: React.FC<PortalDashboardTrendsProps> = (props): React.JSX.Element =>
{

    const { trendsData } = props;

    return (
        <div className="footer-dashboard-trends">
            <Row>
                {
                    trendsData?.map((trend, index) => (
                        <Col xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
                            <CardComponent
                                cardMinHeight="84px"
                                header={trend.name}
                                footerLeft={<p className="trend-value">{trend.value}</p>}
                            />
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default PortalDashboardTrends;