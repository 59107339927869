/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { Box, Button, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addEvent } from '../../../redux/events/Events';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { isImageBuffer, validateEmptyHtml } from '../../../scripts/helpers';
import { useDropzone } from 'react-dropzone';
import { Form } from 'react-bootstrap';
import { updateEvent, uploadImage } from '../../../scripts/apis/events';
import { CONTENT } from '../../../scripts/i18n';
// import QuillEditor from '../../common/QuillEditor';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ColorPicker from '../../../common/ColorPicker';
import { Event, EventFooterLinks } from '../../../pages/Events/interfaces';
import { IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
// import { ILandingPageLayout } from './interfaces/landingpage-details_interface';
import './styles.scss';
import { CalendarInvitation, EventLocationType, LandingPageLayout } from '../../../pages/Events/enum';

// import defaultOneIstoOneSkeleton from '../../assets/1-1-default.svg';
// import defaultSixteenIstoNineImgSkeleton from '../../assets/16-9-default.svg';

// import hoverOneIstoOneSkeleton from '../../assets/1-1-hover.svg';
// import hoverSixteenIstoNineImgSkeleton from '../../assets/16-9-hover.svg';

// import selectedOneIstoOneSkeleton from '../../assets/1-1-selected.svg';
// import selectedSixteenIstoNineImgSkeleton from '../../assets/16-9-selected.svg';
import SquareType from '../../../common/LandingPageTypeIconComponent/SquareType';
import LandscapeType from '../../../common/LandingPageTypeIconComponent/LandscapeType';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { useJsApiLoader, Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import LexicalEditor from '../../../lexical';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import toast from 'react-hot-toast';
import CustomTooltip from '../../../common/Tooltip';

const LandingPageDetails = (props: {eventDetails: Event}): React.JSX.Element => 
{

    const dispatch = useDispatch();
    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });
    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const eventId = props?.eventDetails?.id;

    const [spinner, setSpinner] = useState(false);
    const [eventBannerChanged, setEventBannerChanged] = useState(false);
    // const [layoutImage, setLayoutImage] = useState({
    //     oneIsToOne: props?.eventDetails?.landingPageLayout === 1 ? selectedOneIstoOneSkeleton : defaultOneIstoOneSkeleton,
    //     sixteenIsToNine: props?.eventDetails?.landingPageLayout === 2 ? selectedSixteenIstoNineImgSkeleton : defaultSixteenIstoNineImgSkeleton,
    // });
    const [bannerImage, setBannerImage] = useState('');
    const [aboutEvent, setAboutEvent] = useState('');
    // const [calendarDescription, setCalenarDescription] = useState('');
    const [footerLinks, setFooterLinks] = useState(
        props?.eventDetails?.eventFooterLinks ?
            props?.eventDetails?.eventFooterLinks :
            [{
                name: '',
                link: ''
            }]
    );
    const [landingPagectaColor, setLandingPagectaColor] = useState('#CDD0D5');
    const [expanded, setExpanded] = useState(props?.eventDetails?.calendarInvitation === CalendarInvitation.ENABLED ? true : false);

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
    };

    const handleAddFooterLink = (): void => 
    {
        setFooterLinks([...footerLinks, {
            name: '', link: ''
        }]);
    };

    const handleFooterLinkInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) : void => 
    {
        const { name, value } = event.target;
        setFooterLinks((prevData): EventFooterLinks[] =>  
        {
            return prevData.map((item, i): EventFooterLinks => 
            {
                return (i === index ? {
                    ...item, [name]: value 
                } : item); 
            }); 
        });
        // const list = [...footerLinks];
        // const footerName = list[index].name;
        // const footerLink = list[index].link;
        // if (name === 'name') {
        //     list[index][footerName] = value;
        // }
        // if (name === 'link') {
        //     list[index][footerLink] = value;
        // }
        // setFooterLinks(list);
    };

    const handleRemoveFootertLink = (event: React.SyntheticEvent, index: number): void => 
    {
        const list = [...footerLinks];

        list.splice(index, 1);
       
        setFooterLinks(list);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles) : void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = () : void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    dispatch(
                        addEvent({
                            banner: file,
                        })
                    );
                    setEventBannerChanged(true);
                    setBannerImage(URL.createObjectURL(file));
                }
                else 
                {
                    setEventBannerChanged(false);
                    setBannerImage('');
                    toast.error('Invalid file format or file size exceeds 10MB');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        },
    });

    const Preview = (): React.JSX.Element => 
    {
        return (
            <Box sx={{
                aspectRatio: props?.eventDetails?.landingPageLayout === 1 ? '1/1 !important' : props?.eventDetails?.landingPageLayout === 2 ? '16/9 !important' : 'auto' 
            }} className="preview-container">
                <img src={bannerImage && bannerImage !== '' ? bannerImage : ''} style={{
                    aspectRatio: props?.eventDetails?.landingPageLayout === 1 ? '1/1 !important' : props?.eventDetails?.landingPageLayout === 2 ? '16/9 !important' : 'auto' 
                }} />
            </Box>
        );
    };

    const handleLayoutChange = (type: number): void => 
    {
        formik.setFieldValue('landingPageLayout', type);
    };

    // const changeLayoutImg = (img, type): void => 
    // {
    //     setLayoutImage((prevItems): ILandingPageLayout => 
    //     {
    //         return {
    //             ...prevItems,
    //             oneIsToOne: type === 1 ? img : layoutImage?.oneIsToOne,
    //             sixteenIsToNine: type === 2 ? img : layoutImage?.sixteenIsToNine
    //         }; 
    //     });
    // };

    const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => 
    {
        event?.preventDefault();
        setSpinner(true);

        const parser = new DOMParser();
        const htmlElement = formik.values.aboutEvent ? parser.parseFromString(formik.values.aboutEvent, 'text/html') : '';

        if(htmlElement)
        {
            let images = htmlElement.querySelectorAll('img');
            if(images?.length > 0)
            {
                images.forEach((image) => 
                {
                    if(image.alt?.includes('lexicalimage'))
                    {
                        image.src = `{{${image?.alt}}}`;
                    }
                });
            }
        }

        const validPayload = {
            landingPageLayout: formik.values.landingPageLayout,
            description: formik.values?.overview,
            about: htmlElement && htmlElement?.body?.innerHTML || formik.values?.aboutEvent,
            eventFooterLinks: footerLinks.length > 0 ? footerLinks : null,
            brandColor: landingPagectaColor,
            country: formik.values.country,
            locationDetails: {
                name: formik.values.locationName,
                url: formik.values.locationURL
            },
            calendarInvitation: formik.values.enableCalendarInvitation ? CalendarInvitation.ENABLED : CalendarInvitation.DISABLED,
            calendarDescription: formik.values.calendarDescription,
        };

        let eventUpdated, bannerUpdated;

        try 
        {
            eventUpdated = await updateEvent(eventId, validPayload);
            if (eventUpdated) 
            {
                setSpinner(false);
            }
        }
        catch (error) 
        {
            setSpinner(false);
        }

        if (eventBannerChanged) 
        {
            const banner = new FormData();
            banner.append('file', eventReduxData.banner);
            try 
            {
                bannerUpdated = await uploadImage(eventId, 'eventBanner', banner, csrfTokenData);
                if (bannerUpdated) 
                {
                    setSpinner(false);
                    setEventBannerChanged(false);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }

        if (bannerUpdated || eventUpdated) 
        {
            eventBus.dispatch('Refresh-events-top-bar', {
                refresh: true 
            });
            eventBus.dispatch('event-overview-refreshed', {
                refresh: true 
            });
            eventBus.dispatch('event-details-refreshed', {
                refresh: true 
            });

            toast.success('Event details updated successfully');
            handleDrawerClose();
        }
    };

    const validationSchema = Yup.object().shape({
        aboutEvent: Yup.string().notRequired(),
            // .matches(/<p\b[^>]*>(?:(?!<br\b[^>]*>)[\s\S])*<\/p>/i, 'Enter valid Content')
            // .required('About Event is required'),
        aboutEventHtmlText: Yup.string().notRequired(),
        overview: Yup.string().nullable(),
        locationName: Yup.string().notRequired(),
        locationURL: Yup.string().notRequired(),
        country: Yup.string().notRequired(),
        calendarDescription: Yup.string().notRequired(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            aboutEvent: props?.eventDetails?.about || '',
            aboutEventHtmlText: props?.eventDetails?.about || '',
            landingPageLayout: props?.eventDetails?.landingPageLayout || '',
            overview: props?.eventDetails?.description || '',
            locationName: props?.eventDetails?.locationDetails?.name || '',
            locationURL: props?.eventDetails?.locationDetails?.url || '',
            country: props?.eventDetails?.country || '',
            enableCalendarInvitation: props?.eventDetails?.calendarInvitation === CalendarInvitation.ENABLED ? true : false,
            calendarDescription: props?.eventDetails?.calendarDescription || '',
        },
        onSubmit: async (): Promise<void> => 
        {
            handleSubmit();
        }
    });

    useEffect((): void => 
    {
        setBannerImage(props?.eventDetails?.eventBanner);
        setLandingPagectaColor(props?.eventDetails?.brandColor);
        setAboutEvent(props?.eventDetails?.about);
        // setCalenarDescription(props?.eventDetails?.calendarDescription);
    }, []);

    const { isLoaded } = import.meta.env.VITE_LOCATION_API_KEY ? useJsApiLoader({
        googleMapsApiKey: import.meta.env.VITE_LOCATION_API_KEY,
        libraries: ['places'],
    }) : {
        isLoaded: false
    };
 
 
    const [place, setPlace] = useState(formik.values.locationName);
    const autocompleteRef = useRef(null);
 
 
    const onLoad = (autocomplete) =>
    {
        autocompleteRef.current = autocomplete;
    };
 
 
    const onPlaceChanged = () =>
    {
        if (autocompleteRef.current)
        {
            const selectedPlace = autocompleteRef.current.getPlace();
            if(selectedPlace)
            {
                const countryComponent = selectedPlace.address_components?.find((component) => 
                {
                    return component.types.includes('country');
                });
    
                if (countryComponent) 
                {
                    const countryName = countryComponent.long_name;
                    formik.setFieldValue('country', countryName);
                }
                formik.setFieldValue('locationName', selectedPlace.name);
                formik.setFieldValue('locationURL', selectedPlace.url);
               
                setPlace(selectedPlace.name);
            }
            
        }
    };

    const CalendarInvitationToggleLabel = (): React.JSX.Element =>
    {
        return (
            <div className="calendar-invitation-label">
                <p>Calendar Invitation</p>
                <CustomTooltip title={'This will enable Google calendar invites to the confirmed registrants.'}><FontAwesomeIcon icon={['fal', 'info-circle']} /></CustomTooltip>
            </div>
        )
    }

    return (
        <div id="landingPageDetails">
            <CloseIconComponent onClick={handleDrawerClose} />

            <Form noValidate autoComplete="off" onSubmit={(values): void => 
            {
                return formik.handleSubmit(values);
            }}>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Select Layout" required />
                    <Box className="event-landing-page-type-wrapper">
                        <Box onClick={() : void => 
                        {
                            handleLayoutChange(1); 
                        }} 
                        className={formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? 'active-landing-page-type-container' : 'landing-page-type-container'}>
                            {formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? <SquareType selected={true}/> : <SquareType />}
                            {/* <img src={formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? selectedOneIstoOneSkeleton : layoutImage.oneIsToOne} /> */}
                        </Box>
                        <Box onClick={() : void => 
                        {
                            handleLayoutChange(2); 
                        }}
                        className={formik.values.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE ? 'active-landing-page-type-container' : 'landing-page-type-container'}>
                            {formik.values.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE ? <LandscapeType selected={true}/> : <LandscapeType/>}
                            {/* <img src={formik.values.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE ? selectedSixteenIstoNineImgSkeleton : layoutImage.sixteenIsToNine} /> */}
                        </Box>
                    </Box>
                </Box>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Banner" />
                    <Box className="event-banner-box">
                        {bannerImage && bannerImage !== '' ?
                            <Box className="event-banner-preview-box"><Preview />
                                <Box className="icon-box">
                                    <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(): void => 
                                    {
                                        dispatch(addEvent({
                                            banner: '',
                                        }));
                                        setBannerImage('');
                                    }} />
                                </Box>
                            </Box>
                            :
                            <Box {...getRootProps()} className="empty-upload-box">
                                <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} className="upload-icon" />
                                <input {...getInputProps()} hidden accept="image/*" type="file" />
                                <Box>
                                    <Typography className="drag-drop-text">{'Browse File'}</Typography>
                                    <Typography className="drag-drop-text">{formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? 'Select 1:1' : 'Select 16:9'}</Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Brand Color" />
                    <ColorPicker selectedColor={landingPagectaColor} setSelectedColor={setLandingPagectaColor} />
                </Box>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Overview" />
                    <FormControlComponent type="text" rows={4} placeholder="Description" defaultValue={formik.values.overview} value={formik.values.overview} as="textarea" minHeight="64px !important" maxHeight="64px !important" onChange={(event): void => 
                    {
                        formik.setFieldValue('overview', event.target.value);
                    }} />
                    {formik.touched.overview && formik.errors.overview ? <Typography className="error-msg">{formik.errors.overview}</Typography> : null}
                </Box>
                <Box className="sidebar-container-spacing">
                    <LexicalEditor label='About Event' toolBarOptions={['FontColor', 'BlockFormat', 'Bold', 'Italic', 'InsertImage', 'InsertEmbed', 'InsertPageBreak', 'ElementFormat']} minHeight={'250px'} slashCommands={['Heading 1', 'Heading 2', 'Heading 3', 'Paragraph', 'Divider', 'Bulleted List', 'Embed Youtube Video']} value={formik.values.aboutEvent} handleChange={(html: string, json: any) => { 
                            formik.setFieldValue('aboutEventHtmlText', validateEmptyHtml(html));
                            
                            formik.setFieldValue('aboutEvent', html);
                     }} />
                    {/* <CKTextEditor
                        value={formik.values.aboutEvent} onChangeFunc={(value: string): void => 
                        {
                            formik.setFieldValue('aboutEvent', value);
                        }}
                        labelText={null}
                    /> */}
                    {/* <QuillEditor height={'100%'} value={formik.values.aboutEvent} onChangeFunc={(value: string): void => 
                    {
                        dispatch(addEvent({
                            about: value
                        }));
                        formik.setFieldValue('aboutEvent', value);
                    }} /> */}
                    {formik.touched.aboutEventHtmlText && formik.errors.aboutEventHtmlText ? <Typography className="error-msg">{formik.errors.aboutEventHtmlText}</Typography> : null}
                </Box>

                {/* calendar description */}
                <Box className="sidebar-container-spacing">
                    <div className="calender-description-container">
                        <div className="approval-toggle-wrapper">
                            <div className="icon-label-cont">
                                <FontAwesomeIcon icon={["fal", "calendar"]} className="approval-icon" />
                                <FormLabelComponent label={<CalendarInvitationToggleLabel />} noBottomMargin />
                            </div>
                            <FormControlLabel
                                checked={formik.values.enableCalendarInvitation}
                                control={<CustomSwitch onChange={(event) => { 
                                    formik.setFieldValue('enableCalendarInvitation', event.target.checked);
                                    setExpanded(event.target.checked); 
                                }} />}
                                label={""}
                            />
                        </div>
                        
                        <div className={`editor-container ${expanded ? 'expanded' : ''}`}>
                            {formik.values.enableCalendarInvitation && (
                                <LexicalEditor 
                                    toolBarOptions={['FontColor', 'BlockFormat', 'Bold', 'Italic', 'InsertImage', 'InsertEmbed', 'InsertPageBreak', 'ElementFormat']} 
                                    minHeight={'250px'} 
                                    placeHolder='Calendar Description'
                                    maxHeight='250px' 
                                    slashCommands={['Heading 1', 'Heading 2', 'Heading 3', 'Paragraph', 'Divider', 'Bulleted List', 'Embed Youtube Video']} 
                                    value={formik.values.calendarDescription} 
                                    handleChange={(html: string, json: any) => { 
                                        formik.setFieldValue('calendarDescription', html);
                                    }} 
                                />
                            )}
                        </div>
                    </div>
                </Box>
                {(props?.eventDetails?.locationType === EventLocationType.ONSITE || props?.eventDetails?.locationType === EventLocationType.HYBRID) && <>
                    {isLoaded && (<Box className="container-spacing">
                       <FormLabelComponent label={'Event Location'} />
                           <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                               <FormControlComponent
                                   value={place}
                                   placeholder={'Search for a location...'}
                                   type={'text'}
                                   onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                   {
                                       setPlace(event.target.value);
                                   }}
                               />
                           </GoogleAutocomplete>
                   </Box>
                       )}
                </>}
                <Box className="sidebar-container-spacing">
                    {footerLinks && footerLinks.length > 0 && footerLinks.map((item, index): React.JSX.Element => 
                    {
                        return (
                            <>
                                <Box className="footer-link-container" key={index}>
                                    <Box className="footer-heading-remove-icon-cont">
                                        <FormLabelComponent label={`Footer Link ${index + 1}`} />
                                        <FontAwesomeIcon className="footer-link-remove-icon" onClick={(event): void => 
                                        {
                                            handleRemoveFootertLink(event, index); 
                                        }} icon={['fal', 'circle-minus']} />
                                    </Box>
                                    <Box className="footer-name-url-cont">
                                        <FormControlComponent name="name" placeholder="Name" onChange={(event): void => 
                                        {
                                            handleFooterLinkInputChange(event, index); 
                                        }} value={item.name} type='text' />
                                        <FormControlComponent name="link" placeholder="URL" onChange={(event): void => 
                                        {
                                            handleFooterLinkInputChange(event, index); 
                                        }} value={item.link} type='text' />
                                    </Box>
                                </Box>
                            </>
                        );
                    })}
                    {footerLinks?.length <= 5 && <Button className="add-footer-link-button" onClick={handleAddFooterLink}>{'+ Add Footer Link'}</Button>}
                </Box>
                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={'Save'} btnType={'primary'} />
                    </Stack>
                </Box>
            </Form>
        </div>
    );
};

export default LandingPageDetails;

// Old location details inputs

{/* <Box className="sidebar-container-spacing">
<FormLabelComponent label="Country" />
<CountryDropdown
    value={formik.values.country}
    onChange={(value: string): void => 
    {
        formik.setFieldValue('country', value);
    }}
    classes='country-dropdown'
/>
</Box>
<Box className="sidebar-container-spacing">
<FormLabelComponent label="Event Location Name" />
<FormControlComponent type="text" rows={4} placeholder="Eg. Bengaluru, India" value={formik.values.locationName} onChange={(event): void => 
{
    formik.setFieldValue('locationName', event.target.value);
}} />
{formik.touched.locationName && formik.errors.locationName ? <Typography className="error-msg">{formik.errors.locationName}</Typography> : null}
</Box>
<Box className="sidebar-container-spacing">
<FormLabelComponent label="Event Location URL" />
<FormControlComponent type="text" rows={4} placeholder="URL" value={formik.values.locationURL} onChange={(event): void => 
{
    formik.setFieldValue('locationURL', event.target.value);
}} />
{formik.touched.locationURL && formik.errors.locationURL ? <Typography className="error-msg">{formik.errors.locationURL}</Typography> : null}
</Box> */}