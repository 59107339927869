import { useEffect, useRef, useState } from "react";
import { CustomButton } from "../FormComponents/Buttons";
import { FormControlComponent, FormLabelComponent } from "../FormComponents/ReusableFormComponents";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "../FormComponents/DatePicker";

import './styles.scss';
import CustomTooltip from "../Tooltip";

interface TableFilterProps {
    // selectedOperator: 'contains' | 'equals' | 'notEquals';
    // inputValue: string | number;
    columnOptions: { label: string; value: string; type: 'string' | 'dropdown', filterOptions?: { name: string; value: string | number }[]; }[];
    // selectedColumn: string;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleColumnChange: (event: React.ChangeEvent<HTMLSelectElement>, index: number) => void;
    handleDropdownChange?: (event: React.ChangeEvent<HTMLSelectElement>, index: number) => void;
    handleDateChange?: (date: string, index: number) => void;
    handleApplyFilters?: () => void;
    // filterFn: () => void;
    filters: FilterCriteria[];
    addFilter: () => void;
    removeFilter: (index: number) => void;
    clearFilters: () => void;
    customMappings?: Record<string, Record<string, string>>;
    customFilterFunctions? : Record<string, (obj: any, inputValue: string | number) => boolean>;
    minimalView?: boolean;
}

interface FilterCriteria {
    selectedColumn: string;
    inputValue: string | number;
    type?: 'string' | 'dropdown' | 'date';
    // selectedOperator: 'contains' | 'equals' | 'notEquals';
}

const TableFilterServerSide: React.FC<TableFilterProps> = (props): React.JSX.Element =>
{
    const filterRef = useRef(null);
    const buttonRef = useRef(null);

    const { 
        columnOptions, 
        handleInputChange, 
        handleColumnChange, 
        handleDropdownChange,
        handleDateChange,
        handleApplyFilters,
        filters, 
        addFilter, 
        removeFilter, 
        minimalView,
    } = props;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    // const [selectedOperator, setSelectedOperator] = useState<'contains' | 'equals' | 'notEquals'>('contains');

    const handleClick = () => 
    {
        setIsVisible((prev) => !prev);
    };

    // const filterRows = () => 
    // {
    //     const filteredRows = rows.filter(obj => {
    //         const flatObj = flattenObjectWithNestedKeys(obj);

    //         return filters.every(filter => {
    //             const value = flatObj[filter.selectedColumn];
    //             const { inputValue } = filter;   
                
    //             if(customFilterFunctions)
    //             {
    //                 const customFilterFunction = customFilterFunctions[filter?.selectedColumn];
    //                 if (typeof(customFilterFunction) === 'function') return customFilterFunction(obj, inputValue);
    //             }

    //             if(customMappings)
    //             {
    //                 const customMapping = customMappings[filter.selectedColumn];
    
    //                 // Handle custom filtering logic if a mapping is provided
    //                 if (customMapping) {
    //                     const mappedValue = customMapping[value];
    //                     if (mappedValue) {
    //                         return mappedValue?.toLowerCase().includes(String(inputValue)?.toLowerCase());
    //                     }
    //                     return false;
    //                 }
    //             }
                            
    //             if (Array.isArray(value)) {
    //                 return value.some(val => _.isString(val) && val.toLowerCase().includes(String(inputValue)));
    //             }
    //             if (_.isString(value)) {
    //                 return value.toLowerCase().includes(String(inputValue).toLowerCase());
    //             } else if (_.isNumber(value)) {
    //                 return value === Number(inputValue);
    //             }
    //             return false;
    //         });
    //     });

    //     setRows(filteredRows);
    // };

    const getAvailableColumns = (index: number) => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        return columnOptions.filter(option => !selectedColumns.includes(option.value) || filters[index].selectedColumn === option.value);
    };

    const hasAvailableColumns = (): boolean => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        return columnOptions.some(option => !selectedColumns.includes(option.value));
    };

    // useEffect(() => 
    // {
    //     const handleClickOutside = (event) => 
    //     {
    //         if (filterRef.current && !filterRef.current.contains(event.target) || buttonRef.current && !buttonRef.current.contains(event.target)) 
    //         {
    //             setIsVisible(false);
    //         }
    //     };

    //     if (isVisible) 
    //     {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     }
    //     else    
    //     {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => 
    //     {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [isVisible]);

    const renderFilterTypes = (filter: FilterCriteria, index: number) => {
        if (filter?.type === 'string') 
        {
            return renderInput(filter, index);
        }
        else if (filter?.type === 'dropdown') 
        {
            return renderDropdown(filter, index);
        }
        else if (filter?.type === 'date') 
        {
            return renderDatePicker(filter, index);
        }
    }

    const renderInput = (filter: FilterCriteria, index: number) => {
        return (
            <FormControlComponent 
                value={filter.inputValue} 
                onChange={(event) => handleInputChange(event, index)} 
                type="text"
                placeholder="Filter Value"
                name="filter-input"
            />
        )
    };

    const renderDropdown = (filter: FilterCriteria, index: number) => { 
        return (
            <select 
                className="select-input" 
                value={filter.inputValue} 
                onChange={(event) => {
                    if (handleDropdownChange)
                    {
                        handleDropdownChange(event, index)
                    }
                }}
                name="filter-dropdown"
            >
                {columnOptions.find(option => option.value === filter.selectedColumn)?.filterOptions?.map((option, idx) => (
                    <option key={idx} value={option.value}>{option.name}</option>
                ))}
            </select>
        )
    };

    const renderDatePicker = (filter: FilterCriteria, index: number) => { 
        return (
            <DatePicker 
                value={filter.inputValue as string} 
                onChange={(date) => handleDateChange && handleDateChange(date as any, index)}
            />
        )
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                filterRef.current && 
                !filterRef.current.contains(event.target as Node) &&
                buttonRef.current && 
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsVisible(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="tanstack-table-filter">
            {/* {minimalView ?  */}
                <CustomTooltip title="Filters">
                    <button ref={buttonRef} className="filter-button" onClick={handleClick}>
                        <FontAwesomeIcon icon={['fal', 'filter']} />
                        {/* {filters && filters[0]?.inputValue !== '' && <h3 className="filter-length">{filters?.length}</h3>} */}
                    </button>
                </CustomTooltip>
                {/* :
                <button ref={buttonRef} className="filter-button-normal">
                    <CustomButton onClick={handleClick} name="Filters" btnType="tertiary_grey" />
                </button> 
            } */}
            {
                isVisible && (
                    <div ref={filterRef} className="tanstack-table-filter__content">
                        <div className="heading">
                            <FormLabelComponent label='Columns' />
                            <FormLabelComponent label='Value' />
                        </div>
                        <div className="filter-container">
                            {filters.map((filter, index) => {
                                return (
                                    <div key={index} className="filter-group">
                                        <select name="column-select" className="filter-select" value={filter.selectedColumn} onChange={(event) => { handleColumnChange(event, index); }}>
                                            {    
                                                getAvailableColumns(index).map((column, idx) => 
                                                {
                                                    return (
                                                        <option key={idx} value={column.value}>{column.label}</option>
                                                    )
                                                })
                                            }
                                        </select>

                                        {/* <div>
                                            <FormLabelComponent label='Operator' />
                                            <select className="filter-select" value={filter.selectedOperator} onChange={(event) => setSelectedOperator(event.target.value)}>
                                                <option value="contains">Contains</option>
                                                <option value="equals">Equals</option>
                                                <option value="notEquals">Not Equals</option>
                                            </select>
                                        </div> */}

                                        {
                                            renderFilterTypes(filter, index)
                                        }

                                        {/* {filter?.type === 'string' ? 
                                            (renderInput(filter, index)) 
                                            :
                                            (renderDropdown(filter, index))
                                        } */}

                                        {filters?.length > 1 &&
                                            <FontAwesomeIcon icon={['fal', 'circle-minus']} 
                                                onClick={() => removeFilter(index)}
                                                cursor={'pointer'}
                                            /> 
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {hasAvailableColumns() && <div className="add-filter-button">
                            <CustomButton 
                                name="Add Filter" 
                                btnType="primary_outline"
                                onClick={addFilter}
                            />
                        </div>}

                        <div className="filter-actions-container">
                            <CustomButton 
                                name="Apply Filters" 
                                btnType="primary"
                                onClick={handleApplyFilters}
                            />
                            {
                                filters?.length > 0 && 
                                <div className="clear-filter-button">
                                    <CustomButton 
                                        name="Clear Filters" 
                                        btnType="danger"
                                        onClick={props.clearFilters}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default TableFilterServerSide;