'use client'

import useEmblaCarousel from 'embla-carousel-react';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import EmblaLazyLoadImage from './EmblaLazyLoadImage';
import { useCallback, useEffect, useState } from 'react';
import { NextButton, PrevButton, usePrevNextButtons } from './EmblaPrevNextButtons';
import { DotButton, useDotButton } from './EmblaPaginationImages';
import { EventPreview } from '../../../Events/interfaces';
import { ImageType } from '../../../Events/interfaces/event-photo-sharing_interface';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EmblaCarouselProps { 
    eventData: EventPreview | undefined;
    images: ImageType[];
    initialIndex: number;
    handlePopupClose: () => void;
    screenType?: 'mobile' | 'desktop' | 'tablet';
}

const EmblaCarousel: React.FC<EmblaCarouselProps> = (props): React.JSX.Element =>
{
    const { eventData, images, initialIndex, handlePopupClose, screenType } = props;

    const emblaOptions: EmblaOptionsType = {
        startIndex: initialIndex,
        
    };

    const [emblaRef, emblaApi] = useEmblaCarousel(emblaOptions);

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi);

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

    const [slidesInView, setSlidesInView] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView((slidesInView) => {
          if (slidesInView.length === emblaApi.slideNodes().length) {
            emblaApi.off('slidesInView', updateSlidesInView)
          }
          const inView = emblaApi
            .slidesInView()
            .filter((index: any) => !slidesInView.includes(index))
          return slidesInView.concat(inView)
        })
    }, [])

    function scrollToSelectedDot(selectedIndex) {
        const dotsContainer = document.querySelector('.embla__dots');
        const selectedDot = dotsContainer?.children[selectedIndex];
      
        if (selectedDot) {
          // Calculate the scroll position needed to bring the selected dot into view
          const scrollPosition = selectedDot?.offsetLeft + selectedDot.clientWidth / 2 - dotsContainer.clientWidth / 2;
      
          // Scroll the container to the calculated position
          dotsContainer.scrollTo({
            left: scrollPosition,
            behavior: 'smooth' // Smooth scrolling
          });
        }
    };

    useEffect(() => {
        if (!emblaApi) return
    
        updateSlidesInView(emblaApi)
        emblaApi.on('slidesInView', updateSlidesInView)
        emblaApi.on('reInit', updateSlidesInView)

        // const { slideRegistry } = emblaApi.internalEngine();
        // const snapIndexThatSlideBelongsTo = slideRegistry.findIndex((group) => group.includes(initialIndex));
        // emblaApi.scrollTo(snapIndexThatSlideBelongsTo);

    }, [emblaApi, updateSlidesInView]);

    // useEffect(() => {
    //     scrollToSelectedDot(selectedIndex);
    // }, [selectedIndex]);

    useEffect(() => {
        const container = document.querySelector('.embla__dots'); // The scrollable container
        const selectedDot = container?.querySelector(`.embla__dot:nth-child(${selectedIndex + 1})`); // Assuming dots are direct children
    
        if (container && selectedDot) {
            const containerRect = container.getBoundingClientRect();
            const selectedDotRect = selectedDot.getBoundingClientRect();
    
            // Calculate the scroll position needed to bring the selectedDot into view
            const scrollLeft = selectedDotRect.left + container.scrollLeft - containerRect.left;
    
            // Optionally, adjust `scrollLeft` here if you want some padding around the selected item
    
            // Scroll the container to the selected dot
            container.scrollTo({
                left: scrollLeft,
                behavior: 'smooth' // Smooth scroll
            });
        }
    }, [selectedIndex]);

    return (
        <div>

            <div className="image-popup-actions" style={{ top: screenType === 'mobile' && '8%' }}>
                {/* {!(screenType === 'mobile') && <DownloadImage downloadableUrl={images[selectedIndex]?.downloadableUrl} fileName={`${eventData?.title} photo`} />} */}
                <div className="closeIcon" onClick={handlePopupClose}>
                    <FontAwesomeIcon icon={['fal', 'xmark']} />
                </div>
            </div>

            <div id="emblaCarousel">
                <div className="embla-viewport" ref={emblaRef}>
                    <div className="embla-container">
                        {
                            images?.map((item, index) => {
                                return (
                                    <EmblaLazyLoadImage
                                        key={index}
                                        src={item?.downloadableUrl || ''}
                                        alt={'photo'}
                                        inView={slidesInView.includes(index)}
                                        screenType={screenType}
                                    />
                                )
                            })
                        }
                    </div>

                    <div className="embla__buttons" style={{ margin: screenType === 'mobile' ? '-12px 0' : '' }}>
                        {!prevBtnDisabled ? <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} /> : <div />}
                        {!nextBtnDisabled ? <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} /> : <div />}
                    </div>
                </div>
            </div>

            {/* <div className="embla__dots">
                {images?.map((item, index) => (
                    <DotButton
                        key={index}
                        onClick={() => { onDotButtonClick(index); scrollToSelectedDot(index); }}
                        image={item?.downloadableUrl}
                        className={'embla__dot'.concat(
                            index === selectedIndex ? ' embla__dot--selected' : ''
                        )}
                    />
                ))}
            </div> */}
        </div>
    )
};

export default EmblaCarousel;