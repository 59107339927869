import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { FormControlComponent, FormLabelComponent } from "../../common/FormComponents/ReusableFormComponents";
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { CustomButton } from "../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoImg from '../../../public/light/eventhq_light.png';
import { createInvoiceForm } from "../../scripts/apis/registrationPublic";
import { useSelector } from "react-redux";
import moment from "moment";
import { uniqueTimezoneArr } from "../../components/Events/timezoneGenerateFunction";
import _ from "lodash";

import './styles.scss';
import { EventTicket } from "../Events/interfaces";

countries.registerLocale(en);

interface InvoiceProps {
    invoiceJWT: string;
    eventData: any;
    eventTimeInfo: string;
    paymentSuccessPage: boolean;
    eventDateInfo: string;
    screenType: 'mobile' | 'tablet' | 'desktop';
}

const InvoiceAndPaymentSuccessPage: React.FC<InvoiceProps> = ({ invoiceJWT, eventData, eventTimeInfo, eventDateInfo, screenType, paymentSuccessPage }) => { 

    const currentpath = useLocation().pathname;

    const [spinner, setSpinner] = useState(false);
    const [sameAsShippingAdd, setSameAsShippingAdd] = useState<boolean>(false);  

    const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem):boolean 
    {
        return elem?.value === eventData?.event?.timezone;
    })[0]?.name;

    const eventDateTime = `${moment.unix(Number(eventData?.event?.eventStartDateTime)).format('MMM D, HH:MM')} ${defaultTimezone}`;

    let ticket: EventTicket = eventData?.registrant?.ticket;
    let ticketPrice = Number(ticket?.ticketPrice);

    let discount = undefined;

    if (eventData?.registrant?.coupon?.couponValue)
    {
        const discountPrice = eventData?.registrant?.coupon?.couponValue;
        discount = discountPrice;
    }
    else if (eventData?.registrant?.coupon?.couponPercentage)
    {
        const discountPrice = (eventData?.registrant?.ticket?.ticketPrice * eventData?.registrant?.coupon?.couponPercentage / 100);
        discount = discountPrice;
    }

    let discountedPrice = discount ? (Number(ticketPrice) - Number(discount)) : ticketPrice;

    if(ticket?.tax && ticket.tax?.length > 0)
    {
        if(discount)
        {
            const totalTaxAmount = _.sumBy(ticket.tax, (tax) => {
                const taxAmount = (discountedPrice * Number(tax.percentage)) / 100;
                return taxAmount;
            });
            ticketPrice = discountedPrice + totalTaxAmount;
        }
        else 
        {
            const totalPercentage = _.sumBy(ticket.tax, (tax) => Number(tax.percentage));
            ticketPrice += (ticketPrice * totalPercentage / 100);
        }
    }
    else
    {
        ticketPrice = discountedPrice;
    }

    const finalPrice = ticketPrice;

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const validationSchema = Yup.object().shape({
        billingAddress: Yup.string().trim().required('Billing Address is required'),
        shippingAddress: Yup.string().trim().required('Shipping Address is required'),
        customerName: Yup.string().trim().required('Customer Name is required'),
        taxId: Yup.string(),
        phone: Yup.number().required('Phone Number is required'),
        country: Yup.string().trim().required('Country is required'),
        state: Yup.string().trim().required('State is required'),
        city: Yup.string().trim().required('City is required'),
        zipCode: Yup.number().min(9999, 'Zip Code should have atleast 4 digits').required('Zip Code is required'),
        consent: Yup.bool().oneOf([true], 'You must agree to the terms.'),
    });

    const formik = useFormik({ 
        initialValues: {
            billingAddress: '',
            shippingAddress: '',
            customerName: '',
            // taxId: '',
            phone: '',
            country: '',
            state: '',
            city: '',
            zipCode: 0,
            consent: false
        },
        validationSchema,
        onSubmit: async (values) => {
            setSpinner(true);
            const data = {
                shippingAddress: values.shippingAddress,
                billingAddress: values.billingAddress,
                customerName: values.customerName,
                phone: values.phone,
                // taxId: values.taxId,
                country: values.country,
                state: values.state,
                city: values.city,
                zipCode: values.zipCode,
                consent: values.consent ? 1 : 2,
            };

            try 
            {
                const paymentLinkGenerated = await createInvoiceForm(invoiceJWT, data, csrfTokenData);
                if(paymentLinkGenerated)
                {
                    formik.resetForm();
                    window.location.href = paymentLinkGenerated;
                }
            } 
            catch (error) 
            {
                console.log('Error in submitting invoice form', error);
            }
            finally
            {
                setSpinner(false);
            }
        }
    });

    const checkZipcodeValidation = async (zipcode: number): Promise<any> => { 
    if(formik.values.country && zipcode && String(zipcode)?.length > 4)
    {
        const url = new URL("https://api.zipcodestack.com/v1/search");

        const countryCode = countries.getAlpha2Code(formik.values.country, 'en')?.toLowerCase();

        const params = {
            "codes": String(zipcode),
            "country": countryCode,
        };

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

        const headers = {
            "apikey": "01HYZD1W96NPVQVJFFDWK3VCX3",
            "Accept": "application/json",
        };

        const response = await fetch(url, {
            method: 'GET',
            headers,
        }).then(response => response.json());

        if(response && response?.results?.length > 0)
        {
            const zipCodeData = response?.results[0];
            formik.setValues({
                ...formik.values,
                state: zipCodeData?.region,
            });
        }
        else
        {
            formik.setErrors({
                zipCode: 'Invalid Zip Code'
            });
        }
    }
    };
    
    return(
        <>
            {invoiceJWT && <div>
                {eventData?.org?.additionalInfo && eventData?.org?.additionalInfo?.image && <Box sx={{ position: screenType === 'desktop' && 'sticky', top: screenType === 'desktop' && '0' }} className="invoice-org-logo-header"><img src={eventData?.org?.additionalInfo?.image} /></Box>}
                <div id="invoiceForm">
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className="invoice-eventDetails">
                                <Box className="event-details-cont" sx={{ position: screenType === 'desktop' && 'sticky', top: screenType === 'desktop' && '76px' }}>
                                    <Box className="details-inner-cont">
                                        <img src={eventData?.event?.eventBanner} />
                                        <Box>
                                            <Typography className="title">{eventData?.event?.title}</Typography>
                                            <Typography className="timing">{eventDateTime}</Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className="ticket-cont">
                                        <>
                                            <Box className="ticket-inner-cont">
                                                <Typography className="name">{eventData?.registrant?.ticket?.name}</Typography>
                                                <Typography className="price">{`₹${eventData?.registrant?.ticket?.ticketPrice}`}</Typography>
                                            </Box>
                                        </>
                                        {(eventData?.registrant?.ticket?.tax?.length > 0 || eventData?.registrant?.coupon) ? <div className="tax-container">
                                            <div className="tax-details-container">
                                                {eventData?.registrant?.coupon && <div className="coupon-container">
                                                    <p className="code">{`Coupon Applied (${eventData?.registrant?.coupon?.name || ''})`}</p>
                                                    <p className="value">{`- ₹${discount}`}</p>
                                                </div>}
                                                {
                                                    eventData?.registrant?.ticket?.tax?.length > 0 && eventData?.registrant?.ticket?.tax.map((tax: any, index: number) => {

                                                        let taxValue;
                                                        if(discount)
                                                        {
                                                            const ticketPriceAfterDiscount = Number(ticket?.ticketPrice) - Number(discount);
                                                            taxValue = _.round((Number(ticketPriceAfterDiscount * tax.percentage) / 100), 2);
                                                        }
                                                        else 
                                                        {
                                                            taxValue = _.round((Number(ticket?.ticketPrice * tax.percentage) / 100), 2);
                                                        }
                                                    
                                                        return(
                                                            <div className="tax-inner-cont" key={index}>
                                                                <div className="tax-details">
                                                                    <p className="name">{tax?.name}</p>
                                                                    <p className="percentage">{`(${tax?.percentage}%)`}</p>
                                                                </div>
                                                                <p>{`₹${taxValue > 0 ? taxValue : 0}`}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>    
                                            <div className="total-tax">
                                                <p>{'Total Amount'}</p>
                                                <p>{`₹${finalPrice > 0 ? `${Number(finalPrice)?.toFixed(2)}` : 0}`}</p>
                                            </div>
                                        </div> : null}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Box className="form-heading">
                                    <Typography>{'Your details'}</Typography>
                                </Box>
                                <Form noValidate onSubmit={(values): void => 
                                    {
                                        return formik.handleSubmit(values);
                                    }}>

                                {/* Customer name */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Customer Name' required />
                                    <FormControlComponent 
                                        type='text'
                                        name='customerName'
                                        value={formik.values.customerName}
                                        placeholder="Enter your name"
                                        onChange={formik.handleChange}
                                        required
                                        isInvalid={formik.touched.customerName && !!formik.errors.customerName}
                                    />
                                    {formik.touched.customerName && formik.errors.customerName && <div className="error-msg">{formik.errors.customerName}</div>}
                                </Box>

                                {/* Mobile number */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Mobile Number' required />
                                    <FormControlComponent 
                                        type='number'
                                        name='phone'
                                        placeholder="Enter your mobile number"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onKeyDown={(evt) => 
                                        {
                                            return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                        }}
                                        required
                                        isInvalid={formik.touched.phone && !!formik.errors.phone}
                                    />
                                    {formik.touched.phone && formik.errors.phone && <div className="error-msg">{formik.errors.phone}</div>}
                                </Box>

                                {/* Tax ID */}
                                {/* <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Tax ID' />
                                    <FormControlComponent 
                                        type='text'
                                        name='taxId'
                                        placeholder="Enter Tax ID"
                                        value={formik.values.taxId}
                                        onChange={(event) => {
                                            formik.setValues({
                                                ...formik.values,
                                                taxId: event.target.value?.toUpperCase()
                                            });
                                        }}
                                        isInvalid={formik.touched.taxId && !!formik.errors.taxId}
                                    />
                                    {formik.touched.taxId && formik.errors.taxId && <div className="error-msg">{formik.errors.taxId}</div>}
                                </Box> */}

                                {/* Shipping address */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Shipping Address' required />
                                    <FormControlComponent
                                        type='text'
                                        as={'textarea'}
                                        rows={3}
                                        name='shippingAddress'
                                        value={formik.values.shippingAddress}
                                        onChange={formik.handleChange}
                                        placeholder="Enter your shipping address"
                                        required
                                        isInvalid={formik.touched.shippingAddress && !!formik.errors.shippingAddress}
                                    />
                                    {formik.touched.shippingAddress && formik.errors.shippingAddress && <div className="error-msg">{formik.errors.shippingAddress}</div>}
                                </Box>

                                {/* Billing address */}
                                <Box className="sidebar-container-spacing">
                                    <Box className="billing-address-label-cont">
                                        <FormLabelComponent label='Billing Address' required />
                                        <Box className="billing-address-checkbox-cont">
                                            <input type="checkbox" id="sameAsShippingAddress" name="sameAsShippingAddress" value="sameAsShippingAddress" onChange={(event) => {
                                                if(event.target.checked) {
                                                    formik.setValues({
                                                        ...formik.values,
                                                        billingAddress: formik.values.shippingAddress
                                                    });
                                                }
                                                setSameAsShippingAdd(!sameAsShippingAdd);
                                            }} />
                                            <label htmlFor="sameAsShippingAddress">Same as Shipping Address</label>
                                        </Box>
                                    </Box>
                                    <FormControlComponent 
                                        type='text'
                                        as={'textarea'}
                                        // disabled={formik.values.shippingAddress === formik.values.billingAddress}
                                        rows={3}
                                        disabled={sameAsShippingAdd}
                                        name='billingAddress'
                                        value={formik.values.billingAddress}
                                        onChange={formik.handleChange}
                                        placeholder="Enter your billing address"
                                        required
                                        isInvalid={formik.touched.billingAddress && !!formik.errors.billingAddress}
                                    />
                                    {formik.touched.billingAddress && formik.errors.billingAddress && <div className="error-msg">{formik.errors.billingAddress}</div>}
                                </Box>

                                {/* Country */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Country' required />
                                    <CountryDropdown 
                                        classes="country-dropdown"
                                        value={formik.values.country}
                                        onChange={(val) => {
                                            formik.setValues({
                                                ...formik.values,
                                                country: val
                                            });
                                        }}
                                    />
                                    {formik.touched.country && formik.errors.country && <div className="error-msg">{formik.errors.country}</div>}
                                </Box>

                                {/* Zipcode */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Zip Code' required />
                                    <FormControlComponent 
                                        type='number'
                                        name='zipCode'
                                        placeholder="Enter your zip code"
                                        value={formik.values.zipCode || ''}
                                        onBlur={(event) => {
                                            checkZipcodeValidation(Number(event.target.value));
                                        }}
                                        onChange={(event) => {
                                            formik.setValues({
                                                ...formik.values,
                                                zipCode: Number(event.target.value)
                                            });
                                        }}
                                        onKeyDown={(evt) => 
                                        {
                                            return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                                        }}
                                        required
                                        isInvalid={formik.touched.zipCode && !!formik.errors.zipCode}
                                    />
                                    {formik.touched.zipCode && formik.errors.zipCode && <div className="error-msg">{formik.errors.zipCode}</div>}
                                </Box>

                                {/* State */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='State' required />
                                    <RegionDropdown 
                                        country={formik.values.country}
                                        classes="state-dropdown"
                                        value={formik.values.state}
                                        onChange={(val) => {
                                            formik.setValues({
                                                ...formik.values,
                                                state: val
                                            });
                                        }}
                                    />
                                    {formik.touched.state && formik.errors.state && <div className="error-msg">{formik.errors.state}</div>}
                                </Box>

                                {/* City */}
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='City' required />
                                    <FormControlComponent 
                                        type='text'
                                        name='city'
                                        value={formik.values.city}
                                        placeholder="Enter your city"
                                        onChange={formik.handleChange}
                                        required
                                        isInvalid={formik.touched.city && !!formik.errors.city}
                                    />
                                    {formik.touched.city && formik.errors.city && <div className="error-msg">{formik.errors.city}</div>}
                                </Box>

                                {/* Consent */}
                                <Box className="sidebar-container-spacing">
                                    <Box className="consent-checkbox-cont">
                                        <input type="checkbox" id="consent" name="consent" value="consent" onChange={formik.handleChange} />
                                        <label htmlFor="consent">I hereby confirm that the information provided above is accurate and complete to the best of my knowledge. I consent to the use of this information for the purposes specified.</label>
                                    </Box>
                                    {formik.touched.consent && formik.errors.consent && <div className="error-msg">{formik.errors.consent}</div>}
                                </Box>

                                {/* Submit button */}
                                <Box className="sidebar-container-spacing">
                                    <CustomButton btnType="primary" name="Submit" disabled={spinner} loading={spinner} type="submit" style={{ width: '100%', fontSize: '14px' }} />
                                </Box>
                                </Form>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>}

            {paymentSuccessPage && <div id="paymentSuccessPage">
            
                <Box className="logo-header">
                    {eventData?.org?.additionalInfo?.image ? <img src={eventData?.org?.additionalInfo?.image} className="org-logo-image" /> : <img src={logoImg} className="org-logo-image" />}
                </Box>
                <Container>
                    <Box className="payment-success-eventDetails">
                        <Box className="payment-details-cont">
                            <Box className="thanks_container">
                                <FontAwesomeIcon icon={['fal', 'badge-check']} />
                                <Typography>{'Thanks! Your ticket has been confirmed.'}</Typography>
                            </Box>
                            <Box className="confirmed-msg-cont">
                                <Typography>{`Congratulations! You are all set for the event. You can find your unique QR code via email. See you at ${eventData?.title}!`}</Typography>
                            </Box>
                        </Box>
                        <Box className="desktop-lp-cont">
                            <Box className="landing-page-content">
                                <Box className="landing-page-heading-cont">
                                    {eventData?.title && <Typography className="landing-page-heading">{eventData?.title}</Typography>}
                                    {eventData?.description && <Typography className="landing-page-desc">{eventData?.description}</Typography>}
                                    <Grid container spacing={2} className="venue-details">
                                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="meetups-timing-cont">
                                            <Box className="date-icon">
                                                <FontAwesomeIcon icon={['fal', 'calendar']} />
                                            </Box>
                                            <Box className="meetup-time">
                                                <Box className="meetup-date"><Typography className="meetups-date-text">{eventDateInfo}</Typography></Box>
                                                <Box className="meetup-time"> <Typography className="meetups-time-text">{eventTimeInfo}</Typography></Box>
                                            </Box>

                                        </Grid>
                                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="meetups-location-cont">
                                            <Box className="location-icon">
                                                {eventData?.locationType === 2 ? <FontAwesomeIcon icon={['fal', 'video']} /> : <FontAwesomeIcon icon={['fal', 'location-dot']} />}

                                            </Box>
                                            <Box className="meetup-time">
                                                <Tooltip title={eventData?.locationDetails?.name ? eventData?.locationDetails?.name : null}><a className="meetups-venue-main-text" href={eventData?.locationDetails?.url ? eventData?.locationDetails?.url : '-'} target='_blank' rel="noreferrer">
                                                    {eventData?.locationDetails?.name ? <p>{eventData?.locationDetails?.name?.substring(0, 36)}{eventData?.locationDetails?.name?.length > 36 && '...'}</p> : '-'}
                                                </a></Tooltip>
                                                <Typography className="meetups-venue-sub-text">{eventData?.country ? eventData?.country : '-'}</Typography>
                                            </Box>

                                        </Grid>
                                    </Grid>

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Box className="payment-cont-footer">
                    <FontAwesomeIcon icon={['fal', 'copyright']} />
                    <Typography>{'Powered by EventHQ'}</Typography>
                </Box>
            </div>}
        </>
    );
};

export default InvoiceAndPaymentSuccessPage;