interface GraphData {
    [index: number]: string[] | number[];
}

interface ChartOptions {
    color: string[];
    tooltip: {
        trigger: string;
    };
    grid: {
        left: string;
        right: string;
        containLabel: boolean;
    };
    dataset: {
        source: GraphData;
    };
    legend: {
        show: boolean;
        orient: string;
        icon: string;
        padding: number[];
        itemWidth: number;
        itemHeight: number;
        data?: string[];
    };
    xAxis: {
        type: string;
        data: string[] | number[];
        axisTick: {
            show: boolean;
        };
        axisLabel: {
            interval: number;
            align: string;
            textStyle: {
                color: string;
                fontSize: number;
                fontWeight: number;
                width: number;
                fontFamily: string;
                textAlign: string;
            };
        };
    };
    yAxis: {
        splitLine: {
            show: boolean;
            lineStyle: {
                type: string;
            };
        };
        axisLabel: {
            formatter: (value: number) => string | number;
        };
    };
    series: {
        name: string;
        type: string;
        barWidth: string;
        stack: string;
        data: (number | undefined)[];
        emphasis: {
            focus: string;
            label: {
                show: boolean;
                fontSize: number;
                fontWeight: string;
                color: string;
            };
        };
        itemStyle: {
            borderRadius: number[];
        };
    }[];
}

export const generateOpenOpportunitiesGraphOption = (openStageGraphData: GraphData): ChartOptions => {
    const seriesArray = openStageGraphData[0]?.map((name, index) => ({
        name: name,
        type: 'bar',
        barWidth: '30',
        stack: 'stack',
        data: openStageGraphData[0].map((_, i) => (i === index ? openStageGraphData[1][i] : undefined)),
        emphasis: {
            focus: 'series',
            label: {
                show: true,
                fontSize: 12,
                fontWeight: 'bold',
                color: '#2E90FA',
            },
        },
        itemStyle: {
            borderRadius: [4, 4, 0, 0],
        },
    }));

    return {
        color: ['#2E90FA'],
        tooltip: {
            trigger: 'item'
        },
        grid: {
            left: '8px',
            right: '8px',
            containLabel: false
        },
        dataset: {
            source: openStageGraphData
        },
        legend: {
            show: true,
            orient: 'horizontal',
            icon: 'circle',
            padding: [0, 16, 10, 4],
            itemWidth: 8,
            itemHeight: 8,
        },
        xAxis: {
            type: 'category',
            data: openStageGraphData[0],
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                align: 'center',
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 10,
                    fontWeight: 400,
                    width: 50,
                    fontFamily: 'Inter',
                    textAlign: 'center'
                },
            },
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dotted',
                },
            },
            axisLabel: {
                formatter: function (value) {
                    if (value >= 1000000) {
                        return (value / 1000000) + 'M';
                    } else if (value >= 1000) {
                        return (value / 1000) + 'K';
                    } else {
                        return value;
                    }
                },
            }
        },
        series: seriesArray,
    };
};

export const convertObjectToArrays = (data: { [key: string]: number | string } | null | undefined) => {
    if (!data || Object.keys(data).length === 0) {
        return [[], []];
    }
    const keys = Object.keys(data);
    const values = Object.values(data);
    return [keys, values];
};