import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { resetFormComponents } from '../../../redux/registrationsForms/FormComponents';
import eventBus from '../../../scripts/event-bus';
import { updateRegistrationForm } from '../../../scripts/apis/registrationForm';
import { resetFormField } from '../../../redux/registrationsForms/FormComponentDisplayField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { addRegistrationForms } from '../../../redux/registrationsForms/RegistrationForms';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import EditableHeader from '../../../common/EditableHeader';
import toast from 'react-hot-toast';


const RegistrationsTopBar = (props): React.JSX.Element => 
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentpath = useLocation().pathname;

    // const currentPath = useLocation().pathname;

    const formId = props.formId;
    const [formName, setFormName] = useState(props.name ? props.name : '');
    const [updateName, setUpdateName] = useState(false);
    const [topButton, setTopButton] = useState();
    
    useEffect((): void => 
    {
        const fetchData = (): void => 
        {
            eventBus.on('submit-form-options', (data): void => 
            {
                setTopButton(data.submitButtonComponent);
            });
        };
        fetchData();
    }, []);

    useEffect((): void => 
    {
        if (props.name) 
        {
            setFormName(props.name);
        }
    }, [props?.name]);



    const editRegistrationsFormDataFromTable = async (): Promise<void> => 
    {

        if(formName === '') 
        {
            toast.error('Form name cannot be empty');
            return setFormName(props.name);
        }

        if(formName === props.name) return;

        const registrationFormDetails = {
            name: formName,
            description: props.registrationData.description,
            link: props.registrationData.link,
        };
        try 
        {
            const registrationFormUpdated = await updateRegistrationForm(formId, registrationFormDetails);
            if (registrationFormUpdated) 
            {
                dispatch(addRegistrationForms({
                    name: formName
                }));
                props.setRefresh(true);
                setUpdateName(!updateName);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <Box id="registrationsTopBar">
            <Box className="back-btn-box" onClick={(): void => 
            {
                if(currentpath?.includes('portal'))
                {
                    props?.handleModuleChange('form');
                }
                else
                {
                    navigate('/registrations');
                    dispatch(resetFormComponents());
                    dispatch(resetFormField());
                }
            }} >
                <FontAwesomeIcon icon={['fal', 'arrow-left']} className="back-icon" />
                <Typography className="back-text">
                    {currentpath?.includes('portal') ? 'Back' : 'Back to Forms'}
                </Typography>
            </Box>
            <div className="name-box">
                <EditableHeader 
                    text={formName}
                    placeholder='Enter Form Name'
                    onChange={(text): void => setFormName(text)}
                    handleSave={(): Promise<void> => editRegistrationsFormDataFromTable()}
                />
            </div>
            <div/>
        </Box>
    );
};
export default RegistrationsTopBar;