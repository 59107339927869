import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Stack, Typography, Box } from '@mui/material';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { addICP } from '../../redux/settings/ICP';
import { createICP, updateICP } from '../../scripts/apis/icp';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import { ARR, COUNTRY, EMPLOYEE_RANGE, INDUSTRY, TECH, TECH_CATEGORIES } from '../../pages/Settings/ICP/icp-dataset';
import { AutocompletewithTags, CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { IICP } from '../../pages/Settings/ICP/interfaces';
import { IICPState, IICPValue } from '../../pages/Settings/interface/icp_interface';
import { CustomButton } from '../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';
import { createIcpSponsorPortal, deleteIcpSponsorPortal, updateIcpSponsorPortal } from '../../scripts/apis/sponsorPortal/sponsorPortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeletePopup from '../../common/DeletePopup';

interface ICreateICP {
    icpData?: IICP;
    setRefresh: (value) => void;
    sponsorPortal?: boolean;
    handleDrawerClose?: () => void;
}

const ICP = (props: ICreateICP):React.JSX.Element => 
{
    const { sponsorPortal, icpData } = props;

    const defaultICPInfo = {
        name: '',
        description: '',
        subIndustry: '',
        employeesRange: '',
        estimatedAnnualRevenue: '',
        country: '',
        techCategories: '',
        tech: ''
    };

    const icpReduxData = useSelector((state: IICPState):IICPValue => 
    {
        return state.icp.value;
    });

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [icpInfo, setICPInfo] = useState(defaultICPInfo);
    const [spinner, setSpinner] = useState(false);
    const [icpPropertyCombination, setIcpPropertyCombination] = useState('');

    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const [industry, setIndustry] = useState([]);
    const [employeeRange, setEmployeeRange] = useState([]);
    const [arrRange, setArrRange] = useState([]);
    const [geography, setGeography] = useState([]);
    const [techCategories, setTechCategories] = useState([]);
    const [tech, setTech] = useState([]);

    const [selectedTechCategoriesPosition, setSelectedTechCategoriesPosition] = useState([]);
    const [matchingTech, setMatchingTech] = useState([]);


    const handleIndustryChange = (event, newValue):void => 
    {
        setIndustry([...newValue]);
        const selectedIndustries = newValue.length > 0 ? newValue.map((elem: string):string => 
        {
            return _.find(INDUSTRY, (industry):boolean => 
            {
                return elem === industry; 
            }); 
        }) : [];

        formik.setFieldValue('subIndustry', selectedIndustries.toString());
        dispatch(addICP({
            subIndustry: selectedIndustries.toString()
        }));
    };


    const handleEmployeeRangeChange = (event, newValue):void => 
    {
        setEmployeeRange([...newValue]);
        const selectedEmployeeRanges = newValue.length > 0 ? newValue.map((elem: string):string => 
        {
            return _.find(EMPLOYEE_RANGE, (employeesRange):boolean => 
            {
                return elem === employeesRange; 
            }); 
        }) : [];

        formik.setFieldValue('employeesRange', selectedEmployeeRanges.toString());
        dispatch(addICP({
            employeesRange: selectedEmployeeRanges.toString()
        }));
    };

    const handleArrRangeChange = (event, newValue):void => 
    {
        setArrRange([...newValue]);
        const selectedArrRanges = newValue.length > 0 ? newValue.map((elem: string):string => 
        {
            return _.find(ARR, (arrRange):boolean => 
            {
                return elem === arrRange; 
            }); 
        }) : [];

        formik.setFieldValue('estimatedAnnualRevenue', selectedArrRanges.toString());
        dispatch(addICP({
            estimatedAnnualRevenue: selectedArrRanges.toString()
        }));
    };

    const handleGeographyChange = (event, newValue):void => 
    {
        setGeography([...newValue]);
        const selectedGeographies = newValue.length > 0 ? newValue.map((elem: string):string => 
        {
            return _.find(COUNTRY, (geographies):boolean => 
            {
                return elem === geographies; 
            }); 
        }) : [];

        formik.setFieldValue('country', selectedGeographies.toString());
        dispatch(addICP({
            country: selectedGeographies.toString()
        }));
    };

    const setTechCategoryChanges = (value):void => 
    {
        if(value) 
        {
            if (typeof (value) === 'string') 
            {
                const newValue = value.split(',');
                const techCategoriesPositions = [];
                newValue.forEach((element):void => 
                {
                    const techCategoriesPosition = TECH_CATEGORIES.indexOf(element);
                    techCategoriesPositions.push(techCategoriesPosition);
                });
    
                if (techCategoriesPositions.length > 0) 
                {
                    setMatchingTech([]);
                    techCategoriesPositions.forEach((item):void => 
                    {
                        setSelectedTechCategoriesPosition([...selectedTechCategoriesPosition, item]);
                        if (TECH[item].length > 0) 
                        {
                            TECH[item].map((tech):void => 
                            {
                                setMatchingTech((prevMatchingTech):string[] => 
                                {
                                    return [...prevMatchingTech, tech]; 
                                });
                            });
                        }
    
                    });
                }
            }
            else 
            {
                const techCategoriesPositions = [];
                value.forEach((element):void => 
                {
                    const techCategoriesPosition = TECH_CATEGORIES.indexOf(element);
                    techCategoriesPositions.push(techCategoriesPosition);
                });
    
                if (techCategoriesPositions.length > 0) 
                {
                    setMatchingTech([]);
                    techCategoriesPositions.forEach((item):void => 
                    {
                        setSelectedTechCategoriesPosition([...selectedTechCategoriesPosition, item]);
                        if (TECH[item].length > 0) 
                        {
                            TECH[item].map((tech):void => 
                            {
                                setMatchingTech((prevMatchingTech):string[] => 
                                {
                                    return [...prevMatchingTech, tech]; 
                                });
                            });
                        }
    
                    });
                }
            }
        }
    };

    const setTechChanges = (value):void => 
    {
        if (value) 
        {
            formik.setFieldValue('tech', value.toString());
            dispatch(addICP({
                tech: value.toString()
            }));
        }
    };

    const handleTechCategoriesChange = (event, newValue):void => 
    {

        setTechCategories([...newValue]);
        const selectedTechCategories = newValue.length > 0 ? newValue.map((elem: string):string => 
        {
            return _.find(TECH_CATEGORIES, (techCategories):boolean => 
            {
                return elem === techCategories; 
            }); 
        }) : [];

        if (selectedTechCategories.length > 0) 
        {
            setTechChanges(null);
            setTechCategoryChanges(selectedTechCategories);
        }
        else 
        {
            setMatchingTech([]);
            setSelectedTechCategoriesPosition([]);
        }
        formik.setFieldValue('techCategories', selectedTechCategories.toString());
        dispatch(addICP({
            techCategories: selectedTechCategories.toString()
        }));
    };

    const handleTechChange = (event, newValue):void => 
    {
        setTech(newValue);
        setTechChanges(newValue);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string(),
        subIndustry: Yup.string(),
        employeesRange: Yup.string(),
        estimatedAnnualRevenue: Yup.string(),
        techCategories: Yup.string(),
        country: Yup.string(),
        tech: Yup.string().test('isValidTech', 'Select Valid Tech', (value):boolean => 
        {
            if (value) 
            {
                const x = value.split(',');
                if (x.length > 0) 
                {
                    return x.every((item):boolean => 
                    {
                        return matchingTech.includes(item); 
                    });
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                return true;
            }
    
        })
    }).test('at-least-one-property', null, function (value): true | Yup.ValidationError 
    {
        const { subIndustry, employeesRange, estimatedAnnualRevenue, techCategories, tech, country } = value;
        if (!(subIndustry || employeesRange || estimatedAnnualRevenue || techCategories || tech || country)) 
        {
            setIcpPropertyCombination('At least one property must be selected');
            return new Yup.ValidationError(
                'At least one property must be selected',
                null,
                'at-least-one-property'
            );
        }
        if (subIndustry || employeesRange || estimatedAnnualRevenue || techCategories || tech || country) 
        {
            setIcpPropertyCombination('');
            // return true;
        }
        return true;
    });    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: icpReduxData.name ? icpReduxData.name : '',
            description: icpReduxData.description ? icpReduxData.description : '',
            subIndustry: icpReduxData.subIndustry ? icpReduxData.subIndustry : '',
            employeesRange: icpReduxData.employeesRange ? icpReduxData.employeesRange : '',
            estimatedAnnualRevenue: icpReduxData.estimatedAnnualRevenue ? icpReduxData.estimatedAnnualRevenue : '',
            techCategories: icpReduxData.techCategories ? icpReduxData.techCategories : '',
            tech: icpReduxData.tech ? icpReduxData.tech : '',
            country: icpReduxData.country ? icpReduxData.country : ''
        },
        validationSchema,
        // validate: (values) => {
        //     return !values.subIndustry && !values.employeesRange && !values.estimatedAnnualRevenue && !values.country ? cominationSchema : validationSchema
        // },

        onSubmit: async ():Promise<void> => 
        {

            const icpDetails = {
                name: formik.values.name,
                description: formik.values.description,
                subIndustry: industry ? industry?.toString() : null,
                employeesRange: employeeRange ? employeeRange?.toString() : null,
                estimatedAnnualRevenue: arrRange ? arrRange?.toString() : null,
                techCategories: techCategories ? techCategories?.toString() : null,
                tech: tech ? tech?.toString() : null,
                country: geography ? geography?.toString() : null,
            };

            if(sponsorPortal)
            {
                return handleSubmitForSponsorPortal(icpDetails);
            }

            if ((_.isEmpty(icpData))) 
            {
                try 
                {
                    setSpinner(true);
                    const icpCreated = await createICP(icpDetails, csrfTokenData);
                    if (icpCreated) 
                    {
                        props.setRefresh(true);
                        handleDrawerClose();
                        setSpinner(false);
                    }
                }
                catch (error) 
                {
                    setIcpPropertyCombination(error.message);
                    console.log(error);
                }
            }
            else 
            {

                if (icpInfo?.name !== icpReduxData?.name || icpDetails?.subIndustry || icpDetails?.employeesRange || icpDetails?.estimatedAnnualRevenue || icpDetails?.techCategories || icpDetails?.tech || icpDetails?.country) 
                {
                    try 
                    {
                        // const icp = _.omitBy(icpDetails, _.isNil);
                        setSpinner(true);
                        const icpUpdated = await updateICP(icpData.id, icpDetails);
                        if (icpUpdated) 
                        {
                            props.setRefresh(true);
                            handleDrawerClose();
                            setSpinner(false);

                        }
                    }
                    catch (error) 
                    {
                        setIcpPropertyCombination(error.message);
                        console.log(error);
                        setSpinner(false);
                    }
                }

            }
        }
    });

    const handleSubmitForSponsorPortal = async (data: any): Promise<void> =>
    {
        if(icpData)
        {
            try 
            {
                const icpUpdated = await updateIcpSponsorPortal(icpData?.id, data);
                if(icpUpdated)
                {
                    props.setRefresh(true);
                    props?.handleDrawerClose() || null;
                    toast.success('ICP Updated Successfully');
                }
            } 
            catch (error) 
            {
                toast.error('Error in updating ICP');
                console.log(error);
            }
            finally
            {
                setSpinner(false);
            }
        }
        else
        {
            try 
            {
                const icpCreated = await createIcpSponsorPortal(data);
                if(icpCreated)
                {
                    props.setRefresh(true);
                    props?.handleDrawerClose() || null;
                    toast.success('ICP Created Successfully');
                }
            } 
            catch (error) 
            {
                toast.error('Error in creating ICP');
                console.log(error);
            }
            finally
            {
                setSpinner(false);
            }
        }
    };

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleDeleteSponsorPortalIcp = async (): Promise<void> =>
    {
        try 
        {
            const icpDeleted = await deleteIcpSponsorPortal(icpData?.id);
            if(icpDeleted)
            {
                props.setRefresh(true);
                props?.handleDrawerClose() || null;
                toast.success('ICP Deleted Successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        if (icpData) 
        {
            setICPInfo(icpData);
            if (icpData.estimatedAnnualRevenue) 
            {
                setArrRange(icpData.estimatedAnnualRevenue.split(','));
            }
            if (icpData.employeesRange) 
            {
                setEmployeeRange(icpData.employeesRange.split(','));
            }

            if (icpData.country) 
            {
                setGeography(icpData.country.split(','));
            }

            if(icpData.techCategories)
            {
                setTechCategories(icpData.techCategories.split(','));
            }

            if(icpData.tech)
            {
                setTech(icpData.tech.split(','));
            }

            if (icpData.subIndustry) 
            {
                setIndustry(icpData.subIndustry.split(','));
            }

            dispatch(addICP({
                name: icpData?.name,
                description: icpData?.description,
                subIndustry: icpData?.subIndustry,
                employeesRange: icpData?.employeesRange,
                estimatedAnnualRevenue: icpData?.estimatedAnnualRevenue,
                techCategories: icpData?.techCategories,
                tech: icpData?.tech,
                country: icpData?.country,
            }));
            setTechCategoryChanges(icpData?.techCategories);
            // setLogo(icpData.logo);
        }
    }, []);

    return (
        <Box id="createICP">
            {!sponsorPortal && <CloseIconComponent onClick={handleDrawerClose} />}
            {/* {!props.addEventICP && <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack>} */}
            <Form noValidate autoComplete="off" onSubmit={(values):void => 
            {
                return formik.handleSubmit(values);
            }}>
                {/* Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ICP.FORMS.LABEL.NAME} required />
                    <FormControlComponent
                        type='text'
                        onChange={(event):void => 
                        {
                            dispatch(addICP({
                                name: event.target.value
                            }));
                            formik.setFieldValue('name', event.target.value);
                        }}
                        required
                        value={formik.values.name}
                        placeholder={CONTENT.SETTINGS_PAGE.ICP.FORMS.PLACEHOLDER}
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* Description */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ICP.FORMS.LABEL.DESCRIPTION} />
                    <FormControlComponent
                        as='textarea'
                        rows={3}
                        onChange={(event):void => 
                        {
                            dispatch(addICP({
                                description: event.target.value
                            }));
                            formik.setFieldValue('description', event.target.value);
                        }}
                        value={formik.values.description}
                        placeholder={'Description'}
                        type='text'
                    />
                </Box>

                {/* Industry */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ICP.FORMS.LABEL.INDUSTRY} />
                    <AutocompletewithTags 
                        value={industry}
                        defaultValue={industry}
                        onChange={handleIndustryChange}
                        options={INDUSTRY.map((subIndustry):string => 
                        {
                            return subIndustry;
                        })}
                        placeholder={'Select Industry'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedindustry = industry.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setIndustry(updatedindustry);
                        }}
                    />
                </Box>

                {/* Employee range */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ICP.FORMS.LABEL.EMPLOYEE} />
                    <AutocompletewithTags 
                        value={employeeRange}
                        defaultValue={employeeRange}
                        onChange={handleEmployeeRangeChange}
                        options={EMPLOYEE_RANGE.map((employeesRange):string => 
                        {
                            return employeesRange;
                        })}
                        placeholder={'Select Employee Range'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedEmployeeRange = employeeRange.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setEmployeeRange(updatedEmployeeRange);
                        }}
                    />
                </Box>

                {/* ARR */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ICP.FORMS.LABEL.ARR} />
                    <AutocompletewithTags 
                        defaultValue={arrRange}
                        value={arrRange}
                        onChange={handleArrRangeChange}
                        options={ARR.map((estimatedAnnualRevenue):string => 
                        {
                            return estimatedAnnualRevenue;
                        })}
                        placeholder={'Select ARR'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedArrRange = arrRange.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setArrRange(updatedArrRange);
                        }}
                    />
                </Box>

                {/* Tech Categories */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.TECH_CATEGORIES} />
                    <AutocompletewithTags 
                        defaultValue={techCategories}
                        value={techCategories}
                        onChange={handleTechCategoriesChange}
                        options={TECH_CATEGORIES.map((techCategory):string => 
                        {
                            return techCategory;
                        })}
                        placeholder={'Select Tech Category'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedTechCategories = techCategories.filter((_, i): boolean => 
                            {
                                console.log(i, index)
                                return i !== index;
                            });
                            setTechCategories(updatedTechCategories);
                        }}
                    />
                </Box>

                {/* Tech */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.TECH} />
                    <AutocompletewithTags 
                        defaultValue={tech}
                        value={tech}
                        onChange={handleTechChange}
                        options={matchingTech.map((tech):string => 
                        {
                            return tech; 
                        })}
                        placeholder={'Select Tech'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedTech = tech.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setTech(updatedTech);
                        }}
                    />
                </Box>
                    
                {/* Geography */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ICP.FORMS.LABEL.GEOGRAPHY} />
                    <AutocompletewithTags 
                        defaultValue={geography}
                        value={geography}
                        onChange={handleGeographyChange}
                        options={COUNTRY.map((country):string => 
                        {
                            return country;
                        })}
                        placeholder={'Select Geography'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedGeography = geography.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setArrRange(updatedGeography);
                        }}
                    />
                </Box>
                
                {!props?.sponsorPortal ?
                    <Box className="submit-btn-container">
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                            <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' onClick={() => handleDrawerClose() } />
                            <CustomButton loading={spinner} name={icpData ? CONTENT.SETTINGS_PAGE.ICP.FORMS.BUTTON.UPDATE : CONTENT.SETTINGS_PAGE.ICP.FORMS.BUTTON.CREATE}
                                btnType='primary' type='submit' 
                            />
                        </Stack>
                    </Box>
                    :
                    <Box className="submit-btn-container">
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Stack direction={'row'} spacing={1}>
                                <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' onClick={() => props?.handleDrawerClose()} />
                                <CustomButton loading={spinner} name={icpData ? CONTENT.SETTINGS_PAGE.ICP.FORMS.BUTTON.UPDATE : CONTENT.SETTINGS_PAGE.ICP.FORMS.BUTTON.CREATE}
                                    btnType='primary' type='submit' 
                                />
                            </Stack>
                            <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />
                        </Stack>
                    </Box>
                }
            </Form>

            {
                <DeletePopup 
                    acceptBtn='Delete' 
                    acceptClick={handleDeleteSponsorPortalIcp} 
                    cancelClick={() => { 
                        setShowDeletePopup(false);
                        eventBus.dispatch('selected-row-id', null); 
                    }} 
                    modalContent={`Are you sure you want to delete ${icpData?.name}?`}
                    modalTitle='Delete ICP'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </Box >
    );
};

export default ICP;