import { useEffect, useState } from 'react';

export const useCountdown = (startTime: number): {days?: number, hours?: number, minutes?: number, seconds?: number} => 
{
    const calculateTimeLeft = (): {days?: number, hours?: number, minutes?: number, seconds?: number} => 
    {
        const difference = +new Date(startTime) - +new Date();
        
        let timeLeft = {
        };

        if (difference > 0) 
        {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => 
    {
        const timer = setTimeout((): void => 
        {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return (): void => 
        {
            return clearTimeout(timer); 
        };
    });

    return timeLeft;
};