import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const defaultDate = new Date();
// const defaultDateStamp = moment(defaultDate).format('X');
// const defaultTimeStamp = moment(defaultDate).format('X');

export const ScheduleRowSlice = createSlice({
    name: 'schedule',
    initialState: {
        value: {
            title: '',
            description: '',
            status: 0,
            segmentType: 0,
            segmentTypeIds: {
                stage: {},
                session: {},
                expo: {}
            },
            ticketSaleStartDate: '',
            ticketSaleStartTime: '',
            ticketSaleEndDate: '',
            ticketSaleEndTime: '',
            ticketSpeakerIds: [],
            tags: []
        }
    },
    reducers: {
        addSchedule: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetSchedule: (state) => {
            state.value = {
                title: '',
                description: '',
                status: 0,
                segmentType: 0,
                segmentTypeIds: {
                    stage: {},
                    session: {},
                    expo: {}
                },
                ticketSaleStartDate: '',
                ticketSaleStartTime: '',
                ticketSaleEndDate: '',
                ticketSaleEndTime: '',
                ticketSpeakerIds: [],
                tags: []
            }
        },
        removeEventSpeakers: (state, actions) => {
            state.value.ticketSpeakerIds.splice(actions.payload.index, 1)
        },
    },
});

export const { addSchedule, resetSchedule, removeEventSpeakers } = ScheduleRowSlice.actions;
export default ScheduleRowSlice.reducer;