import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, InputGroup } from 'react-bootstrap';
import { Autocomplete, Box, FormControlLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import './styles.scss';
import { CardComponentProps, IAutoCompleteWithTagsProps, IAutocompleteComponentProps, IAutocompleteComponentWithFilterOptionProps, ICreatableAutoCompleteComponentProps, IFormControlComponentProps, IMultiSelectComponentProps, IRadioGroupComponentProps, ISelectComponentProps, ITimezoneSelectComponentProps, timezonevalues } from './IReusableFormComponents';
import MuiChip, { DotIcon } from './MuiChip';

export const CloseIconComponent: React.FC<{ onClick: () => void }> = (props: { onClick: () => void }): React.JSX.Element => 
{
    return (
        <div id="closeIconComponent">
            <FontAwesomeIcon icon={['fal', 'xmark']} className="close-icon-circle" onClick={props?.onClick} />
        </div>
    );
};

export const FormLabelComponent: React.FC<{ label: string | React.JSX.Element, required?: boolean, noBottomMargin?: boolean }> = (props: { label: string | React.JSX.Element, required?: boolean, noBottomMargin?: boolean }): React.JSX.Element => 
{
    return (
        <div id="formLabelComponent">
            <Form.Label style={{
                marginBottom: props?.noBottomMargin && 'unset' 
            }}><Box className="form-component-container"><Typography className="form-label">{props.label}</Typography>{props?.required && < Typography className="required-icon">{'*'}</Typography>}</Box></Form.Label>
        </div>
    );
};

export const FormControlComponent: React.FC<IFormControlComponentProps> = (props: IFormControlComponentProps): React.JSX.Element => 
    {
        return (
            <div id="formControlComponent">
                {props?.asInputGroup &&
                    <InputGroup.Text className="formcontrol-inputGroup-text">{props?.inputGroupText}</InputGroup.Text>
                }
                {
                    props?.startIcon && <FontAwesomeIcon icon={['fal', props?.startIcon]} className="input-start-icon" />
                }
                <Form.Control
                    ref={props?.ref}
                    className={props?.asInputGroup ? 'form-control-asInputGroup' : props?.startIcon ? 'form-control form-control-with-startIcon' : 'form-control'}
                    name={props?.name}
                    type={props?.type}
                    defaultValue={props?.defaultValue}
                    as={props?.as}
                    rows={props?.rows}
                    placeholder={props?.placeholder}
                    value={props?.value}
                    style={props?.style}
                    maxLength={props?.maxLength} // Add maxLength for text inputs
                    onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
                        if (props?.onBlur) {
                            props.onBlur(event); 
                        }
                    }}
                    onClick={(event) => {
                        event?.preventDefault();
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                    {
                        // Enforce character limit for number inputs
                        if (props?.maxLength && event.target.value.length > props.maxLength) {
                            event.target.value = event.target.value.slice(0, props.maxLength);
                        }
                        if (props?.onChange) {
                            props.onChange(event); 
                        }
                    }}
                    isInvalid={props?.isInvalid}
                    disabled={props?.disabled}
                    required={props?.required}
                    minLength={props?.minLength}
                    min={props?.min}
                    max={props?.max}
                    onKeyDown={(event: React.KeyboardEvent): void => 
                    {
                        if(event.key === 'ArrowUp' || event.key === 'ArrowDown') 
                        {
                            event.preventDefault();
                        }
                        
                        if(props?.type === 'number' && props?.onKeyDown) 
                        {
                            props.onKeyDown(event); 
                        }
                    }}
                />
                {
                    typeof props?.value === 'string' && props?.clearable && props?.value?.length > 0 && <FontAwesomeIcon icon={['fal', 'xmark']} className="input-clear-icon" onClick={(): void => 
                    {
                        props?.clearFn ? props.clearFn() : props?.onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>); 
                    }} />
                }
            </div>
        );
    };

export const SelectComponent: React.FC<ISelectComponentProps> = (props: ISelectComponentProps): React.JSX.Element => 
{
    return (
        <div id="selectComponentContainer">
            <Select
                className="select-component"
                displayEmpty
                disabled={props.disabled}
                value={props.value || ''}
                style={props?.style}
                IconComponent={(props): React.JSX.Element => 
                {
                    return (
                        <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons popup-icon ${props.className}`} />
                    ); 
                }}
                onChange={props.onChange}
                renderValue={(selected): React.JSX.Element => 
                {
                    if (props?.customValueRender)
                    {
                        return props?.customValueRender(selected);
                    }
                    else if (selected) 
                    {
                        return (
                            <Box className="selected-placeholder-container">
                                {
                                    props?.showOptionChip ?
                                    <Stack direction="row" spacing={1} alignItems={'center'}>
                                        <DotIcon color={props?.options?.find((option) => option.id === selected)?.optionChipColor} />
                                        <Typography className="select-placeholder-active">{props?.optionLabels && props?.optionLabels[Number(selected)]}</Typography>
                                    </Stack> :
                                    <Typography className="select-placeholder-active">{props?.selectedAsString ? props?.optionLabels[String(selected)] : props?.optionLabels[Number(selected)]}</Typography>
                                }
                            </Box>
                        );
                    }
                    else if (props?.showNone)
                    {
                        return <Typography className="select-placeholder-active">{props?.showNone}</Typography>;
                    }
                    if (props?.defaultPlaceholder) {
                        return (<Typography className="select-placeholder">{props?.defaultPlaceholder}</Typography>);
                    }
                }}
                MenuProps={{
                    style: {
                        zIndex: 1301,
                    },
                    slotProps: {
                        paper: {
                            sx: {
                                borderRadius: '8px',
                                background: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '4px',
                                marginTop: '8px',
                                padding: '2px',
                                zIndex: 1301,
                                position: 'fixed',
                                boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                                '& .MuiMenu-list': {
                                    width: '100%',
                                    padding: '4px',
                                },
                                '& .MuiMenuItem-root.Mui-selected': {
                                    color: 'var(--colors-text-text-primary-900, #101828)',
                                    borderRadius: 'var(--spacing-sm, 6px)',
                                    backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB)',
                                },
                                '& .MuiMenuItem-root:hover': {
                                    color: 'var(--colors-text-text-primary-900, #101828)',
                                    borderRadius: 'var(--spacing-sm, 6px)',
                                    backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                },
                                '& .MuiMenuItem-root': {
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                },
                                '& .option-text span': {
                                    color: 'var(--colors-text-text-primary-900, #101828)',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                    borderRadius: '8px',
                                },
                                '& .checked-icon': {
                                    color: '#1570EF'
                                }
                            }
                        },
                    }
                }}
            >
                {props?.showNone && <MenuItem value=''><ListItemText className="option-text" primary={props?.showNone} /></MenuItem>}
                {/* <MenuItem value=''><ListItemText className="option-text" primary={props?.defaultPlaceholder} /></MenuItem> */}
                {props.options && props.options.map((type, index): React.JSX.Element => 
                {
                    return (
                        <MenuItem disabled={props?.optionDisabled} key={index} value={type.id} className="select-menu-item"
                            onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => 
                            {
                                event.stopPropagation();
                            }}
                        >
                            {type?.optionChipColor && <div style={{ marginRight: '8px' }}><DotIcon color={type?.optionChipColor} /></div>}
                            {type?.startIcon && type?.startIcon}
                            <ListItemText className="option-text" primary={type.name} />
                            {Number(props.value) === type.id && <FontAwesomeIcon className="checked-icon" icon={['fal', 'check']} />}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

export const MultipleSelectComponent: React.FC<IMultiSelectComponentProps> = (props: IMultiSelectComponentProps): React.JSX.Element => 
{
    return (
        <div id="multipleSelectComponent">
            <Select
                value={props?.value}
                displayEmpty
                IconComponent={(props): React.JSX.Element => 
                {
                    return (
                        <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                    ); 
                }}
                onChange={props.onChange}
                multiple={props?.multiple}
                className="multiple-select"
                renderValue={(selected): React.JSX.Element => 
                {
                    if (selected?.length > 0) 
                    {
                        return (
                            <Box className="selected-placeholder-container">
                                <Typography className="select-placeholder-active">{props?.optionLabels[Number(selected)]}</Typography>
                            </Box>
                        );
                    }
                    return <Typography className="select-placeholder">{props?.defaultPlaceholder}</Typography>;
                }}
                MenuProps={{
                    slotProps: {
                        paper: {
                            sx: {
                                borderRadius: '8px',
                                background: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '4px',
                                marginTop: '8px',
                                padding: '2px',
                                boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                                '& .MuiMenu-list': {
                                    width: '100%',
                                    padding: '4px',
                                },
                                '& .MuiMenuItem-root.Mui-selected': {
                                    color: '#375DFB !important',
                                    background: '#EBF1FF !important',
                                    borderRadius: '4px',
                                },
                                '& .MuiMenuItem-root:hover': {
                                    backgroundColor: '#F6F8FA',
                                    color: '#161922',
                                    borderRadius: '4px',
                                },
                                '& .MuiMenuItem-root': {
                                    color: '#525866',
                                    backgroundColor: '#fff',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    lineHeight: '20px',
                                    borderRadius: '4px',
                                }
                            }
                        },
                    }
                }
                }
            >
                {props.options && props.options.map((type, index): React.JSX.Element => 
                {
                    return (
                        <MenuItem key={index} value={type.id} className="select-menu-item"
                            onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => 
                            {
                                event.stopPropagation();
                            }
                            }
                        >
                            <ListItemText primary={type.name} />
                        </MenuItem>
                    );
                })}
            </Select>
            <Box className="autocomplete-selected-box-container">
                {
                    props?.value && props?.value.length > 0 && !props?.all && props?.value.map((element, index): React.JSX.Element => 
                    {
                        return (
                            <Stack direction={'row'} spacing={1} className="autocomplete-selected-box" key={index}>
                                <Typography>{element}</Typography>
                                <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                {
                                    props.onRemoveClick(index); 
                                }} />
                            </Stack>
                        );
                    })
                }
            </Box>
        </div>
    );
};

export const TimezoneSelectComponent: React.FC<ITimezoneSelectComponentProps> = (props:ITimezoneSelectComponentProps ): React.JSX.Element => 
{

    return (
        <div id="timezoneSelectComponent">
            <Autocomplete
                // defaultValue={props?.defaultValue}
                value={props?.value}
                onChange={(event, newValue: string): void => 
                {
                    props?.onChange(event, newValue); 
                }}
                className="time-zone-select"
                options={props?.optionsArr?.map((item: timezonevalues): string => 
                {
                    return item.name;
                })}
                getOptionLabel={(option: string): string => 
                {
                    return option;
                }}
                disabled={props?.disabled}
                popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                renderOption={(props, option, { selected }): React.JSX.Element => 
                {
                    return (
                        <li {...props}>
                            <Typography>{option}</Typography>
                            {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
                        </li>
                    );
                }}
                // forcePopupIcon={false}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '8px',
                            background: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '4px',
                            marginTop: '14px',
                            zIndex: 3,
                            width: '100%',
                            padding: '4px',
                            boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                            '& .MuiAutocomplete-listbox': {
                                width: '100% !important'
                            },
                            '& .MuiAutocomplete-input': {
                                maxWidth: props?.maxWidth ? props?.maxWidth : '150px',
                            },
                            '& .MuiAutocomplete-option': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                fontSize: '14px',
                                fontWeight: 500,
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                borderRadius: '8px',
                            },
                            '& .MuiAutocomplete-option.Mui-focused': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                            },
                            '& .MuiAutocomplete-option[aria-selected=true]': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                            '& .check-icon': {
                                color: '#1570EF'
                            }
                        }
                    }
                }}
                handleHomeEndKeys
                renderInput={(params) : React.JSX.Element => 
                {
                    return (
                        <div className="timezone-text-field">
                            <FontAwesomeIcon icon={['fal', 'globe']} className={props.value ? 'timezone-icon' : 'timezone-icon-default'} />
                            <TextField placeholder={props?.placeholder} {...params} />
                        </div>
                    );
                }}
            />
            {/* <FontAwesomeIcon className="clearIcon" icon={['fal', 'xmark']} onClick={props?.onClearClick} /> */}
        </div>
    );
};

export const AutocompleteComponent: React.FC<IAutocompleteComponentProps> = (props: IAutocompleteComponentProps) : React.JSX.Element => 
{

    const { storeAsObject } = props;

    return (
        <div id="autocompleteComponent">
            <Autocomplete
                defaultValue={props?.defaultValue}
                value={props?.value}
                inputValue={props?.inputValue || undefined}
                onInputChange={(event: React.SyntheticEvent, value: string) => props?.onInputChange && props?.onInputChange(event, value) || undefined}
                onChange={(event: React.SyntheticEvent, newValue: string): void => 
                {
                    props?.onChange(event, newValue); 
                }}
                disabled={props?.disabled}
                className="autocomplete-select"
                filterSelectedOptions={props?.filterSelectedOptions}
                options={props?.optionsArr?.map((item): string => 
                {
                    if(props?.keyToShow)
                    {
                        return item[props.keyToShow];
                    } 
                    else
                    {
                        return item;
                    }
                })}
                getOptionLabel={(option): string => 
                {
                    if (storeAsObject)
                    {
                        return option[storeAsObject] || '';
                    }
                    else
                    {
                        return option;
                    }
                }}
                selectOnFocus
                popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                disableClearable={props?.disableClearable ? props?.disableClearable : undefined}
                // forcePopupIcon={false}
                renderOption={(props, option, { selected }): React.JSX.Element => 
                {
                    return (
                        <li {...props}>
                            <Typography>{storeAsObject ? option[storeAsObject] : option}</Typography>
                            {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
                        </li>
                    );
                }}
                isOptionEqualToValue={(option, value) => {
                    if (storeAsObject) {
                        return option[storeAsObject] === value[storeAsObject];
                    } else {
                        return option === value;
                    }
                }}
                // forcePopupIcon={false}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '8px',
                            background: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '4px',
                            marginTop: '14px',
                            zIndex: 3,
                            width: '100%',
                            padding: '4px',
                            boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                            '& .MuiAutocomplete-listbox': {
                                width: '100% !important'
                            },
                            '& .MuiAutocomplete-input': {
                                maxWidth: props?.maxWidth ? props?.maxWidth : '150px',
                            },
                            '& .MuiOutlinedInput-input': {
                                height: 'unset !important'
                            },
                            '& .MuiAutocomplete-option': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                fontSize: '14px',
                                fontWeight: 500,
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                borderRadius: '8px',
                                '& .MuiTypography-root': {
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                }
                            },
                            '& .MuiAutocomplete-option.Mui-focused': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                            },
                            '& .MuiAutocomplete-option[aria-selected=true]': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                            '& .check-icon': {
                                color: '#1570EF'
                            }
                        }
                    }
                }}
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params): React.JSX.Element => 
                {
                    return (
                        <div className="autocomplete-text-field">
                            <TextField placeholder={props?.placeholder} {...params} />
                        </div>
                    );
                }}
            />
        </div>
    );
};

export const AutocompleteComponentWithFilterOption: React.FC<IAutocompleteComponentWithFilterOptionProps> = (props: IAutocompleteComponentWithFilterOptionProps): React.JSX.Element => 
{
    return (
        <div id="autocompleteComponent">
            <Autocomplete
                defaultValue={props?.defaultValue}
                value={props?.value}
                onChange={(event: React.SyntheticEvent, newValue: string): void => 
                {
                    props?.onChange(event, newValue); 
                }}
                className="autocomplete-select"
                options={props?.optionsArr?.map((item): string => 
                {
                    return item[props.keyToShow];
                })}
                getOptionLabel={(option: string): string => 
                {
                    return option;
                }}
                selectOnFocus
                popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                filterOptions={(options, params): string[] => 
                {
                    return props?.filterOptions(options, params); 
                }}
                // forcePopupIcon={false}
                renderOption={(props, option, { selected }): React.JSX.Element => 
                {
                    return (
                        <li {...props}>
                            <Typography>{option}</Typography>
                            {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
                        </li>
                    );
                }}
                // forcePopupIcon={false}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '8px',
                            background: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '4px',
                            marginTop: '14px',
                            zIndex: 3,
                            width: '100%',
                            padding: '4px',
                            boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                            '& .MuiAutocomplete-listbox': {
                                width: '100% !important'
                            },
                            '& .MuiAutocomplete-input': {
                                maxWidth: props?.maxWidth ? props?.maxWidth : '150px',
                            },
                            '& .MuiAutocomplete-option': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                fontSize: '14px',
                                fontWeight: 500,
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                borderRadius: '8px',
                                '& .MuiTypography-root': {
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                }
                            },
                            '& .MuiAutocomplete-option.Mui-focused': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                fontSize: '14px',
                            },
                            '& .MuiAutocomplete-option[aria-selected=true]': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                fontSize: '14px',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                            '& .check-icon': {
                                color: '#1570EF'
                            }
                        }
                    }
                }}
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params): React.JSX.Element => 
                {
                    return (
                        <div className="autocomplete-text-field">
                            <TextField placeholder={props?.placeholder} {...params} />
                        </div>
                    );
                }}
            />
        </div>
    );
};

export const AutocompleteMultiSelectComponentWithFilterOption: React.FC<ICreatableAutoCompleteComponentProps> = (props: ICreatableAutoCompleteComponentProps): React.JSX.Element => 
{
    return (
        <div id="autocompleteComponent">
            <Autocomplete
                multiple
                defaultValue={props?.defaultValue}
                value={props?.value}
                onChange={(event: React.SyntheticEvent, newValue: string[]): void => 
                {
                    props?.onChange(event, newValue); 
                }}
                className="autocomplete-select"
                options={props?.optionsArr?.map((item): string => 
                {
                    return item;
                })}
                getOptionLabel={(option: string): string => 
                {
                    return option;
                }}
                renderTags={() => null}
                selectOnFocus
                popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                filterOptions={(options, params): string[] => 
                {
                    return props?.filterOptions(options, params); 
                }}
                // forcePopupIcon={false}
                renderOption={(props, option, { selected }): React.JSX.Element => 
                {
                    return (
                        <li {...props}>
                            <Typography>{option}</Typography>
                            {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
                        </li>
                    );
                }}
                // forcePopupIcon={false}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '8px',
                            background: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '4px',
                            marginTop: '14px',
                            zIndex: 3,
                            width: '100%',
                            padding: '4px',
                            boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                            '& .MuiAutocomplete-listbox': {
                                width: '100% !important'
                            },
                            '& .MuiAutocomplete-input': {
                                maxWidth: props?.maxWidth ? props?.maxWidth : '150px',
                            },
                            '& .MuiAutocomplete-option': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                fontSize: '14px',
                                fontWeight: 500,
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                borderRadius: '8px',
                                '& .MuiTypography-root': {
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                }
                            },
                            '& .MuiAutocomplete-option.Mui-focused': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                            },
                            '& .MuiAutocomplete-option[aria-selected=true]': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                            '& .check-icon': {
                                color: '#1570EF'
                            }
                        }
                    }
                }}
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params): React.JSX.Element => 
                {
                    return (
                        <div className="autocomplete-text-field">
                            <TextField placeholder={props?.placeholder} {...params} />
                        </div>
                    );
                }}
            />
            {props?.value && props?.value.length > 0 && 
                <Box className="autocomplete-selected-box-container">
                    {
                        props?.value && props?.value.length > 0 && props?.value.map((element, index) => 
                        {
                            const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                            return (
                                <MuiChip 
                                    key={index}
                                    circleIcon
                                    chipColor={chipColors[index % chipColors.length]}
                                    label={<Stack direction={'row'} spacing={1} key={index} className="autocomplete-selected-label">
                                        <Typography>{element}</Typography>
                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                        {
                                            props?.onRemoveClick(element); 
                                        }} />
                                    </Stack>}
                                />
                            );
                        })
                    }
                </Box>
            }
        </div>
    );
};

export const AutocompletewithTags: React.FC<IAutoCompleteWithTagsProps> = (props:IAutoCompleteWithTagsProps ): React.JSX.Element => 
{    
    return (
        <div id="autocompleteWithTags">
            <Autocomplete
                defaultValue={props?.defaultValue}
                value={props?.value}
                className="autocomplete-with-tags"
                popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                multiple={true}
                disabled={props?.disabled}
                renderTags={(): null => 
                {
                    return null; 
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: any[]): void => 
                {
                    props?.onChange(event, newValue); 
                }}
                getOptionDisabled={(option): boolean => {
                    return props?.disabledOptions && props?.disabledOptions?.length > 0 ? props?.disabledOptions.includes(option) : false;
                }}
                options={props?.options && props?.options.length > 0 ? props?.options.map((option): string => 
                {
                    if(props?.keyToShow)
                    {
                        return option[props?.keyToShow];
                    }
                    else
                    {
                        return option;
                    }
                }) : []}
                renderInput={(params): React.JSX.Element => 
                {
                    return <TextField className="autocomplete-placeholder" placeholder={props?.placeholder} {...params} />;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option: string | number | any, { selected }): React.JSX.Element => 
                {
                    return (
                        <li {...props}>
                            <Typography>{option}</Typography>
                            {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
                        </li>
                    );
                }}
                // forcePopupIcon={false}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '8px',
                            background: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '4px',
                            marginTop: '14px',
                            zIndex: 3,
                            width: '100%',
                            padding: '4px',
                            boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                            '& .MuiAutocomplete-listbox': {
                                width: '100% !important'
                            },
                            '& .MuiAutocomplete-input': {
                                maxWidth: props?.maxWidth ? props?.maxWidth : '150px',
                            },
                            '& .MuiAutocomplete-option': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                fontSize: '14px',
                                fontWeight: 500,
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                borderRadius: '8px',
                                '& .MuiTypography-root': {
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                }
                            },
                            '& .MuiAutocomplete-option.Mui-focused': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                            },
                            '& .MuiAutocomplete-option[aria-selected=true]': {
                                color: 'var(--colors-text-text-primary-900, #101828)',
                                borderRadius: 'var(--spacing-sm, 6px)',
                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                            '& .check-icon': {
                                color: '#1570EF'
                            }
                        }
                    }
                }}
            />
            {props?.value && props?.value.length > 0 && <Box className="autocomplete-selected-box-container">
                {
                    props?.value && props?.value.length > 0 && props?.value.map((element, index) => 
                    {
                        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                        return (
                            <MuiChip 
                                key={index}
                                circleIcon
                                chipColor={chipColors[index % chipColors.length]}
                                label={<Stack direction={'row'} spacing={1} key={index} className="autocomplete-selected-label">
                                    <Typography>{element}</Typography>
                                    <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                    {
                                        props.onRemoveClick(index); 
                                    }} />
                                </Stack>}
                            />
                        );
                    })
                }
            </Box>}
        </div>
    );
};

export const FormControlNumberInput: React.FC<{ label: string, placeholder?: string, value: number | string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, required?: boolean, isInvalid?: boolean }> = (props: { label: string, placeholder?: string, value: number | string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, required?: boolean, isInvalid?: boolean }): React.JSX.Element => 
{
    return (
        <div id="formControlNumberInput">
            <Box className="number-input-container">
                <FormLabelComponent label={props.label} required={props?.required} noBottomMargin />
                <Form.Control
                    type="number"
                    size="lg"
                    placeholder={props?.placeholder}
                    value={props?.value}
                    onKeyDown={(evt): void => 
                    {
                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                    {
                        props?.onChange(event);
                    }}
                    className="form-control-number"
                    isInvalid={props?.isInvalid}
                />
            </Box>
        </div>
    );
};

export const RadioGroupComponent: React.FC<IRadioGroupComponentProps> = (props: IRadioGroupComponentProps): React.JSX.Element => 
{
    return(
        <div id="radioGroupComponent">
            <Box className="radio-group-container">
                <RadioGroup row={props?.row} value={props.value} onChange={(event): void => 
                {
                    return props?.onChange(event); 
                }}>
                    {
                        props?.options?.map((item, index):React.JSX.Element => 
                        {
                            return (
                                <FormControlLabel disabled={props?.disabled} className="radio-group-control" sx={{
                                    border: item.value === props?.value ? '2px solid var(--Colors-Border-border-brand-solid, #1570EF) !important' : '2px solid var(--Colors-Border-border-secondary, #EAECF0) !important',
                                }} key={index} value={item.value} control={<Radio className="radio-button" sx={{
                                    color: '#E3E4E9', '&.Mui-checked': {
                                        color: '#375DFB' 
                                    },
                                }} />} label={
                                    <Box className="radio-label-cont">
                                        {item?.icon && <div className="icon-cont">
                                            {item?.icon}
                                        </div>}
                                        <div>
                                            <Typography className="radio-control-label">{item?.name}</Typography>
                                            <Typography className="radio-control-desc">{item?.description}</Typography>
                                        </div>
                                    </Box>
                                }
                                />
                            );
                        })

                    }
                </RadioGroup>
            </Box>
        </div>
    );
};

export const FormControlCardComponent: React.FC<CardComponentProps> = ({ icon, header, helperText, cardStyle }) => {
    return (
        <div id="formControlCardComponent" className={cardStyle}>
            <div className="icon">{icon}</div>
            <div className="text-container">
                <h4 className="header">{header}</h4>
                <p className="helper-text">{helperText ? helperText : ''}</p>
            </div>
        </div>
    );
};