import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'progressColor',
})(({ theme, progressColor }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: progressColor || (theme.palette.mode === 'light' ? '#38C793' : '#38C793'),
  },
}));