import SponsorsBPPage from "./SponsorsBPPage";
import ICPPage from "./SponsorsICPPage";

const ICPAndBPPage: React.FC = (): React.JSX.Element => 
{ 
    return (
        <div id="icpAndBpPage">
            <ICPPage />
            <SponsorsBPPage />
        </div>
    )
};

export default ICPAndBPPage;