import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { CONTENT } from '../../../../scripts/i18n';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import CreateEvent from '../../../../components/Events/EventComponents/CreateEvent';
import { eventOverviewBarProps } from './eventOverview_interface';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { IntegrationTypes } from '../../../Settings/enum/integrations.enum';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { getEventRegistrantCount, getEventRegistrantStatusCount } from '../../../../scripts/apis/eventRegistrants';
import { getEventBudgetGraph } from '../../../../scripts/apis/eventBudget';
import { NumberFormatter } from '../../../../scripts/helpers';
import { UserContext } from '../../../../contexts/UserContext';
import { UserViewType } from '../../../Settings/enum/users.enum';

const EventOverviewBar: React.FC<eventOverviewBarProps> = (props: eventOverviewBarProps): React.JSX.Element => 
{

    const {
        userDetails
    } = useContext(UserContext);

    const [counts, setCounts] = useState<{
        registrants: number | string;
        checkIns: number | string;
        budget: number | string;
        expenses: number | string;
    }>({
        registrants: '-',
        checkIns: '-',
        budget: '-',
        expenses: '-'
    });

    const handleEditEvent = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EDIT.HEADING,
            component: <CreateEvent eventData={props?.eventDetails} routeFromId={false} fromOverview={true} customFields={props.customFields} isLumaIntegrated={isLumaIntegrated} />,
            hideCloseButton: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const isLumaIntegrated = props?.integrations?.some((integration): boolean => {
        return integration?.type === IntegrationTypes.LUMA;
    })

    const formatEventTiming = (eventStartDateTime: number, eventEndDateTime: number): string =>
    {
        const startDate = moment.unix(eventStartDateTime);
        const endDate = moment.unix(eventEndDateTime);
        const sameMonthYear = startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year');
        const formattedDate = sameMonthYear
            ? `${startDate.format('DD')}-${endDate.format('DD MMM YYYY')}`
            : `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`;

        return formattedDate;
    };

    const eventDetails: {name: string; value: string | number;}[] = [
        {
            name: 'Time',
            value: formatEventTiming(props?.eventDetails?.eventStartDateTime, props?.eventDetails?.eventEndDateTime)
        },
        {
            name: 'Format',
            value: CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL[props?.eventDetails?.locationType]
        },
        {
            name: 'Type',
            value: CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL[props?.eventDetails?.type]
        },
        {
            name: 'Starts in',
            value: props?.countDown || '-'
        },
        {
            name: 'Registered',
            value: counts?.registrants || '-'
        },
        {
            name: 'Check in',
            value: counts?.checkIns || '-'
        },
        {
            name: 'Budget',
            value: Number(counts?.budget) > 0 ? `$${NumberFormatter(counts?.budget)}` : '-'
        },
        {
            name: 'Expense',
            value: Number(counts?.expenses) > 0 ? `$${NumberFormatter(counts?.expenses)}` : '-'
        }
    ];

    const renderHeaderForNormalView = (): React.JSX.Element =>
    {
        return (
            <div className="header-container">
                <h3 className="header">{'Event Overview'}</h3>
                <CustomButton btnType='secondary' name='Edit Event' onClick={handleEditEvent} />
            </div>
        );
    };

    const renderHeaderForMinimalView = (): React.JSX.Element =>
    {
        return (
            <div className="header-container-minimal">
                <div>
                    <h3 className="header">{'Event Overview'}</h3>
                    <p className="description">{'Manage your Event here'}</p>
                </div>
                <CustomButton 
                    btnType="secondary"
                    name="Edit Event"
                    onClick={handleEditEvent}
                />
            </div>
        );
    };

    const fetchRegistrantCount = async (): Promise<void> =>
    {
        try 
        {
            const registrantsCount = await getEventRegistrantCount(props?.eventDetails?.id);
            if(registrantsCount)
            {
                setCounts((prevState) => ({ 
                    ...prevState,
                    registrants: registrantsCount
                }));
            }

            const statusCounts = await getEventRegistrantStatusCount(props?.eventDetails?.id);
            if(statusCounts && statusCounts.hasOwnProperty('7'))
            {
                setCounts((prevState) => ({ 
                    ...prevState,
                    checkIns: statusCounts['8']
                }));
            }

            const eventBudgetData = await getEventBudgetGraph(props?.eventDetails?.id);
            if(eventBudgetData && eventBudgetData?.trend?.spent)
            {
                setCounts((prevState) => ({ 
                    ...prevState,
                    budget: eventBudgetData?.trend?.budget || 0,
                    expenses: eventBudgetData?.trend?.spent || 0,
                }));
            }
        } 
        catch (error) 
        {
            console.log(`Error fetching registrant count: ${error}`);
        }
    };

    useEffect(() => {
        fetchRegistrantCount();
    }, []);

    return (
        <Box className={userDetails?.viewType === UserViewType.NORMAL ? "event-overview-bar" : "event-overview-bar-minimal"}>
            {
                userDetails?.viewType === UserViewType.NORMAL ? renderHeaderForNormalView() : renderHeaderForMinimalView()
            }
            <div className="overview-trends-card-container">
                <Grid container spacing={3}>
                    {
                        eventDetails?.map((item, index) => {
                            return (
                                <Grid key={index} item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <CardComponent 
                                        header={item.name}
                                        contentHeading={<p style={{ marginTop: '4px' }}>{item.value}</p>}
                                        contentHeadingBold
                                        cardMinHeight='80px'
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </Box>
    );
};

export default EventOverviewBar;