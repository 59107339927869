import { useContext } from "react";
import { GoalsContext } from "../../../../../contexts/GoalsContext";

import './styles.scss';
import { Grid, LinearProgress } from "@mui/material";
import CardComponent from "../../../../../components/Events/Registrations/CardComponent";
import { IEventGoal } from "../../../interfaces";
import { EventGoalType } from "../../../enum/event-goal.enum";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eventBus from "../../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../../scripts/constants";
import GoalsDrawerSection from "../../../../../components/Events/EventGoals/CreateGoals";
import { BorderLinearProgress } from "../../../../../common/StyledComponents/linearProgress.styled";

const GoalsCard: React.FC = (): React.JSX.Element =>
{

    const { rows, isEmpty, setRefresh, showSpinner, eventId  }  = useContext(GoalsContext);

    const openDrawer = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: `${rows.length > 0 ? 'Edit Goals' : 'Create Goals'}`,
            hideCloseButton: false,
            component: <GoalsDrawerSection eventId={Number(eventId)} goalsData={rows} setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    return (
        <div className="goals-card">
            <div className="header-container">
                <h3 className="header">{'Event Goals'}</h3>
            </div>
            <Grid container spacing={2}>
                {
                    rows && rows.length > 0 && rows?.map((item: IEventGoal, index: number) => {

                        let headerIcon: IconName = 'ticket-perforated';
                        let contentName: string = '';
                        let goalTarget: string | number = '';
                        let percentage: number = item.weightage || 0;
                        let progressColor: string = '';

                        if(item?.progress <= 49)
                        {
                            progressColor = '#F04438';
                        }
                        else if(item?.progress >= 50 && item?.progress <= 80)
                        {
                            progressColor = '#F79009';
                        }
                        else
                        {
                            progressColor = '#17B26A';
                        }


                        if(item.type === EventGoalType.REGISTRATION)
                        {
                            headerIcon = 'ticket-perforated';
                            contentName = 'Registrations';
                            goalTarget = item.target;
                        }
                        else if(item.type === EventGoalType.ATTENDEE)
                        {
                            headerIcon = 'users';
                            contentName = 'Attendee';
                            goalTarget = item.target;
                        }
                        else if(item.type === EventGoalType.PIPELINE)
                        {
                            headerIcon = 'magnet';
                            contentName = 'Pipeline';
                            goalTarget = item.target > 0 ? `$${item.target}` : '-' ;
                        }
                        else if(item.type === EventGoalType.REVENUE_RECOGNIZED)
                        {
                            headerIcon = 'dollar-sign';
                            contentName = 'Revenue Recognized';
                            goalTarget = item.target > 0 ? `$${item.target}` : '-' ;
                        }

                        const headerIconDiv = <div className="card-heading-icon-div"><FontAwesomeIcon icon={['fal', headerIcon]} /></div>

                        const progressBar = <div className="goal-progress-div"><BorderLinearProgress variant="determinate" sx={{ width: '90%' }} progressColor={progressColor} value={item?.progress > 100 ? 100 : item?.progress} /><p className="progress-value">{`${item?.progress > 100 ? 100 : item?.progress}%`}</p></div>
                        

                        return (
                            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                                <CardComponent 
                                    header={headerIconDiv}
                                    contentHeading={<div className="goals-card-content"><h3>{contentName}</h3><p>{`${item?.actual || 0} / ${goalTarget}`}</p></div>}
                                    contentHeadingBold
                                    // iconsRight={`${percentage}%`}
                                    footerLeft={progressBar}
                                    cardMinHeight="160px"
                                    footerWidth="100%"
                                    onClick={openDrawer}
                                />
                            </Grid>
                        )
                    })
                }
                {rows?.length < 4 && <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <CardComponent 
                        emptyText='+ Add Goal'
                        onClick={openDrawer}
                        cardMinHeight="160px"
                        emptyCardHeight="126px"
                    />
                </Grid>}
            </Grid>
        </div>
    )
};

export default GoalsCard;