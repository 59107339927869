import { createSlice } from '@reduxjs/toolkit';

export const registrationFormsRowSlice = createSlice({
    name: 'registrationForms',
    initialState: {
        value: {
            name: '',
            description: '',
            link: '',
            componentPosition: [''],
        }
    },
    reducers: {
        addRegistrationForms: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetRegistrationForms: (state) => {
            state.value = {
                name: '',
                description: '',
                link: '',
                componentPosition: [''],
            };
        }
    },
});

export const { addRegistrationForms, resetRegistrationForms } = registrationFormsRowSlice.actions;
export default registrationFormsRowSlice.reducer;