import React from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import './styles.scss';
import { Outlet } from 'react-router-dom';

// const styles = {
//     contentDiv: {
//         // display: 'flex',
//         width: 'calc(100% - 65px)',

//     },
// };

const AppLayout = () => 
{
    // { children }
    // const userMe = LocalStorage.get('@UserMe');
    // const isAuthenticated = useIsAuthenticated() ;
    // const [userLoggedIn, setUserLoggedIn] = useState(userMe);
    // useEffect(() =>
    // {
    //     eventBus.on('login-process-completed', (user) =>
    //     {
    //         setUserLoggedIn(user);
    //     });
        
    // }, []);

    return (
        // <Box id="appLayout">
        //     <Global/>
        //     <div className="content-div">
        //         {userLoggedIn ? <SideBarNavigation /> : ''}
        //         {/* {isAuthenticated && userLoggedIn ? <SecondarySideBar/> : ''} */}
        //         <Box className="w-100">
        //             <TopAppBar />
        //             <Box className={ userLoggedIn ? 'layout-box' : ''} sx={{
        //                 width: '100%' 
        //             }}>
        //                 {children}</Box>
        //         </Box> 
        //         {/* <Box className={ userLoggedIn ? 'layout-box w-default' : 'w-default'}> */}
        //         { userLoggedIn ? <SideDrawer/> : ''}
        //         {userLoggedIn ? <PopUp /> : ''}
        //         {userLoggedIn ? <Alerts/> : ''}
        //     </div>
        // </Box>
        <main className="App">
            <Outlet />
        </main>
    );
};

export default AppLayout;
