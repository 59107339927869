import { createSlice } from '@reduxjs/toolkit';

export const VendorsRowSlice = createSlice({
    name: 'vendors',
    initialState: {
        value: {
            name: '',
            email: '',
            url: '',
            vendorName: '',
            description: '',
            social: {
                twitter: '',
                linkedIn: '',
                facebook: '',
                invision: '',
                github: '',
                dribble: '',
            }
        }
    },
    reducers: {
        addVendors: (state, action):void => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetVendors: (state):void => 
        {
            state.value = {
                name: '',
                email: '',
                url: '',
                vendorName: '',
                description: '',
                social: {
                    twitter: '',
                    linkedIn: '',
                    facebook: '',
                    invision: '',
                    github: '',
                    dribble: '',
                }
            };
        },
    },
});

export const { addVendors, resetVendors } = VendorsRowSlice.actions;
export default VendorsRowSlice.reducer;