import { EventStage, UpdateEventStage } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const getStageCount = async (eventId: string | number, startTime?: number, endTime?: number): Promise<number> => 
{
    if (startTime && endTime) 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '/count?' + '&stageStartDateTime=' + startTime + '&stageEndDateTime=' + endTime;
        return triggerApi(API_URL, 'GET', '');
    }
    else 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/'  + eventId + '/count';
        return triggerApi(API_URL, 'GET', '');
    }
};

export const getAllStage = async (eventId: string | number, pageSize: number, offset: number, startTime?: number, endTime?: number): Promise<EventStage[]> => 
{
    if (startTime && endTime) 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '?' + '&pageSize=' + pageSize + '&offset=' + offset + '&stageStartDateTime=' + startTime + '&stageEndDateTime=' + endTime;
        return triggerApi(API_URL, 'GET', '');
    }
    else 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '?' + '&pageSize=' + pageSize + '&offset=' + offset;
        return triggerApi(API_URL, 'GET', '');
    }
};

export const getStageById = async (eventId: string, stageId: string): Promise<EventStage> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '/' + stageId;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventStage = async (eventId: string, stageData: EventStage, csrfToken: string): Promise<EventStage> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId;
    return triggerApi(API_URL, 'POST', stageData, csrfToken);
};

export const uploadStageImage = async (eventId: string, stageId: string, imageData: FormData, csrfToken: string): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '/' + stageId + '/upload-image';
    return triggerApi(API_URL, 'POST', imageData, csrfToken, false);
};

export const updateEventStage = async (eventId: string, stageId: string, stageData: UpdateEventStage): Promise<EventStage> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '/' + stageId;
    return triggerApi(API_URL, 'PUT', stageData);
};

export const deleteEventStage = async (eventId: string, stageId: string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-stage/' + eventId + '/' + stageId;
    return triggerApi(API_URL, 'DELETE', '');
};
