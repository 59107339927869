import { createSlice } from '@reduxjs/toolkit';

interface sessionState {
    value: {
        id?: number,
        title: string,
        description: string,
        additionalInfo: string,
        startDate?: string,
        startTime?: string,
        endDate?: string,
        endTime?: string,
        image: any,
        imageAlternateText: string,
        speakerIds: [],
        // ticketGroupIds: [],
        maximumParticipants: number,
        scheduled: number
    }
}

const initialState: sessionState = {
    value: {
        title: '',
        description: '',
        additionalInfo: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        imageAlternateText: '',
        image: '',
        speakerIds: [],
        // ticketGroupIds: [],
        maximumParticipants: 0,
        scheduled: 2,
    }
};

export const SessionRowSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        addSession: (state, action):void => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetSession: (state):void => 
        {
            state.value = {
                title: '',
                description: '',
                additionalInfo: '',
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                imageAlternateText: '',
                image: '',
                speakerIds: [],
                // ticketGroupIds: [],
                maximumParticipants: 0,
                scheduled: 2,
            };
        },
        removeEventSpeakers: (state, actions):void => 
        {
            state.value.speakerIds.splice(actions.payload.index, 1);
        },
        // removeTicketGroups: (state, action):void => 
        // {
        //     state.value.ticketGroupIds.splice(action.payload.index, 1);
        // },
    },
});

export const { addSession, resetSession, removeEventSpeakers } = SessionRowSlice.actions;
export default SessionRowSlice.reducer;