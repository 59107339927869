import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Charts from '../../common/ECharts/Charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpView from '../../common/PopupView';
import { getAudienceGraphData } from '../../scripts/apis/audience';
import CustomSpinner from '../../common/CustomSpinner';
import _ from 'lodash';
import TrendsComponent from '../../common/TrendsComponent';
import { getRegistrantsGraphData } from '../../scripts/apis/eventRegistrants';
import { AudienceGraphProps } from './AudienceGraphInterface';
import { IAudienceGraph } from './interfaces';
import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import APP_CONSTANTS from '../../scripts/constants';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';
import usePirschAnalyticsData from '../../hooks/usePirschAnalytics';
import moment from 'moment';

export const arrGraphXaxisData = ['$0-$1M', '$1M-$10M', '$10M-$50M', '$50M-$100M', '$100M-$250M', '$250M-$500M', '$500M-$1B', '$1B-$10B', '$10B+'];

const AudienceGraph = (props: AudienceGraphProps): React.JSX.Element => 
{

    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        icpGraph: false,
        buyerPersonaGraph: false,
        revenueGraph: false,
        registrationGraph: false
    });
    const [audienceICPData, setAudienceICPData] = useState([]);
    const [audienceBuyerPersonaData, setAudienceBuyerPersonaData] = useState([]);
    const [audienceRevenueData, setAudienceRevenueData] = useState([]);

    const [pirschGraphReady, pirschGraphData ] = usePirschAnalyticsData({
        eventId: Number(props?.eventId)
    });

    const [isChartReady, setChartReady] = useState<{
        icpGraphReady?: boolean,
        buyerPersonaGraphReady?: boolean,
        revenueGraphReady?: boolean;
    }>({
        icpGraphReady: false,
        buyerPersonaGraphReady: false,
        revenueGraphReady: false,
    });
    // const [graphHeight, setGraphHeight] = useState(0);
    // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [trendData, setTrendData] = useState(null);

    useEffect((): void => 
    {
        if (props?.eventId) 
        {
            fetchRegistrantGraphData();
        }
        else 
        {
            fetchAudienceGraphData();
        }
    }, []);

    // useEffect((): () => void => 
    // {
    //     const handleResize = (): void => 
    //     {
    //         const screenHeight = window.innerHeight;

    //         if (props?.eventRegistrantsGraph) 
    //         {

    //             const graphHeight = 0.77 * screenHeight;

    //             const containerHeight = screenHeight - graphHeight - 10;
    //             setGraphHeight(containerHeight);

    //         }
    //         else 
    //         {
    //             const seventyPercentHeight = 0.75 * screenHeight;

    //             const containerHeight = screenHeight - seventyPercentHeight;
    //             setGraphHeight(containerHeight);
    //         }

    //     };

    //     handleResize();

    //     window.addEventListener('resize', handleResize);
    //     return (): void => 
    //     {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [graphHeight]);

    const fetchAudienceGraphData = async (): Promise<void> => 
    {

        try 
        {
            const audienceGraphData: IAudienceGraph = await getAudienceGraphData();

            if (audienceGraphData.icp) 
            {
                const sum = _.sumBy(_.values(audienceGraphData.icp));
                const icpArr = Object.entries(audienceGraphData.icp).filter(([, value]): boolean => 
                {
                    return value !== 0;
                })
                    .map(([name, value]): {
                        name: string | number;
                        percentage: number;
                        value: string | number;
                    } => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        };
                    });

                setAudienceICPData([...icpArr]);
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, icpGraphReady: true
                    };
                });
            }
            else 
            {
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, icpGraphReady: true
                    };
                });
            }

            if (audienceGraphData.buyerPersona) 
            {
                const sum = _.sumBy(_.values(audienceGraphData.buyerPersona));
                const buyerPersonaArr = Object.entries(audienceGraphData.buyerPersona).filter(([, value]): boolean => 
                {
                    return value !== 0;
                })
                    .map(([name, value]): {
                        name: string | number;
                        percentage: number;
                        value: string | number;
                    } => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        };
                    });

                setAudienceBuyerPersonaData(buyerPersonaArr);
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, buyerPersonaGraphReady: true
                    };
                });
            }
            else 
            {
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, buyerPersonaGraphReady: true
                    };
                });
            }

            if (!(_.isEmpty(audienceGraphData.revenue))) 
            {
                // const ArrData = Object.entries(audienceGraphData.revenue).map(([range, value]) => value == null ? [range, 0] : [range, value]);

                const yAxisValues = Array(arrGraphXaxisData.length).fill(0);
                Object.entries(audienceGraphData.revenue).forEach(([key, value]): void => 
                {
                    const index = arrGraphXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisValues[index] = value;
                    }
                });
                const resultArray = [arrGraphXaxisData, yAxisValues];

                setAudienceRevenueData(resultArray);
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, revenueGraphReady: true
                    };
                });
            }
            else 
            {
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, revenueGraphReady: true
                    };
                });
            }

            if (audienceGraphData?.trend && (!(_.isEmpty(audienceGraphData?.trend)))) 
            {
                setTrendData(audienceGraphData?.trend);
            }

        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchRegistrantGraphData = async (): Promise<void> => 
    {
        try 
        {
            const registrantGraphData = await getRegistrantsGraphData(props?.eventId);

            if (registrantGraphData.icp) 
            {
                const sum = _.sumBy(_.values(registrantGraphData.icp));
                const icpArr = Object.entries(registrantGraphData.icp).filter(([, value]): boolean => 
                {
                    return value !== 0;
                })
                    .map(([name, value]): {
                        name: string | number;
                        percentage: number;
                        value: string | number;
                    } => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        };
                    });

                setAudienceICPData([...icpArr]);
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, icpGraphReady: true
                    };
                });
            }
            else 
            {
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, icpGraphReady: true
                    };
                });
            }

            if (registrantGraphData.buyerPersona) 
            {
                const sum = _.sumBy(_.values(registrantGraphData.buyerPersona));
                const buyerPersonaArr = Object.entries(registrantGraphData.buyerPersona).filter(([, value]): boolean => 
                {
                    return value !== 0;
                })
                    .map(([name, value]): {
                        name: string | number;
                        percentage: number
                        value: string | number;
                    } => 
                    {
                        return {
                            name,
                            percentage: Math.round((Number(value) / sum) * 100),
                            value,
                        };
                    });

                setAudienceBuyerPersonaData(buyerPersonaArr);
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, buyerPersonaGraphReady: true
                    };
                });
            }
            else 
            {
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, buyerPersonaGraphReady: true
                    };
                });
            }

            if (!(_.isEmpty(registrantGraphData.arr))) 
            {
                // const ArrData = Object.entries(registrantGraphData.revenue).map(([range, value]) => value == null ? [range, 0] : [range, value]);

                const yAxisValues = Array(arrGraphXaxisData.length).fill(0);
                Object.entries(registrantGraphData.arr).forEach(([key, value]): void => 
                {
                    const index = arrGraphXaxisData.indexOf(key);
                    if (index !== -1) 
                    {
                        yAxisValues[index] = value;
                    }
                });
                const resultArray = [arrGraphXaxisData, yAxisValues];

                setAudienceRevenueData(resultArray);
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, revenueGraphReady: true
                    };
                });
            }
            else 
            {
                setChartReady((prevState): {
                    icpGraphReady?: boolean;
                    buyerPersonaGraphReady?: boolean;
                    revenueGraphReady?: boolean;
                } => 
                {
                    return {
                        ...prevState, revenueGraphReady: true
                    };
                });
            }

            if (import.meta.env.VITE_NODE_ENV === 'demo' && registrantGraphData?.trend && (!(_.isEmpty(registrantGraphData?.trend)))) 
            {
                setTrendData(registrantGraphData?.trend);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const AllICPDataGraph = constructObjectForPieChart(audienceICPData, ['50%', '72%'], 'vertical');

    const ICPDataGraph = constructObjectForPieChart(audienceICPData, ['50%', '72%']);

    const AllBuyerPersonaDataGraph = constructObjectForPieChart(audienceBuyerPersonaData, ['50%', '72%'], 'vertical');

    const BuyerPersonaDataGraph = constructObjectForPieChart(audienceBuyerPersonaData, ['50%', '72%']);

    const xAxisKey = audienceRevenueData.length > 0 ? audienceRevenueData[0] : null;

    const ArrGraph = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis'
        },
        dataset: {
            source: audienceRevenueData
        },
        legend: {
            show: false,
            orient: 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 4],
        },
        xAxis: {
            type: 'category',
            nameGap: 35,
            data: xAxisKey,
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameGap: 35,
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            type: 'line',
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            barWidth: 15,
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                },
            },
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            data: audienceRevenueData[1],
        }]
    };

    const registrationsGraphOption = {
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: pirschGraphData?.registrations?.length > 0 && pirschGraphData?.registrations[0]?.map((date): string => {
                return moment(date).format('DD MMM');
            })
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: pirschGraphData?.registrations?.length > 0 && pirschGraphData?.registrations[1],
                type: 'line',
                smooth: true,
                itemStyle: {
                    color: '#1570EF'
                },
                // areaStyle: {
                //     color: '#1570EF'
                // },
            }
        ]
    };

    return (
        <Box id="audienceGraph" sx={{
            height: props?.eventId ? 'calc(44% - 60px)' : '44%'
        }}>
            <TopPageAnalyticsContainer
                TrendsComponent={
                    <TrendsComponent
                        Trends={[{
                            title: props?.trendsTitle,
                            value: `${Number(props.audCount) !== 0 ? props.audCount : '-'}`
                        },
                        {
                            title: 'Customers',
                            value: trendData?.customer && trendData?.customer !== '' ? trendData?.customer : '-',
                        },
                        {
                            title: 'Prospects',
                            value: trendData?.prospect && trendData?.prospect !== '' ? trendData?.prospect : '-',
                        },
                        {
                            title: 'Prospects in ICP',
                            value: props?.eventId ? trendData?.icp && trendData?.icp !== '' ? trendData?.icp : '-' : trendData?.prospectsInIcp && trendData?.prospectsInIcp !== '' ? trendData?.prospectsInIcp : '-',
                        }
                        ]}
                    />
                }
                GraphComponentsArray={
                    [
                        {
                            GraphComponent: <Box className="icp-breakdown-container" key={'icp-breakdown'}>
                                <Box className="title-container">
                                    <Typography className="graph-title">{'ICP Breakdown'}</Typography>
                                    {audienceICPData.length > 0 &&
                                        <Box className="enlarge-view" onClick={(): void => 
                                        {
                                            setOpenEnlargeGraphView({
                                                icpGraph: true, buyerPersonaGraph: false, revenueGraph: false, registrationGraph: false
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                        </Box>
                                    }
                                </Box>
                                {isChartReady.icpGraphReady ? audienceICPData.length != 0 ? (<Charts height={'100%'} chartOption={ICPDataGraph} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                            </Box>,
                            GraphSpacingBlockCSS: {
                                flex:'1 !important'
                            }
                        },
                        // {
                        //     GraphComponent: <Box className="buyer-persona-data-container" key={'bp-data'}>
                        //         <Box className="title-container">
                        //             <Typography className="graph-title">{'Buyer Persona Breakdown'}</Typography>
                        //             {audienceBuyerPersonaData.length > 0 &&
                        //                 <Box className="enlarge-view" onClick={(): void => 
                        //                 {
                        //                     setOpenEnlargeGraphView({
                        //                         icpGraph: false, buyerPersonaGraph: true, revenueGraph: false
                        //                     });
                        //                 }}>
                        //                     <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                        //                 </Box>
                        //             }
                        //         </Box>
                        //         {isChartReady.buyerPersonaGraphReady ? audienceBuyerPersonaData.length != 0 ? (<Charts height={'100%'} chartOption={BuyerPersonaDataGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />) : <Box className="noData" sx={{
                        //             height: '100%'
                        //         }}>{'No data available'}</Box> : (<CustomSpinner height={'100%'} />)}
                        //     </Box>,
                        //     GraphSpacingBlockCSS: {
                        //         flex:'1 !important'
                        //     }
                        // },
                        {
                            GraphComponent: <Box className="revenue-data-container" key={'registrations'}>
                                <Box className="title-container">
                                    <Typography className="graph-title">{props?.eventId ? 'Registrations' : 'ARR Breakdown'}</Typography>
                                    {props?.eventId && pirschGraphData?.registrations && pirschGraphData?.registrations?.length > 0 && 
                                        <Box className="enlarge-view" onClick={(): void => 
                                        {
                                            setOpenEnlargeGraphView({
                                                icpGraph: false, buyerPersonaGraph: false, revenueGraph: false, registrationGraph: true
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                        </Box>
                                    }
                                    {
                                        !props?.eventId && audienceRevenueData.length > 0 &&
                                        <Box className="enlarge-view" onClick={(): void => 
                                        {
                                            setOpenEnlargeGraphView({
                                                icpGraph: false, buyerPersonaGraph: false, revenueGraph: true, registrationGraph: false
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                        </Box>
                                    }
                                </Box>
                                {!props?.eventRegistrantsGraph ? isChartReady.revenueGraphReady ? audienceRevenueData.length > 0 ? (<Charts height={'100%'} chartOption={ArrGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="1px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />) : null}
                                {props?.eventRegistrantsGraph ? props?.eventId && pirschGraphReady?.registrations ? pirschGraphData?.registrations && pirschGraphData?.registrations?.length > 0 ? (<Charts height={'100%'} chartOption={registrationsGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="1px" />) : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />) : null}
                            </Box>,
                            GraphSpacingBlockCSS: {
                                flex:'2 !important'
                            }
                        }
                    ]
                }
            />

            {openEnlargeGraphView.icpGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        icpGraph: false, buyerPersonaGraph: false, revenueGraph: false, registrationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'ICP Breakdown'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllICPDataGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.buyerPersonaGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        icpGraph: false, buyerPersonaGraph: false, revenueGraph: false, registrationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Buyer Persona Breakdown'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={AllBuyerPersonaDataGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.revenueGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        icpGraph: false, buyerPersonaGraph: false, revenueGraph: false, registrationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'ARR Breakdown'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={ArrGraph} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}

            {openEnlargeGraphView.registrationGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        icpGraph: false, buyerPersonaGraph: false, revenueGraph: false, registrationGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Registrations'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={registrationsGraphOption} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}
        </Box>
    );
};

export default AudienceGraph;


export const calculateContainerHeightForAudience = (props): number => 
{

    const screenHeight = props.windowHeight;

    let containerHeight = 0;

    if (screenHeight <= 990) 
    {
        const availableHeight = 0.72 * screenHeight;

        containerHeight = screenHeight - availableHeight;


    }
    else 
    {
        const availableHeight = 0.65 * screenHeight;

        containerHeight = screenHeight - availableHeight;
    }

    return containerHeight;
};